<template>
  <Field        
    v-bind:value="value"
    v-on:input="$emit('input', $event.target.value)"
    v-on:focus="selectOnFocus && $event.target.select()"
    :name="name || ''" 
    :id="name || ''" 
    :label="label" 
    autocomplete="off"
    class="form-control"
    as="input"
    :required="$attrs.as=='select' && $attrs.placeholder"
    v-bind="$attrs"
  >
    <slot name="field"></slot>
    <option v-if="$attrs.as=='select' && $attrs.placeholder" value="" disabled selected hidden>{{$attrs.placeholder}}</option>
    <option v-if="addClearOption">{{ clearOptionText || '-- Any --'  }}</option>
    <template v-if="data && data.length">
      <option  v-for="dto in data" :key="dto[valueField]" :value="dto[valueField]">{{dto[displayField]}}</option>
    </template>
  </Field>
</template>

<script>

  import { Field} from "vee-validate";

  export default {
    name: 'BaseField',
    inheritAttrs: false,
    props: {
      name: String, 
      label: String, 
      value: String,
      data: Array, 
      addClearOption: Boolean,
      clearOptionText: String,
      valueField: { 
        type: String, 
        default: 'id' 
      }, 
      displayField: { 
        type: String, 
        default: 'name' 
      },
      selectOnFocus: Boolean
    },
    components: {
      Field
    }    
  }

</script>

<style scoped>

html.white-theme select option[value=""], select:invalid {
  color: #bbb;
}

html.white-theme select option {
  color: #212529;
}


</style>