<template>

  <base-modal :name="modalName" :title="modalName" v-bind="$attrs" :width="600" @close="close" style="z-index:99999;">
    <template #body> 
      <div v-if="modalName=='Report SQL' || modalName=='Report JSON'" id="col-scripts" style="max-height:400px !important; overflow-y:scroll;">
        <pre id="col-html"></pre>
      </div>
      <div v-if="modalName=='Report configuration share'" id="col-html" class="row" style="max-height:400px !important; overflow-y:hidden;">
        <div class="col-8">
          Copy guid/link below and send/share it with other user(s).
          <Form @submit="onSubmit" ref="form" class="form-horizontal form-bordered">
            <FormField name="guid-string" v-model="shareGuid" type="text" label="" :topLabel="true" style="min-width:300px;" />
          </Form>
        </div>
        <div class="col-4"></div>
      </div>
      <div v-else id="col-html" style="max-height:400px !important; overflow-y:scroll;"></div>
    </template>
    <template #footer>
      <button v-if="objDescr=='model-info'" type="button" @click.prevent="print" class="btn btn-secondary">Print</button>
      <button v-if="modalName=='Report SQL' || modalName=='Report JSON' || modalName=='Report SQL raw' || modalName=='Report JSON raw'" type="button" @click.prevent="copy" class="btn btn-secondary">Copy</button>
      <button v-if="modalName=='Report configuration share'" type="button" @click.prevent="copyGuid" class="btn btn-secondary">Copy</button>
      <button type="button" @click.prevent="close" class="btn btn-primary">Close</button>
    </template>
  </base-modal>

</template>

<script>
import BaseModal from '@/App/components/Modal/BaseModal';
import FormField from '@/App/components/Form/FormField';
import { Form } from "vee-validate";
import _app from '@/App/App'

export default {
  name: 'ReportingInfoModal',
  inheritAttrs: false,
  //emits: ['ok'],
  props: {
    title: String,
    html: String,
    objDescr: String
  },
  components: {
    BaseModal,
    FormField,
    Form,
  }, 
  data() {
    return {
      modalName: this.title,
      shareGuid: null,
      scriptTry: 0,
    };
  },
  mounted() {
    let div = document.getElementById('col-scripts');

    if(this.modalName=='Report SQL') {  
      const script = document.createElement('script');
      try {
        script.src = 'https://unpkg.com/sql-formatter@2.3.3/dist/sql-formatter.min.js';
        div.appendChild(script);
      } catch(err) {
        console.log(err);
      }
    }

    if(this.modalName=='Report JSON') {  
      const script = document.createElement('script');
      try {
        script.src = 'https://unpkg.com/format-to-json@3.0.3/fmt2json.min.js';
        div.appendChild(script);
      } catch(err) {
        console.log(err);
      }
    }

    let delay = (this.modalName=='Report SQL' || this.modalName=='Report JSON') ? 300 : 0;
    setTimeout(() => {
        this.doLayout(this.html, this.objDescr);
    }, delay);
  },
  methods: {
    checkScriptSql(modalHtml) {
      if(this.scriptTry==10)
        return false;

      try {
        let format = window.sqlFormatter.format;
        if(typeof format === 'undefined') {
          this.scriptTry++;
          window.setTimeout(this.checkScriptSql(modalHtml), 100); //check for formatter every 100 milliseconds
        } 
        else
          document.getElementById('col-html').innerText = format(modalHtml);
      }
      catch(err) {
        this.scriptTry++;
        window.setTimeout(this.checkScriptSql(modalHtml), 100); //check for formatter every 100 milliseconds
      }
    },
    checkScriptJson(modalHtml) {
      if(this.scriptTry==10)
        return false;

      try {
        let fmt2json = window.fmt2json;
        if(typeof fmt2json === 'undefined') {
          this.scriptTry++;
          window.setTimeout(this.checkScriptJson(modalHtml), 100); //check for formatter every 100 milliseconds
        }
        else
          document.getElementById('col-html').innerText = fmt2json(modalHtml);
      }
      catch(err) {
        this.scriptTry++;
        window.setTimeout(this.checkScriptJson(modalHtml), 100); //check for formatter every 100 milliseconds
      }
    },
    doLayout(modalHtml, modalObj) {
      if(this.modalName=='Report SQL') {
        this.scriptTry = 0;
        this.checkScriptSql(modalHtml);
        /*
        var format = window.sqlFormatter.format;
        if(typeof format === 'undefined') {
          setTimeout(() => {
            document.getElementById('col-html').innerText = format(modalHtml);
          }, 500);
        } else
          document.getElementById('col-html').innerText = format(modalHtml);
        */
      } else if(this.modalName=='Report JSON') {
        this.scriptTry = 0;
        this.checkScriptJson(modalHtml);
        /*
        var fmt2json = window.fmt2json;
        if(typeof fmt2json === 'undefined') {
          setTimeout(() => {
            document.getElementById('col-html').innerText = fmt2json(modalHtml);
          }, 500);
        } else
          document.getElementById('col-html').innerText = fmt2json(modalHtml);
        */
      } else if(this.modalName=='Report configuration share') {
        this.shareGuid = modalHtml;
      } else
        document.getElementById('col-html').innerHTML += modalHtml;
    },
    close() {
      this.$vfm.hide(this.modalName);
    },
    copy() {
      let text = document.getElementById('col-html').innerText;
      _app.$helper.copyToClipboard(text);
    },
    copyGuid() {
      let text = document.getElementById('guid-string').value;
      _app.$helper.copyToClipboard(text);
    },
    print() {
      let divContents = document.getElementById('col-html').innerHTML,
          a = window.open('', '', 'height=768, width=1024');
      
      a.document.write('<html><body >');
      a.document.write('<h2>Reporting <u>' + this.modalName + '</u></h2>');
      a.document.write(divContents);
      a.document.write('</body></html>');
      a.document.close();
      a.print();
    },
    onSubmit(values) {
      return false;
    },
  }
}
</script>

<style scoped>

</style>

<style>
.eqjs-query-text-yellow {
    color: #FFCE19;
}
.eqjs-query-text-purple {
    color: #FF19FF;
}
.eqjs-query-text-underline {
    text-decoration: underline;
}
</style>