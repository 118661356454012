<template>
	<div class="ctx-menu-container dropdown-menu shadow" ref="container" :style="{
		'display': isOpen ? 'inline-block' : 'none',
		'left': `${position.x}px`,
		'top': `${position.y}px`,
	}">
		<slot :ctx="ctx">
      <a v-for="(item, index) in items" :key="index" class="dropdown-item" href="#" @click.prevent="onMenuItemClick(item, $event)"><i class="fa" :class="item.iconCls"></i>{{item.text}}</a>
    </slot>
	</div>
</template>

<script>
	export default {
    name: 'ContextMenu',
		data() {
			return {
        items: [],
				isOpen: false,
				position: {
					x: 0,
					y: 0,
				},
				ctx: null
			};
		},

    unmounted() {
      if (this.docClickListener)
        this.stopDocClickListener();
    },

		methods: {
			open(items, event, ctx) {

				if (event) {
					this.position.x = event.pageX;
					this.position.y = event.pageY;
				}
				
        this.items = items || [];
				this.ctx = ctx;
				this.isOpen = true;

        event.stopPropagation();

        this.startDocClickListener();
			},

			close() {        
				this.isOpen = false;

        this.stopDocClickListener();
			},

      startDocClickListener() {

        if (this.docClickListener)
          this.stopDocClickListener();

        this.clickListener = document.addEventListener('click', this.onDocClick);
      },

      stopDocClickListener() {

        if (this.docClickListener == null)
          return;

          document.removeEventListener("click", this.docClickListener);

          this.docClickListener = null;
      },

      onDocClick(e) {

				if (!this.$refs.container || !this.isOpen)
					return;

				const insideMenu = this.$refs.container.contains(e.target);

				if (!insideMenu)
					this.close();
      },

      onMenuItemClick(item, e) {
        if (item.handler)
          item.handler(this.ctx, item, e);

        this.close();
      }
		}
	};
</script>

<style scoped>
	.ctx-menu-container {
		position: absolute;
	}
</style>