<template>
  <div>
  <ToolbBar v-if="false" style="border-top-width:0px;border-bottom-width:0px;">    
    <label class="label" style="padding-right:20px;">Metrics</label>
    <BaseButton text="Add metric" />
  </ToolbBar>
  <BaseGrid ref="grid" :columns="gridData.columns" :rows="gridData.rows" @cell-click="onMetricCellClick" @value-change="onMetricChange" :striped="false" :enableVScroll="true" class="metrics-grid table-sm"/>
  </div>
</template>

<script>
  import ToolbBar from '@/App/components/Common/ToolBar'
  import BaseGrid from '@/App/components/Grid/BaseGrid'
  import BaseButton from '@/App/components/Common/BaseButton'

  /* APEX CHARTS basic colors:
    blue = rgb(0, 143, 251) || #008efb
    green = rgb(0, 227, 150) || #00e396
    orange = rgb(254, 176, 25) || #feb019
    red = rgb(255, 69, 96) || #ff4560
    purple = rgb(119, 93, 208) || #775dd0

    BZ: changing to Infinitus initial color scheme
    green = #00FFD7
    orange = #feb019
    purple = #ff19ff
    blue = #1485cc
    red = #ff4560
  */
  //const _defaultColors = ['#008efb','#00e396','#feb019','#ff4560','#775dd0'];
  const _defaultColors = ['#00FFD7','#feb019','#1485cc','#ff4560','#ff19ff'];

  export default {
    name: 'MetricsView',
    components: {
      ToolbBar,
      BaseButton,
      BaseGrid
    },
    props: {
      state: Object
    },
    emits:['add-metric', 'remove-metric', 'edit-metric', 'metric-color-change', 'metric-y-axis-change'],
    data() {
      return {
        gridData: {
          columns: [
            {
              title: 'Device',
              dataIndex: 'deviceName',
              style: 'cursor:pointer;',
              width: 200
            },
            {
              title: 'Metric',
              dataIndex: 'metricName',
              style: 'cursor:pointer;'
            },     
            {
              title: 'Color',
              dataIndex: 'color',
              type: 'ColorPicker',
              align: 'center',              
              width: 70
            },
            {
              title: 'y - axis',
              dataIndex: 'yAxis',
              width: 100,
              hdAlign: 'center',
              type: 'ButtonGroup',
              valueType: 'int',
              buttons: [
                {
                  text: 'left',
                  value: 1,
                  width: 45
                },
                {
                  text: 'right',
                  value: 2,
                  width: 45
                }
              ]
            },            
            // actions
            {
              width:80,
              actions: [
                {
                  text: 'Remove',
                  handler: this.removeMetric
                }
              ]
            }
          ],
          rows: [],
          enableVScroll: true
        }
      }
    },
    mounted() {
      if (this.state.metrics)
        this.gridData.rows = this.state.metrics;
    },
    methods: {  
      getMetrics() {
        return this.gridData.rows;
      },
      addMetric(data, removeExisting) {

        if (removeExisting)
        this.gridData.rows.length = 0;

        var existing = this.gridData.rows.filter(i => i.deviceId == data.deviceId && i.metricId == data.metricId);

        if (existing.length > 0) {
          this.$emit('add-metric', existing[0]);
          return;
        }

        data.color = this.getNewMetricColor();
        data.yAxis = this.getNewMetricYAxis();

        this.gridData.rows.push(data);

        this.$emit('add-metric', data);
      },   
      removeMetric(rec) {
        const index = this.gridData.rows.indexOf(rec);
        if (index !== -1) {
          this.gridData.rows.splice(index, 1);
        }        
        this.$emit('remove-metric', rec);
      },
      onMetricChange(rec, col, value) {
        switch(col.dataIndex) {
          case 'color':
            this.$emit('metric-color-change', rec, value);
            break;
          case 'yAxis':
            this.$emit('metric-y-axis-change', rec, value);
            break;
        }
      },
      onMetricCellClick(row, col, event) {
        if (col.dataIndex == 'deviceName' || col.dataIndex == 'metricName')
          this.$emit('edit-metric', row);
      },
      getNewMetricColor() {  
        const dic = this.$app.$helper.toDictionary(this.gridData.rows, 'color');
        for (let i = 0; i < _defaultColors.length; i++) {
          const color = _defaultColors[i];
          if (!dic[color])
            return color;
        }
        return _defaultColors[_defaultColors.length - 1];
      },
      getNewMetricYAxis() {
        return 1;
      }
    }
  }
</script>

<style scoped>
  .metrics-grid tr td {
    vertical-align: middle;
  }
</style>