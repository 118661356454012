import axios from 'axios'
import AuthService from './Auth/auth.service';
import AppHelper from '@/App/AppHelper';

import store from '../store';
import router from '../router';

const _headers = {
  'Content-Type': 'application/json'
};

const _instance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  //timeout: 1000 * 60 * 10,
  headers: _headers
});

// Add a request interceptor
_instance.interceptors.request.use(
  function (config) {
    var token = AuthService.getToken();
    if (token)
      config.headers.Authorization = 'Bearer ' + token;
    return config;
  }, 
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

const _isJsonBlob = (data) => data instanceof Blob && data.type === "application/json";

// Add a response interceptor
_instance.interceptors.response.use(
  function (response) {
    return response;
  }, 
  async function (error) {   
    
    try {

      if (error.response?.status == 401 && router.currentRoute?.value?.name == 'login')
        return;

      const responseData = _isJsonBlob(error.response?.data) ? await (error.response?.data)?.text() : error.response?.data || null;
      
      const responseJson = (typeof responseData === "string") ? JSON.parse(responseData) : responseData;

      AppHelper.notifyError(responseJson?.message || error.message);

      if (process.env.NODE_ENV == 'development') 
        console.log(responseJson?.message || error.message);
    }
    catch(ex) {
      console.log(ex)
    }
    
    if (error.response?.status == 401) {
      store.dispatch('auth/logout');
      router.push('/login');    
    }
    return Promise.reject(error);
  }
);

export default _instance;
