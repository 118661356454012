<template>
  <div class="inf-card">
    <VScroll ref="maskEl">
      <!-- display settings -->
      <BaseForm @submit="applyDisplaySettings" :state="state" :validation-schema="schema">
        <FormSection ref="displaySection" title="Display settings">
          <template #body>
            <FormFieldGroup v-if="this.displayComponents.length > 1" fieldName="displaySite" label="Display">
              <div class="btn-group align-items-center" style="width:auto" role="group" @change="onDisplayChange">
                <template v-for="display in displayComponents" :key="display.number">
                  <input type="radio" class="btn-check" name="display" :id="'display-' + display.number" :value="display.number" autocomplete="off" :checked="display.number == displayNumber">
                  <label class="btn btn-outline-yellow" :for="'display-' + display.number">
                    {{display.label}}
                  </label>
                </template>
              </div> 
            </FormFieldGroup>            

            <FormOnOffGroupField :value="state.displayOn" @change="onTurnDisplayCheckChange" name="displayOn" label="Turn display" />
            <FormOnOffGroupField v-if="this.displayHasTouchScreen" :value="state.touchScreenEnabled" @change="onTouchScreenEnableChange" name="touchScreenEnabled" label="Touch screen" onBtnText="Enable" offBtnText="Disable" />
            <FormSliderField pbClass="pb-2" :value="state.backlightLevel" @change="onBacklightLevelChange" name="backlightLevel" label="Backlight level" />
            <FormSliderField pbClass="pb-2" :value="state.gammaValue" @change="v => state.gammaValue = v" :min="1.8" :max="2.6" :step="0.2" name="gammaValue" label="GAMMA value" />
            <FormSliderField pbClass="pb-2" :value="state.redColorBalance" @change="v => state.redColorBalance = v" name="redColorBalance" label="Red color balance" />
            <FormSliderField pbClass="pb-2" :value="state.greenColorBalance" @change="v => state.greenColorBalance = v" name="greenColorBalance" label="Green color balance" />
            <FormSliderField pbClass="pb-2" :value="state.blueColorBalance" @change="v => state.blueColorBalance = v" name="blueColorBalance" label="Blue color balance" />
            <FormSliderField pbClass="pb-2" :value="state.brightness" @change="v => state.brightness = v" name="brightness" label="Black level" />
            <FormGroupRow class="mt-3">
              <BaseButton type="submit" text="Apply settings" loadingText="Applying settings..." :loading="state.applyingDisplaySettings"></BaseButton>
              <!--<BaseButton text="test" @click="getData()"></BaseButton>-->
            </FormGroupRow> 
          </template>
        </FormSection>  
      </BaseForm>

      <!-- ambient sensor -->
      <BaseForm @submit="applyAmbientSensorSettings" :state="state" :validation-schema="schema">
        <FormSection title="Ambient light sensor">
          <template #body>
            <FormField v-model.number="state.brightnessLevel" name="brightnessLevel" label="Current ambient brightness level (lux)" disabled style="width:70px;text-align:center" />
            <FormOnOffGroupField :value="state.backlightAutomaticStatus" @change="v => state.backlightAutomaticStatus = v" name="backlightAutomaticStatus" label="Adjust backlight level automatically" onBtnText="Enable" offBtnText="Disable" />
            <FormDualSliderField 
              label="Min/Max backlight (%)" 
              name="ambientSensorBacklightRange" 
              fromLabel="Min (%)" toLabel="Max (%)" 
              :inputWidth="90" 
              :fromValue="state.ambientSensorMinBacklight" 
              :toValue="state.ambientSensorMaxBacklight" 
              :currentValue="50"
              @from-value-change="v => state.ambientSensorMinBacklight = v" 
              @to-value-change="v => state.ambientSensorMaxBacklight = v">
            </FormDualSliderField>
            <FormDualSliderField 
              label="Min/Max ambient light (lux)" 
              name="ambientSensorAmbientLightRange" 
              fromLabel="Min (LUX)" 
              toLabel="Max (LUX)" 
              :fromValue="state.ambientSensorMinAmbientLight" 
              :toValue="state.ambientSensorMaxAmbientLight" 
              :inputWidth="90" 
              :max="100000" 
              scaleType="LUX" 
              :currentValue="50" 
              @from-value-change="v => state.ambientSensorMinAmbientLight = v" 
              @to-value-change="v => state.ambientSensorMaxAmbientLight = v">
            </FormDualSliderField>

            <FormGroupRow class="mt-3">
              <BaseButton type="submit" text="Apply settings" loadingText="Applying settings..." :loading="state.applyingAmbientSensorSettings"></BaseButton>
            </FormGroupRow>
          </template>
        </FormSection>
      </BaseForm> 

      <!-- turn display off scheduler -->
      <BaseForm @submit="applyTurnDisplayOffSettings" :state="state" :validation-schema="schema">
      <FormSection :title="displayComponents.length > 1 ? 'Turn displays off' : 'Turn display off'">
        <template #body>
          <FormFieldGroup label="Workdays" class="d-flex align-items-center">
            <div class="col-sm-8 d-flex align-items-center">
            <label class="me-2">From</label>
            <BaseField v-model="state.wdTimeOffFrom" name="wdTimeOffFrom" required type="time" style="width:90px;" />
            <label class="mx-2">To</label>
            <BaseField v-model="state.wdTimeOffTo" name="wdTimeOffTo" required type="time" style="width:90px;" />
            <BaseButton text="Clear" @click="state.wdTimeOffFrom=null;state.wdTimeOffTo=null;" class="btn-secondary ms-3"></BaseButton>
            </div>
          </FormFieldGroup>
          <FormFieldGroup label="Weekends" class="d-flex align-items-center">
            <div class="col-sm-8 d-flex align-items-center">
            <label class="me-2">From</label>
            <BaseField v-model="state.wkndTimeOffFrom" name="wkndTimeOffFrom" required type="time" style="width:90px;" />
            <label class="mx-2">To</label>
            <BaseField v-model="state.wkndTimeOffTo" name="wkndTimeOffTo" required type="time" style="width:90px;" />
            <BaseButton text="Clear" @click="state.wkndTimeOffFrom=null;state.wkndTimeOffTo=null;" class="btn-secondary ms-3"></BaseButton>
            </div>
          </FormFieldGroup>    
          <FormFieldGroup label="Holidays" class="d-flex align-items-center">
            <div class="col-sm-8 d-flex align-items-center">
            <label class="me-2">From</label>
            <BaseField v-model="state.holTimeOffFrom" name="holTimeOffFrom" required type="time" style="width:90px;" />
            <label class="mx-2">To</label>
            <BaseField v-model="state.holTimeOffTo" name="holTimeOffTo" required type="time" style="width:90px;" />
            <BaseButton text="Clear" @click="state.holTimeOffFrom=null;state.holTimeOffTo=null;" class="btn-secondary ms-3"></BaseButton>
            </div>
          </FormFieldGroup> 
          <FormGroupRow class="mt-3">
            <BaseButton type="submit" text="Apply settings" loadingText="Applying settings..." :loading="state.applyingTurnDisplayOffSettings"></BaseButton>
          </FormGroupRow>         
        </template>
      </FormSection>
      </BaseForm>

      <!-- UPS (Skyss) -->
      <FormSection v-if="upsComponent" title="UPS">
        <template #body>
          <FormFieldGroup label="UPS Safe Shutdown">
            <div class="col-sm-8">
              <BaseButton @click="upsShutdown()" text="UPS shutdown" class="btn-danger" loadingText="UPS shutdown in progress..." :loading="state.upsShutdown"></BaseButton>
            </div>      
          </FormFieldGroup>
          <FormFieldGroup label="Group 1">
            <div class="col-sm-8">
              <BaseButton @click="upsResetGroup(1)" text="Reset" class="btn-danger" loadingText="Reset in progress..." :loading="state.upsResetGroup1"></BaseButton>
            </div>      
          </FormFieldGroup>
          <FormFieldGroup label="Group 2">
            <div class="col-sm-8">
              <BaseButton @click="upsResetGroup(2)" text="Reset" class="btn-danger" loadingText="Reset in progress..." :loading="state.upsResetGroup2"></BaseButton>
            </div>      
          </FormFieldGroup>
        </template>
      </FormSection>

      <!-- Network switch -->
      <template v-for="component in networkSwitchComponents" :key="component.number">
        <FormSection :title="component.label">
          <template #body>
            <FormFieldGroup :label="component.labelReset">
              <div class="col-sm-8">
                <BaseButton :name="component.id + '_reset'" @click="resetRouter(component)" text="Reset" class="btn-danger" loadingText="Reset in progress..." :loading="state['resettingRouter' + component.number]"></BaseButton>
              </div>      
            </FormFieldGroup>
          </template>
        </FormSection>
      </template>      

      <!-- Network router - Network router with relay -->
      <template v-for="component in networkRouterWithRelayComponents" :key="component.number">
        <FormSection :title="component.label">
          <template #body>
            <FormFieldGroup :label="component.labelReset">
              <div class="col-sm-8">
                <BaseButton :name="component.id + '_reset'" @click="resetNetworkRouterWithRelay(component)" text="Reset" class="btn-danger" loadingText="Reset in progress..." :loading="state['resettingNetworkRouterWithRelay' + component.number]"></BaseButton>
              </div>      
            </FormFieldGroup>                        
            <FormOnOffGroupField :name="component.id + '_onoff'" :value="state['networkRouterWithRelay' + component.number + 'Status']" :ref="'networkRouterWithRelay' + component.number + 'Toggle'"  :label="component.labelPort1" @change="setNetworkRouterWithRelayOnOff(component, $event)" :loading="state['togglingNetworkRouterWithRelay' + component.number]">
              <BaseButton :name="component.id + '_reset_port'" @click="resetNetworkRouterWithRelayPort(component)" text="Reset" class="btn-danger" style="width:auto;" loadingText="Reset in progress..." :loading="state['resettingNetworkRouterWithRelayPort' + component.number]"></BaseButton>
            </FormOnOffGroupField>
          </template>
        </FormSection>
      </template>      

      <!-- Network relay (Power switch)-->
      <template v-for="component in networkRelayComponents" :key="component.number">        
        <FormSection :title="component.label">
          <template #body>
            <template v-if="component.optionPort1 == 'FULL'">
              <FormOnOffGroupField :name="component.id + '_1_onoff'" :value="state['networkRelay_' + component.number + '_1_Status']" :ref="'networkRelay_' + component.number + '_1_Toggle'" :label="component.labelPort1" @change="setNetworkRelayOnOf(component, 1, $event)" :loading="state['togglingNetworkRelay_' + component.number + '_1']">
                <BaseButton :name="component.id + '_1_reset'" @click="resetNetworkRelay(component, 1)" text="Reset" class="btn-danger" style="width:auto;" loadingText="Reset in progress..." :loading="state['resettingNetworkRelay_' + component.number + '_1']"></BaseButton>
              </FormOnOffGroupField>
            </template>
            <template v-else>
              <FormFieldGroup :label="component.labelPort1">
                <div class="col-sm-8">
                  <BaseButton :name="component.id + '_1_reset'" @click="resetNetworkRelay(component, 1)" text="Reset" class="btn-danger" loadingText="Reset in progress..." :loading="state['resettingNetworkRelay_' + component.number + '_1']"></BaseButton>
                </div>      
              </FormFieldGroup>                               
            </template>
            <template v-if="component.optionPort2 == 'FULL'">
              <FormOnOffGroupField :name="component.id + '_2_onoff'" :value="state['networkRelay_' + component.number + '_2_Status']" :ref="'networkRelay_' + component.number + '_2_Toggle'" :label="component.labelPort2" @change="setNetworkRelayOnOf(component, 2, $event)" :loading="state['togglingNetworkRelay_' + component.number + '_2']">
                <BaseButton :name="component.id + '_2_reset'" @click="resetNetworkRelay(component, 2)" text="Reset" class="btn-danger" style="width:auto;" loadingText="Reset in progress..." :loading="state['resettingNetworkRelay_' + component.number + '_2']"></BaseButton>
              </FormOnOffGroupField>
            </template>
            <template v-else>
              <FormFieldGroup :label="component.labelPort2">
                <div class="col-sm-8">
                  <BaseButton :name="component.id + '_2_reset'" @click="resetNetworkRelay(component, 2)" text="Reset" class="btn-danger" loadingText="Reset in progress..." :loading="state['resettingNetworkRelay_' + component.number + '_2']"></BaseButton>
                </div>      
              </FormFieldGroup> 
            </template>          
          </template>
        </FormSection>
      </template>      
    </VScroll>
  </div>
</template>

<script>

import _app from '@/App/App.js';
import FormField from '@/App/components/Form/FormField';
import FormSection from '@/App/components/Form/FormSection';
import FormFieldGroup from '@/App/components/Form/FormFieldGroup';
import BaseField from '@/App/components/Form/BaseField';
import BaseForm from '@/App/components/Form/BaseForm';
import FormSliderField from '@/App/components/Form/FormSliderField';
import FormGroupRow from '@/App/components/Form/FormGroupRow';
import BaseButton from '@/App/components/Common/BaseButton';
import FormOnOffGroupField from '@/App/components/Form/FormOnOffGroupField';
import FormDualSliderField from '@/App/components/Form/FormDualSliderField'
import VScroll from '@/App/components/Common/VScroll'
import _this from '@/App/AppHelper';

export default {
  name: 'GenericDeviceSetup',
  props:['device'],
  components: {
    FormField,
    FormSection,
    FormFieldGroup,
    BaseField,
    BaseForm,
    FormSliderField,
    FormGroupRow,
    BaseButton,
    FormOnOffGroupField,
    FormDualSliderField,
    VScroll
  },    
  data() {
    return {
      schema: {
        // name: 'required'
      },
      state: _app.$data.getState('DeviceSetupState', {
        deviceId: this.device?.id,
        displayOn: true,
        touchScreenEnabled: true,          
        backlightLevel: 50,
        blueColorBalance: 50,
        brightness: 50,
        gammaValue: 2.2,
        greenColorBalance: 50,
        redColorBalance: 50,
        
        backlightAutomaticStatus: true,
        ambientSensorMinBacklight: 50,
        ambientSensorMaxBacklight: 100,        
        ambientSensorMinAmbientLight: 0,
        ambientSensorMaxAmbientLight: 10000        
      }),
      deviceComponents: null,
      displayNumber: 1,
      evTypeState: null,
      deviceLocalSettings: null
    };
  },  
  mounted() {
    if (this.device)
      this.getData(true);
  },
  watch: {
    device() {
      if (this.device)
        this.getData(true);
    }
  },   
  computed: {
    displayComponents: function() {
      return this.deviceComponents?.filter(obj => obj.categoryId === _app.$data.DeviceComponentCategory.Display) || [];
    },
    upsComponent: function() {
      const filter = this.deviceComponents?.filter(obj => obj.categoryId === _app.$data.DeviceComponentCategory.UPS) || [];
      return filter.length == 0 ? null : filter[0];
    },
    networkSwitchComponents: function() {
      return this.deviceComponents?.filter(obj => obj.categoryId === _app.$data.DeviceComponentCategory.NetworkSwitch) || [];
    },
    networkRouterWithRelayComponents: function() {
      return this.deviceComponents?.filter(obj => obj.categoryId === _app.$data.DeviceComponentCategory.NetworkRouterWithRelay) || [];
    },
    networkRelayComponents: function() {
      return this.deviceComponents?.filter(obj => obj.categoryId === _app.$data.DeviceComponentCategory.NetworkRelay) || [];
    },
    touchMatrixComponents: function() {
      return this.deviceComponents?.filter(obj => obj.categoryId === _app.$data.DeviceComponentCategory.TouchMatrix) || [];        
    },
    displayHasTouchScreen() {
      return !!this.displayComponents.find(obj => obj.number === this.displayNumber)?.touchMatrix;
    }
  },
  methods: {
    onDisplayChange(e) {
      this.displayNumber = parseInt(e.target.value);

      this.showDisplayAndAlsData();
    },
    onTurnDisplayCheckChange(on) {

      this.state.backlightLevel = on ? 100 : 0;

      this.state.displayOn = this.state.backlightLevel > 0;

      if (on && !this.state.backlightAutomaticStatus) {
        _app.$modal.alert('Please enable the ambient light sensor','Please enable the ambient light sensor, if you want automatic backlight regulation.');
      } else if (!on && this.state.backlightAutomaticStatus) {
        _app.$modal.alert('Please disable the ambient light sensor', 'In order to ensure that the display remains turned off, it is necessary to disable the ambient light sensor.');
      }      
    },
    onTouchScreenEnableChange(enable) {
      this.state.touchScreenEnabled = enable;
    },
    onBacklightLevelChange(value) {
      this.state.backlightLevel = value;
      this.state.displayOn = this.state.backlightLevel > 0;
    },    
    getData(resetDisplayNumber) {

      Promise.all([      
        _app.$api.get('Device/GetDeviceEvTypeState', { params: { deviceId: this.device.id } }),
        _app.$api.get('Device/GetDeviceLocalSettings', { params: { deviceId: this.device.id } }),
        _app.$api.get('Device/GetDeviceComponents', { params: { deviceId: this.device.id } })
      ]).then(
        (results) => {          

          // store data
          this.evTypeState = results[0].data;
          this.deviceLocalSettings = results[1].data || {};
          this.deviceComponents = results[2].data;

          if (!this.evTypeState) {

            _app.notify('No status');

            this.evTypeState = {};
          }            

          // activate display 1
          if (resetDisplayNumber)
            this.displayNumber = 1;
          
          // display & als data
          this.showDisplayAndAlsData();

          // turn desplay off schedules
          this.state.wdTimeOffFrom = this.deviceLocalSettings.wdTimeOffFrom || null;
          this.state.wdTimeOffTo = this.deviceLocalSettings.wdTimeOffTo || null;
          this.state.wkndTimeOffFrom = this.deviceLocalSettings.wkndTimeOffFrom || null;
          this.state.wkndTimeOffTo = this.deviceLocalSettings.wkndTimeOffTo || null;
          this.state.holTimeOffFrom = this.deviceLocalSettings.holTimeOffFrom || null;
          this.state.holTimeOffTo = this.deviceLocalSettings.holTimeOffTo || null;          

          
          // network router with relay
          this.state.networkRouterWithRelay1Status = !_app.isEmpty(this.evTypeState.rt_relay_status) ? (this.evTypeState.rt_relay_status == '0') : true;
          this.state.networkRouterWithRelay2Status = !_app.isEmpty(this.evTypeState.rt2_relay_status) ? (this.evTypeState.rt2_relay_status == '0') : true;

          // newtowk relay
          this.state.networkRelay_1_1_Status = !_app.isEmpty(this.evTypeState.r_reley1_status) ? this.evTypeState.r_reley1_status == 'ON' : true;
          this.state.networkRelay_1_2_Status = !_app.isEmpty(this.evTypeState.r_reley2_status) ? this.evTypeState.r_reley2_status == 'ON' : true;
          this.state.networkRelay_2_1_Status = !_app.isEmpty(this.evTypeState.r2_reley1_status) ? this.evTypeState.r2_reley1_status == 'ON' : true;
          this.state.networkRelay_2_2_Status = !_app.isEmpty(this.evTypeState.r2_reley2_status) ? this.evTypeState.r2_reley2_status == 'ON' : true;

          //console.log(this.state);
        },
        () => {
          // loader.hide();
        }
      );     
    },
    showDisplayAndAlsData() {
        
      const prefix = "d" + (this.displayNumber > 1 ? this.displayNumber : '');

      // display
      this.state.brightness = !_app.isEmpty(this.evTypeState[prefix + "_brightness"]) ? this.evTypeState[prefix + "_brightness"] : 50; // Black level
      this.state.backlightLevel = !_app.isEmpty(this.evTypeState[prefix + "_backlight_level"]) ? this.evTypeState[prefix + "_backlight_level"] : 50;
      this.state.gammaValue = !_app.isEmpty(this.evTypeState[prefix + "_gamma_value"]) ? this.evTypeState[prefix + "_gamma_value"] : 2.2;
      this.state.redColorBalance = !_app.isEmpty(this.evTypeState[prefix + "_red_color_balance"]) ? this.evTypeState[prefix + "_red_color_balance"] : 50;
      this.state.greenColorBalance = !_app.isEmpty(this.evTypeState[prefix + "_green_color_balance"]) ? this.evTypeState[prefix + "_green_color_balance"] : 50;
      this.state.blueColorBalance = !_app.isEmpty(this.evTypeState[prefix + "_blue_color_balance"]) ? this.evTypeState[prefix + "_blue_color_balance"] : 50;
      this.state.brightnessLevel = !_app.isEmpty(this.evTypeState[prefix + "_ambient_light"]) ? this.evTypeState[prefix + "_ambient_light"] :  null;
      this.state.touchScreenEnabled = _app.isEmpty(this.evTypeState[prefix + "_touch_matrix_status"]) || this.evTypeState[prefix + "_touch_matrix_status"] !== 'Disabled';
      this.state.displayOn = _app.isEmpty(this.state.backlightLevel) || this.state.backlightLevel > 0;
      
      // ambient sensor backlight automation
      this.state.backlightAutomaticStatus = !_app.isEmpty(this.evTypeState[prefix + "_backlight_automatic_status"]) ? this.evTypeState[prefix + "_backlight_automatic_status"] != 0 : true;
      this.state.ambientSensorMinBacklight = !_app.isEmpty(this.evTypeState[prefix + "_min_backlight"]) ? this.evTypeState[prefix + "_min_backlight"] : 50;
      this.state.ambientSensorMaxBacklight = !_app.isEmpty(this.evTypeState[prefix + "_max_backlight"]) ? this.evTypeState[prefix + "_max_backlight"] : 100;
      this.state.ambientSensorMinAmbientLight = !_app.isEmpty(this.evTypeState[prefix + "_min_ambient_light"]) ? this.evTypeState[prefix + "_min_ambient_light"] : 0;
      this.state.ambientSensorMaxAmbientLight = !_app.isEmpty(this.evTypeState[prefix + "_max_ambient_light"]) ? this.evTypeState[prefix + "_max_ambient_light"] : 10000;      
    },
    applyDisplaySettings(values) {  

      _app.$modal.confirm('Apply display settings', 'Are you sure you want to apply display settings?', (confirmed) => {
        if (confirmed) {
          this.state.applyingDisplaySettings = true;
          _app.$api.post('Device/ApplyDisplaySettings', {
            deviceId: this.device.id,
            displayNumber: this.displayNumber,
            dBrightness: this.state.brightness,
            dTouchScreenStatus: this.state.touchScreenEnabled ? 'ON' : 'OFF',
            dBacklightLevel: this.state.backlightLevel,
            dGammaValue: this.state.gammaValue,
            dRedColorBalance: this.state.redColorBalance,
            dGreenColorBalance: this.state.greenColorBalance,
            dBlueColorBalance: this.state.blueColorBalance
          }).then(
            (result) => {              
              this.state.applyingDisplaySettings = false;
              this.getData();
              if (result.data.length > 0) {
                for(let i = 0; i < result.data.length; i++) {
                  _app.notifyError(result.data[i]);
                }
              }
            },
            (error) => {
              this.state.applyingDisplaySettings = false;
              _app.handleError(error);
            }            
          );
        }
      });            
    }, 
    applyAmbientSensorSettings(values) {      
      _app.$modal.confirm('Apply ambient sensor settings', 'Are you sure you want to apply ambient sensor settings?', (confirmed) => {
        if (confirmed) {
          this.state.applyingAmbientSensorSettings = true;  
          _app.$api.post('Device/ApplyAmbientSensorSettings', { 
            deviceId: this.device.id,
            number: this.displayNumber,
            dBacklightAutomaticStatus: this.state.backlightAutomaticStatus,
            dMinBacklight: this.state.ambientSensorMinBacklight,
            dMaxBacklight: this.state.ambientSensorMaxBacklight,
            dMinAmbientLight: this.state.ambientSensorMinAmbientLight,
            dMaxAmbientLight: this.state.ambientSensorMaxAmbientLight
          }).then(
            (result) => {
              this.state.applyingAmbientSensorSettings = false;
              this.getData();
              if (result.data.length > 0) {
                for(let i = 0; i < result.data.length; i++) {
                  _app.notifyError(result.data[i]);
                }
              }              
            },
            (error) => {
              this.state.applyingAmbientSensorSettings = false;
              _app.handleError(error);
            }            
          );
        }
      });      
    },  
    applyTurnDisplayOffSettings(values) {
      _app.$modal.confirm('Apply turn display off settings', 'Are you sure you want to apply turn display off settings?', (confirmed) => {
        if (confirmed) {
          this.state.applyingTurnDisplayOffSettings = true;  
          _app.$api.post('Device/ApplyTurnDisplayOffSettings', { 
            deviceId: this.device.id,
            wdTimeOffFrom: this.state.wdTimeOffFrom, 
            wdTimeOffTo: this.state.wdTimeOffTo, 
            wkndTimeOffFrom: this.state.wkndTimeOffFrom, 
            wkndTimeOffTo: this.state.wkndTimeOffTo, 
            holTimeOffFrom: this.state.holTimeOffFrom, 
            holTimeOffTo: this.state.holTimeOffTo
          }).then(
            (result) => {
              this.state.applyingTurnDisplayOffSettings = false;
              this.getData();
              if (result.data.length > 0) {
                for(let i = 0; i < result.data.length; i++) {
                  _app.notifyError(result.data[i]);
                }
              }              
            },
            (error) => {
              this.state.applyingTurnDisplayOffSettings = false;
              _app.handleError(error);
            }            
          );
        }
      });
    }, 
    upsReboot() {
      _app.$modal.confirm('UPS safe reboot', 'Are you sure you want to rebooot UPS?', (confirmed) => {
        if (confirmed) {
          this.state.upsRebooting = true;   
          _app.$api.get('Device/UpsReboot', { params: { deviceId: this.device.id } }).then(
            (result) => {
              this.state.upsRebooting = false;
              _app.notify(result.data);
            },
            (error) => {
              this.state.upsRebooting = false;
              _app.handleError(error);
            }            
          );
        }
      });
    }, 
    upsShutdown() {
      _app.$modal.confirm('UPS safe shutdown', 'Are you sure you want to shutdown UPS?', (confirmed) => {
        if (confirmed) {
          this.state.upsShutdown = true;   
          _app.$api.get('Device/UpsShutdown', { params: { deviceId: this.device.id } }).then(
            (result) => {
              this.state.upsShutdown = false;
              _app.notify(result.data);
            },
            (error) => {
              this.state.upsShutdown = false;
              _app.handleError(error);
            }            
          );          
        }
      });
    },
    upsResetGroup(num) {
      _app.$modal.confirm('Reset UPS group ' + num, 'Are you sure you want to reset UPS group ' + num + '?', (confirmed) => {
        if (confirmed) {    
          this.state['upsResetGroup' + num] = true;   
          _app.$api.get('Device/UpsGroup' + num + 'Reset', { params: { deviceId: this.device.id } }).then(
            (result) => {
              this.state['upsResetGroup' + num] = false;
              _app.notify(result.data);
            },
            (error) => {
              this.state['upsResetGroup' + num] = false;
              _app.handleError(error);
            }            
          );
        }
      });
    },
    resetRouter(component) {

      _app.$modal.confirm('Reset ' + component.label, 'Are you sure you want to reset ' + component.labelReset + '?', (confirmed) => {
        if (confirmed) {    
          this.state['resettingRouter' + component.number] = true;   
          _app.$api.get('Device/Router' + component.number + 'Reset', { params: { deviceId: this.device.id } }).then(
            (result) => {
              this.state['resettingRouter' + component.number] = false;
              _app.notify(result.data);
            },
            (error) => {
              this.state['resettingRouter' + component.number] = false;
              _app.handleError(error);
            }            
          );
        }
      });
    },
    resetNetworkRouterWithRelay(component) {      
      _app.$modal.confirm('Reset ' + component.label, 'Are you sure you want to reset ' + component.labelReset + '?', (confirmed) => {
        if (confirmed) {
          this.state['resettingNetworkRouterWithRelay' + component.number] = true;
          _app.$api.get('Device/ResetNetworkRouterWithRelay', { 
            params: { 
              deviceId: this.device.id,
              number: component.number
            } 
          }).then(
            (result) => {
              this.state['resettingNetworkRouterWithRelay' + component.number] = false;
              _app.notify(result.data);
            },
            (error) => {
              this.state['resettingNetworkRouterWithRelay' + component.number] = false;
              _app.handleError(error);
            }            
          );
        }
      });      
    },
    setNetworkRouterWithRelayOnOff(component, on) {
      _app.$modal.confirm('Toogle ' + component.label, 'Are you sure you want to toggle ' + component.labelPort1 + (on ? ' ON?' : ' OFF?'), (confirmed) => {
        if (confirmed) {
          this.state['togglingNetworkRouterWithRelay' + component.number] = true;
          _app.$api.get('Device/SetNetworkRouterWithRelayOnOff', { 
            params: { 
              deviceId: this.device.id,
              number: component.number,
              on: on
            } 
          }).then(
            (result) => {
              this.state['togglingNetworkRouterWithRelay' + component.number] = false;
              _app.notify(result.data);
            },
            (error) => {
              this.state['togglingNetworkRouterWithRelay' + component.number] = false;
              _app.handleError(error);

              this.$refs['networkRouterWithRelay' + component.number + 'Toggle'][0].setValue(!on);
            }            
          );
        }
        else
        {
          this.$refs['networkRouterWithRelay' + component.number + 'Toggle'][0].setValue(!on);
        }
      });  
    }, 
    resetNetworkRouterWithRelayPort(component) {
      _app.$modal.confirm('Reset ' + component.label, 'Are you sure you want to reset ' + component.labelPort1 + '?', (confirmed) => {
        if (confirmed) {
          this.state['resettingNetworkRouterWithRelayPort' + component.number] = true;
          _app.$api.get('Device/ResetNetworkRouterWithRelayPort', { 
            params: { 
              deviceId: this.device.id,
              number: component.number
            } 
          }).then(
            (result) => {
              this.state['resettingNetworkRouterWithRelayPort' + component.number] = false;
              _app.notify(result.data);
            },
            (error) => {
              this.state['resettingNetworkRouterWithRelayPort' + component.number] = false;
              _app.handleError(error);
            }            
          );
        }
      });
    },
    setNetworkRelayOnOf(component, port, on) {
      const onOffToggleRef = this.$refs['networkRelay_' + component.number + '_' + port + '_Toggle'][0];
      _app.$modal.confirm('Toggle ' + component.label, 'Are you sure you want to toggle ' + (port == 1 ? component.labelPort1 : component.labelPort2) + (on ? ' ON?' : ' OFF?'), (confirmed) => {        
        if (confirmed) {
          const loadingStateKey = 'togglingNetworkRelay_' + component.number + '_' + port;
          this.state[loadingStateKey] = true;
          _app.$api.get('Device/SetNetworkRelayOnOff', { 
            params: { 
              deviceId: this.device.id,
              number: component.number,
              port: port,
              on: on
            } 
          }).then(
            (result) => {
              this.state[loadingStateKey] = false;
              _app.notify(result.data);
            },
            (error) => {
              this.state[loadingStateKey] = false;
              _app.handleError(error);

              onOffToggleRef.setValue(!on);
            }            
          );
        }
        else
        {
          onOffToggleRef.setValue(!on);
        }
      });
    }, 
    resetNetworkRelay(component, port) {

      _app.$modal.confirm('Reset ' + component.label, 'Are you sure you want to reset ' + (port == 1 ? component.labelPort1 : component.labelPort2) + '?', (confirmed) => {
        if (confirmed) {    
          const loadingStateKey = 'resettingNetworkRelay_' + component.number + '_' + port;
          this.state[loadingStateKey] = true;   
          _app.$api.get('Device/ResetNetworkRelay', { params: { 
              deviceId: this.device.id,
              number: component.number,
              port: port
          } }).then(
            (result) => {
              this.state[loadingStateKey] = false;
              _app.notify(result.data);
            },
            (error) => {
              this.state[loadingStateKey] = false;
              _app.handleError(error);
            }            
          );
        }
      });
    }
  }   
}
</script>

<style scoped>

</style>