<template>
  <nav class="navbar navbar-header navbar-expand-sm navbar-dark bg-dark fixed-top ps-3 pe-2">   

    <a v-if="false" href="#" @click.shift.ctrl.prevent="onTitleClick" class="navbar-brand">
      <i class="fa-solid fa-chart-column"></i> 
      <span class="brand-name">LAB22</span><br/><span class="app-name">admin</span>
    </a>
    <img @click.shift.ctrl.prevent="onTitleClick" src="@/assets/img/Logotip_CONTROLMOTION.svg" class="ininitus-hd-logo" />
    <!--<img @click.shift.ctrl.prevent="onTitleClick" src="@/assets/img/Logotip_LAB22_demo.png" class="ininitus-hd-logo" />-->
    
    <div v-if="isLogedInUserView" class="collapse navbar-collapse" id="navbarsCollapseDiv">
      
      <!-- <form class="form-inline my-2 my-md-0 m-2">
        <input class="form-control main-search" type="text" placeholder="Search">
      </form> 
      -->

      <ul v-if="debugMode" class="navbar-nav">        
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="testDropdown" data-bs-toggle="dropdown">Test</a>
          <div class="dropdown-menu" aria-labelledby="testDropdown">
            <h6 class="dropdown-header">Set theme</h6>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" @click.prevent="this.$app.setTheme('white-theme')">White theme</a>
            <a class="dropdown-item" @click.prevent="this.$app.setTheme('dark-theme')">Dark theme</a>
            <div class="dropdown-divider"></div>
            <div class="submenu-item dropend">
              <a class="dropdown-item dropdown-toggle dropright" href="#" id="navbarDropdownProgressMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Progress
              </a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdownProgressMenuLink">
                <a class="dropdown-item" @click.prevent="startProgress()">Start progress</a>
                <a class="dropdown-item" @click.prevent="stopProgress()">Stop progress</a>
              </div>
            </div> 
            <div class="submenu-item dropend">
              <a class="dropdown-item dropdown-toggle dropright" href="#" id="navbarDropdownToastMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Toast examples
              </a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdownToastMenuLink">
                <a class="dropdown-item" @click.prevent="showToast('default')">Default</a>
                <a class="dropdown-item" @click.prevent="showToast('success')">Success</a>
                <a class="dropdown-item" @click.prevent="showToast('info')">Info</a>
                <a class="dropdown-item" @click.prevent="showToast('warning')">Warning</a>
                <a class="dropdown-item" @click.prevent="showToast('error')">Error</a>
              </div>
            </div>  
            <div class="submenu-item dropend">
              <a class="dropdown-item dropdown-toggle dropright" href="#" id="navbarDropdownMessageBoxMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Message box
              </a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdownMessageBoxMenuLink">
                <a class="dropdown-item" @click.prevent="showAlert()">Show alert</a>
                <a class="dropdown-item" @click.prevent="showConfirm()">Show confirm</a>
                <a class="dropdown-item" @click.prevent="showPrompt()">Show prompt</a>
                <a class="dropdown-item" @click.prevent="showProgress()">Show progress</a>
                <a class="dropdown-item" @click.prevent="showWait()">Show wait</a>
                <a class="dropdown-item" @click.prevent="showNotification()">Show notification</a>
              </div>
            </div>               
          </div>
        </li>
      </ul>
    </div>

    <div v-if="isLogedInUserView && viewHistory.length>0 && isLabUser" id="history" class="ml-auto" style="margin-right:50px;">
      <a href="#" id="a-toggle-history-icon" @click="toggleHistoryIcon" class="dropdown-toggle notification-icon" data-bs-toggle="dropdown" aria-expanded="false" style="width:25px; height:25px; color:#ccc;">
        <i id="flippableHistoryIcon" class="fas fa-history" style="font-size:0.75rem; line-height:25px;"></i>
        <!--<span class="badge" :style="(viewHistory.length>0) ? 'background:#D2312D' : 'background:#2baab1;'">{{ (viewHistory.length>0) ? viewHistory.length.toString() : '0'}}</span>-->
      </a>
      <!--<button type="button" class="btn btn-outline-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" style="opacity: 0.8 !important; border: none !important;">History</button>-->
      <ul class="dropdown-menu dropdown-menu-end" style="right: auto !important; top: 50px !important; right:20px !important;">
        <li v-for="device in this.viewHistory" :key="device.guid"><a class="dropdown-item" @click.prevent="routeToDevice(device.guid)">{{device.name}}</a></li>
      </ul>
    </div>

    <div v-if="isLogedInUserView" id="userbox" class="userbox ml-auto" style="margin-right:30px;">
      <a ref="dropdown" href="#" data-bs-toggle="dropdown" @click.prevent="changeFontClass" aria-expanded="false">
        <figure class="profile-picture">
          <img :src="this.userImg" class="rounded-circle" :data-lock-picture="this.userImg" :style="this.userImgStyle">
        </figure>
        <div class="profile-info" data-lock-name="{{ this.headName }}" data-lock-email="{{ this.headEmail }}" style="margin-right:15px !important;">
          <span class="name menu-dark">{{ this.headName }}</span>
          <span class="role menu-dark">{{ this.headEmail }}</span>
        </div>

        <i class="fa custom-caret menu-dark"></i>
      </a>

      <div class="dropdown-menu" style="min-width:140px;">
        <ul class="list-unstyled mb-2">
          <!--<li class="customer-name" v-if="authUser.isCustomerUser()">{{authUser.customerName}}</li>-->
          <li class="divider"></li>
          <!-- <li>
            <a role="menuitem" tabindex="-1" @click.prevent="userDetails" style="cursor:pointer !important;"><i class="fa-solid fa-user"></i> My Profile</a>
          </li> -->
          <!-- <li>
            <div class="form-check">
              <input type="checkbox" @click.prevent.stop="onDarkThemeCheck" class="form-check-input" id="dropdownCheck" :checked="this.$app.darkTheme">
              <label class="form-check-label" for="dropdownCheck">
                Dark theme
              </label>
            </div>
          </li> 
          <li><hr class="divider"></li>     -->
          <li>
            <a role="menuitem" tabindex="-1" @click.prevent="changePassword" style="cursor:pointer !important;"><i class="fa-solid fa-hashtag"></i> Change password</a>
          </li>
          <li>
            <a role="menuitem" tabindex="-1" @click.prevent="logOut" style="cursor:pointer !important;"><i class="fa-solid fa-power-off"></i> Logout</a>
          </li>
        </ul>
      </div>
    </div>
    <!--
    <div v-if="isLogedInUserView" class="navbar-nav ml-auto">
      <li class="nav-item">
        <a class="nav-link" @click.prevent="logOut">
          <i role="button" class="fa-solid fa-power-off fa-lg" title="Logout"></i>
        </a>
      </li>
    </div>
    -->
  </nav>
</template>

<script>
import ChangePasswordModal from '@/Modules/User/ChangePasswordModal'
//VDynamicAdvacedModal

export default {
  name: 'AppHeader',
  props: {
    isLogedInUserView: Boolean
  },
  beforeMount () {
    if(this.$app.darkTheme) {
      this.userImg = this.imgUserDarkSrc;
      this.userImgStyle = 'opacity:0.8';
    } else {
      this.userImg = this.imgUserWhiteSrc;
      this.userImgStyle = 'filter:grayscale(75%)';
    }
  },
  mounted () {
    let _user = this.$store.state.auth.user; 
    if (_user) {
      this.headName = _user.username;
      if(this.$app.user.isCustomerUser())
        this.headEmail = this.$app.user.customerName;
      else
        this.headEmail = (_user.username!==_user.email) ? _user.email : '';
    
      this.isLabUser = ( this.$app.$data.Lab22Users.indexOf(_user.email) == -1 ) ? false : true;
    }
    
    let _this = this;
    _this.timerId = setInterval(function() {
      _this.viewHistory = _this.$app.$data.viewHistory;
    }, 1000);
  }, 
  unmounted() {
    clearInterval(this.timerId);
  },
  data() {
    return {
      progressQueue: [],
      show: false,
      debugMode: this.$app.debugModeEnabled,
      headName: null,
      headEmail: null,
      authUser: this.$app.user,
      imgUserWhiteSrc: require('../../assets/img/logged-user.jpg'),
      imgUserDarkSrc: require('../../assets/img/user-acc.svg'),
      userImg: null,
      userImgStyle: null,
      viewHistory: this.$app.$data.viewHistory,
      isLabUser: false,
      timerId: null,
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user; 
    }
	},
  methods: {
    onTitleClick() {
      this.debugMode = !this.debugMode;

      this.$app.setDebugMode(this.debugMode); 
    },
    showAlert() {
      this.$app.$modal.alert('Info', 'Alert text...', () => {
        this.$app.notify('Closed');
      });
    },
    showConfirm() {
      this.$app.$modal.confirm('Confirm', 'Please confirm text...', (confirmed) => {
        this.$app.notify(confirmed ? 'Confirmed' : 'Not confirmed');
      });
    },
    showPrompt() {
      console.log('showPrompt');
    },
    showProgress() {
      console.log('shoshowProgresswConfirm');
    },
    showWait() {
      console.log('showWait');
    },
    showNotification() {
      console.log('showNotification');
    },
    startProgress() {
      this.progressQueue.push(this.$progress.start());
    },
    stopProgress() {
      const progress = this.progressQueue.pop();
      if (progress) 
        progress.finish();
    },
    showModalExample() {
      this.$app.$modal.advancedDynamicExample();
    },
    showToast(type) {
      this.$app.$helper.notify('Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit...', type, 2000);
    },
    logOut() {
      // this.$store.dispatch('auth/logout');
      // this.$router.go("/login");
      this.$app.logOut();
    },
    userDetails() {
      this.$router.push({
        name: 'user-details',
        params: { 
          data: JSON.stringify(this.$store.state.auth.user)
        }
      });
    },
    changePassword() {
      this.$vfm.show({
        component: ChangePasswordModal,
        bind: {
          'editRec': this.$store.state.auth.user
        },
        on: {
          ok: (data) => {
            this.$app.$helper.notify('Password was successfully changed.', 'info', 3000);
          }
        }
      });
    },
    onDarkThemeCheck(e) {
      this.$app.toggleDarkTheme(e.target.checked);
    },
    routeToDevice(guid) {
      const linkElement = document.getElementById('a-toggle-history-icon');
      if (linkElement)
        linkElement.click();

      setTimeout(() => {
        this.$router.push('/devices/' + guid + '/details');
      }, 100);
    },
    toggleHistoryIcon() {
      var icon = document.getElementById('flippableHistoryIcon');
          icon.classList.toggle('flipped');
    },
  }
}
</script>

<style scoped>

.flipped {
    transform: rotate(180deg);
    transition: transform 0.5s ease;
}

.navbar {
  height: 60px;
}

.navbar-brand {
  line-height:6px;
}

.brand-name {
  font-weight: bold;
  font-size: 15px;
  padding-left:3px;
}

.app-name {
  font-size: 12px;
  letter-spacing: 4px;
  color: rgba(255,255,255,.7)
}

.menu-dark {
  color: #7d7d7d !important;
}

.main-search {  
  height: 32px;
  line-height: 32px;
}

/* makes sub-menu S open on hover */
.submenu-item:hover > .dropdown-menu {
  display: block;
}

.ininitus-hd-logo {
  height: 45px;
}

/*.customer-name {
  text-indent: 45px;
  font-size: 14px;
}*/

.dropdown-menu .form-check {
  padding-left: 32px;
  margin-top: 15px;
}

.dropdown-menu .form-check input {
  margin-top: 2px;
  margin-right:0px
}

.dropdown-menu .form-check .form-check-label {
  padding-left: 0px;
  font-size: 12px;
}

.dropdown-menu .form-check .form-check-label {
  color: #7d7d7d;
}

html.dark .dropdown-menu .form-check .form-check-label {
  color: #c3c3c3;
}

</style>