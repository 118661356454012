<template>

  <GoogleMap
    api-key="AIzaSyBcODKG_2zHRbBwJkjJwSXY0W9MtgCS5iM"
    ref="mapRef"
    style="width: 100%;"
    :center="center"
    :mapTypeControl="mapTypeControl"
    :mapTypeControlOptions="mapTypeControlOptions"
    :zoom="4"
  >
    <Marker :key="marker.id" ref="mapItems" v-for="marker in markers" :options="marker" @click="onClick(marker.id)"></Marker>
  </GoogleMap>

  <FilterBar ref="filterBar" class="shadow" v-slot="{state}" :filterState="getFilterState()" @reload="load()" @reset="load()" showReload showReset>
    <BaseField v-if="!this.$app.user.isCustomerUser()" v-model="state.customerId" name="customerId" placeholder="Customer" as="select" :data="this.$app.$data.customers" addClearOption />
    <BaseField v-model="state.name" name="name" placeholder="Device name" />
    <BaseField v-model="state.modelId" name="modelId" placeholder="Device model" as="select" :data="this.$app.$data.deviceModelLkp" addClearOption />
    <BaseField v-model="state.attrSerial" name="attrSerial" placeholder="Serial number" />
    <BaseField v-model="state.statusId" name="statusId" placeholder="Status" as="select" :data="this.$app.$data.deviceStatusList" addClearOption />
  </FilterBar>
</template>

<script>
import _app from '@/App/App';
import { GoogleMap, Marker } from 'vue3-google-map';
import FilterBar from '@/App/components/Common/FilterBar';
import BaseField from '@/App/components/Form/BaseField';

export default {
  name: 'LocationMap',
  emits: ['set-main-view-class'],
  components: {
    GoogleMap, 
    Marker, 
    FilterBar,
    BaseField
  },
  data() {
    return {
      center: { lat: 52.237049, lng: 21.017532 },      
      markers: [],
      mapTypeControl: true,
      mapTypeControlOptions: { },
      mapApi: null,
      map: null,
      imgMarkerOn: require('../../assets/img/icons/location-online-48dp.svg'),
      imgMarkerOff: require('../../assets/img/icons/location-off-48dp.svg'),
    };
  },
  mounted() {
    // remove stable scrollbar-gutter on main view
    this.$emit('set-main-view-class', 'no-gutter');
    
    // watch for map ready
    this.$watch(() => this.$refs.mapRef.ready, (ready) => {
        
        if (!ready) return

        this.mapApi = this.$refs.mapRef.api;
        this.map = this.$refs.mapRef.map;

        this.mapTypeControlOptions = { 
          position: this.mapApi.ControlPosition.BOTTOM_RIGHT  
        };

        this.load();
      }
    );
    
    this.doLayout();
    window.addEventListener('resize', this.doLayout);
      
    if (this.$route.params.filter)
      this.$refs.filterBar.reset(JSON.parse(this.$route.params.filter), true);
  },
  unmounted() {
    // remove no-gutter class from main-view
    this.$emit('set-main-view-class', '');
    // remove listeners
    window.removeEventListener("resize", this.doLayout);
  },
  methods: {
    doLayout() {
      // todo: get header height from dom element and add getMainViewHeight function to this.$app
      if (this.$refs.mapRef.$el) this.$refs.mapRef.$el.style.height = (window.innerHeight - 60) + "px";
    },
    getFilterState() {
      return _app.$data.getState('DeviceListFilter');
    },
    load(reload) {
      //let loader = this.$loading.show();
      this.$app.$data.getDevices(reload).then(
        result => {

          this.markers = result.map((rec) => {

            let pos = { 
              lat: parseFloat(rec.latitude),
              lng: parseFloat(rec.longitude)
            };
            
            if (isNaN(pos.lat) || isNaN(pos.lng))
              return false;

            return {
              id: rec.id,
              device: rec,
              position: pos,
              visible: true,
              icon: { 
                //url: 'https://maps.google.com/mapfiles/ms/icons/'+((rec.statusId==2) ? 'green' : 'red')+'-dot.png'
                url: (rec.statusId==2) ? this.imgMarkerOn : this.imgMarkerOff,
                scaledSize: new this.mapApi.Size(32, 32, 'px', 'px'),
                optimized:false
              }
            }
          });

          const filter = this.getFilterState();

          this.markers = this.markers.filter((rec) => { 
            if (!rec) return false;
            if (filter.customerId && rec.device.customerId != filter.customerId) return false;
            if (filter.name && rec.device.name.toLowerCase().indexOf(filter.name.toLowerCase()) == -1) return false;
            if (filter.modelId && rec.device.modelId != filter.modelId) return false;
            if (filter.attrSerial && rec.device.attrSerial.toLowerCase().indexOf(filter.attrSerial.toLowerCase()) == -1) return false;
            if (filter.statusId && rec.device.statusId != filter.statusId) return false;

            return true;
          });

          if (this.markers.length == 1) {
            this.map.setCenter(this.markers[0].position);
            this.map.setZoom(10);
          } else {
            this.map.setCenter(this.center);
            this.map.setZoom(4);
          }
          

          /*
          this.$app.$api.get('Device/GetDeviceMapCenter').then(
            result => {
              //loader.hide();
              let _lat = parseFloat(result.data.latitude),
                  _lng = parseFloat(result.data.longitude);
              this.center = { lat: _lat, lng: _lng }
              _this.panTo(_lat, _lng);
            },
            () => {
              //loader.hide();
            }
          );
          */
        },
        error => {
          console.log(error);
        }
      );
    },
    onClick(id) {
      let _marker = this.markers.filter(function(o){ 
                      return (id==o.id);
                    })[0];
      this.routeToDevice(_marker.device.guid);
    },
    panTo(lat, lng) {
      this.$refs.mapRef.map.panTo({ lat: lat, lng: lng });
    },
    routeToDevice(id) {
      this.$router.push('/devices/'+id+'/status');
    }
  }
}
</script>

<style scoped>  
  .filterbar {
    position: absolute;
    top: 70px;
    margin-left:15px;
    margin-right:80px;
    border: 1px solid rgb(222, 226, 230);
    border-radius: 5px;
    background-color: #fafafa;
    padding-left:10px !important;
    padding-right:6px !important;
    margin-bottom: 0px !important;
  }

  html.dark .filterbar {
    background-color: #161616;
    border-color: #161616;
  }
</style>