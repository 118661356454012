<template>
  <ToolbBar>
    <BaseButton @click="showAddMaintenanceModal(device)" text="Add new" class="btn-primary"></BaseButton>
    <button @click="load" :disabled="loading" class="btn icon-btn btn-secondary ms-2">
      <span v-show="loading" class="spinner-border spinner-border-sm"></span>
      <i v-show="!loading" class="fa-solid fa-rotate-right"></i>
    </button>
    
  </ToolbBar>
  
  <BaseGrid :columns="gridData.columns" :rows="gridData.rows" @cell-click="onGridCellClick" @mouseover="onMouseOver" @mouseout="onMouseOut" :enableVScroll="true" class="cursor-pointer" />
</template>

<script>
import _app from '@/App/App'
import ToolbBar from '@/App/components/Common/ToolBar'
import BaseGrid from '@/App/components/Grid/BaseGrid'
import BaseButton from '@/App/components/Common/BaseButton'
import AddDeviceMaintenanceModal from './AddDeviceMaintenanceModal'

export default {
  name: 'DeviceMaintenance',
  props: ['device'],
  components: {
    ToolbBar,
    BaseGrid,
    BaseButton
  },
  emits: ['refreshStatus'],
  data() {
    return {
      loading: false,
      deviceId: this.device?.id,
      mouseover: false,
      gridData: {
        columns: [
          {
            title: 'Id',
            dataIndex: 'id'
          },
          {
            title: 'Start',
            dataIndex: 'startDt',
            type: 'DateTime'
          },
          {
            title: 'Duration',
            dataIndex: 'startDt',
            renderer: (value, rec) => {
              const date1 = new Date(value);
              const date2 = (rec.endDt==null) ? _app.toUtcDate(new Date()) : new Date(rec.endDt);
              const days = parseInt((date2 - date1) / (1000 * 60 * 60 * 24));
              const hours = parseInt(Math.abs(date2 - date1) / (1000 * 60 * 60) % 24);
              const minutes = parseInt(Math.abs(date2.getTime() - date1.getTime()) / (1000 * 60) % 60);
              return days.toString() + 'd ' + hours.toString() + 'h ' + minutes.toString() + 'min';
            }
          },
          {
            title: 'Component',
            dataIndex: 'modelComponent'
          },
          {
            title: 'Reason',
            dataIndex: 'reasonName'
          },
          {
            title: 'Action',
            dataIndex: 'actionName'
          },
          {
            title: 'Action comment',
            dataIndex: 'actionComment'
          },
          {
            title: 'End',
            dataIndex: 'endDt',
            type: 'DateTime'
          },
          {
            title: 'Resolution',
            dataIndex: 'resolutionName'
          },
          // actions
          {
            width:90,
            align:'right',
            actions: [
              {
                text: 'Edit',
                handler: this.editMaintenance
              },          
              {
                text: 'Remove',
                handler: this.removeMaintenance
              }
            ]
          }
        ],
        rows: []
      },
      data: [],
    }
  },
  mounted() {
    this.load();
  },
  watch: {
    device() {
      if (this.device)
        this.load();
    }
  },
  methods: {
    load() {
      if(this.deviceId==null || typeof this.deviceId == 'undefined') {
        this.$router.push({
          name: 'devices'
        });
      } else {
        this.loading = true;

        Promise.all([
          this.$app.$api.get('DeviceMaintenance/GetDeviceMaintenanceList', { params: {deviceId:this.deviceId} })
        ])
        .then(results => {
          this.data = results[0].data;
          this.updateGridView();
          this.loading = false;
        });
      }
    },
    /*reload() {
      this.$forceUpdate();
    },*/
    editMaintenance(rec) {
      this.$vfm.show({
        component: AddDeviceMaintenanceModal,
        bind: {
          'editRec': rec
        },        
        on: {
          ok: (data) => {
            //console.log(data);
            this.load();
          }
        }
      });
    },
    showAddMaintenanceModal(device) {
      this.$vfm.show({
        component: AddDeviceMaintenanceModal,
        bind: {
          'device': device
        },
        on: {
          ok: (data) => {
            //console.log(data);
            this.load();
          }
        }
      });
    },
    removeMaintenance(rec) {
      _app.$modal.confirm('Remove maintenance', `Are you sure you want to remove this maintenance entry?`, (confirmed) => {
        
        if (!confirmed) 
            return;
      
        const index = this.gridData.rows.indexOf(rec);
        if (index !== -1)
          this.gridData.rows.splice(index, 1);

        this.loading = true;

        this.$app.$api.get('DeviceMaintenance/DelDeviceMaintenance', { params: { id: rec.id } }).then(
          () => {
            this.loading = false;
          },
          () => {
            this.loading = false;
            this.load();
          }
        );  
      });
    },
    updateGridView() {
      this.gridData.rows = this.data;
    },
    onGridCellClick(row, col) {
      this.editMaintenance(row);
    },
    onMouseOver() {
      this.mouseover = true;
      setTimeout(() => {
        /*if(this.mouseover)
          TODO show some tooltip*/
      }, 500);
    },
    onMouseOut() {
      this.mouseover = false;
    }
  }
}
</script>

<style scoped>
.toolbar {
  border-top-width: 0px;
  margin-top: 2px;
  padding-left:0px;  
}

.btn-group {
  min-width: 100px;
}
</style>