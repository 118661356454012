<template>
  <FilterBar :filterState="getFilterState()" :initialValues="{ statusId: 1}" @reload="load()" showReload showReset :loading="loading">
    <template v-slot="{state}">
      <BaseField v-model="state.statusId" name="statusId" placeholder="Alert status" as="select" :data="this.statusOptions" style="width:100px" />
      <BaseField v-model="state.conditionStateId" name="conditionStateId" placeholder="Condition state" as="select" style="width:140px" :data="this.conditionStatusOptions" addClearOption />
      <BaseField v-model="state.id" name="name" placeholder="Alert id" style="width:100px" />
      <BaseField v-model="state.alertText" name="alertText" placeholder="Alert text" style="width:100px" />
    </template>
    <template #end>
      <button v-if="checkedRecords.length > 0" @click="closeSelected" type="button" class="btn btn-primary mx-2">Close selected ({{checkedRecords.length}})</button>
    </template>
  </FilterBar>

  <AlertListBase ref="alertListBase" :filterState="getFilterState()" @checkchange="onCheckChange"  @loading="(loadingStatus) => loading = loadingStatus" :deviceMode="true" :listStoreKey="this.listStoreKey"></AlertListBase>
</template>

<script>

import FilterBar from '@/App/components/Common/FilterBar'
import BaseField from '@/App/components/Form/BaseField'

import AlertListBase from '@/Modules/Alerts/AlertListBase'

const PAGE_SIZE = 100;

export default {
  name: 'DeviceAlertList',
  props: ['device'],
  components: {
    FilterBar,
    BaseField,
    AlertListBase
  },
  emits: ['refreshStatus'],
  data() {
    return {
      loading: false,
      statusOptions: [
        { id: 1, name: 'Opened'},
        { id: 2, name: 'Closed'}
      ],
      conditionStatusOptions: [
        { id: 1, name: 'Active'},
        { id: 2, name: 'Not Active'}        
      ],
      checkedRecords: [],
      listStoreKey: 'DEVICE_ALERT_LIST'
    }
  },
  mounted() {
    
    if (this.$app.$data.storeLoaded(this.listStoreKey))
      this.$refs.alertListBase.load(false);  

    if (this.device)
      this.load();
  },
  watch: {
    device() {
      if (this.device)
        this.load();
    }
  },  
  methods: {
    getFilterState() {
      return this.$app.$data.getState('DeviceAlertListFilter', { deviceAlertList: true, rowNum: 1, pageSize: PAGE_SIZE, limit: 100 });
    },
    load() {

      if (!this.device) {
        this.$refs.alertListBase.clear();
        return;
      }

      let filter = this.getFilterState();

      filter.deviceId = this.device.id

      this.$refs.alertListBase.setFilter(this.getFilterState());
    },
    exportList(type) {
      this.$refs.alertListBase.exportList(type);
    },
    onCheckChange(checkedRecords) {
      this.checkedRecords = checkedRecords;
    },
    closeSelected() {
      this.$refs.alertListBase.closeSelected();
    }
  }
}
</script>

<style scoped>
.filterbar {
  border-top-width: 0px;
  margin-top: 2px;
}
</style>