<template>

  <base-modal :name="modalName" :title="modalName" :width="700" v-bind="$attrs" @close="close" style="z-index:99999;">
    <template #body> 
      <div class="container mt-3">
        <div class="row">
          <div class="col-9">
            <div class="embed-responsive embed-responsive-16by9" style="height:360px !important;">
              <iframe class="embed-responsive-item" :src="videoSrc" style="width:100%; height:100%;" allowfullscreen></iframe>
            </div>
          </div>
          <div class="col-3">
            By watching this video you should get an overview of our Reporting tool's functionalities as well as a quick tutorial with same helpful use cases.<br /><br />
            Remember, you can always contact us for premium support or drop us a note via other available channels.
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <button type="button" @click.prevent="close" class="btn btn-primary">Close</button>
    </template>
  </base-modal>

</template>

<script>
import BaseModal from '@/App/components/Modal/BaseModal';
import _app from '@/App/App'

export default {
  name: 'ReportingVideoModal',
  inheritAttrs: false,
  //emits: ['ok'],
  props: {
    title: String,
    videoSrc: String,
  },
  components: {
    BaseModal,
  }, 
  data() {
    return {
      modalName: this.title,
    };
  },
  mounted() {
    
  },
  methods: {
    close() {
      this.$vfm.hide(this.modalName);
    },
  }
}
</script>

<style scoped>

</style>