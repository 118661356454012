<template>
  <div class="inf-card">
    <VScroll ref="maskEl">
      <BaseForm @submit="applyDisplaySettings" :state="state" :validation-schema="schema">
        <FormSection ref="displaySection" title="Display settings">
          <template #body>
            <FormOnOffGroupField :value="state.displayOn" @change="onTurnDisplayCheckChange" name="displayOn" label="Turn display" />
            <FormOnOffGroupField :value="state.touchScreenEnabled" @change="onTouchScreenEnableChange" name="touchScreenEnabled" label="Touch screen" onBtnText="Enable" offBtnText="Disable" />
            <!--<FormSliderField :value="state.brightness" @change="v => state.brightness = v" name="brightness" label="Display brightness" />-->
            <!-- <FormSliderField :state="state.displayContrast" @change="v => state.displayContrast = v" name="displayContrast" label="Display contrast" /> -->
            <FormSliderField pbClass="pb-2" :value="state.backlightLevel" @change="onBacklightLevelChange" name="backlightLevel" label="Backlight level" />
            <FormSliderField pbClass="pb-2" :value="state.gammaValue" @change="v => state.gammaValue = v" :min="1.8" :max="2.6" :step="0.2" name="gammaValue" label="GAMMA value" />
            <FormSliderField pbClass="pb-2" :value="state.redColorBalance" @change="v => state.redColorBalance = v" name="redColorBalance" label="Red color balance" />
            <FormSliderField pbClass="pb-2" :value="state.greenColorBalance" @change="v => state.greenColorBalance = v" name="greenColorBalance" label="Green color balance" />
            <FormSliderField pbClass="pb-2" :value="state.blueColorBalance" @change="v => state.blueColorBalance = v" name="blueColorBalance" label="Blue color balance" />
            <FormSliderField pbClass="pb-2" :value="state.brightness" @change="v => state.brightness = v" name="brightness" label="Black level" />
            <FormGroupRow class="mt-3">
              <BaseButton type="submit" text="Apply settings" loadingText="Applying settings..." :loading="state.applyingDisplaySettings"></BaseButton>
            </FormGroupRow>
          </template>
        </FormSection>  
      </BaseForm>

      <BaseForm @submit="applyAmbientSensorSettings" :state="state" :validation-schema="schema">
        <FormSection title="Ambient sensor">
          <template #body>
            <FormField v-model.number="state.brightnessLevel" name="brightnessLevel" label="Current ambient brightness level (lux)" disabled style="width:70px;text-align:center" />
            <!--<FormOnOffGroupField :value="state.backlightAutomaticStatus" @change="v => state.backlightAutomaticStatus = v" name="backlightAutomaticStatus" label="Adjust display brightness automatically" onBtnText="Enable" offBtnText="Disable" />-->              
            <FormOnOffGroupField :value="state.backlightAutomaticStatus" @change="v => state.backlightAutomaticStatus = v" name="backlightAutomaticStatus" label="Adjust backlight level automatically" onBtnText="Enable" offBtnText="Disable" />
            <FormDualSliderField 
              label="Min/Max backlight (%)" 
              name="ambientSensorBacklightRange" 
              fromLabel="Min (%)" toLabel="Max (%)" 
              :inputWidth="90" 
              :fromValue="state.ambientSensorMinBacklight" 
              :toValue="state.ambientSensorMaxBacklight" 
              :currentValue="50"
              @from-value-change="v => state.ambientSensorMinBacklight = v" 
              @to-value-change="v => state.ambientSensorMaxBacklight = v">
            </FormDualSliderField>
            <FormDualSliderField 
              label="Min/Max ambient light (lux)" 
              name="ambientSensorAmbientLightRange" 
              fromLabel="Min (LUX)" 
              toLabel="Max (LUX)" 
              :fromValue="state.ambientSensorMinAmbientLight" 
              :toValue="state.ambientSensorMaxAmbientLight" 
              :inputWidth="90" 
              :max="100000" 
              scaleType="LUX" 
              :currentValue="50" 
              @from-value-change="v => state.ambientSensorMinAmbientLight = v" 
              @to-value-change="v => state.ambientSensorMaxAmbientLight = v">
            </FormDualSliderField>

            <!-- 
            <FormSliderField :value="state.ambientSensorMinBacklight" @change="v => state.ambientSensorMinBacklight = v" name="ambientSensorMinBacklight" label="Minimum backlight (%)" />
            <FormSliderField :value="state.ambientSensorMaxBacklight" @change="v => state.ambientSensorMaxBacklight = v" name="ambientSensorMaxBacklight" label="Maximum backlight (%)" />
            <FormField name="ambientSensorMinAmbientLight" label="Minimum ambient light (lux)" type="number" stgep="100" v-model.number="state.ambientSensorMinAmbientLight" placeholder="lux" style="max-width:150px" />
            <FormField name="ambientSensorMaxAmbientLight" label="Maximum ambient light (lux)" type="number" step="1000" v-model.number="state.ambientSensorMaxAmbientLight" placeholder="lux" style="max-width:150px" /> 
            -->
            <FormGroupRow class="mt-3">
              <BaseButton type="submit" text="Apply settings" loadingText="Applying settings..." :loading="state.applyingAmbientSensorSettings"></BaseButton>
            </FormGroupRow>
          </template>
        </FormSection>
      </BaseForm> 

      <BaseForm @submit="applyTurnDisplayOffSettings" :state="state" :validation-schema="schema">
      <FormSection title="Turn display off">
        <template #body>
          <FormFieldGroup label="Workdays" class="d-flex align-items-center">
            <div class="col-sm-8 d-flex align-items-center">
            <label class="me-2">From</label>
            <BaseField v-model="state.wdTimeOffFrom" name="wdTimeOffFrom" required type="time" style="width:90px;" />
            <label class="mx-2">To</label>
            <BaseField v-model="state.wdTimeOffTo" name="wdTimeOffTo" required type="time" style="width:90px;" />
            <BaseButton text="Clear" @click="state.wdTimeOffFrom=null;state.wdTimeOffTo=null;" class="btn-secondary ms-3"></BaseButton>
            </div>
          </FormFieldGroup>
          <FormFieldGroup label="Weekends" class="d-flex align-items-center">
            <div class="col-sm-8 d-flex align-items-center">
            <label class="me-2">From</label>
            <BaseField v-model="state.wkndTimeOffFrom" name="wkndTimeOffFrom" required type="time" style="width:90px;" />
            <label class="mx-2">To</label>
            <BaseField v-model="state.wkndTimeOffTo" name="wkndTimeOffTo" required type="time" style="width:90px;" />
            <BaseButton text="Clear" @click="state.wkndTimeOffFrom=null;state.wkndTimeOffTo=null;" class="btn-secondary ms-3"></BaseButton>
            </div>
          </FormFieldGroup>    
          <FormFieldGroup label="Holidays" class="d-flex align-items-center">
            <div class="col-sm-8 d-flex align-items-center">
            <label class="me-2">From</label>
            <BaseField v-model="state.holTimeOffFrom" name="holTimeOffFrom" required type="time" style="width:90px;" />
            <label class="mx-2">To</label>
            <BaseField v-model="state.holTimeOffTo" name="holTimeOffTo" required type="time" style="width:90px;" />
            <BaseButton text="Clear" @click="state.holTimeOffFrom=null;state.holTimeOffTo=null;" class="btn-secondary ms-3"></BaseButton>
            </div>
          </FormFieldGroup> 
          <FormGroupRow class="mt-3">
            <BaseButton type="submit" text="Apply settings" loadingText="Applying settings..." :loading="state.applyingTurnDisplayOffSettings"></BaseButton>
          </FormGroupRow>            
        </template>
      </FormSection>
      </BaseForm>

      <FormSection title="UPS">
        <template #body>
        <!-- <FormFieldGroup label="UPS Safe Reboot">
          <div class="col-sm-8">
            <BaseButton @click="upsReboot()" text="UPS reboot" class="btn-danger" loadingText="UPS reboot in progress..." :loading="state.upsRebooting"></BaseButton>
          </div>      
        </FormFieldGroup> -->
        <FormFieldGroup label="UPS Safe Shutdown">
          <div class="col-sm-8">
            <BaseButton @click="upsShutdown()" text="UPS shutdown" class="btn-danger" loadingText="UPS shutdown in progress..." :loading="state.upsShutdown"></BaseButton>
          </div>      
        </FormFieldGroup>
        <FormFieldGroup label="Group 1">
          <div class="col-sm-8">
            <BaseButton @click="upsResetGroup(1)" text="Reset" class="btn-danger" loadingText="Reset in progress..." :loading="state.upsResetGroup1"></BaseButton>
          </div>      
        </FormFieldGroup>
        <FormFieldGroup label="Group 2">
          <div class="col-sm-8">
            <BaseButton @click="upsResetGroup(2)" text="Reset" class="btn-danger" loadingText="Reset in progress..." :loading="state.upsResetGroup2"></BaseButton>
          </div>      
        </FormFieldGroup>
        </template>
      </FormSection>

      <!--<FormSection title="Router">-->
      <FormSection title="Network switch">
        <template #body>
        <!--<FormFieldGroup label="Reset router 1">-->
        <FormFieldGroup label="Reset network switch 1">
          <div class="col-sm-8">
            <BaseButton @click="resetRouter(1)" text="Reset" class="btn-danger" loadingText="Router reset in progress..." :loading="state.resettingRouter1"></BaseButton>
          </div>      
        </FormFieldGroup>
        <!--<FormFieldGroup label="Reset router 2">-->
        <FormFieldGroup label="Reset network switch 2">
          <div class="col-sm-8">
            <BaseButton @click="resetRouter(2)" text="Reset" class="btn-danger" loadingText="Router reset in progress..." :loading="state.resettingRouter2"></BaseButton>
          </div>      
        </FormFieldGroup>
        </template>
      </FormSection>

      <FormSection title="Power switch">
        <template #body>
          <FormOnOffGroupField :value="state.powerSwitch1Status" ref="powerSwitch1Toggle" name="powerSwitch1On" label="Equipment LCD + PC" @change="onPowerSwitchChange(1, $event)" :loading="state.togglingPowerSwitch1">
            <BaseButton @click="resetPowerSwitch(1)" text="Reset" class="btn-danger" style="width:auto;" loadingText="Reset in progress..." :loading="state.resettingPowerSwitch1"></BaseButton>
          </FormOnOffGroupField>
          <!-- <FormOnOffGroupField ref="powerSwitch2Toggle" name="powerSwitch2On" label="Beaglebone" @change="onPowerSwitchChange(2, $event)" :loading="state.togglingPowerSwitch2">
            <BaseButton @click="resetPowerSwitch(2)" text="Reset" class="btn-danger" loadingText="Reset in progress..." :loading="state.resettingPowerSwitch2"></BaseButton>
          </FormOnOffGroupField>     -->
          <FormFieldGroup label="Beaglebone">
          <div class="col-sm-8">
            <BaseButton @click="resetPowerSwitch(2)" text="Reset" class="btn-danger" loadingText="Reset in progress..." :loading="state.resettingPowerSwitch2"></BaseButton>
          </div>      
        </FormFieldGroup>
        </template>
      </FormSection>
        
      <!-- <FormSection title="System log">
        <template #body>
          <FormGroupRow>
            <BaseButton @click="downloadSyslog()" text="Download log file" class="btn-outline-secondary" iconClass="fa-solid fa-download" :loading="state.downloadingSyslog" ></BaseButton>
          </FormGroupRow>
        </template>
      </FormSection> -->
    </VScroll>
  </div>
</template>

<script>

import _app from '@/App/App.js';
import FormField from '@/App/components/Form/FormField';
import FormSection from '@/App/components/Form/FormSection';
import FormFieldGroup from '@/App/components/Form/FormFieldGroup';
import BaseField from '@/App/components/Form/BaseField';
import BaseForm from '@/App/components/Form/BaseForm';
import FormSliderField from '@/App/components/Form/FormSliderField';
import FormGroupRow from '@/App/components/Form/FormGroupRow';
import BaseButton from '@/App/components/Common/BaseButton';
import FormOnOffGroupField from '@/App/components/Form/FormOnOffGroupField';
import FormDualSliderField from '@/App/components/Form/FormDualSliderField'
import VScroll from '@/App/components/Common/VScroll'

export default {
  name: 'DeviceSetup',
  props:['device'],
  components: {
    FormField,
    FormSection,
    FormFieldGroup,
    BaseField,
    BaseForm,
    FormSliderField,
    FormGroupRow,
    BaseButton,
    FormOnOffGroupField,
    FormDualSliderField,
    VScroll
  },    
  data() {
    return {
      schema: {
        // name: 'required'
      },
      state: _app.$data.getState('DeviceSetupState', {
        deviceId: this.device?.id,
        displayOn: true,
        touchScreenEnabled: true,          
        backlightLevel: 50,
        blueColorBalance: 50,
        brightness: 50,
        displayContrast: 50,
        gammaValue: 2.2,
        greenColorBalance: 50,
        redColorBalance: 50,
        
        //brightnessLevel: 7.5,
        backlightAutomaticStatus: true,
        ambientSensorMinBacklight: 50,
        ambientSensorMaxBacklight: 100,        
        ambientSensorMinAmbientLight: 0,
        ambientSensorMaxAmbientLight: 10000,
        
        powerSwitch1Status: true,
        powerSwitch2Status: true
      })
    };
  },  
  mounted() {
    if (this.device)
      this.getStatus();
  },
  watch: {
    device() {
      if (this.device)
        this.getStatus();
    }
  },   
  methods: {
    onTurnDisplayCheckChange(on) {
      this.state.backlightLevel = on ? 100 : 0;
    },
    onTouchScreenEnableChange(enable) {
      this.state.touchScreenEnabled = enable;
    },
    onBacklightLevelChange(value) {
      this.state.backlightLevel = value;
      this.state.displayOn = this.state.backlightLevel > 0;
    },    
    getStatus() {
      // let loader = this.$loading.show({
      //     container: this.$refs.maskEl.$el
      // }); 

      Promise.all([
         _app.$api.get('Device/GetDeviceEvTypeState', { params: { deviceId: this.device.id } }),
         _app.$api.get('Device/GetDeviceLocalSettings', { params: { deviceId: this.device.id } }),
      ]).then(
        (results) => {
          // loader.hide();

          const evTypeState = results[0].data;

          if (evTypeState) {
            
            // if (evTypeState.r_reley1_status)
            //   this.$refs.powerSwitch1Toggle.setValue(evTypeState.r_reley1_status === 'ON')
            // if (evTypeState.r_reley2_status)
            //   this.$refs.powerSwitch2Toggle.setValue(evTypeState.r_reley2_status === 'ON')   

            this.state.touchScreenEnabled = true;

            if (!_app.isEmpty(evTypeState.d_brightness))
              this.state.brightness = evTypeState.d_brightness;

            if (!_app.isEmpty(evTypeState.d_contrast))
              this.state.displayContrast = evTypeState.d_contrast;

            if (!_app.isEmpty(evTypeState.d_backlight_level))
              this.state.backlightLevel = evTypeState.d_backlight_level;
              
            if (!_app.isEmpty(evTypeState.d_gamma_value))
              this.state.gammaValue = evTypeState.d_gamma_value;

            if (!_app.isEmpty(evTypeState.d_red_color_balance))
              this.state.redColorBalance = evTypeState.d_red_color_balance;

            if (!_app.isEmpty(evTypeState.d_green_color_balance))
              this.state.greenColorBalance = evTypeState.d_green_color_balance;

            if (!_app.isEmpty(evTypeState.d_blue_color_balance))
              this.state.blueColorBalance = evTypeState.d_blue_color_balance;

            if (!_app.isEmpty(evTypeState.d_ambient_light))
              this.state.brightnessLevel = evTypeState.d_ambient_light;
              
            this.state.touchScreenEnabled = evTypeState.d_touch_matrix_status !== 'Disabled';
          } else {
            this.$app.notify('No status');
          }          

          const deviceLocalSettings = results[1].data || {};

          // ambient sensor backlight automation
          if (!_app.isEmpty(deviceLocalSettings.dBacklightAutomaticStatus))
            this.state.backlightAutomaticStatus = deviceLocalSettings.dBacklightAutomaticStatus;

          if (!_app.isEmpty(deviceLocalSettings.dMinBacklight))
            this.state.ambientSensorMinBacklight = deviceLocalSettings.dMinBacklight;

          if (!_app.isEmpty(deviceLocalSettings.dMaxBacklight))
            this.state.ambientSensorMaxBacklight = deviceLocalSettings.dMaxBacklight;

          if (!_app.isEmpty(deviceLocalSettings.dMinAmbientLight))
            this.state.ambientSensorMinAmbientLight = deviceLocalSettings.dMinAmbientLight;

          if (!_app.isEmpty(deviceLocalSettings.dMaxAmbientLight))
            this.state.ambientSensorMaxAmbientLight = deviceLocalSettings.dMaxAmbientLight;

          // turn desplay off schedules
          this.state.wdTimeOffFrom = deviceLocalSettings.wdTimeOffFrom || null;
          this.state.wdTimeOffTo = deviceLocalSettings.wdTimeOffTo || null;
          this.state.wkndTimeOffFrom = deviceLocalSettings.wkndTimeOffFrom || null;
          this.state.wkndTimeOffTo = deviceLocalSettings.wkndTimeOffTo || null;
          this.state.holTimeOffFrom = deviceLocalSettings.holTimeOffFrom || null;
          this.state.holTimeOffTo = deviceLocalSettings.holTimeOffTo || null;
            
          this.state.displayOn = this.state.backlightLevel > 0;

          // Power switch on/off status
          this.state.powerSwitch1Status = evTypeState.r_reley1_status == 'ON';
          this.state.powerSwitch2Status = evTypeState.r_reley2_status == 'ON';
        },
        () => {
          // loader.hide();
        }
      );     
    },
    applyDisplaySettings(values) {  

      _app.$modal.confirm('Apply display settings', 'Are you sure you want to apply display settings?', (confirmed) => {
        if (confirmed) {
          this.state.applyingDisplaySettings = true;
          _app.$api.post('Device/ApplyDisplaySettings', {
            deviceId: this.device.id,
            dBrightness: this.state.brightness,
            dTouchScreenStatus: this.state.touchScreenEnabled ? 'ON' : 'OFF',
            //dContrast: this.state.displayContrast,
            dBacklightLevel: this.state.backlightLevel,
            dGammaValue: this.state.gammaValue,
            dRedColorBalance: this.state.redColorBalance,
            dGreenColorBalance: this.state.greenColorBalance,
            dBlueColorBalance: this.state.blueColorBalance
          }).then(
            (result) => {              
              this.state.applyingDisplaySettings = false;
              this.getStatus();
              if (result.data.length > 0) {
                for(let i = 0; i < result.data.length; i++) {
                  _app.notifyError(result.data[i]);
                }
              }
            },
            (error) => {
              this.state.applyingDisplaySettings = false;
              _app.handleError(error);
            }            
          );
        }
      });            
    }, 
    applyAmbientSensorSettings(values) {      
      _app.$modal.confirm('Apply ambient sensor settings', 'Are you sure you want to apply ambient sensor settings?', (confirmed) => {
        if (confirmed) {
          this.state.applyingAmbientSensorSettings = true;  
          _app.$api.post('Device/ApplyAmbientSensorSettings', { 
            deviceId: this.device.id,
            dBacklightAutomaticStatus: this.state.backlightAutomaticStatus,
            dMinBacklight: this.state.ambientSensorMinBacklight,
            dMaxBacklight: this.state.ambientSensorMaxBacklight,
            dMinAmbientLight: this.state.ambientSensorMinAmbientLight,
            dMaxAmbientLight: this.state.ambientSensorMaxAmbientLight
          }).then(
            (result) => {
              this.state.applyingAmbientSensorSettings = false;
              this.getStatus();
              if (result.data.length > 0) {
                for(let i = 0; i < result.data.length; i++) {
                  _app.notifyError(result.data[i]);
                }
              }              
            },
            (error) => {
              this.state.applyingAmbientSensorSettings = false;
              _app.handleError(error);
            }            
          );
        }
      });      
    },  
    applyTurnDisplayOffSettings(values) {
      //console.log(values);
      _app.$modal.confirm('Apply turn display off settings', 'Are you sure you want to apply turn display off settings?', (confirmed) => {
        if (confirmed) {
          this.state.applyingTurnDisplayOffSettings = true;  
          _app.$api.post('Device/ApplyTurnDisplayOffSettings', { 
            deviceId: this.device.id,
            wdTimeOffFrom: this.state.wdTimeOffFrom, 
            wdTimeOffTo: this.state.wdTimeOffTo, 
            wkndTimeOffFrom: this.state.wkndTimeOffFrom, 
            wkndTimeOffTo: this.state.wkndTimeOffTo, 
            holTimeOffFrom: this.state.holTimeOffFrom, 
            holTimeOffTo: this.state.holTimeOffTo
          }).then(
            (result) => {
              this.state.applyingTurnDisplayOffSettings = false;
              this.getStatus();
              if (result.data.length > 0) {
                for(let i = 0; i < result.data.length; i++) {
                  _app.notifyError(result.data[i]);
                }
              }              
            },
            (error) => {
              this.state.applyingTurnDisplayOffSettings = false;
              _app.handleError(error);
            }            
          );
        }
      }); 


 
    }, 
    upsReboot() {
      _app.$modal.confirm('UPS safe reboot', 'Are you sure you want to rebooot UPS?', (confirmed) => {
        if (confirmed) {
          this.state.upsRebooting = true;   
          _app.$api.get('Device/UpsReboot', { params: { deviceId: this.device.id } }).then(
            (result) => {
              this.state.upsRebooting = false;
              _app.notify(result.data);
            },
            (error) => {
              this.state.upsRebooting = false;
              _app.handleError(error);
            }            
          );
        }
      });
    }, 
    upsShutdown() {
      _app.$modal.confirm('UPS safe shutdown', 'Are you sure you want to shutdown UPS?', (confirmed) => {
        if (confirmed) {
          this.state.upsShutdown = true;   
          _app.$api.get('Device/UpsShutdown', { params: { deviceId: this.device.id } }).then(
            (result) => {
              this.state.upsShutdown = false;
              _app.notify(result.data);
            },
            (error) => {
              this.state.upsShutdown = false;
              _app.handleError(error);
            }            
          );          
        }
      });
    },
    upsResetGroup(num) {
      _app.$modal.confirm('Reset UPS group ' + num, 'Are you sure you want to reset UPS group ' + num + '?', (confirmed) => {
        if (confirmed) {    
          this.state['upsResetGroup' + num] = true;   
          _app.$api.get('Device/UpsGroup' + num + 'Reset', { params: { deviceId: this.device.id } }).then(
            (result) => {
              this.state['upsResetGroup' + num] = false;
              _app.notify(result.data);
            },
            (error) => {
              this.state['upsResetGroup' + num] = false;
              _app.handleError(error);
            }            
          );
        }
      });
    },
    resetRouter(num) {
      //_app.$modal.confirm('Reset router ' + num, 'Are you sure you want to reset router ' + num + '?', (confirmed) => {
      _app.$modal.confirm('Reset network switch ' + num, 'Are you sure you want to reset network switch ' + num + '?', (confirmed) => {
        if (confirmed) {    
          this.state['resettingRouter' + num] = true;   
          _app.$api.get('Device/Router' + num + 'Reset', { params: { deviceId: this.device.id } }).then(
            (result) => {
              this.state['resettingRouter' + num] = false;
              _app.notify(result.data);
            },
            (error) => {
              this.state['resettingRouter' + num] = false;
              _app.handleError(error);
            }            
          );
        }
      });
    }, 
    onPowerSwitchChange(num, checked) {
      _app.$modal.confirm('Toggle Power switch ' + num + (checked ? ' on' : ' off'), 'Are you sure you want to toggle Power switch ' + num + (checked ? ' ON' : ' OFF') + '?', (confirmed) => {
        if (confirmed) {    
          this.state['togglingPowerSwitch' + num] = true;   
          _app.$api.get('Device/PowerSwitch' + num + 'Turn' + (checked ? 'On' : 'Off'), { params: { deviceId: this.device.id } }).then(
            (result) => {
              this.state['togglingPowerSwitch' + num] = false;
              _app.notify(result.data);
            },
            (error) => {
              this.state['togglingPowerSwitch' + num] = false;
              _app.handleError(error);

              if (num == 1)
                this.$refs.powerSwitch1Toggle.setValue(!checked)
              
              // if (num == 2)
              //   this.$refs.powerSwitch2Toggle.setValue(!checked)                 
            }            
          );
        } else {
            
            if (num == 1)
              this.$refs.powerSwitch1Toggle.setValue(!checked)
            
            // if (num == 2)
            //   this.$refs.powerSwitch2Toggle.setValue(!checked)   
        }
      });
    }, 
    resetPowerSwitch(num) {
      _app.$modal.confirm('Reset power switch ' + num, 'Are you sure you want to reset power switch ' + num + '?', (confirmed) => {
        if (confirmed) {
          this.state['resettingPowerSwitch' + num] = true;
          _app.$api.get('Device/PowerSwitch' + num + 'Reset', { params: { deviceId: this.device.id } }).then(
            (result) => {
              this.state['resettingPowerSwitch' + num] = false;
              _app.notify(result.data);
            },
            (error) => {
              this.state['resettingPowerSwitch' + num] = false;
              _app.handleError(error);
            }            
          );
        }
      });
    },
    downloadSyslog() {
      this.state.downloadingSyslog = true;
      setTimeout(() => {
          this.state.downloadingSyslog = false;
      }, 2000);
    }
  }   
}
</script>

<style scoped>

</style>