<template>

  <base-modal :name="modalName" :title="modalName" v-bind="$attrs" :width="720" @close="close" style="z-index:9999;">
    <template #body>
      <div style="max-height:400px !important; overflow-y:scroll;">
        <div class="text-center">Available models & reports</div>
        <nav class="nav nav-pills flex-column flex-sm-row" style="margin-top:15px; margin-bottom:10px;">
          <a v-for="(entity,index) in entities" :key="index" class="flex-sm-fill text-sm-center nav-link nav-link-drilldown-entities" :class="{ active:(entity.is_default) }" aria-current="page" href="#" @click.prevent="showEntities(index)">{{entity.name }}</a>
        </nav>
        <div v-for="(entity,index) in entities" :key="index" class="div-drilldown-entities" :class="{ visibleSubsList:(index==0), hiddenSubsList:(index>0) }" style="min-height:100px;">
          <div v-for="sub in entities[index].reports" :key="sub.id" class="form-check">
            <input class="form-check-input lab22-chk-drilldown" type="radio" name="subs" :id="sub.id" :value="sub.id" v-model="selectedField">
            <label class="form-check-label" :for="sub.id">{{sub.name}}</label>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <button type="button" @click.prevent="load" class="btn btn-primary">Load report</button>
      <button type="button" @click.prevent="close" class="btn btn-secondary">Cancel</button>
    </template>
  </base-modal>

</template>

<script>
import BaseModal from '@/App/components/Modal/BaseModal';
import _app from '@/App/App'

export default {
  name: 'ReportingReports',
  inheritAttrs: false,
  emits: ['ok'],
  props: {
    title: String,
    Tabs: Object,
    Entities: Object,
    autoRefresh: Boolean
  },
  components: {
    BaseModal,
  }, 
  data() {
    return {
      modalName: this.title,
      entities: this.Entities,
      currentSub: 0,
      selectedField: '',
    };
  },
  mounted() {
    this.showEntities(0);
  },
  methods: {
    showEntities(index) {
      let inputs = document.getElementsByClassName('lab22-chk-drilldown'),
          tabs = this.Tabs;
      for(let i=0; i<inputs.length; i++) {
        for(let j=0; j<tabs.length; j++) {
          if( parseInt(tabs[j].reportId)==parseInt(inputs[i].id) )
            inputs[i].disabled = true;
        }
      }

      if(this.currentSub==index)
        return false;
      
      this.currentSub = index;

      let navs = document.getElementsByClassName('nav-link-drilldown-entities');
      for(let i=0; i<navs.length; i++) {
        if(i==index)
          navs[i].classList.add('active');
        else
          navs[i].classList.remove('active');
      }

      let divs = document.getElementsByClassName('div-drilldown-entities');
      for(let i=0; i<divs.length; i++) {
        if(i==index) {
          divs[i].classList.remove('hiddenSubsList');
          divs[i].classList.add('visibleSubsList');
        } else {
          divs[i].classList.add('hiddenSubsList');
          divs[i].classList.remove('visibleSubsList');
        }
      }
    },
    load() {
      let reportId = this.selectedField,
          show = true,
          tabs = this.Tabs;
      for(let i=0; i<tabs.length; i++) { //not necessary any more, because i handled disabled flag in such cases
        if(tabs[i].reportId==reportId)
          show = false;
      }

      if(show) {
        this.$emit('ok', reportId);
        this.$vfm.hide(this.modalName);
      } else
        _app.$helper.notifyInfo(`Selected report is already open.`);
    },
    close() {
      this.$vfm.hide(this.modalName);
    },
  }
}
</script>

<style>
.visibleSubsList {
  display: block;
}
.hiddenSubsList {
  display: none;
}
</style>