<template>
<Form @submit="onSubmit" :validation-schema="schema" class="form-horizontal form-bordered">
  <base-modal :name="modalName" title="Add new device" @close="close" v-bind="$attrs">
    <template #body>  
      <FormField v-if="!this.$app.user.isCustomerUser()" name="customerId" label="Customer" :topLabel="true" as="select" :data="this.$app.$data.customers" />
      <FormField name="modelId" label="Device model" :topLabel="true" as="select" :data="this.$app.$data.deviceModelLkp" />
      <FormField name="name" label="Device name" :topLabel="true" />
    </template>
    <template #footer>
      <slot name="footer">
        <button @click="close" type="button" class="btn btn-default" data-dismiss="modal">Cancel</button>
        <button type="submit" class="btn btn-primary">Create device</button>
      </slot>
  </template>
  </base-modal>
</Form>
</template>

<script>
import BaseModal from '@/App/components/Modal/BaseModal'
import FormField from '@/App/components/Form/FormField';
import { Form } from "vee-validate";

export default {
  name: 'AddDeviceModal',
  inheritAttrs: false,
  emits: ['create'],
  components: {
    BaseModal,
    FormField,
    Form
  },   
  data() {
    const schema = {
      modelId: 'required',
      name: 'required'      
    }
    if (!this.$app.user.isCustomerUser())
      schema.customerId = 'required';

    return {
      modalName: 'AddDeviceModal',
      schema: schema
    };
  },
  mounted() {
      this.$app.$data.getCustomers().then(data => {
        this.customers = data;
      });
  },
  methods: {
    onSubmit(values) {  
      // console.log(values);

      // this.$vfm.hide(this.modalName);

      // this.$emit('create', this.$app.$data.devices.filter(i => { return i.id == 10; })[0]);
      
      let loader = this.$loading.show({ container: this.$refs.modalEl }); 

      this.$app.$api.post('Device/CreateDevice', values).then(
        result => {
          loader.hide();

          this.$vfm.hide(this.modalName);

          this.$emit('create', result.data);              
        },
        () => {
          loader.hide();      
        }
      );
    },
    close() {
      this.$vfm.hide(this.modalName);
    }
  }
}
</script>

<style scoped>
</style>