<template>
  <Form
    :initial-values="state"
    class="form-horizontal"
    v-bind="$attrs"
  >
    <template v-for="(_, slot) of $slots" v-slot:[slot]="scope">
      <slot :name="slot" :state="state" v-bind="scope"></slot>
    </template>
  </Form>
</template>

<script>
  import { Form } from "vee-validate";

  export default {
    name: 'BaseForm',
    props: {
      state: Object
    },    
    components: {
      Form
    }  
  }
</script>