<template>

  <ToolbBar style="padding-left:1px;">    
    <div class="btn-group align-items-center" role="group" @change="onViewChange">
      <input type="radio" v-model="state.activeView" class="btn-check" name="viewToggle" id="DashboardView" autocomplete="off" :disabled="loading" :value="view.Dashboard">
      <label class="btn btn-outline-primary" for="DashboardView">Dashboard</label>
      <input type="radio" v-model="state.activeView" class="btn-check" name="viewToggle" id="RawDataView" autocomplete="off" :disabled="loading" :value="view.RawData">
      <label class="btn btn-outline-primary" for="RawDataView">Raw data</label>
      <input type="radio" v-model="state.activeView" class="btn-check" name="viewToggle" id="MsgDataView" autocomplete="off" :disabled="loading" :value="view.MsgData">
      <label class="btn btn-outline-primary" for="MsgDataView">Messages</label>
    </div>
    <BaseField v-if="state.activeView == view.RawData || state.activeView == view.MsgData" v-model="state.dateFrom" name="dateFrom" required @change="onPeriodChange" type="datetime-local" style="width:185px; margin-left:15px;" />
    <BaseField v-if="state.activeView == view.RawData || state.activeView == view.MsgData" v-model="state.dateTo" name="dateTo" required @change="onPeriodChange" type="datetime-local" style="width:185px; margin-left:8px;" />
    <BaseField v-if="state.activeView == view.RawData" v-model="state.evTypeId" @change="onEvTypeChange()" placeholder="Field group" as="select" :data="this.EvTypeList" :disabled="loading" style="width:200px" class="ms-2" />
    <button type="button" @click="load()" :disabled="loading" class="btn icon-btn btn-secondary ms-3">
      <span v-show="loading" class="spinner-border spinner-border-sm"></span>
      <i v-show="!loading" class="fa-solid fa-rotate-right"></i>
    </button>
    <button v-if="isLabUser && (state.activeView == view.RawData || state.activeView == view.MsgData)" type="button" @click="exportCsv(state.activeView)" :disabled="loading" class="btn icon-btn btn-secondary ms-2"><i class="fa fa-file-csv"></i></button>

  </ToolbBar>

  <div v-if="state.activeView == view.RawData" style="width:100%;overflow-x: auto;">
    <BaseGrid :columns="gridData.columns" :rows="gridData.rows" :enableVScroll="true" />
  </div>

  <div v-if="state.activeView == view.MsgData" style="width:100%;overflow-x: auto;">
    <BaseGrid :columns="gridMsgData.columns" :rows="gridMsgData.rows" :enableVScroll="true" />
  </div>
  
  <VScroll v-if="state.activeView == view.Dashboard">
    <div v-for="group in state.dashboardGroups" :key="group.id" class="dash-group">
      <div class="dash-group-name">{{group.name}}</div>
      <div class="dash-group-items">
        <div v-for="item in group.items" :key="item.field_id" class="dash-item shadow-sm" @click="onDashboardItemClick(item, $event)" @contextmenu.prevent="onDashboardItemClick(item, $event)">
          <div class="row" style="height:90px !important;">
            <div class="col-9">
              <div class="row" style="height:25px !important;">
                <div class="col">
                  <div class="dash-item-name-wrap d-flex">
                    <div class="dash-item-name">{{item.field_name}}</div>         
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="dash-item-value-wrap d-flex">
                    <span class="dash-item-value" :class="[{ 'alert': item.alert_id !== null }, { 'no-value': item.field_value === null}]">{{this.formatFieldValue(item.field_db_name, item.field_value) || '-'}}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-3">
              <i class="dash-item-icon" :class="item.icon"></i>
            </div>
          </div>
          <div class="row" style="height:70px !important; padding: 0px 10px 10px 10px">
            <div class="col dash-item-chart">
              <apexchart v-if="item.series[0].data.length && item.data_points_24h!==''"
                type="area"
                ref="chart" 
                height="60px"
                :series=item.series
                :options="itemChartOptions" 
              />
            </div>
          </div>
        </div>
      </div>      
    </div>
    <ContextMenu ref="dashItemContextMenu"></ContextMenu>  
  </VScroll>
</template>

<script>
import _app from '@/App/App'
import ToolbBar from '@/App/components/Common/ToolBar'
import BaseField from '@/App/components/Form/BaseField'
import BaseGrid from '@/App/components/Grid/BaseGrid'
import VScroll from '@/App/components/Common/VScroll'
import ContextMenu from '@/App/components/Common/ContextMenu'
import AlertDetailsModal from '@/Modules/Alerts/AlertDetailsModal'
import dayjs from 'dayjs';

const DeviceEventType = 3; //all devices have at least "device" component

const View = {
  Dashboard: 'DASHBOARD',
  RawData: 'RAW_DATA',
  MsgData: 'MSG_DATA'
};

export default {
  name: 'DeviceStatus',
  props:['device'],
  components: {
    ToolbBar,
    BaseField,
    BaseGrid,
    VScroll,
    ContextMenu
  },
  emits: ['refreshStatus'],
  data() {
    return {
      user: this.$store.state.auth.user,
      isLabUser: false,
      gridData: {
        columns: [],
        rows: []
      },
      gridMsgData: {
        columns: [
          {
            title: 'Timestamp',
            dataIndex: 'inserted',
            type: 'DateTime',
            dateTimeKind: 'UTC'
          } ,
          {
            title: 'Message',
            dataIndex: 'msg',
          }
        ],
        rows: []
      },
      components: null,
      EvTypeListFull: [],
      EvTypeList: [],
      yoctoButtons: [],
      autoRefresh: false,
      loading: false,
      state: this.$app.$data.getState('DeviceStatus', {
        activeView: View.Dashboard,
        evTypeId: DeviceEventType,
        dateFrom: dayjs().add(-1, 'hour').format(_app.ISO_FORMAT),
        dateTo: dayjs().endOf('day').format(_app.ISO_FORMAT),
        dashboardGroups: []
      }),
      view: View,
      itemChartOptions: {
        chart: {
          sparkline: {
            enabled: true,
          },
          animations: {
            enabled: false
          },
        },
        stroke: {
          curve: 'smooth',
          width: 1,
          colors: ['#12B39A'],
        },
        fill: {
          colors: ['#12B39A']
        },
        tooltip: {
          enabled: false
        }
      },
      modelId: null,
    };
  },
  mounted() {
    const currentUser = _app.user;
    if(currentUser)
      this.isLabUser = ( _app.$data.Lab22Users.indexOf(currentUser.email) == -1 ) ? false : true;

    this.load();

  },
  watch: {
    device() {
      this.clearComponents();
      this.load();
    }
  },
  methods: {
    formatFieldValue(name, value) { //dashboard view only
      switch(name) {
        case 'e_uptime': return this.secToString(value, true);
        case 'e_humidity': 
          if(this.modelId==16) {
            if( !(_app.$helper.isEmpty(value, false)) )
              return ( parseInt(value)>=900 ) ? 'CLOSED' : 'OPEN';
            else
              return '-';
          } else {
            //if( value!==null && value.toString()!=='')
            if( !(_app.$helper.isEmpty(value, false)) )
              return ( parseInt(value)<100 ) ? 'LIQUID DETECTED' : 'DRY';
            else
              return '-';
          }
        case 'd_backlight_automatic_status': return ( parseInt(value)==1 ) ? 'Enabled' : 'Disabled';
        case 'd2_backlight_automatic_status': return ( parseInt(value)==1 ) ? 'Enabled' : 'Disabled';
        case 'd3_backlight_automatic_status': return ( parseInt(value)==1 ) ? 'Enabled' : 'Disabled';
        case 'd_touch_matrix_status': return ( ( value=='Driver is running' || value=='Enabled' ) ? 'Enabled' : ( ( value==null) ? 'Unknown' : 'Disabled' ) );
        case 'd2_touch_matrix_status': return ( ( value=='Driver is running' || value=='Enabled' ) ? 'Enabled' : ( ( value==null) ? 'Unknown' : 'Disabled' ) );
        case 'd3_touch_matrix_status': return ( ( value=='Driver is running' || value=='Enabled' ) ? 'Enabled' : ( ( value==null) ? 'Unknown' : 'Disabled' ) );
        case 'u_battery_remaining': return this.secToString(value, true);
        case 'u_status': 
          if( !(_app.$helper.isEmpty(value, false)) )
            return ( value=='on line interactive normal' ) ? 'On power grid' : 'On battery';
          else
            return '-';
        case 'rt_relay_status': return ( value=='0') ? 'On' : 'Off';
        default:
          return value;
      }
    },
    secToString(value, displaySeconds) {
      if(value && value !== '-' && value !== '')
        return _app.$helper.formatSeconds(value, displaySeconds);
      else
        return value;
    },
    setDefaultEtType(stateEvTypeId, allEvTypeIds) {
      let found = false;
      for(let i=0; i<allEvTypeIds.length; i++) {
        if(allEvTypeIds[i].id==stateEvTypeId)
          found = true;
      }
      if(!found)
        this.state.evTypeId = DeviceEventType;
    },
    clearComponents() {
      this.components = null;
      this.EvTypeListFull = [];
      this.EvTypeList = [];
    },
    load() {
      if (!this.device)
        return;
      
      this.$emit('refreshStatus');

      this.modelId = this.device.modelId;

      this.loading = true;
      const progress = this.$progress.start();

      this.clearComponents();

      let _this = this;

      Promise.all([
        _this.$app.$api.get('Device/GetDeviceComponentsExt', { params: {deviceId: _this.device.id} }),
        _this.$app.$data.getEventTypeList()
      ]).then(
        result => {
          _this.loading = false;
          progress.finish();

          _this.components = result[0].data;
          _this.EvTypeListFull = result[1];

          _this.EvTypeList = [];

          for(let i=0; i<_this.components.length; i++) {
            if( _this.components[i].event_type_id!==null ) {
              let _name = _this.components[i].label;
              /*let _evType = _this.EvTypeListFull.filter(obj => {
                        return obj.id==_this.components[i].event_type_id;
                      })[0];
                      
              switch(_evType.name) {
                case 'NORPAY_DISPLAY':
                case 'NORPAY_DISPLAY2':
                case 'NORPAY_DISPLAY3': _name = _this.components[i].category + ' ' + _name; break;
                default: break;
              }*/
              _this.EvTypeList.push({id:_this.components[i].event_type_id, name:_name});
            }

            if( _this.components[i].key=='Yocto5Buttons' ) {
              _this.yoctoButtons = JSON.parse(_this.components[i].json_settings).items.filter(obj => {
                                      return obj.active==true;
                                    });
            }
          }
          //console.log(_this.EvTypeListFull);
          //console.log(_this.components);
          //console.log(_this.EvTypeList);
          //console.log(_this.yoctoButtons);

          switch(_this.state.activeView) {
            case View.Dashboard:
              _this.loadDashboardData();
              break;
            case View.RawData:
              _this.setDefaultEtType(_this.state.evTypeId, _this.EvTypeList);
              _this.updateGridColumns();
              _this.loadRawData();
              break; 
            case View.MsgData:
              _this.loadMsgData();
              break;          
          }
        },
        error => {
          _this.loading = false;
          progress.finish();

          _this.$app.handleError(error);
        }
      );

    },
    onPeriodChange() {
      switch(this.state.activeView) {
        case View.RawData:
          this.gridData.rows = [];
          this.updateGridColumns();
          this.loadRawData();
          break; 
        case View.MsgData:
          this.loadMsgData();
          break;          
      }
    },
    loadMsgData() {
      this.gridMsgData.rows = [];

      this.loading = true;
      const progress = this.$progress.start();
      this.$app.$api.get('EventType/GetDeviceEvTypeMsgData', { 
        params: { 
          deviceId: this.device.id,
          dateFrom: this.$app.toUtcDate(this.state.dateFrom),
          dateTo: this.$app.toUtcDate(this.state.dateTo),
        } 
      }).then(
        result => {
          this.loading = false;
          progress.finish();

          this.gridMsgData.rows = result.data;
        },
        error => {
          this.loading = false;
          progress.finish();

          this.$app.handleError(error);
        }
      );
    },
    loadRawData() {
      //console.log(this.device.id, this.state.evTypeId, this.state.dateFrom, this.state.dateTo)
      this.loading = true;
      const progress = this.$progress.start();
      this.$app.$api.get('Device/GetDeviceEvTypeRawDataV2', { 
        params: { 
          deviceId: this.device.id,
          evTypeId: this.state.evTypeId,
          dateFrom: this.$app.toUtcDate(this.state.dateFrom),
          dateTo: this.$app.toUtcDate(this.state.dateTo),
        } 
      }).then(
        result => {
          this.loading = false;
          progress.finish();

          for(let i=0; i<result.data.length; i++) {
            result.data[i].status_id = (result.data[i].status_id.toString()=='1') ? 'No' : 'Yes';
            if(result.data[i].rt_relay_status)
              result.data[i].rt_relay_status = (result.data[i].rt_relay_status.toString()=='0') ? 'On' : 'Off';
          }

          this.gridData.rows = result.data;
        },
        error => {
          this.loading = false;
          progress.finish();

          this.$app.handleError(error);
        }
      );
    },
    componentExists(id) {
      if (!this.components)
        return false;

      let c = this.components,
          r = false;
      for(let i=0; i<c.length; i++) {
        if(c[i].event_type_id==id)
          r = true;
      }
      return r;
    },
    checkTouchMatrix(item) {
      let comp = this.components,
          ret = false,
          num = 0;

      if(item.field_db_name=='d_touch_matrix_status')
        num = 1;
      else if (item.field_db_name=='d2_touch_matrix_status')
        num = 2;
      else if (item.field_db_name=='d3_touch_matrix_status')
        num = 3;

      if(num>0) {
        for(let i=0; i<comp.length; i++) {
          if(comp[i].category=='Display' && comp[i].number==num && comp[i].touch_matrix!==null)
            ret = true;
        }
      }
      else
        ret = true;

      return ret;
    },
    loadDashboardData() {
      this.loading = true;
      Promise.all([
        this.$app.$api.get('Device/GetDeviceDashboardData', { params: { deviceId: this.device.id } }),
        this.$app.$api.get('Device/GetDeviceEvTypeState', { params: { deviceId: this.device.id } })
      ]).then(
        results => {
          this.loading = false;

          let dataDash = results[0].data,
              dataState = results[1].data;

          if (dataDash) {
            //for dashboard sections we keep only groups that are registered in components
            dataDash = dataDash.filter(obj => {
                return this.componentExists(obj.measurement_event_type_id) && this.checkTouchMatrix(obj);
              });

            switch(this.modelId) {
              case 1: //stari Skyss model
                  break;
              case 15: //novi G7 model
                  for(let i=0; i<dataDash.length; i++) {
                    dataDash[i].field_name = dataDash[i].field_name.replace("D2 ", "");
                    dataDash[i].field_name = dataDash[i].field_name.replace("D3 ", "");
                  }
                  break;
              case 16: //novi FLOW safety model
                  for(let i=0; i<dataDash.length; i++) {
                    if(dataDash[i].field_db_name==='e_humidity') {
                      dataDash[i].field_name = 'AED Compartment';
                      dataDash[i].icon = 'inf-dash-item-aed';
                      dataDash[i].data_points_24h = '';
                      dataDash[i].series = {};
                      dataDash[i].measurement_id = null;
                      dataDash[i].measurement_name = null;
                    }
                    dataDash[i].field_name = dataDash[i].field_name.replace("D2 ", "");
                    dataDash[i].field_name = dataDash[i].field_name.replace("D3 ", "");
                  }
                  break;
              case 22: //novi Skyss model
                  break;
              default:
                  break;
            }
          }

          if (dataState) {
              dataState.d_out_of_order_status =  (dataState.d_source=='ARGB')  ? 'Out of order' : 'Content running'; //bz (13.10.2022): special case when one NULL field is dependant on another field
              dataState.d2_out_of_order_status = (dataState.d2_source=='ARGB') ? 'Out of order' : 'Content running'; //bz (19.1.2023): special case when one NULL field is dependant on another field
              dataState.d3_out_of_order_status = (dataState.d3_source=='ARGB') ? 'Out of order' : 'Content running'; //bz (19.1.2023): special case when one NULL field is dependant on another field
          }

          this.renderDashboard(dataDash, dataState);
        },
        errors => {
          this.loading = false;

          this.$app.handleError(errors);
        }        
      );
    },
    onViewChange(e) {
      if (this.state.activeView == View.RawData) {
        this.updateGridColumns();
      }
      this.load();
    },
    onEvTypeChange() {

      this.gridData.rows = [];

      this.updateGridColumns();

      this.loadRawData();
    },
    getEventIdByName(name) {
      let o = this.EvTypeListFull.filter(obj => {
                return obj.name===name;
              })[0];
      return o.id;
    },
    updateGridColumns() {

      const columns = [
        {
          title: 'Timestamp',
          dataIndex: 'device_dt',
          type: 'DateTime',
          dateTimeKind: 'UTC'
        }       
      ];

      let id = this.state.evTypeId;

      //switch(id) {
      switch(true) {
        case id == this.getEventIdByName('NORPAY_DEVICE'):
          //#region Device
          columns.push(            
            {
              //title: 'Status',
              title: 'Out of order',
              dataIndex: 'status_id'
            },
            {
              title: 'Heater status',
              dataIndex: 'e_hater_status'
            },            
            {
              title: 'Temperature Protection',
              dataIndex: 'e_temp_protection'
            },
            {
              title: 'Air filter(s) status',
              dataIndex: 'e_air_filters'
            },
            {
              title: 'Imotion enclosure',
              dataIndex: 'e_enclosure_status'
            },
            {
              title: 'Fan 1 speed',
              dataIndex: 'e_fan1_speed'
            },
            {
              title: 'Fan 1 speed target',
              dataIndex: 'e_fan1_target'
            },
            /*{
              title: 'Fan 1 speed target (%)',
              dataIndex: 'e_fan1_target_ratio'
            },*/
            {
              title: 'Fan 2 speed',
              dataIndex: 'e_fan2_speed'
            },
            {
              title: 'Fan 2 speed target',
              dataIndex: 'e_fan2_target'
            },
            /*{
              title: 'Fan 2 speed target (%)',
              dataIndex: 'e_fan2_target_ratio'
            },*/
            {
              title: 'Fan 3 speed',
              dataIndex: 'e_fan3_speed'
            },
            {
              title: 'Fan 3 speed target',
              dataIndex: 'e_fan3_target'
            },
            /*{
              title: 'Fan 3 speed target (%)',
              dataIndex: 'e_fan3_target_ratio'
            },*/
            {
              title: 'Fan 4 speed',
              dataIndex: 'e_fan4_speed'
            },
            {
              title: 'Fan 4 speed target',
              dataIndex: 'e_fan4_target'
            },
            /*{
              title: 'Fan 4 speed target (%)',
              dataIndex: 'e_fan4_target_ratio'
            },*/
            {
              title: 'Internal temperature',
              dataIndex: 'e_internal_temp'
            },
            {
              title: 'Intake temperature',
              dataIndex: 'e_intake_temp'
            },
            // {
            //   title: 'ECU firmware version',
            //   dataIndex: 'e_firmware_ver'
            // },
            {
              title: (this.modelId==16) ? 'AED Compartment' : 'Liquid ingress sensor',
              dataIndex: 'e_humidity'
            },
            {
              title: 'ECU uptime (sec)',
              dataIndex: 'e_uptime',
              align: 'center'
            },
            {
              title: 'Client version',
              dataIndex: 'client_version'
            },
          );
          break;
          //#endregion
        case id == this.getEventIdByName('NORPAY_DISPLAY'):
          //#region Display
          columns.push(            
            /*{
              title: 'Display status',
              dataIndex: 'd_display_status'
            },
            {
              title: 'Out of order message',
              dataIndex: 'd_out_of_order_status'
            },
            {
              title: 'Touchscreen status',
              dataIndex: 'd_touch_screen_status'
            },
            {
              title: 'Disable automatic source search',
              dataIndex: 'd_source_search_status'
            },*/
            {
              title: 'Source: HDMI/Displayport',
              dataIndex: 'd_source'
            },
            {
              //title: 'Brightness',
              title: 'Black level',
              dataIndex: 'd_brightness'
            },
            /*{
              title: 'Contrast',
              dataIndex: 'd_contrast'
            },*/
            {
              title: 'Backlight level',
              dataIndex: 'd_backlight_level'
            },
            {
              title: 'GAMMA value',
              dataIndex: 'd_gamma_value'
            },
            {
              title: 'Red color balance',
              dataIndex: 'd_red_color_balance'
            },
            {
              title: 'Green color balance',
              dataIndex: 'd_green_color_balance'
            },
            {
              title: 'Blue color balance',
              dataIndex: 'd_blue_color_balance'
            },
            {
              title: 'Ambient light (lux)',
              dataIndex: 'd_ambient_light'
            },
            {
              title: 'Backlight automation',
              dataIndex: 'd_backlight_automatic_status',
              align: 'center'
            },
            {
              title: 'Touch screen status',
              dataIndex: 'd_touch_matrix_status'
            },
            {
              title: 'Panel controller firmware',
              dataIndex: 'd_firmware'
            },
            {
              title: 'Minimum ambient light (lux)',
              dataIndex: 'd_min_ambient_light'
            },
            {
              title: 'Maximum ambient light (lux)',
              dataIndex: 'd_max_ambient_light'
            },
            {
              title: 'Minimum backlight (%)',
              dataIndex: 'd_min_backlight'
            },
            {
              title: 'Maximum backlight (%)',
              dataIndex: 'd_max_backlight'
            }
          );          
          break;
          //#endregion
        case id == this.getEventIdByName('NORPAY_DISPLAY2'):
          //#region Display2
          columns.push(            
            {
              title: 'Source: HDMI/Displayport',
              dataIndex: 'd2_source'
            },
            {
              title: 'Black level',
              dataIndex: 'd2_brightness'
            },
            {
              title: 'Backlight level',
              dataIndex: 'd2_backlight_level'
            },
            {
              title: 'GAMMA value',
              dataIndex: 'd2_gamma_value'
            },
            {
              title: 'Red color balance',
              dataIndex: 'd2_red_color_balance'
            },
            {
              title: 'Green color balance',
              dataIndex: 'd2_green_color_balance'
            },
            {
              title: 'Blue color balance',
              dataIndex: 'd2_blue_color_balance'
            },
            {
              title: 'Ambient light (lux)',
              dataIndex: 'd2_ambient_light'
            },
            {
              title: 'Backlight automation',
              dataIndex: 'd2_backlight_automatic_status',
              align: 'center'
            },
            {
              title: 'Touch screen status',
              dataIndex: 'd2_touch_matrix_status'
            },
            {
              title: 'Panel controller firmware',
              dataIndex: 'd2_firmware'
            },
            {
              title: 'Minimum ambient light (lux)',
              dataIndex: 'd2_min_ambient_light'
            },
            {
              title: 'Maximum ambient light (lux)',
              dataIndex: 'd2_max_ambient_light'
            },
            {
              title: 'Minimum backlight (%)',
              dataIndex: 'd2_min_backlight'
            },
            {
              title: 'Maximum backlight (%)',
              dataIndex: 'd2_max_backlight'
            }
          );          
          break;
          //#endregion
        case id == this.getEventIdByName('NORPAY_DISPLAY3'):
          //#region Display3
          columns.push(            
            {
              title: 'Source: HDMI/Displayport',
              dataIndex: 'd3_source'
            },
            {
              title: 'Black level',
              dataIndex: 'd3_brightness'
            },
            {
              title: 'Backlight level',
              dataIndex: 'd3_backlight_level'
            },
            {
              title: 'GAMMA value',
              dataIndex: 'd3_gamma_value'
            },
            {
              title: 'Red color balance',
              dataIndex: 'd3_red_color_balance'
            },
            {
              title: 'Green color balance',
              dataIndex: 'd3_green_color_balance'
            },
            {
              title: 'Blue color balance',
              dataIndex: 'd3_blue_color_balance'
            },
            {
              title: 'Ambient light (lux)',
              dataIndex: 'd3_ambient_light'
            },
            {
              title: 'Backlight automation',
              dataIndex: 'd3_backlight_automatic_status',
              align: 'center'
            },
            {
              title: 'Touch screen status',
              dataIndex: 'd3_touch_matrix_status'
            },
            {
              title: 'Panel controller firmware',
              dataIndex: 'd3_firmware'
            },
            {
              title: 'Minimum ambient light (lux)',
              dataIndex: 'd3_min_ambient_light'
            },
            {
              title: 'Maximum ambient light (lux)',
              dataIndex: 'd3_max_ambient_light'
            },
            {
              title: 'Minimum backlight (%)',
              dataIndex: 'd3_min_backlight'
            },
            {
              title: 'Maximum backlight (%)',
              dataIndex: 'd3_max_backlight'
            }
          );          
          break;
          //#endregion
        case id == this.getEventIdByName('NORPAY_UPS'):
          //#region Ups
          columns.push(
            {
              //title: 'Ups device status',
              title: 'Status',
              dataIndex: 'u_status'
            },
            {
              title: 'Input Voltage',
              dataIndex: 'u_input_voltage'
            },
            {
              title: 'Output current',
              dataIndex: 'u_ouput_power'
            },
            {
              title: 'Battery status',
              dataIndex: 'u_battery_status'
            },
            {
              //title: 'Charging remaining time',
              title: 'Battery capacity (sec)',
              dataIndex: 'u_battery_remaining'
            }
            // {
            //   title: 'Charging est. remaining',
            //   dataIndex: 'u_charging_remaining'
            // },
            // {
            //   title: 'Uptime',
            //   dataIndex: 'u_uptime'
            // },
            /*{
              title: 'Last Safe Reboot',
              dataIndex: 'u_last_reboot'
            },
            {
              title: 'Last Safe Reboot',
              dataIndex: 'u_last_safe_off'
            }*/
          );
          break;
          //#endregion
        case id == this.getEventIdByName('NORPAY_POWER_RELEY'):
          //#region PowerSwitch
          columns.push(
            {
              //title: 'Relay digital port 1 status',
              //title: 'Equipment PC + LCD status',
              title: this.getColumnName(this.components, 'NetworkRelay', 1, 'r_reley1_status', 'Port 1'),
              dataIndex: 'r_reley1_status',
              align: 'center'
            },
            {
              //title: 'Relay digital port 2 status',
              //title: 'Beaglebone status',
              title: this.getColumnName(this.components, 'NetworkRelay', 1, 'r_reley2_status', 'Port 2'),
              dataIndex: 'r_reley2_status',
              align: 'center'
            }           
          );
          break;
          //#endregion
        case id == this.getEventIdByName('NORPAY_POWER_RELEY2'):
          //#region PowerSwitch
          columns.push(
            {
              //title: 'Equipment PC + LCD status',
              title: this.getColumnName(this.components, 'NetworkRelay', 2, 'r2_reley1_status', 'Port 1'),
              dataIndex: 'r2_reley1_status',
              align: 'center'
            },
            {
              //title: 'Beaglebone status',
              title: this.getColumnName(this.components, 'NetworkRelay', 2, 'r2_reley2_status', 'Port 2'),
              dataIndex: 'r2_reley2_status',
              align: 'center'
            }           
          );
          break;
          //#endregion
        case id == this.getEventIdByName('NORPAY_ROUTER2'):
          //#region Router
          columns.push(
            {
              //title: 'Port status',
              title: this.getColumnName(this.components, 'NetworkRouterWithRelay', 1, 'rt_relay_status', 'Port status'),
              dataIndex: 'rt_relay_status'
            }/*, 
            {
              //title: 'Router 1 status',
              title: 'Network switch 1 status',
              dataIndex: 'rt_router1_status'
            },
            {
              //title: 'Router 2 status',
              title: 'Network switch 2 status',
              dataIndex: 'rt_router2_status'
            }*/
          );
          break;
          //#endregion
        case id == this.getEventIdByName('NORPAY_CAMERA'):  
          //#region Camera
          columns.push(
            {
              //title: 'Status',
              title: 'Uptime',
              dataIndex: 'c_status'
            },
            {
              title: 'Line cross. counter',
              dataIndex: 'c_line_crossing_cnt_1'
            },
            /*{
              title: 'Line cross. 2 counter',
              dataIndex: 'c_line_crossing_cnt_2'
            },
            {
              title: 'Line cross. 3 counter',
              dataIndex: 'c_line_crossing_cnt_3'
            },
            {
              title: 'Line cross. 4 counter',
              dataIndex: 'c_line_crossing_cnt_4'
            },*/
          );
          break;
          //#endregion
        case id == this.getEventIdByName('NORPAY_CAMERA2'):
          //#region Camera2
          columns.push(
            {
              //title: 'Status',
              title: 'Uptime',
              dataIndex: 'c2_status'
            },
            {
              title: 'Line cross. counter',
              dataIndex: 'c2_line_crossing_cnt_1'
            },
            /*{
              title: 'Line cross. 2 counter',
              dataIndex: 'c2_line_crossing_cnt_2'
            },
            {
              title: 'Line cross. 3 counter',
              dataIndex: 'c2_line_crossing_cnt_3'
            },
            {
              title: 'Line cross. 4 counter',
              dataIndex: 'c2_line_crossing_cnt_4'
            },*/
          );
          break;
          //#endregion
        case id == this.getEventIdByName('NORPAY_YOCTO'):
          //#region Yocto
          if(this.yoctoButtons.length>0) {
            for(let i=0; i<this.yoctoButtons.length; i++) {
              columns.push({
                title: this.yoctoButtons[i].logicalName,
                dataIndex: 'yocto_'+this.yoctoButtons[i].number
              });
            }
          }
          break;
          //#endregion
      }
      
      this.gridData.columns = columns;
    },
    getColumnName(components, category, categoryNum, dataIndex, defaultTitle) {
      let component = components.filter(obj => {
                        return obj.category==category && obj.number==categoryNum;
                      })[0],
          ret;
      
      switch(dataIndex) {
        case 'rt_relay_status': ret = (component.label_port1==null || component.label_port1=='') ? defaultTitle : component.label_port1; break;
        case 'r_reley1_status': ret = (component.label_port1==null || component.label_port1=='') ? defaultTitle : component.label_port1; break;
        case 'r_reley2_status': ret = (component.label_port2==null || component.label_port2=='') ? defaultTitle : component.label_port2; break;
        default: ret = defaultTitle; break;
      }

      return ret;
    },
    renderDashboard(items, values) {
      const groups = [];
      let group = null;
      for(let i = 0; i < items.length; i++) {
        const item = items[i];
        
        item.field_value = values[item.field_db_name];
        
        /*if (item.field_value == null) //bz (30.7.2022): commented this because Infinitus sent email (29.7.2022) which cards need to be displayed no matter what the value
          continue;*/
        
        item.icon = this.getDashboardItemIcon(item);
        
        item.series = [{data:{}}];
        /*try {
          item.series = [{
            data: item.data_points_24h ? item.data_points_24h.split(',').map(i => { return parseInt(i); }) : []
          }];
        }
        catch(err) {
          console.log(err);
        }*/

        if (group?.name !== item.group_name) {
          group = {
            id: groups.length + 1,
            name: item.group_name,
            items: []
          };
          groups.push(group);
        }        
        group.items.push(item);
      }

      this.state.dashboardGroups = groups;

      this.loadSparklines();
    },
    loadSparklines() {
        let _this = this;
        for(let grp=0; grp<_this.state.dashboardGroups.length; grp++) {
          for(let itm=0; itm<_this.state.dashboardGroups[grp].items.length; itm++) {
            let item = _this.state.dashboardGroups[grp].items[itm],
                d = new String();

            this.$app.$api.get('Analytics/GetAnalyticsDataTable', {
              params: { 
                eventTypeId: item.measurement_event_type_id, 
                devices: this.device.id, 
                measurements: item.measurement_id,  
                dateFrom: dayjs().add(-25, 'hour').format(this.$app.ISO_FORMAT).slice(0,-5)+'00:00', 
                dateTo: dayjs().add(1, 'hour').format(this.$app.ISO_FORMAT).slice(0,-5)+'00:00', 
                minutesRound: 60,
                groupDevices: false,
                limit: 24 
              }
            }).then(
              result => { 
                for(let i=0; i<result.data.length; i++) {
                  let val = parseFloat(result.data[i]['m_'+item.measurement_id]).toString(),
                      add = (i>0) ? ',' : '';
                  d += add+val;
                }
                try {
                  item.data_points_24h = d || '';
                  item.series = [{
                    data: item.data_points_24h ? item.data_points_24h.split(',').map(i => { return parseFloat(i); }) : []
                  }];
                }
                catch(err) {
                  console.log(err);
                }
              },
              error => {
                console.log(error);
              }
            );
          }
        }
    },
    getDashboardItemIcon(item) {
      /*switch(item.field_db_name) {
        default:
          return 'fa-bolt-lightning';
      }*/
      if(item.icon) {
        return item.icon;
      } else
        return 'inf-dash-item-default';
    },
    onDashboardItemClick(item, e) {

      var menuItems = [];

      if (item.measurement_id !== null) {
        menuItems.push({
          text: 'Analyse',
          iconCls: 'fa-square-poll-vertical',
          handler: (ctx) => {
            this.$router.push({
              name: 'analytics',              
              params: { 
                data: JSON.stringify({ 
                  metric: {
                    deviceId: this.device.id, 
                    deviceName: this.device.name, 
                    metricId: item.measurement_id,
                    metricName: item.measurement_name,
                    evTypeId: item.measurement_event_type_id
                  }
                })
              }
            });            
          }
        });
      }
      
      menuItems.push({
        text: 'Show raw data',
        iconCls: 'fa-solid fa-align-justify',
        handler: (ctx) => {
          //console.log(ctx)
          this.state.evTypeId = ctx.measurement_event_type_id;            
          this.state.activeView = View.RawData;
          this.updateGridColumns();
          this.load();
        }
      });

      if (item.alert_id) {
        menuItems.push({
          text: 'Alert details',
          iconCls: 'fa-solid fa-bell',
          handler: (ctx) => {
            this.$vfm.show({
              component: AlertDetailsModal,
              bind: {
                'id': ctx.alert_id
              }
            }); 
          }
        });
      }

      this.$refs.dashItemContextMenu.open(menuItems, e, item);
    },
    isValidDate(dateString) {
      const datePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/;
      if (!datePattern.test(dateString)) {
        return false;
      }

      const date = new Date(dateString);

      return date instanceof Date && !isNaN(date);
    },
    exportCsv(view) {
      let cols = (view==this.view.RawData) ? this.gridData.columns : this.gridMsgData.columns;
      if(cols.length==0) {
        _app.$helper.notifyInfo('Columns definition not available');
        return false;
      }

      const progress = this.$progress.start(),
            _deviceId = this.device.id,
            endpoint = (view==this.view.RawData) ? 'Device/GetDeviceEvTypeRawDataV2Full' : 'EventType/GetDeviceEvTypeMsgDataFull',
            _params =  (view==this.view.RawData) ? { deviceId: _deviceId, evTypeId: this.state.evTypeId, dateFrom: this.$app.toUtcDate(this.state.dateFrom), dateTo: this.$app.toUtcDate(this.state.dateTo) } : { deviceId: _deviceId, dateFrom: this.$app.toUtcDate(this.state.dateFrom), dateTo: this.$app.toUtcDate(this.state.dateTo) } ;

      this.$app.$api.get(endpoint, { 
        params: _params
      }).then(
        result => {
          progress.finish();

          let rows = result.data,
              separator = ';';

          if(rows.length==0) {
              _app.$helper.notifyInfo('No data available to export');
              return false;
          }

          const progress2 = this.$progress.start();

          try {
            let col = [],
                csv = [];

            for (let i=0; i < cols.length; i++) {
                let val = cols[i].title.replace(/(\r\n|\n|\r)/gm, '').replace(/(\s\s)/gm, ' ')
                val = val.replace(/"/g, '""');
                col.push('"' + val + '"');
            }
            csv.push(col.join(separator));

            for (let i=0; i < rows.length; i++) {
                let row = [];
                for (const [key, value] of Object.entries(rows[i])) {
                    let val = (value==null) ? '' : value;

                    if( this.isValidDate(val) )
                        val = _app.$helper.formatDate(val, 'DD.MM.YY HH:mm:ss', 'UTC')
                    else
                        val = val.toString().replace(/(\r\n|\n|\r)/gm, '').replace(/(\s\s)/gm, ' ');

                    val = val.replace(/"/g, '""');
                    row.push('"' + val + '"');
                }
                csv.push(row.join(separator));
            }

            let csv_string = csv.join('\n'),
                prefix = (view==this.view.RawData) ? 'rawData' : 'msgData',
                filename = prefix + '_deviceId-' + _deviceId.toString() + '_' + new Date().toLocaleDateString() + '.csv',
                link = document.createElement('a');
            
            link.style.display = 'none';
            link.setAttribute('target', '_blank');
            link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv_string));
            link.setAttribute('download', filename);
            document.body.appendChild(link);

            progress2.finish();

            link.click();
            document.body.removeChild(link);
          }
          catch(Err) {
            progress2.finish();
            _app.$helper.notifyInfo(Err.message);
            this.$app.handleError(Err);
          }
        },
        error => {
          progress.finish();
          this.$app.handleError(error);
        }
      );
    },
  }
}
</script>

<style scoped>
.toolbar {
  border-top-width: 0px;
  margin-top: 2px;
}

.btn-group label.btn {
  min-width: 100px;
}

.dash-group-name {
  /*color: #bbc5d6;
  font-weight: bold;
  font-size:14px;*/
  margin-bottom: 10px;
  font-size: 15px;
  color: #fff;
  font-weight: 400;
  font-style: normal;
  text-transform: uppercase;
}

.dash-group-items {
  display: inline-block;
}

.dash-item {
  width: 250px;
  height: 160px;
  float:left;
  margin-right: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  background-color: #262626;
  color: #fff;
  border: 0;
}

.dash-item:hover {
  cursor: pointer;
}

.dash-item-icon {
  display: block;
  width: 32px;
  height: 32px;
  background-position: center center;
  background-repeat: no-repeat;
  margin-top: 20px;
}

.dash-item-name-wrap {
  padding: 10px 10px;
  padding-right: 0px;
}

.dash-item-name {
  color: #fff;
  font-size: 12px;
}

.dash-item-value {
  font-size: 20px;
  padding: 10px 10px 0px 10px;
}

.dash-item-value.alert {
  color: #FF19FF;
}

.dash-item-value.no-value {
  color: #fff;
}
</style>