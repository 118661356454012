<template>

<Form @submit="onSubmit" ref="form" class="form-horizontal form-bordered">
  <base-modal :name="modalName" v-bind="$attrs" title="Report configuration" :width="600" @close="close">
    <template #body> 
      <div class="row">
        <div class="col-12">
          <FormField name="jsonConfig" type="text" label="Paste json configuration or report link" :topLabel="true" />
        </div>
      </div>
      <div class="row">
        <div class="col-12" id="col-scripts" style="max-height:400px !important; overflow-y:scroll;">
          <pre id="col-json" style="opacity:0.8;"></pre>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="row" style="width:100%;">
        <div class="col-6" id="col-scripts">
          <button @click="formatJson" type="button" class="btn btn-outline-secondary" style="margin-right:5px;">Format json</button>
          <!--<button type="button" @click.prevent="copy" class="btn btn-outline-secondary">Copy json</button>-->
        </div>
        <div class="col-6">
          <button @click="close" type="button" class="btn btn-default" data-dismiss="modal" style="margin-left:5px; float:right;">Cancel</button>
          <button type="submit" class="btn btn-primary" style="float:right;">Load query</button>
        </div>
      </div>
    </template>
  </base-modal>
</Form>

</template>

<script>

import BaseModal from '@/App/components/Modal/BaseModal';
import FormField from '@/App/components/Form/FormField';
import { Form } from "vee-validate";
import _app from '@/App/App'

export default {
  name: 'ReportingConfigModal',
  props: {
    
  },
  inheritAttrs: false,
  emits: ['ok'],
  components: {
    BaseModal,
    FormField,
    Form
  }, 
  data() {
    return {
      modalName: 'Report configuration',
    };
  },
  mounted() {
    let div = document.getElementById('col-scripts');
    const script = document.createElement('script');
    try {
      script.src = 'https://unpkg.com/format-to-json@3.0.3/fmt2json.min.js';
      div.appendChild(script);
    } catch(err) {
      console.log(err);
    }
  },
  methods: {
    formatJson() {
        let val = document.getElementById('jsonConfig').value;
        if(val=='') {
          _app.$helper.notifyWarning('No value provided in configuration field');
          return false;
        }
        
        document.getElementById('col-json').innerText = '';

        if(this.validateJson(val)) {
          try {
            var fmt2json = window.fmt2json;
            document.getElementById('col-json').innerText = fmt2json(val);
          }
          catch(err) {
            _app.$helper.notifyWarning('Json formatter did not load yet or correctly. Please, try again.');
          }
        } else
          this.formatJsonFromShareLink(val);
    },
    validateJson(txt) {
      try {
        let json = JSON.parse(txt),
            model = json.modelId;

        if(!model || model==undefined)
          return false;
        else
          return true;
      } catch(err) {
          return false;
      }
    },
    formatJsonFromShareLink(val) {
      let loader = this.$loading.show({ container: this.$refs.form.$el });
      
      _app.$api.get('Reporting/GetReportShareLink', { params: { guid: val } }).then(result => {
          loader.hide();
          let json = result.data;
          if(this.validateJson(json.queryJson)) {
            var fmt2json = window.fmt2json;
            document.getElementById('col-json').innerText = fmt2json( JSON.stringify(json.queryJson) );
          } else {
            _app.$helper.notifyWarning('Text/Link in configuration field is not in valid json format and/or does not contain correct model info');
            document.getElementById('col-json').innerText = '';
          }
      }, (error) => {
        loader.hide();
        _app.handleError(error);
      });
    },
    /*copy() {
      let text = document.getElementById('col-json').innerText;
      if(text!=='')
        _app.$helper.copyToClipboard(text);
    },*/
    onSubmit(values) {
      let val = values.jsonConfig,
          json;

      if(val=='') {
        _app.$helper.notifyWarning('No value provided in configuration field');
        return false;
      }

      if(this.validateJson(val)) {

        json = JSON.parse(val);
        this.$emit('ok', json);
        this.$vfm.hide(this.modalName);

      } else {

        let loader = this.$loading.show({ container: this.$refs.form.$el });
      
        _app.$api.get('Reporting/GetReportShareLink', { params: { guid: val } }).then(result => {
            loader.hide();
            let json = result.data.queryJson;
            if(this.validateJson(json)) {
              this.$emit('ok', JSON.parse(json));
              this.$vfm.hide(this.modalName);
            } else {
              _app.$helper.notifyWarning('Text/Link in configuration field is not in valid json format and/or does not contain correct model info');
              document.getElementById('col-json').innerText = '';
              return false;
            }
        }, (error) => {
          loader.hide();
          _app.handleError(error);
          return false;
        });

      }

    },
    close() {
      this.$vfm.hide(this.modalName);
    }
  }
}
</script>

<style scoped>
 /* */
</style>