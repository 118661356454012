<template>

<Form @submit="save" :validation-schema="schema" class="form-horizontal form-bordered">
  <base-modal :name="modalName" :title="getTitle()" v-bind="$attrs" :width="350" @close="close">
    <template #body>  
      <FormField name="resolutionId" label="Resolution" :topLabel="true" as="select" :data="resolutionList" />
      <FormField name="comment" label="Comment" as="textarea" style="height:150px;" :topLabel="true" />
    </template>
    <template #footer>
      <button @click="close" type="button" class="btn btn-default" data-dismiss="modal">Cancel</button>
      <button type="submit" class="btn btn-primary">Close alert</button>
    </template>
  </base-modal>
</Form>

</template>

<script>

import BaseModal from '@/App/components/Modal/BaseModal'
import FormField from '@/App/components/Form/FormField';
import { Form } from "vee-validate";

import _app from '@/App/App'

export default {
  name: 'CloseAlertModal',
  props: {
    closeAlerts: Array
  },
  inheritAttrs: false,
  emits: ['save'],
  components: {
    BaseModal,
    FormField,
    Form
  },   
  data() {
    return {
      modalName: 'CloseAlertModal',
      resolutionList: [],      
      schema: {
        resolutionId: 'required'
      }
    };
  },
  mounted() {
    _app.$api.get('Alert/GetAlertCloseResolutionList').then(result => {
      this.resolutionList = result.data;
    }, _app.handleError);
  },
  methods: {
    getTitle() {
      return this.closeAlerts.length ==  1 ? 'Close alert (id: ' + this.closeAlerts[0] + ')' : 'Close ' + this.closeAlerts.length + ' alerts'
    },
    save(data) {  

      data.idList = this.closeAlerts;
      
      let loader = this.$loading.show({ container: this.$refs.modalEl }); 
      
      _app.$api.post('Alert/CloseAlerts', data).then(
        () => {
          loader.hide();

          this.$vfm.hide(this.modalName);

          this.$emit('save');
        },
        () => {
          loader.hide();
        }
      );
    },
    close() {
      this.$vfm.hide(this.modalName);
    }
  }
}
</script>

<style scoped>
</style>