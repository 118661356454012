<template>
    <apexchart type="area" :series="chartSeries" :options="chartOptions" :noData="chartNoData"></apexchart>
</template>

<script>
export default {
  name: 'LineAreaChart',
  data: function() {
    return {
      chartOptions: {
        legend: { //https://apexcharts.com/docs/options/legend/
          position: 'right',
          offsetY: 40,
          showForSingleSeries: true,
          horizontalAlign: 'left',
          labels: {
            useSeriesColors: true
          }
        },
        stroke: { //https://apexcharts.com/javascript-chart-demos/line-charts/dashed/
          //dashArray: [9, 8, 7, 6, 5, 4, 3, 2, 1]     
        },
        chart: {
          type: 'area',
          stacked: false,
          height: 'auto',
          zoom: {
            type: 'x',
            enabled: true,
            autoScaleYaxis: true
          },
          toolbar: {
            autoSelected: 'zoom'
          }
        },
        dataLabels: {
          enabled: false
        },
        markers: {
          size: 1,
        },
        title: {
          text: '',
          align: 'center'
        },        
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.3,
            opacityTo: 0,
            stops: [0, 90, 100]
          },
        },
        yaxis: {}, //https://apexcharts.com/docs/options/yaxis/
        xaxis: {}, //https://apexcharts.com/docs/options/xaxis/
        tooltip: {
          shared: false,
          /*y: {
            formatter: function (val) {
              return (val / 1000000).toFixed(0)
            }
          }*/
        }
      },
      chartSeries: [],
      chartNoData: { text: 'Loading...' }
    };
  }
}
</script>

<style scoped>
  /* */
</style>