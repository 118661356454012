<template>
  <FormFieldGroup :fieldName="name" :label="label" :topLabel="topLabel">
    <div :class="topLabel ? '' : 'col-sm-8'">
      <Field        
        v-bind:value="value"
        v-on:input="$emit('input', $event.target.value)"
        :name="name" 
        :id="name" 
        :label="label" 
        autocomplete="off" 
        class="form-control"
        as="input"
        :required="$attrs.as=='select' && $attrs.placeholder"
        v-bind="$attrs"
      >
        <slot name="field"></slot>
        <option v-if="$attrs.as=='select' && $attrs.placeholder" value="" disabled selected hidden>{{$attrs.placeholder}}</option>
        <template v-if="data && data.length">
          <option v-if="addClearOption" :value="clearOptionValue">{{ clearOptionText || '-'  }}</option>
          <option  v-for="dto in data" :key="dto[valueField]" :value="dto[valueField]">{{dto[displayField]}}</option>
        </template>
      </Field>     
      <ErrorMessage :name="name" class="error-feedback" />
    </div>  
  </FormFieldGroup>
</template>

<script>

  import { Field, ErrorMessage } from "vee-validate";
  import FormFieldGroup from '@/App/components/Form/FormFieldGroup';

  export default {
    name: 'FormField',
    inheritAttrs: false,
    props: {
      name: String, 
      label: String, 
      value: String, 
      data: Array, 
      addClearOption: Boolean,
      clearOptionValue: null,
      clearOptionText: String,
      valueField: { 
        type: String, 
        default: 'id' 
      }, 
      displayField: { 
        type: String, 
        default: 'name' 
      },
      topLabel: Boolean
    },
    components: {
      Field,
      ErrorMessage,
      FormFieldGroup
    }    
  }

</script>

<style scoped>

html.white-theme select option[value=""], select:invalid {
  color: #bbb;
}

html.white-theme select option {
  color: #212529;
}


</style>
<!-- select with custom options template example
<FormField name="customerId" label="Customer" v-model="this.$app.$data.deviceEditState.customerId2" as="select">
  <template v-slot:field>
    <option  v-for="dto in customers" :key="dto.id" :value="dto.id">{{dto.name}}</option>
  </template>
</FormField> 
-->