
import AppApi from '@/App/AppApi';
import AppHelper from '@/App/AppHelper';
import { reactive } from "vue";
class AppData {

  //#region enums

  DeviceStatus = {
    Pending: 1,
    Active: 2,
    Maintenance: 3,
    Deactivated: 4,
    OutOfOrder: 5,
    Updating: 6,
    getStatusName: function(status) {
      switch(status) {
        case this.Pending:
          return 'Pending';
        case this.Active:
          return 'Paired'; //return 'Active';
        case this.Maintenance:
          return 'Maintenance';
        case this.Deactivated:
          return 'Deactivated';
        case this.OutOfOrder:
          return 'Out of order';
        case this.Updating:
          return 'Updating';          
        default:
          return AppHelper.isEmpty(status) ? '' : ('Status ' + status);
      }
    },
    getStatusList: function() {
      return [
        { id: this.Pending, name: this.getStatusName(this.Pending) },
        { id: this.Active, name: this.getStatusName(this.Active) },
        { id: this.Maintenance, name: this.getStatusName(this.Maintenance) },
        { id: this.Deactivated, name: this.getStatusName(this.Deactivated) },
        { id: this.OutOfOrder, name: this.getStatusName(this.OutOfOrder) }
      ];
    }
  };

  DeviceNetworkStatus = {
    Online: 1,
    Offline: 0,
    getStatusName: function(status) {
      switch(status) {
        case this.Online:
          return 'Synced';
        case this.Offline:
          return 'Offline';
        default:
          return AppHelper.isEmpty(status) ? '' : ('Status ' + status);
      }
    },
    getStatusList: function() {
      return [
          //{'id':this.Online, 'name':'Online'},
          {'id':this.Online, 'name':'Synced'},
          {'id':this.Offline, 'name':'Offline'}
        ]
    }
  };

  EvType = {
    Ecu: 1,
    Device: 3,
    Display: 4,
    Ups: 5,
    PowerSwitch: 6,
    Router: 7,
    getEvTypeList() {
      return [
        { id: this.Device, name: 'Device'},
        { id: this.Display, name: 'Display'},
        { id: this.Ups, name: 'Ups'},
        { id: this.PowerSwitch, name: 'Power switch'},        
        //{ id: this.Router, name: 'Network'}
        //{ id: this.Router, name: 'Network switch'}
      ];
    }
  };

  AttType = {
    Number: 1,
    Text: 2,
    DateTime: 3,
    Lookup: 4
  };

  DeviceComponentCategory = {
    ECU: 2,
    NetworkRelay: 3,
    UPS: 4,
    Display: 6,
    AmbientSensor: 7,
    LiquidSensor: 8,
    TouchMatrix: 9,
    NetworkSwitch: 13,
    NetworkRouterWithRelay:	14,
    Camera:	15,
    Beaglebone: 16,
    Yocto5Buttons: 17,

    getName: function(id) {
      switch(id) {
        case this.ECU:
          return 'ECU';
        case this.NetworkRelay:
          return 'Network relay';
        case this.UPS:
          return 'UPS';
        case this.Display:
          return 'Display';
        case this.AmbientSensor:
          return 'Ambient sensor';
        case this.LiquidSensor:
          return 'Liquid sensor';
        case this.TouchMatrix:
          return 'Touch screen';
        case this.NetworkSwitch:
          return 'Network switch';
        case this.NetworkRouterWithRelay:
          return 'Network router with power relay';
        case this.Camera:
          return 'IP Camera';
        case this.Beaglebone:
          return 'Beaglebone';
        case this.Yocto5Buttons:
          return 'Yocto button';
        default:
          return AppHelper.isEmpty(id) ? '' : ('Category ' + id);
      }
    }
  };

  DeviceComponentModel = {
    ECU: 3,
    NetworkRelay: 4,
    UPS: 5,
    Display_ALT_1920: 7,
    AmbientSensor: 8,
    LiquidSensor: 9,
    TouchMatrix: 10,
    NetworkSwitch: 14,
    Display_SP_4096: 17,
    NetworkRouterWithRelay:	18,
    Camera:	19,
    Yocto5Buttons: 21,

    models: [],

    getModel: function(id) {
      if (!this.dic)
        this.dic = AppHelper.toDictionary(_this.componentModels, 'id'); 

      return this.dic[id];
    },

    getName: function(id) {

      var model = this.getModel(id);

      return  model ?  model.name : '';
    },

    getConfigOptions() {

      if (!this._configOptions) {
        this._configOptions = _this.componentModels?.filter((item) => 
          item.id == this.ECU ||
          item.id == this.NetworkRelay ||
          item.id == this.UPS ||
          item.id == this.Display_ALT_1920 ||
          item.id == this.AmbientSensor ||
          item.id == this.TouchMatrix ||
          item.id == this.NetworkSwitch ||
          item.id == this.Display_SP_4096 ||
          item.id == this.NetworkRouterWithRelay ||
          item.id == this.Camera ||
          item.id == this.Yocto5Buttons
        ) || [];
      }

      return this._configOptions;
    }
  };  

  Lab22Users = [
    'leskovskov@gmail.com',
    'lev.cedilnik@preset.si',
    'blazhek@gmail.com',
    'admin@lab22.com'
  ];
  
  //#endregion

  //#region state

  _state = reactive({});

  getState(key, initialState) {
    let state = _this._state[key];
    if (AppHelper.isEmpty(state)) {
      _this._state[key] = state = AppHelper.isDefined(initialState) ? initialState : {};
    }
    return state;
  }

  setState(key, value) {
    _this._state[key] = value;
  }

  hasState(key) {
    return !AppHelper.isEmpty(_this._state[key]);
  }

  getSelectedDevice() {
    return _this._state['SELECTED_DEVICE'];
  }

  setSelectedDevice(dto) {
    _this._state['SELECTED_DEVICE'] = dto;
  }

  //deviceEditState = {};

  get deviceEditState() {
    var o = _this._state['DEVICE_EDIT_STATE'];

    if (!o) 
      _this._state['DEVICE_EDIT_STATE'] = o = {};
    
    return o;
  }

  set deviceEditState(value) {
    _this._state['DEVICE_EDIT_STATE'] = value;
  }

  deviceFilterState = {};

  //device view history
  /*getViewHistory() {
    return _this._state['VIEW_HISTORY'];
  }
  
  setViewHistory(guid, name) {
    _this._state['VIEW_HISTORY'] = ???;
  }*/

  viewHistory = JSON.parse(localStorage.getItem('history')) || [];
  
  //#endregion

  //#region stores

  _stores = {};

  getStore(storeKey, apiUrl, reload, postData) {
    return new Promise((resolve, reject) => {
      var data = !reload ? _this._stores[storeKey] : null;
      if (data) {
        resolve(data);
      } else {

        const successCallback = result => {    
          _this._stores[storeKey] = result.data;
          resolve(result.data);
        };

        const errorCallback = error => {
          reject(error);  // error?.response?.data
        };

        if (AppHelper.isDefined(postData)) {
          AppApi.post(apiUrl, postData).then(successCallback, errorCallback);
        } else {
          AppApi.get(apiUrl).then(successCallback, errorCallback);
        }
      }
    });
  }  

  storeLoaded(storeKey) {
    return !!_this._stores[storeKey]?.length;
  }

  clearStore(storeKey) {
    _this._stores[storeKey] = [];
  }

  getDevices(reload) {
    return _this.getStore('DEVICE', 'Device/GetDeviceList', reload);
  }

  // eslint-disable-next-line no-unused-vars
  getDeviceModelLkpStore(reload) {
    return _this.getStore('DEVICE_MODEL_LKP', 'Device/GetDeviceModelLkpList', reload);
  }

  getComponentModelList(reload) {
    return _this.getStore('COMPONENT_MODEL_LIST', 'Device/GetComponentModelList', reload);
  }

  getDeviceStatusList(reload) {
    return _this.getStore('DEVICE_STATUS_LIST', 'Device/GetDeviceStatusList', reload);
  }

  getConnectedStatusList(reload) {
    return _this.getStore('CONNECTED_STATUS_LIST', 'Device/GetConnectedStatusList', reload);    
  }

  getConnectedStatusName(status) {
    let statusName;
    if (_this.connectedStatusList) {
      const items = _this.connectedStatusList.filter(i => i.id === status);
      if (items?.length)
        statusName = items[0].name;
    }
    return statusName ?? status + '';
  }

  getEventTypeList(reload) {
    return _this.getStore('EVENT_TYPE_LIST', 'EventType/GetEventTypeList', reload);
  }

  getUsers(reload) {
    return _this.getStore('USER_LIST', 'AppUser/GetAppUserList', reload);
  } 

  getRoles(reload) {
    return _this.getStore('ROLE_LIST', 'AppRole/GetAppRoleList', reload);
  }

  getCustomers(reload) {
    return _this.getStore('CUSTOMER_LIST', 'Customer/GetCustomerList', reload);
  } 
  
  getCountries(reload) {
    return _this.getStore('COUNTRIES', 'Country/GetCountryList', reload);
  }

  get eventTypeList() {
    return _this._stores['EVENT_TYPE_LIST'] || [];
  }

  get users() {
    return _this._stores['USER_LIST'] || [];
  }

  get customers() {
    return _this._stores['CUSTOMER_LIST'] || [];
  }

  get deviceModelLkp() {
    return _this._stores['DEVICE_MODEL_LKP'] || [];
  }

  get componentModels() {
    return _this._stores['COMPONENT_MODEL_LIST'] || [];
  }  

  get deviceStatusList() {
    return _this._stores['DEVICE_STATUS_LIST'] || [];
  }

  get connectedStatusList() {
    return _this._stores['CONNECTED_STATUS_LIST'] || [];
  }

  get devices() {
    if (!_this._stores['DEVICE']) {      
      _this._stores['DEVICE'] = [];
      _this.getDevices(true);
    }
    return _this._stores['DEVICE'];
  }

  async preloadStores() {
    await Promise.all([
      _this.getUsers(true),
      _this.getCustomers(true),
      _this.getDeviceModelLkpStore(true),
      _this.getComponentModelList(true),
      _this.getDeviceStatusList(true),
      _this.getConnectedStatusList(true),
      _this.getEventTypeList(true),
      _this.getDevices(true)
    ]);
  }

  //#endregion
}

const _this = new AppData();

export default _this;