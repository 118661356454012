

import AppApi from '@/App/AppApi';
import AppData from '@/App/AppData';
import AppHelper from '@/App/AppHelper';
import AppModal from '@/App/AppModal';

import AuthService from '@/App/Auth/auth.service';

import _store from '../store';
import _router from '../router';

const _themes = {
  WHITE: 'white-theme',
  DARK: 'dark-theme'
};

class App {

  $api = AppApi;

  $data = AppData;

  $helper = AppHelper;

  $modal = AppModal;
  
  //#region helper functions

  id = AppHelper.id;

  nextNegativeId = AppHelper.nextNegativeId;

  isEmpty = AppHelper.isEmpty;

  toLookup = AppHelper.toLookup;

  handleError = AppHelper.handleError;

  getErrorResponseData = AppHelper.getErrorResponseData;

  notify = AppHelper.notifyInfo;

  notifyWarning = AppHelper.notifyWarning;
  
  notifyError = AppHelper.notifyError;

  formatDate = AppHelper.formatDate;

  toUtcDate = AppHelper.toUtcDate;

  ISO_FORMAT = AppHelper.ISO_FORMAT;

  setTheme(theme) {

    switch(theme) {
      case _themes.WHITE:
      case _themes.DARK:
        localStorage.setItem('APP_THEME', theme);
        break;
      default:
        throw 'Invalid theme "' + theme + '"';
    }
    
    _router.go();
  }

  toggleDarkTheme(enable) {
    _this.setTheme(enable ? _themes.DARK : _themes.WHITE);
  }

  getTheme() {
    return localStorage.getItem('APP_THEME') ?? _themes.DARK;
  }

  get darkTheme() {
    return _this.getTheme() === _themes.DARK;
  }

  get user() {
    return AuthService.getUser();
  }

  get userAuthenticated() {
    return !_this.isEmpty(AuthService.getToken()); 
  }

  async refreshAuthData() {

    try {
      const result = await AppApi.get('App/GetClientAuthData');

      AuthService.updateAuthData(result.data);
    }
    catch(err) {
      _this.handleError(err);
      _this.logOut();
    }
  }

  logOut() {
    _store.dispatch('auth/logout');
    _router.go('/login');
  }

  get isDevelopment() {
    return process.env.NODE_ENV === 'development';
  } 

  downloadFile(url, postData) {
    return new Promise((resolve) => {
      AppApi({
        url: url,
        method: postData ? 'post' : 'get',
        data: postData,
        responseType: 'blob',
      }).then(
        (response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');
      
          fileLink.href = fileURL;
      
          const contentDisposition = response.headers['content-disposition'];
          let fileName = 'unknown';
          if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(contentDisposition);
            if (matches != null && matches[1]) { 
              fileName = matches[1].replace(/['"]/g, '');
            }
          }
          fileLink.setAttribute('download', fileName);
          document.body.appendChild(fileLink);
          fileLink.click();
          fileLink.remove();
          window.URL.revokeObjectURL(url);

          resolve(true);
        })
        .catch(() => {
          resolve(false);
        });
    });
  }

  get debugModeEnabled () {
    return localStorage.getItem('DEBUG_MODE_ENABLED') === 'true';
  }

  setDebugMode(debugMode) {
    if (debugMode) {
      localStorage.setItem('DEBUG_MODE_ENABLED', 'true');
    } else {
      localStorage.removeItem('DEBUG_MODE_ENABLED')
    }
  }

  //#endregion

  //#region plugin

  async install(app) {

    app.config.globalProperties.$app = _this;

    // Assign a unique id to each component
    app.mixin({
      beforeCreate: function() {
        this._lab22Id = _this.id();
      },
      methods: {
        $id(id) {
          return this._lab22Id + "-" + id;    
        }
      }
    });
    

    // if (_this.userAuthenticated)
    //   await _this.$data.preloadStores();
  }

  //#endregion 
}

const _this = new App();

export default _this;