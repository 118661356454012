import { createRouter, createWebHashHistory } from 'vue-router';

import _app from '@/App/App'

import LoginPage from "../Pages/LoginPage.vue";

import DashboardView from "../Modules/Overview/Infinitus/DashboardView.vue";
// import DashboardViewWhite from "../Modules/Overview/Infinitus/DashboardViewWhite.vue";
import DashboardLab22 from "../Modules/Overview/Lab22/DashboardLab22.vue";
import AnalyticsView from "../Modules/Analytics/AnalyticsView.vue";
//import ReportingFrame from "../Modules/Reporting/ReportingFrame.vue";
import ReportingUi from "../Modules/Reporting/ReportingUi.vue";
import LocationMap from "../Modules/Locations/LocationMap.vue";

import DeviceList from "../Modules/Device/DeviceList.vue";
import DeviceDetails from "../Modules/Device/DeviceDetails.vue";
import DeviceGeneral from "../Modules/Device/Details/DeviceGeneral.vue";
import DeviceSetup from "../Modules/Device/Details/DeviceSetup.vue";
import GenericDeviceSetup from "../Modules/Device/Details/GenericDeviceSetup.vue";
//import DeviceStatus from "../Modules/Device/Details/DeviceStatus.vue";
import DeviceStatus from "../Modules/Device/Details/DeviceStatusV2.vue";
import DeviceComponents from "../Modules/Device/Details/DeviceComponents.vue";
import DeviceAlertList from "../Modules/Device/Details/DeviceAlertList.vue";
import DeviceMaintenance from "../Modules/Device/Details/DeviceMaintenance.vue";

import AlertList from "../Modules/Alerts/AlertList.vue";
import AlertDetails from "../Modules/Alerts/AlertDetails.vue";

//import ThresholdList from "../Modules/Thresholds/ThresholdList.vue";
import ThresholdList from "../Modules/Thresholds/ThresholdListV2.vue";

import UserList from "../Modules/User/UserList.vue";
import UserDetails from "../Modules/User/UserDetails.vue";

import RoleList from "../Modules/Role/RoleList.vue";
//import RoleDetails from "../Modules/Role/RoleDetails.vue";

import AuditLog from "../Modules/Audit/AuditLog.vue";

import PageNotFound from '../Pages/PageNotFound.vue';

import AnalyticsPrototype from "../Modules/Analytics/AnalyticsPrototype.vue";

//const metaTitle = 'Controlmotion - Remote Technical Software';

const routes = [
  {
    path: '/',
    name: 'home',
    component: PageNotFound/*,
    meta: { title: metaTitle }*/
  },
  {
    path: '/overview',
    name: 'overview',
    component: DashboardView
  },  
   {
     path: '/dashboard-lab22',
     name: 'dashboard-lab22',
     component: DashboardLab22
   }, 
  {
    path: '/analytics',
    name: 'analytics',
    component: AnalyticsView
  },
  {
    path: '/analytics-prototype',
    component: AnalyticsPrototype
  },  
  {
    path: '/reporting',
    component: ReportingUi
  },
  {
    path: "/devices",
    name: 'devices',
    meta: { cssClass: 'bg-white'},
    component: DeviceList
  },
  {
    path: '/devices/:guid',
    name: 'device',
    meta: { cssClass: 'bg-white'},
    component: DeviceDetails,
    props: true,  // route params will be set as the component props
    children: [
      // UserHome will be rendered inside User's <router-view>
      // when /users/:username is matched
      { path: '', component: DeviceGeneral },

      { path: 'details', component: DeviceGeneral },

      // UserProfile will be rendered inside User's <router-view>
      // when /users/:username/profile is matched
      { path: 'setup', component: DeviceSetup },

      { path: 'generic-setup', component: GenericDeviceSetup },

      // UserPosts will be rendered inside User's <router-view>
      // when /users/:username/posts is matched
      { path: 'status', component: DeviceStatus },

      { path: 'device-components', component: DeviceComponents },

      { path: 'alerts', component: DeviceAlertList },

      { path: 'maintenance', component: DeviceMaintenance }
    ]
  },   
  {
    path: "/locations",
    name: 'locations',
    component: LocationMap
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import('../Modules/UserMgmt/ProfileView.vue')
  },
  {
    path: "/alerts",
    name: "alerts",
    component: AlertList
  },
  {
    path: "/alerts/alert-details",
    name: "alert-details",
    component: AlertDetails
  },    
  {
    path: "/thresholds",
    component: ThresholdList
  },  
  {
    path: "/users",
    component: UserList
  },
  {
    path: "/users/add-user",
    name: "add-user",
    component: UserDetails
  },
  {
    path: "/users/user-details",
    name: "user-details",
    component: UserDetails
  },  
  {
    path: "/roles",
    component: RoleList
  },
  /*{
    path: "/roles/add-role",
    name: "add-role",
    component: RoleDetails
  },
  {
    path: "/roles/role-details",
    name: "role-details",
    component: RoleDetails
  },*/ 
  {
    path: "/audit-log",
    component: AuditLog
  },  
  {
    path: "/login",
    name: 'login',
    component: LoginPage
  },
  {
    path: "/registration",
    name: 'registration',
    component: () => import('../Pages/RegistrationPage.vue')
  },
  {
    path: "/reset-password",
    name: 'reset-password',
    component: () => import('../Pages/ResetPasswordPage.vue')
  },  
  {
    path: "/alert-mobile",
    name: 'alert-mobile',
    component: () => import('../Pages/AlertMobilePage.vue')
  },   
  {
    path: "/lab22-test",
    component: () => import('../Pages/TestPage.vue')
  },
  {
    path: "/forms-basic",
    component: () => import('../Modules/Test/FormsBasic.vue')
  },
  {
    path: "/forms-advanced",
    component: () => import('../Modules/Test/FormsAdvanced.vue')
  },
  {
    path: "/forms-test",
    component: () => import('../Modules/Test/FormsTest.vue')
  },
  {
    path: "/tables-basic",
    component: () => import('../Modules/Test/TablesBasic.vue')
  },
  {
    path: "/tables-advanced",
    component: () => import('../Modules/Test/TablesAdvanced.vue')
  },
  {
    path: "/tables-test",
    component: () => import('../Modules/Test/TablesTest.vue')
  },
  {
    path: "/ui-elements-tabs",
    component: () => import('../Modules/Test/UiElementsTabs.vue')
  },  
  {
    path: '/:pathMatch(.*)',
    component: PageNotFound
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/registration','/reset-password', '/alert-mobile'];
  const authRequired = !publicPages.includes(to.path);

  const currentUser = _app.user;

  //check for special routes
  let isLabRouteAllowed = true,
    route = to.name || '';
  if( (route==='dashboard-lab22'||route==='reporting' ) && currentUser ) //can add other routes for lab22 users here
    isLabRouteAllowed = ( _app.$data.Lab22Users.indexOf(currentUser.email) == -1 ) ? false : true;
  
  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !currentUser) {
    next('/login');
  } else if (!isLabRouteAllowed) {
    next('/devices');
  } else if (to.path == '/') {
    if (currentUser?.isAdmin() || currentUser.idDeviceManager()) {
      if(isLabRouteAllowed && currentUser.email==='blazhek@gmail.com') //can add other users for default page here
        next('/dashboard-lab22');
      else
        next('/overview');
    } else if (currentUser.isAnalyticsUser()) {
      next('/analytics');
    } else {
      next();  
    }
  } else if(!currentUser?.isAdmin() && to.path == '/la-test') {
    next('/');
  } else {
    next();
  }    
});

export default router;
