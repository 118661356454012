<template>

<Form @submit="onSubmit" ref="form" class="form-horizontal form-bordered">
  <base-modal :name="modalName" v-bind="$attrs" :title="this.title" :width="400" @close="close">
    <template #body> 
      <div v-if="this.action=='renameWs' || this.action=='renameReport'" class="row" style="margin-bottom:20px;">
        <div class="col-12">
          Old name: {{this.oldName}}
        </div>
      </div>
      <div v-if="this.action=='saveAsWs'" class="row" style="margin-bottom:20px;">
        <div class="col-12">
          Existing name: {{this.oldName}}
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <FormField name="newName" type="text" :label="this.label" :topLabel="true" />
        </div>
      </div>
      <div v-if="this.action=='saveAsWs'" class="row" style="margin-top:10px;">
        <div class="col-12">
          <input class="form-check-input" type="checkbox" id="chk-is-default" >
          <label class="form-check-label" for="chk-is-default">is default</label>
        </div>
      </div>
    </template>
    <template #footer>
      <button @click="close" type="button" class="btn btn-default" data-dismiss="modal" style="margin-left:5px; float:right;">Cancel</button>
      <button type="submit" class="btn btn-primary" style="float:right;">OK</button>
    </template>
  </base-modal>
</Form>

</template>

<script>

import BaseModal from '@/App/components/Modal/BaseModal';
import FormField from '@/App/components/Form/FormField';
import { Form } from "vee-validate";
import _app from '@/App/App'

export default {
  name: 'ReportingNameModal',
  props: {
    title: String,
    oldName: String,
    action: String
  },
  inheritAttrs: false,
  emits: ['ok'],
  components: {
    BaseModal,
    FormField,
    Form
  }, 
  data() {
    return {
      modalName: this.title,
      label: null,
    };
  },
  mounted() {
    switch(this.action) {
      case 'renameWs': this.label = 'New name'; break;
      case 'renameReport': this.label = 'New name'; break;
      case 'saveAsWs': this.label = 'New name'; break;
      default: 'New name'; break;
    }
  },
  methods: {
    onSubmit(values) {
      let val = values.newName;

      if(!val || val=='') {
        _app.$helper.notifyWarning('Please enter a valid name (at least 1 character)');
        return false;
      }

      if(val==this.oldName) {
        _app.$helper.notifyWarning('New name should not be the same as existing name');
        return false;
      }

      let chk = false;
      if(this.action=='saveAsWs')
        chk = document.getElementById('chk-is-default').checked;

      let data = {
          name:       val, 
          isDefault:  chk
      };

      this.$emit('ok', data);
      this.$vfm.hide(this.modalName);
    },
    close() {
      this.$vfm.hide(this.modalName);
    }
  }
}
</script>

<style scoped>
 /* */
</style>