<template>

<Form class="form-horizontal form-bordered">
  <base-modal :name="modalName" title="Alert details" v-bind="$attrs" :width="380" @close="close">
    <template #body>  
      <div class="loading-div text-center" v-if="loading">Loading...</div>
      <div class="details-row" v-for="(item, index) in details" :key="index">
        <label>{{item.label}}:</label><span>{{item.value}}</span>
      </div>
    </template>
    <template #footer>
      <button @click="close" type="button" class="btn btn-default" data-dismiss="modal">Close</button>
      <!-- <button type="submit" class="btn btn-primary">Close alert</button> -->
    </template>
  </base-modal>
</Form>

</template>

<script>

import BaseModal from '@/App/components/Modal/BaseModal'
import { Form } from "vee-validate";

import _app from '@/App/App'

export default {
  name: 'AlertDetailsModal',
  props: {
    id: Number
  },
  inheritAttrs: false,
  components: {
    BaseModal,
    Form
  },   
  data() {
    return {
      modalName: 'AlertDetailsModal',
      loading: false,
      details: []
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.loading = true;
      _app.$api.get('Alert/GetAlertDetails', { params: { id: this.id }}).then(result => {
        this.loading = false;

        let fieldValue = null;

        if (!_app.isEmpty(result.data.metric_value_dec)) {
          fieldValue = result.data.metric_value_dec;
        } else if (!_app.isEmpty(result.data.metric_value_text)) {
          fieldValue = result.data.metric_value_text;
        } else if (!_app.isEmpty(result.data.value_dec)) {
          fieldValue = result.data.value_dec;
        } else if (!_app.isEmpty(result.data.value_text)) {
          fieldValue = result.data.value_text;
        }
         
        this.details.push({
          label: 'Id', 
          value: result.data.id
        }, {
          //label: 'Date created', 
          label: 'Time triggered', 
          value: _app.formatDate(result.data.created)           
        }, {
          label: 'Status', 
          value: result.data.status_name          
        }, {
          label: 'Open time', 
          value: this.formatMinutes(result.data.alert_open_minutes)
        }, {
          label: 'Device', 
          value: result.data.device_name
        }, {
          label: 'Component', 
          value: result.data.et_field_group_name
        }, {
          label: 'Action', 
          value: result.data.action_type_name
        }, {
          //label: 'Condition state', 
          label: 'Alert state', 
          value: result.data.condition_state_name
        }, {
          //label: 'Field', 
          label: 'Alert field', 
          value: result.data.et_field_name
        }, {
          //label: 'Field value', 
          label: 'Alert value', 
          value: fieldValue
        }, {
          label: 'Condition text', 
          value: result.data.condition_text
        }, {
          label: 'Resolution', 
          value: result.data.close_resolution_name
        }, {
          label: 'Close comment', 
          value: result.data.close_comment
        });
      }, err => {
        this.loading = false;
        _app.handleError(err);
      });
    },
    close() {
      this.$vfm.hide(this.modalName);
    },
    formatMinutes(minutes) {

      if (minutes < 1) 
        return minutes;

      const hours = Math.floor(minutes / 60);
      
      minutes = minutes % 60;

      let s = '';

      if (hours == 1)
        s += hours + ' hour ';
      else if (hours > 1)
        s += hours + ' hours ';

      if (minutes == 1)
        s += minutes + ' minute';
      else if (minutes > 1)
        s += minutes +  ' minutes';

      return s;
    }
  }
}
</script>

<style scoped>

.loading-div {
  min-height: 310px;
  line-height: 310px;
}

div.details-row {
  margin-bottom:5px;
}

label {
  width: 110px;
  color: #adadad;
  font-size: 14px;
}

span {
  color: #212529;
  font-size: 14px;
}

html.dark label {
  color: #808697
}

html.dark span {
  color: #c3c3c3
}

</style>