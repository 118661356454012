<template>
  <AppHeader :isLogedInUserView="isLogedInUserView()" />    
  <vue3-progress-bar></vue3-progress-bar>
  <AppSideBar v-if="isLogedInUserView()" @set-collapsed="setCollapsed" @set-is-on-mobile="setIsOnMobile" />
  <div id="main-view" :class="[{'collapsed' : collapsed && this.isLogedInUserView() }, {'onmobile' : isOnMobile}, {'sidebar': this.isLogedInUserView()}, routeClass]">
    <div id="main-view-container" class="container-fluid" :class="mainViewClass">     
      <router-view v-if="!showNoPermissionsView() && !loading" @set-main-view-class="(cls) => mainViewClass = cls" />
    </div>
  </div>
  <modals-container></modals-container>
</template>

<script>
import AppHeader from './App/components/AppHeader'
import AppSideBar from './App/components/AppSideBar'
import { ModalsContainer } from 'vue-final-modal'
import AuthService from '@/App/Auth/auth.service';

export default { 
  components: {
    AppHeader,
    AppSideBar,
    ModalsContainer
  }, 
  //inject: ['appService'], 
  data() {    
    return {
      collapsed: true,
      isOnMobile: false,
      routeClass: null,
      loading: false,
      mainViewClass: "",
      currentUser: this.$app.user
    }
  },
  async mounted () {

    if (this.$app.darkTheme) {
      document.documentElement.classList.add('dark');
    } else if (this.$app.getTheme()) {
      document.documentElement.classList.add(this.$app.getTheme());
    }

    document.getElementById("main-view-container")
        .addEventListener("scroll", (ev) =>{
            this.$store.commit('updateMainScrollOffset', ev.target.scrollTop);
    })

    this.doLayout();
    this.onRouteChange();
    window.addEventListener('resize', this.doLayout);

    if (this.$app.userAuthenticated) {
      this.loading = true;
      const loader = this.$loading.show();
      try {

        await this.$app.refreshAuthData();

        this.currentUser = this.$app.user

        await this.$app.$data.preloadStores();      

        this.loading = false;
        
        loader.hide();        
      }
      catch(e) {

        this.loading = false;
        loader.hide();
      }
      //console.log(this.currentUser, this.$app.user.isCustomerUser());
    } 
  },   
  /*created() {
    window.addEventListener('beforeunload', this.beforeWindowUnload)
  },
  beforeUnmount() {
    window.removeEventListener('beforeunload', this.beforeWindowUnload)
  },*/  
  methods: {
    doLayout: function() {
      // todo: get header height from dom element
      document.getElementById('main-view-container').style.height = (window.innerHeight - 60) + "px";
    },
    setCollapsed(collapsed) {
      this.collapsed = collapsed;
    },
    setIsOnMobile(isOnMobile) {
      this.isOnMobile = isOnMobile;
    },
    onRouteChange() {
      this.routeClass = this.$route.meta.cssClass;
    },
    isLogedInUserView() {
      return !this.$app.isEmpty(this.currentUser) && ['login','registration','reset-password', 'alert-mobile'].indexOf(this.$route.name) == -1;
    },    
    showNoPermissionsView() {

      const user = this.$app.user;

      return this.isLogedInUserView() && !user?.isAdmin() && !user?.idDeviceManager() && !user?.isAnalyticsUser();
    },
    /*beforeWindowUnload(e) {
      this.$app.$modal.confirm('Leave application', 'Are you sure that you want to leave?', (confirmed) => {
        if (!confirmed) {
          // Cancel the event
          e.preventDefault()
          // Chrome requires returnValue to be set
          e.returnValue = ''
          return;
        }
      });
    },*/
  },
  watch: {
    $route() {
      // on route change
      document.getElementById('main-view-container').scrollTop = 0;

      this.onRouteChange();
    }
  }
};
</script>

<style>

body,
html {
  margin: 0;
  padding: 0; 
  color: rgb(36, 41, 47);
  overflow:hidden;
}

body {
  color: #262626;
  padding-top: 60px;  
}

html.white-theme #main-view {
  color: rgb(36, 41, 47);
}

#main-view-container {
  padding: 0px !important;  
  overflow-y: auto;
  /* scrollbar-color: #e0e0e0 #e0e0e0; */
  scrollbar-width: thin;
  scrollbar-gutter: stable;
  transition: scrollbar-color 0.3s ease-out;
}

#main-view-container.no-gutter {
  scrollbar-gutter: unset;
}

/* #main-view.devices-view, 
#main-view.device-add-view, 
#main-view.device-view {
  background-color: white;
} */

::-webkit-scrollbar {
    width: 12px;
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: #dadada;
    border-radius: 100px;
    border: 3px solid transparent;
    background-clip: content-box;
}

.lab22-mv-padding {
  padding: 20px 15px 0px 30px;
}

.c-toast-container {
  padding-top:65px !important;
}

.vue3-progress-bar-container .vue3-progress-bar  {
  background-color: #bffaf3 !important;
}

.navbar.module-tbar {
  padding-left: 0px;
  padding-right: 0px;
  margin-top:10px;
  /* border-bottom: 1px solid #eee; */
}

html body .tabs {
  margin-bottom:0px;
}

html.white-theme .error-feedback {
  color: #dc3545;
  line-height:30px;
}

.lab22-modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

section.card {
  margin-top: 10px !important;
  margin-bottom: 20px;
}

.form-footer {
  padding-left: 10px;
  margin-top: 20px;
}

/*#region filterbar */
.filterbar form > * {
  margin-right: 5px;
}

.filterbar {
  border-top: 1px solid rgb(222, 226, 230);
  padding-left: 12px;
  padding-right: 12px;  
}

.filterbar label {
  padding-right: 5px;
  font-size:14px;
  color: #adadad; 
}
/*#endregion */

/*#region form */

.form-check-input, input[type=checkbox], input[type=radio] {
  padding-top:0px;
  width: 18px; 
  height: 18px;
  margin-top: 1px;
  cursor: pointer;
}

input[type=radio] {
  padding-top:0px;
  width: 16px; 
  height: 16px;
  cursor: pointer;
}

th.hd-checkbox-col .form-check-input {
  margin-top: -2px;
}

.form-check-label {
  padding-left: 5px;
  cursor: pointer;
}

.toolbar .form-check label.form-check-label {
  color: #adadad;
}

/*#endregion */

/*#region buttons */

.icon-btn i, .icon-btn span.spinner-border  {
  width: 14px !important;
}

/*#endregion */

/*#region menu */

.dropdown-item {
  cursor: pointer;
}

.dropdown-item.disabled {
  cursor: default;
}

html.white-theme .dropdown-item i {
  width:20px;
  margin-right: 6px;
  color: #0088CC;
  text-align:center;
}

/*#endregion */

.cursor-pointer {
  cursor: pointer;
}

/* Hide input number arrows */
.no-arrows::-webkit-inner-spin-button,
.no-arrows::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-arrows {
  -moz-appearance: textfield;
}

button span.spinner-border {
  height:14px;
}

</style>

