<template>
  <div>
    <BaseGrid v-slot="{row, columns}" :columns="gridData.columns" :rows="gridData.rows" :enableVScroll="true" @row-contextmenu="onRowContextMenu">
      <td>
        <input type="checkbox" @change="checkChange($event, row)" :value="row.id" v-model="checkedRecords" class="form-check-input big-checkbox" />
      </td>
      <BaseGridCell :col="columns[1]" :row="row" />
      <BaseGridCell :col="columns[2]" :row="row" />
      <BaseGridCell v-if="!deviceMode" :col="columns[3]" :row="row" />
      <BaseGridCell v-if="!deviceMode" :col="columns[4]" :row="row" />
      <BaseGridCell v-if="!deviceMode" :col="columns[5]" :row="row" />
      <BaseGridCell :col="columns[6]" :row="row" />
      <BaseGridCell :col="columns[7]" :row="row" />
      <BaseGridCell :col="columns[8]" :row="row" />
      <BaseGridCell :col="columns[9]" :row="row" />
    </BaseGrid>
    <ContextMenu ref="gridRowContextMenu"></ContextMenu>
  </div>
</template>

<script>
import BaseGrid from '@/App/components/Grid/BaseGrid'
import BaseGridCell from '@/App/components/Grid/BaseGridCell'
import CloseAlertModal from './CloseAlertModal'
import AlertDetailsModal from './AlertDetailsModal'
import ContextMenu from '@/App/components/Common/ContextMenu'
import dayjs from 'dayjs';

const PAGE_SIZE = 100;

export default {
  name: 'AlertListBase',
  props: {    
    deviceMode: Boolean,
    listStoreKey: String,
    columns: Array
  },
  emits: ['checkchange', 'loading'],
  components: {
    BaseGrid,
    BaseGridCell,
    ContextMenu
  },
  data() {
    return {
      gridData: {
        columns: [
          {
            title: '',
            dataIndex: 'selected',
            type: 'checkbox',
            changeHandler: (e) => {              
              this.checkAll(e.target.checked);
            }
          },
          {
            title: 'Id',
            dataIndex: 'id'
          },
          {
            title: 'Status',
            dataIndex: 'statusId',
            renderer: (value) => {
              return this.statusDic[value] || value;
            }
          },
          {
            title: 'Device',
            dataIndex: 'deviceName',
            skipRender: this.deviceMode
          },
          {
            title: 'Device model',
            dataIndex: 'deviceModelId',
            renderer: (value) => {
              return this.deviceModelLookup[value] || value;
            },
            skipRender: this.deviceMode
          },
          {
            title: 'Location',
            dataIndex: 'deviceAddress',
            skipRender: this.deviceMode
          },
          {
            //title: 'Condition state',
            title: 'Alert state',
            dataIndex: 'conditionStateId',
            renderer: (value) => {
              return this.conditionStatusDic[value] || value;
            },
            align: 'center'
          },
          {
            //title: 'Condition',
            title: 'Alert threshold',
            dataIndex: 'conditionText'
          },
          {
            //title: 'Date created',
            title: 'Time triggered',
            dataIndex: 'created',
            type: 'DateTime'
          },
          // actions
          {
            align:'right',
            actions: [
              {
                text: 'Close',
                handler: this.closeAlert
              },
              {
                text: 'Details',
                handler: this.showDetails
              }
            ]
          }
        ],
        rows: []
      },
      checkedRecords: [],
      statusOptions: [
        { id: 1, name: 'Opened'},
        { id: 2, name: 'Closed'}
      ],
      statusDic: {},
      conditionStatusOptions: [
        { id: 1, name: 'Active'},
        { id: 2, name: 'Not Active'}        
      ],
      conditionStatusDic: {},
      deviceModelLookup: this.$app.toLookup(this.$app.$data.deviceModelLkp),
      loading: false,
      storeKey: this.deviceMode ? 'DEVICE_ALERT_LIST' : 'ALERT_LIST',
      filter: {}
    };
  },
  mounted() {

    this.statusDic = Object.assign({}, ...this.statusOptions.map((i) => ({[i.id]: i.name})));
    this.conditionStatusDic = Object.assign({}, ...this.conditionStatusOptions.map((i) => ({[i.id]: i.name})));
  
  },
  methods: {   
    setFilter(filter) {
      this.filter = filter;

      this.load(true);
    },
    load(reload, resetPaging) {

      if (resetPaging)
        this.filter.rowNum = 1;

      // this.showBackButton = filter.rowNum > 1;

      // this.disableNextButton = true;
      
      if (reload) {
        this.loading = true;
        this.$emit('loading', this.loading);
      }
      

      this.$app.$data.getStore(this.storeKey, 'Alert/GetAlertList', reload, this.filter).then(
        (result) => {

          if (this.loading) {
            this.loading = false;
            this.$emit('loading', this.loading);
          }

          this.gridData.rows = result;

          // this.showBackButton = filter.rowNum > 1;

          // this.disableNextButton = this.gridData.rows.length < PAGE_SIZE;
        },
        () => {
          if (this.loading) {
            this.loading = false;
            this.$emit('loading', this.loading);
          }
        }        
      );
    },
    clear() {
      this.filter.rowNum = 1;
      this.gridData.rows = [];
    },
    showDetails(dto) {
      this.$vfm.show({
        component: AlertDetailsModal,
        bind: {
          'id': dto.id
        }
      });      
    },
    closeAlert(dto) {
      this.$vfm.show({
        component: CloseAlertModal,
        bind: {
          'closeAlerts': [dto.id]
        },
        on: {
          save: () => {
            this.load(true, true);

            const checkedRecords = this.checkedRecords.filter(function(id){ 
                return id != dto.id;
            });

            if (checkedRecords.length != this.checkedRecords.length) {
              this.checkedRecords = checkedRecords;
              this.onCheckChange();
            }
          }
        }
      });
    },
    closeSelected() {
      this.$vfm.show({
        title: 'test',
        component: CloseAlertModal,
        bind: {
          'closeAlerts': this.checkedRecords
        },        
        on: {
          save: () => {
            this.checkedRecords = [];
            this.onCheckChange();

            this.load(true, true);
          }
        }
      }); 
    },
    checkAll(checked) {
      if (checked) {
        this.checkedRecords = this.gridData.rows.map(r => { return r.id });
      } else {
        this.checkedRecords = [];
      }
      this.onCheckChange();
    },
    checkChange(e) {
      if (!e.target.checked) {
        this.gridData.columns[0].checked = false;
      } else if (this.checkedRecords.length == this.gridData.rows.length) {
        this.gridData.columns[0].checked = true;
      }
      this.onCheckChange();  
    },
    onCheckChange() {
      this.$emit('checkchange', this.checkedRecords);
      //this.$app.$data.getState('AlertListSelection', []) = 
    },
    exportList(type) {
      let rows = this.gridData.rows,
          row = rows[0],
          output = "data:text/"+type+";charset=utf-8,";

      for ( let property in row ) {
        let key = '';
          switch(property) {
            case 'statusId': key = 'status'; break;
            case 'deviceModelId': key = 'deviceModel'; break;
            case 'conditionStateId': key = 'conditionState'; break;
            default: key = property; break;
          }
        output += key + ';';
      }
      output += "\r\n";

      for(let i=0; i<rows.length; i++) {
        for ( let property in row ) {
          let val = '';
          switch(property) {
            case 'statusId': val = this.statusDic[rows[i][property]] || rows[i][property]; break;
            case 'deviceModelId': val = this.deviceModelLookup[rows[i][property]] || rows[i][property]; break;
            case 'conditionStateId': val = this.conditionStatusDic[rows[i][property]] || rows[i][property]; break;
            default: val = rows[i][property]; break;
          }
          output += val + ';';
        }
        output += "\r\n";
      }
      
      let encodedUri = encodeURI(output);
      window.open(encodedUri);
    },
    backPage() {
      this.filter.rowNum -= PAGE_SIZE;

      if (this.filter.rowNum < 1)
        this.filter.rowNum = 1;

      this.load(true);
    },
    nextPage() {
      this.filter.rowNum += PAGE_SIZE;

      this.load(true);
    },
    onRowContextMenu(item, event) {

      event.preventDefault();

      var multisel = this.checkedRecords.length > 1 && this.checkedRecords.includes(item.id);
      
      var menuItems = [
        {
          text: 'Analyse',
          iconCls: 'fa-square-poll-vertical',
          handler: (ctx) => {

            let metric = null;

            let dateFrom, dateTo;

            if (multisel) {

              metric = [];
              
              for(let i = 0; i < this.checkedRecords.length && i < 5; i++) {

                var recId = this.checkedRecords[i];

                var rec = this.gridData.rows.find(row => { return row.id == recId; });

                if (rec) {
                  if (rec.eventTypeIdAnalytics) {
                    metric.push({
                      deviceId: rec.deviceId, 
                      deviceName: rec.deviceName, 
                      metricId: rec.measurementId,
                      metricName: rec.measurementName,
                      evTypeId: rec.eventTypeIdAnalytics                  
                    });
                  }

                  let recDateFrom = dayjs(rec.created).startOf('day').toDate();
                  let recDateTo = dayjs(rec.created).startOf('day').add(1, 'day').toDate();

                  if (!dateFrom || recDateFrom < dateFrom)
                    dateFrom = recDateFrom;

                    if (!dateTo || recDateTo > dateTo)
                      dateTo = recDateTo;                    
                }
              }
            } else {
              if (item.eventTypeIdAnalytics) {
                metric = {
                  deviceId: item.deviceId, 
                  deviceName: item.deviceName, 
                  metricId: item.measurementId,
                  metricName: item.measurementName,
                  evTypeId: item.eventTypeIdAnalytics
                };
              }
              dateFrom = dayjs(item.created).startOf('day').toDate();
              dateTo = dayjs(item.created).startOf('day').add(1, 'day').toDate();
            }
            
            this.$router.push({
              name: 'analytics',              
              params: { 
                data: JSON.stringify(
                  {
                    metric: metric,
                    dateFrom: dateFrom,
                    dateTo: dateTo,
                    removeExisting: metric && !event.ctrlKey
                })
              }
            });            
          }
        },
        // {
        //   text: 'Show raw data',
        //   iconCls: 'fa-solid fa-align-justify',
        //   handler: (ctx) => {
        //     this.state.evTypeId = ctx.measurement_event_type_id;            
        //     this.state.activeView = View.RawData;
        //     this.updateGridColumns();
        //     this.load();
        //   }
        // },
        {
          text: 'Alert details',
          iconCls: 'fa-solid fa-bell',
          handler: (ctx) => {
            this.showDetails(ctx); 
          }
        }
      ];

      this.$refs.gridRowContextMenu.open(menuItems, event, item);
    }
  }
}
</script>
