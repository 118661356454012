<template>  
  <nav class="navbar navbar-light module-hd justify-content-start">    
    <div class="flex-grow-1 d-flex align-items-center">            
      <i v-if="showBackButton" @click="goBack()" class="fa-solid fa-circle-left back-icon"></i>
      <a class="navbar-brand" @click.prevent="$emit('titleClick')">
        <slot name="title">
          <i v-if="iconClass" :class="iconClass + ' module-icon'"></i>
          <div class="module-title" :class="{ 'module-title-without-subtitle': !subtitle }">
            <div class="module-title-text">{{title || 'Loading...'}}</div>
            <span v-if="subtitle" class="module-subtitle">{{subtitle}}</span>
          </div>        
        </slot>
      </a>      
    </div>    
    <slot></slot>    
  </nav>
</template>

<script>
  
  export default {
    name: 'ModuleHeader',
    props: {
      title: String,
      subtitle: String,
      iconClass: String,
      showBackButton: Boolean
    },
    emits:['titleClick'],
    data() {
      return {

      }
    },
    methods: {
      goBack() {
        this.$router.back()
      }
    }
  }
</script>

<style scoped>

.navbar.module-hd {
  height: 56px;
  padding-left: 0px;
  padding-right: 5px;
  margin-bottom: 15px;
}

.module-title {
  height: 46px;  
  font-size: 20px;
  line-height: 18px
}

.module-title-text {
  margin-top: -2px;
}

.module-icon {
  font-size: 46px;
  margin-right: 15px;
  margin-top: -8px;
  float:left;
  color:#bbc5d6;
}

.module-title-without-subtitle .module-title-text {
  line-height: 34px;
}

.module-subtitle {
  font-size: 13px;
  color: #aaa;
  line-height: 13px !important;
  padding-left:1px;
}

.back-icon {
  font-size: 24px;
  margin-right: 15px;  
  margin-top: -12px;
  color:#bbc5d6;
  cursor: pointer;
}

</style>

<style>

.navbar.module-hd button {
  margin-top:-15px;
}

</style>