<template>
  <base-modal :name="modalName" :title="title" @close="close" footerClass="justify-content-center" v-bind="$attrs">
    <template #body>{{ msg }}</template>
    <template #footer>
      <slot name="footer">
        <button @click="ok()" type="button" class="btn btn-primary">Ok</button>      
      </slot>
  </template>
  </base-modal>
</template>

<script>
import BaseModal from './BaseModal.vue'

export default {
  name: 'AlertModal',
  inheritAttrs: false,
  emits: ['callback'],
  props: {
    modalName: {
      type: String,
      default: 'AlertModal'
    },    
    title: {
      type: String,
      default: 'Info'
    },
    msg: String 
  },
  components: {
    BaseModal
  },
  methods: {
    ok () {
      this.$emit('callback');
      this.close();
    },
    close() {
      this.$vfm.hide(this.modalName);
      this.$emit('callback');
    }
  }
}
</script>

<style scoped>
  button {
    min-width: 100px;
  }
</style>
