<template>
  <section class="card" :class="this.class">
    <header v-if="!!title || this.$slots.header" class="card-header">
      <h2 v-if="!!title" class="card-title">{{ title }}</h2>
      <slot name="header"></slot>      
    </header>     
    <div class="card-body"> 
      <slot name="body"></slot>
    </div> 
    <footer v-if="!!this.$slots.footer" class="card-footer">
      <slot name="footer"></slot>
    </footer>          
  </section>
</template>

<script>
  export default {
    name: 'FormSection',
    inheritAttrs: false,
    props: {
      title: String,
      class: String
    }    
  }
</script>
