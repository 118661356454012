<template>

<Form @submit="onSubmit" ref="form" class="form-horizontal form-bordered">
  <base-modal :name="modalName" v-bind="$attrs" :title="modelName+' - report with ChatGPT'" :width="1024" @close="close">
    <template #body> 
      <div class="row" style="max-height:500px !important; overflow-y:scroll;">
        <div class="col-8">
          <div class="row">
            <div class="col-12">
              <FormField id="bzPromptField" name="promptConfig" v-model="promptConfigValue" type="text" label="Write query instructions (prompt)" :topLabel="true" />
            </div>
          </div>
          <!--
          <div class="row">
            <div class="col-12" style="overflow-x:scroll;">
              <pre id="col-sql" style="opacity:0.8;"></pre>
            </div>
          </div>
          -->
          <div id="displayTableGrid" class="row" style="max-height:500px !important; overflow-x:scroll; overflow-y:scroll; padding: 0px 20px 0px 20px;">
              <div class="flex-grow-1 d-flex align-items-center">
                  <BaseGrid ref="reportGrid" v-if="tableDone" :columns="gridData.columns" :rows="gridData.rows" :enableVScroll="false" />
              </div>
              <div v-if="allRows.length>0 && tableDone" id="displayTablePaging" class="flex-grow-1 d-flex align-items-center">
                  <button v-if="showBackButton" @click="backPage()" type="button" class="btn icon-btn btn-outline-secondary mx-2"><i class="fa-solid fa-arrow-left"></i></button>
                  <button v-if="showNextButton" @click="nextPage()" type="button" class="btn icon-btn btn-outline-secondary"><i class="fa-solid fa-arrow-right"></i></button>
                  <div class="pages-info">{{this.pagesInfo}}</div>
              </div>
          </div>
        </div>
        <div class="col-4">
          <u>Current context/conversation:</u>
          <li v-for="msg in this.userConversation" :key="msg">{{msg}}</li>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="row" style="width:100%;">
        <div class="col-6">
          <button v-if="conversation.length>1" @click="followUp" type="button" class="btn btn-outline-secondary" style="margin-left:5px;">Follow-up</button>
          <button :disabled="promptConfigValue==''" @click="getData" type="button" class="btn btn-primary" style="float:left;">Chat</button>
        </div>
        <div class="col-6">
          <button @click="clear" type="button" class="btn btn-default" data-dismiss="modal" style="margin-left:5px; float:right;">Clear</button>
          <button type="submit" :disabled="submitDisabled" class="btn btn-primary" style="float:right;">Open in Reporting</button>
        </div>
      </div>
    </template>
  </base-modal>
</Form>

</template>

<script>

import BaseModal from '@/App/components/Modal/BaseModal';
import FormField from '@/App/components/Form/FormField';
import BaseGrid from '@/App/components/Grid/BaseGrid'
import { Form } from "vee-validate";
import _app from '@/App/App'

const prompt1 = "Write a MySQL select statement from text `";
const prompt2 = "` using the following database schema: ";
const prompt3 = " For column aliases use comments in database schema and enclose these aliases with ` character. Do not add a semicolon character at the end of your response. I want you to reply with a select statement only in a single line, and nothing else. Do not write explanations.";

const PAGE_SIZE = 100;

export default {
  name: 'ReportingChatGptModal',
  props: {
    modelName: String,
  },
  inheritAttrs: false,
  emits: ['ok'],
  components: {
    BaseModal,
    FormField,
    BaseGrid,
    Form
  }, 
  data() {
    return {
      user: this.$store.state.auth.user,
      modalName: 'Report with ChatGPT',
      modelDbSchema: '',
      promptConfigValue: '',
      conversation: [],
      userConversation: [],
      submitDisabled: true,
      gridData: {
          columns: [],
          rows: []
      },
      allRows: [],
      allSumsPage: [],
      allSumsTotal: [],
      currentPage: 0,
      showBackButton: false,
      showNextButton: false,
      pagesInfo: '',
      tableDone: false,
    };
  },
  mounted() {
    //console.log(this.modelName);
    switch(this.modelName) {
      case 'Alerts':
        this.modelDbSchema = `
            CREATE TABLE v_dwh_fact_alerts (
              ID bigint(20), -- id
              DEVICE_ID int(11), -- device id
              CONDITION_ID int(11), -- condition id
              CONDITION_TEXT text, -- condition txt
              STATUS_NAME varchar(250), -- status name
              STATUS_CODE varchar(50), -- status code
              CLOSE_RESOLUTION varchar(250), -- close resolution
              CLOSE_COMMENT text, -- close comment
              CONDITION_STATE_NAME varchar(250), -- condition state name
              CONDITION_STATE_CODE varchar(50), -- condition state code
              FACT_ID bigint(20), -- agg. id
              FACT_TABLE varchar(100), -- agg. table
              METRIC_VALUE_DEC decimal(18,6), -- metric value num
              METRIC_VALUE_TEXT varchar(512), --  metric value txt
              CURR_METRIC_VALUE_DEC decimal(18,6), -- current metric value num
              CURR_METRIC_VALUE_TEXT varchar(512), -- current metric value txt
              CREATED datetime, -- created ts
              UPDATED datetime, -- update ts
              IOT_DEVICE_ID varchar(200), -- device iot
              DEVICE_NAME varchar(250), -- device name
              DEVICE_SERIAL varchar(250), -- device serial
              LONGITUDE decimal(12,6), -- longitude
              LATITUDE decimal(12,6), -- latitude
              ADDRESS varchar(200), -- address
              CUSTOMER_NAME varchar(500), -- customer
              CUSTOMER_COUNTRY_ISO2 varchar(3), -- customer country abbr
              CUSTOMER_COUNTRY_NAME varchar(250), -- customer country
              DEVICE_STATUS_NAME varchar(250), -- device status
              CONNECTED_STATUS_NAME varchar(250), -- connected status
              MODEL_NAME text, -- model
              CREATED_DATE date, -- created
              UPDATED_DATE date -- updated
            );
          `;
        break;
      case 'Device readings':
        this.modelDbSchema = "";
        break;
      case 'Devices':
        this.modelDbSchema = "";
        break;
      default:
        break;
    }
  },
  watch: {
    currentPage(){ //when this.currentPage changes
        this.changePage();
    },
  },
  methods: {
    /*validateJson(txt) {
      try {
        let json = JSON.parse(txt),
            model = json.modelId;

        if(!model || model==undefined)
          return false;
        else
          return true;
      } catch(err) {
          return false;
      }
    },*/
    followUp() {
      this.promptConfigValue = '';
      //document.getElementById('col-sql').innerText = '';
      
      const inputField = document.getElementById('bzPromptField');
            inputField.focus();
    },
    clear() {
      _app.$modal.confirm('Clear conversation', `Are you sure you want to clear your prompts along with your conversation (context) history?`, (confirmed) => {
      
        if (!confirmed) 
            return;
        
        this.clearGrid();
        this.promptConfigValue = '';
        this.conversation = [];
        this.userConversation = [];
        //document.getElementById('col-sql').innerText = '';
      });
    },
    getData() {
      if(this.modelDbSchema=='') {
        _app.$helper.notifyWarning('Selected model does not allow prompts. Try again later.');
        return false;
      }

      let _val = this.promptConfigValue;

      if(!_val || _val=='') {
        _app.$helper.notifyWarning('No prompt provided in instructions field');
        return false;
      }
      else {
        let _promptTxt = (this.conversation.length==0) ? prompt1 + _val + prompt2 + this.modelDbSchema + prompt3 : _val + prompt3;
        //console.log(_promptTxt);
        
        this.conversation.push({'role':'user', 'content':_promptTxt});
        
        const progress = this.$progress.start();
        
        //console.log(JSON.stringify(this.conversation));
        this.$app.$api.post('Reporting/ProcessPrompt', {"PromptJson":JSON.stringify(this.conversation)}).then(result => {
            progress.finish();
            
            let res = result.data;

            this.userConversation.push(this.user.username + ': ' + _val);
            this.conversation.push({'role':'assistant', 'content':res});

            let updRes = (res.indexOf('SELECT ')==0) ? 'SQL query provided and table displayed' : res;
            this.userConversation.push('assistant: ' + updRes);

            if(res.indexOf('SELECT ')>=0) {
              this.clearGrid();
              this.updateTable(res);
            }
            /*else
              _app.$helper.notifyWarning('Sql statement not defined');*/

            //document.getElementById('col-sql').innerText = res;
            
        }, (error) => {
            progress.finish();
            _app.handleError(error);
        });
      }
    },
    onSubmit(values) {
      console.log(values);
/*
      if(this.validateJson(val)) {

        json = JSON.parse(val);
        this.$emit('ok', json);
        this.$vfm.hide(this.modalName);

      } else {

        let loader = this.$loading.show({ container: this.$refs.form.$el });
      
        _app.$api.get('Reporting/GetReportShareLink', { params: { guid: val } }).then(result => {
            loader.hide();
            let json = result.data.queryJson;
            if(this.validateJson(json)) {
              this.$emit('ok', JSON.parse(json));
              this.$vfm.hide(this.modalName);
            } else {
              _app.$helper.notifyWarning('Text/Link in configuration field is not in valid json format and/or does not contain correct model info');
              document.getElementById('col-json').innerText = '';
              return false;
            }
        }, (error) => {
          loader.hide();
          _app.handleError(error);
          return false;
        });

      }
*/
    },
    close() {
      this.$vfm.hide(this.modalName);
    },
    changePage() {
        if(this.currentPage>0) {
            const _allRows = this.allRows.length,
                _page = this.currentPage,
                _addPage = ((_allRows % PAGE_SIZE)==0) ? 0 : 1,
                _allPages = parseInt(_allRows/PAGE_SIZE)+_addPage,
                _recStart = ((_page-1)*PAGE_SIZE)+1,
                _recEnd = ((_page*PAGE_SIZE)>_allRows) ? _allRows : (_page*PAGE_SIZE);
            
            this.showBackButton = (_page==1) ? false : true;
            this.showNextButton = (_page==_allPages) ? false : true;

            let i = 0;
            this.gridData.rows = this.allRows.filter(obj => {
                                        i++;
                                        return (i>=_recStart && i<=_recEnd);
                                    });
    
            this.pagesInfo = `${_page}/${_allPages} pages, records ${_recStart}-${_recEnd}, total records ${_allRows}`;
        }
        else {
            this.showBackButton = false;
            this.showNextButton = false;
            this.pagesInfo = '';
        }
    },
    clearGrid() {
        this.gridData.columns = [];
        this.gridData.rows = [];
        this.currentPage = 0;
    },
    updateTable(query) {
      let _this = this;
      
      const progress = _this.$progress.start();

      _app.$api.get('Reporting/FetchReportData', { params: {query:query } }).then(
          result => {
              progress.finish();

              const _res = result.data;

              if(_res.length>0) {
                _this.allRows = _res;

                for (const [key, value] of Object.entries(_res[0])) {
                  _this.gridData.columns.push({title:key, dataIndex:key});
                }

                _this.currentPage = 1;
                _this.tableDone = true;
              }
              else
                _app.$helper.notifyInfo('Your query returned no data.');
          },
          error => {
              progress.finish();
              _app.handleError(error);
          }
      );
    },
    backPage() {
        this.currentPage--;
    },
    nextPage() {
        this.currentPage++;
    },
  }
}
</script>

<style scoped>
div.pages-info {
    display: inline-block !important;
    margin-left: 20px;
}
</style>