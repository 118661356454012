import {
  Form as VeeForm,
  Field as VeeField,
  defineRule,
  configure,
  ErrorMessage,
} from "vee-validate";

import {
  required,
  // min,
  // max,
  // alpha_spaces as alphaSpaces,
  email,
  // min_value as minVal,
  // max_value as maxVal,
  // not_one_of as excluded,
  // confirmed,
  ip_or_fqdn
} from "@vee-validate/rules";

export default {
  install(app) {
    app.component("VeeForm", VeeForm);
    app.component("VeeField", VeeField);
    app.component("ErrorMessage", ErrorMessage);

    defineRule("required", required);
    // defineRule("min", min);
    // defineRule("max", max);
    // defineRule("alpha_spaces", alphaSpaces);
    defineRule("email", email);
    // defineRule("min_value", minVal);
    // defineRule("max_value", maxVal);
    // defineRule("excluded", excluded);
    // defineRule("country_excluded", excluded);
    // defineRule("password_mismatch", confirmed);
    // only for vue 2 ??? defineRule("ip_or_fqdn", ip_or_fqdn);

    defineRule("ip", (value) => {
      if (!value || !value.length) {
        return true;
      }
      if (!/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(value)) {
        return "invalid IP address";
      }
      return true;
    });

    configure({
      generateMessage: (context) => {
        const messages = {
          required: `${context.field} is required`,
          // min: `This field ${context.field} is too short.`,
          // max: `This field ${context.field} is too long.`,
          // alpha_spaces: `This field ${context.field} can only contain letters and spaces.`,
          email: `This field ${context.field} is not a valid email.`,
          // min_value: `This field ${context.field} is too low.`,
          // max_value: `This field ${context.field} is too high.`,
          // excluded: "This field is not allowed.",
          // country_excluded: "We do not allow users from this location",
          // password_mismatch: `This field ${context.field} does not match.`,
          //ip_or_fqdn: `This field ${context.field} is not a valid ipv4 or ipv6 or fully-qualified domain name.`
          ip: `This field ${context.field} is not a valid IP address.`
        };
        const message = messages[context.rule.name]
          ? messages[context.rule.name]
          : `${context.field} is not valid`;
        return message;
      },
      // validateOnBlur: true,
      // validateOnChange: true,
      // validateOnInput: false,
      // validateOnModelUpdate: true,
    });
  },
};