<template>
  <div class="d-flex align-content-center col-sm-10 col-md-10 col-lg-10">
    <div v-show="showInputs">
      <label v-if="showInputs && fromLabel" :for="$id('fromInput')" class="form-label">{{fromLabel}}</label>
      <input ref="fromInput" :id="$id('fromInput')" class="form-control" type="number" :value="inputFromValue" :min="min" :max="max" :style="inputStyle" @focus="$event.target.select()" />    
    </div>
    <div class="flex-grow-1 slider-wrap" :class="{ 'mx-3': showInputs }">
      <input ref="fromSlider" type="range" class="range-from-slider" :min="sliderMin" :max="sliderMax" style="height:0px;z-index: 1" />
      <input ref="toSlider" type="range" class="range-to-slider" :min="sliderMin" :max="sliderMax" />
    </div>
    <div v-show="showInputs">
      <label v-if="showInputs && toLabel" :for="$id('toInput')" class="form-label">{{toLabel}}</label>
      <input ref="toInput" :id="$id('toInput')" class="form-control" type="number" :value="inputToValue" :min="min" :max="max" :style="inputStyle" @focus="$event.target.select()" />
    </div>
  </div>
  <div v-if="false && currentValue!=null">{{currentValue}}</div>
  </template>
  
  <script>
  
  //#region private
  
  //const _sliderColor = '#C6C6C6', _rangeColor = '#25daa5';
  const _sliderColor = '#E6E6E6', _rangeColor = '#00FFD7';
  
  const _scaleType = {
    LINEAR: 'LINEAR',
    LOGARITHMIC: 'LOGARITHMIC',
    LUX: 'LUX'
  }
  //#endregion
  
  export default {
    name: 'BaseDualSliderField',
    props: {
      min: {
        type: Number,
        default: 0
      },
      max: {
        type: Number,
        default: 100
      },    
      fromValue: Number,
      fromLabel: String,
      toValue: Number,
      toLabel: String,
      showInputs: {
        type: Boolean,
        default: true
      },
      inputWidth: {
        type: Number,
        default: 65
      },
      currentValue: Number,
      scaleType: {
        type: String,
        default: _scaleType.LINEAR
      }
    },
    emits: ['fromValueChange', 'toValueChange'],
    data() {
      let inputFromValue = this.fromValue || this.min;
      let inputToValue = this.toValue || this.max;
      return {
        inputFromValue: inputFromValue,
        inputToValue: inputToValue, 
        sliderMin: this.min,
        sliderMax: this.max,
        inputStyle: { 
          width: this.inputWidth + 'px'
        },
        scaleValues: []
      }
    },
    mounted() {
  
      // get refs
      const fromSlider = this.$refs.fromSlider;
      const toSlider = this.$refs.toSlider;
      const fromInput = this.$refs.fromInput;
      const toInput = this.$refs.toInput;

      // logaritmic slider
      if (this.scaleType == _scaleType.LOGARITHMIC || this.scaleType == _scaleType.LUX) {
        if (this.scaleType == _scaleType.LOGARITHMIC) {
          for(let i = 0; i <= 100; i++) {
            this.scaleValues.push(this.getLogValue(i));
          }
        }
        else if (this.scaleType == _scaleType.LUX) {
          for(let i = 0; i <= 5; i++) { this.scaleValues.push(i); }
          for(let i = 2; i <= 10; i++) { this.scaleValues.push(i * 5); }
          for(let i = 6; i <= 10; i++) { this.scaleValues.push(i * 10); }
          for(let i = 3; i <= 10; i++) { this.scaleValues.push(i * 50); }
          for(let i = 6; i <= 15; i++) { this.scaleValues.push(i * 100); }        
          for(let i = 4; i <= 10; i++) { this.scaleValues.push(i * 500); }
          for(let i = 6; i <= 20; i++) { this.scaleValues.push(i * 1000); }
          for(let i = 5; i <= 20; i++) { this.scaleValues.push(i * 5000); }
        }
        
        this.sliderMin = 0;
        this.sliderMax = this.scaleValues.length - 1;  
        //console.log(this.inputToValue, this.sliderToValue)
        //console.log(this.scaleValues.length, this.scaleValues);
      }

      fromSlider.value = this.inputValueToSliderValue(this.inputFromValue)
      toSlider.value = this.inputValueToSliderValue(this.inputToValue)
  
      // attach to events
      fromSlider.oninput = () => this.onFromSliderInput(fromSlider, toSlider, fromInput)
      fromSlider.onchange = (e) => { this.onValueChange('fromValueChange', this.slideValueToInputValue(e.target.value), e) }
      toSlider.oninput = () => this.onToSliderInput(fromSlider, toSlider, toInput)
      toSlider.onchange = (e) => { this.onValueChange('toValueChange', this.slideValueToInputValue(e.target.value), e) }
  
      if (this.showInputs) {
        fromInput.oninput = (e) => {
          this.onFromInput(fromSlider, fromInput, toInput)
          this.onValueChange('fromValueChange', fromInput.value, e)
        }
        toInput.oninput = (e) => {
          this.onToInput(toSlider, fromInput, toInput, toSlider)
          this.onValueChange('toValueChange', toInput.value, e)
        }              
      } 

      this.fillSliderRange();
      this.setToggleAccessible(toSlider);
    },
    watch: {
      fromValue() {
        this.inputFromValue = this.fromValue || this.min;    
        this.$refs.fromSlider.value = this.inputValueToSliderValue(this.inputFromValue)
        this.fillSliderRange();
      },
      toValue() {
        this.inputToValue = this.toValue || this.max;
        this.$refs.toSlider.value = this.inputValueToSliderValue(this.inputToValue)
        this.fillSliderRange();
      }      
    },
    methods: {
      onValueChange(eName, value, e) {
        // console.log(this.inputFromValue, this.inputToValue);
        // console.log(eName, value);
        this.$emit(eName, parseInt(value, 10), e)
      },

      onFromInput(fromSlider, fromInput, toInput) {
        const [from, to] = this.getCurrentValues(fromInput, toInput);
        if (from > to) {
          fromSlider.value = this.inputValueToSliderValue(to);
          fromInput.value = to;
        } else {
          fromSlider.value = this.inputValueToSliderValue(from);
        }
        this.fillSliderRange();
      },
          
      onToInput(toSlider, fromInput, toInput) {
        let [from, to] = this.getCurrentValues(fromInput, toInput);

        if (to > this.max)
            to = this.max;

        if (from <= to) {
          toSlider.value = this.inputValueToSliderValue(to);
          toInput.value = to;
        } else {
          toSlider.value = this.inputValueToSliderValue(from);
          //toInput.value = from;
        }
        this.fillSliderRange();
        this.setToggleAccessible(toInput, toSlider);
      },
  
      onFromSliderInput(fromSlider, toSlider, fromInput) {
        let [from, to] = this.getCurrentValues(fromSlider, toSlider);    
        if (from > to) {        
          fromSlider.value = to;
          from = to;
        }     
        fromInput.value = this.slideValueToInputValue(from);
        this.fillSliderRange();   
      },
  
      onToSliderInput(fromSlider, toSlider, toInput) {
        const [from, to] = this.getCurrentValues(fromSlider, toSlider);     
        if (from <= to) {
          toSlider.value = to;
          toInput.value = this.slideValueToInputValue(to);
        } else {
          toSlider.value = from;
          toInput.value = this.slideValueToInputValue(from);        
        }
        this.fillSliderRange();      
        this.setToggleAccessible(toSlider);           
      },
  
      getCurrentValues(currentFrom, currentTo) {
        const from = parseInt(currentFrom.value, 10);
        const to = parseInt(currentTo.value, 10);
        return [from, to];
      },
  
      fillSliderRange() {

        const rangeDistance = this.sliderMax-this.sliderMin;
        const fromPosition = this.$refs.fromSlider.value - this.sliderMin;
        const toPosition = this.$refs.toSlider.value - this.sliderMin;
        // console.log(fromPosition, toPosition)
        this.$refs.toSlider.style.background = `linear-gradient(
          to right,
          ${_sliderColor} 0%,
          ${_sliderColor} ${(fromPosition)/(rangeDistance)*100}%,
          ${_rangeColor} ${((fromPosition)/(rangeDistance))*100}%,
          ${_rangeColor} ${(toPosition)/(rangeDistance)*100}%, 
          ${_sliderColor} ${(toPosition)/(rangeDistance)*100}%, 
          ${_sliderColor} 100%)`;
      },
  
      setToggleAccessible(currentTarget, toSlider) {
        toSlider = toSlider || currentTarget;
        if (Number(currentTarget.value) <= 0 ) {
          toSlider.style.zIndex = 2;
        } else {
          toSlider.style.zIndex = 0;
        }
      }, 
  
      slideValueToInputValue(sliderValue) {
        
        if (this.scaleValues.length > 0 && sliderValue >= 0 && sliderValue < this.scaleValues.length) {
          return this.scaleValues[sliderValue];
        } else {
          return sliderValue;
        }
      },
  
      inputValueToSliderValue(inputValue) {
  
        if (this.scaleValues.length === 0)
          return inputValue;
        
        const scaleValue = this.getClosestScaleValue(inputValue);
  
        return this.scaleValues.indexOf(scaleValue);
      },
  
      getClosestScaleValue(scaleValue) {
  
        if (this.scaleValues.length == 0)
          return null;
        
        let closest = this.scaleValues[0];
        var diff = Math.abs (scaleValue - closest);
        for (var val = 0; val < this.scaleValues.length; val++) {
            var newdiff = Math.abs(scaleValue - this.scaleValues[val]);
            if (newdiff < diff) {
                diff = newdiff;
                closest = this.scaleValues[val];
            }
        }        
        //console.log(closest);
  
        return closest;
      },
  
      getLogValue(pos) {
        
        // position will be between 0 and 100
        const minPos = 0, maxPos = 100;
  
        if (pos === minPos)
          return this.min;
  
        if (pos == maxPos)
          return this.max;
  
        // min max log value
        const minLogVal = this.min === 0 ? 0 : Math.log(this.min), maxLogVal = this.max === 0 ? 0 :  Math.log(this.max);
  
        // calculate adjustment factor
        var scale = (maxLogVal -  minLogVal) / (maxPos - minPos);
  
        return Math.round(Math.exp(minLogVal + scale * (pos - minPos))) 
      }
    }
  }
  </script>
  
<style scoped>
  html.dark input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    pointer-events: all;
    width: 16px;
    height: 16px;
    background-color: #00ffd7;
    border-radius: 50%;
    box-shadow: 0 0 0 1px #00ffd7;
    cursor: pointer;
  }
  
  html.dark input[type=range]::-moz-range-thumb {
    -webkit-appearance: none;
    pointer-events: all;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #00ffd7;
    box-shadow: none !important;
    border:0px !important;
    cursor: pointer;  
  }
  
  /* input[type=range]::-webkit-slider-thumb:hover {
    background: #f7f7f7;
  } */
  
  /* input[type=range]::-webkit-slider-thumb:active {
    box-shadow: inset 0 0 3px #387bbe, 0 0 9px #387bbe;
    -webkit-box-shadow: inset 0 0 3px #387bbe, 0 0 9px #387bbe;
  }
   */
   
  input[type="range"] {
    -webkit-appearance: none; 
    appearance: none;
    height: 2px;
    width: 100%;
    position: absolute;
    bottom: 18px;
    background-color: #C6C6C6;
    pointer-events: none;
  }
  
  input[type="number"] {
    text-align: center;  
    padding-right:2px;
  }
  
  .slider-wrap {
    min-height: 40px;
    position:relative;
  }

  html.dark input[type="range"].range-from-slider {
    height:0px;
    bottom: 19px;
  }

  html.dark input[type="range"].range-to-slider {
    height:8px;
    bottom: 15px;    
  }

</style>