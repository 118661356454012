<template>
  <div class="lab22-mv-padding">
    <ModuleHeader iconClass="fa-solid fa-bell" :title="'Alert details - To Do'" @titleClick="load(true)">
      <div class="d-flex">
        <!-- <button @click="close" type="button" class="btn btn-primary">Close alert</button> -->
      </div>
    </ModuleHeader>
  </div>
</template>

<script>
import ModuleHeader from '@/App/components/Common/ModuleHeader'
export default {
  name: 'AlertDetails',
  components: {
    ModuleHeader
  },  
  data() {
    return {
      data: null
    }
  },
  mounted() {
    if (this.$route.params.data)
      this.data = JSON.parse(this.$route.params.data)
    else 
      this.$router.back();

    // this.load();
  },
  methods: {
    // load(reload) {
    //   const progress = reload ? this.$progress.start() : null;
    //   this.$app.$api.get('Alert/GetAlert', { params: { id: this.data?.id } }).then(
    //     result => {
    //       if (progress) progress.finish();

    //       this.data = result.data;
    //     },
    //     error => {
    //       if (progress) progress.finish();
          
    //       this.$app.handleError(error);          
    //     }
    //   );
    // },
    // close() {
    //   this.$app.notify('Close alert');
    // }
  } 
}
</script>

<style scoped>

</style>