<template>
<Form @submit="onSubmit" :validation-schema="validationSchema" class="form-horizontal form-bordered">
  <base-modal :name="modalName" title="Add component" :width="400" @close="close" v-bind="$attrs">
    <template #body>  
      <FormField name="modelId" label="Component" :topLabel="true" as="select" :data="configComponents" />
    </template>
    <template #footer>
      <slot name="footer">
        <button @click="close" type="button" class="btn btn-default" data-dismiss="modal">Cancel</button>
        <button type="submit" class="btn btn-primary">Add</button>
      </slot>
  </template>
  </base-modal>
</Form>
</template>

<script>
import BaseModal from '@/App/components/Modal/BaseModal'
import FormField from '@/App/components/Form/FormField';
import { Form } from "vee-validate";

export default {
  name: 'AddDeviceComponentModal',
  inheritAttrs: false,
  emits: ['addComponent'],
  components: {
    BaseModal,
    FormField,
    Form
  },   
  data() {
    return {
      modalName: 'AddDeviceComponentModal',
      validationSchema: {
        modelId: 'required'
      }
    };
  },
  mounted() {
      
  },
  computed: {
    configComponents: function() {
      return this.$app.$data.DeviceComponentModel.getConfigOptions().map(i => {
        return {
          id: i.id,
          name: `${this.$app.$data.DeviceComponentCategory.getName(i.modelCategoryId)} - ${i.name}`
        }
      });
    }
  },
  methods: {
    onSubmit(values) {  

      this.$vfm.hide(this.modalName);

      this.$emit('addComponent', values);
    },
    close() {
      this.$vfm.hide(this.modalName);
    }
  }
}
</script>

<style scoped>
</style>