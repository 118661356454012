<template>
  <div class="lab22-mv-padding">

    <!--<ModuleHeader title="System Health Check" />-->

    <div style="width:100%;overflow-x: hidden; max-width:1000px;">
      <div class="row text-left" style="margin-bottom:20px;"> <!-- colors legend -->
        <div class="col-12">
          <span style="color:#161616; background-color:#fff;">&nbsp;NEUTRAL&nbsp;</span>
          <span style="color:#161616; background-color:#00FFD7; margin-left:10px;">&nbsp;GOOD&nbsp;</span>
          <span style="color:#161616; background-color:#ffcc19; margin-left:10px;">&nbsp;WARNING&nbsp;</span>
          <span style="color:#161616; background-color:#ff19ff; margin-left:10px;">&nbsp;CRITICAL&nbsp;</span>
        </div>
      </div>
      <div class="row">
          <div class="col-6"> <!-- AZURE DAILY MESSAGE QUOTA -->
            <DashboardKpiWidgetLab22 
              ref="msgQuota" 
              headerTitle="AZURE DAILY MESSAGE QUOTA" 
              :bigNumber="'~'+this.msgPercentageTxt" 
              :classList="this.quotaColor" 
              showFooter 
              :footerText="this.msgStats" 
              @click="this.routeMe('azure')"
              :chartData="this.quotaChart"
            />
          </div>
          <div class="col-6"> <!-- MAX CLIENT EVENT DELAY -->
            <DashboardKpiWidgetLab22 
              ref="eventDelay" 
              headerTitle="MAX CLIENT EVENT DELAY" 
              :bigNumber="this.eventDelayTxt" 
              :classList="this.eventDelayColor" 
              showFooter 
              footerText="difference between client timestamp and database timestamp" 
              @click="this.routeMe('device')" 
            />
          </div>
      </div>
      <div class="row">
        <div class="col-6"> <!-- etl hourly job table -->
          <div v-if="hourlyEtlError" class="row">
            <div class="col-12" style="color:#ff19ff;">ERROR IN HOURLY ETL!<br />{{ hourlyEtlErrorDescr }}</div>
          </div>
          <div v-if="aggregationEtlError" class="row">
            <div class="col-12" style="color:#ff19ff;">ERROR IN AGGREGATION ETL!<br />{{ aggregationEtlErrorDescr }}</div>
          </div>
          <div class="row">
            <div class="col-12">
              <table class="table lab-table table-borderless">
                <tr>
                  <th style="text-align:left;">JOB NAME</th><th style="text-align:left;">STARTED</th><th style="text-align:left;">ENDED</th><th style="text-align:left;">DURATION [s]</th>
                </tr>                            
                <tr v-for="item in etlHourlyData" :key="item.ended">
                  <td align="left" class="lab-td-elipsis">{{ item.job_name }}</td>
                  <td align="left">{{ item.started }}</td>
                  <td align="left">{{ item.ended }}</td>
                  <td v-if="item.duration_sec<300" align="right" style="color:#fff;">{{ item.duration_sec }}&nbsp;&nbsp;&nbsp;</td>
                  <td v-if="item.duration_sec>=300 && item.duration_sec<600" align="right" style="color:#ffcc19;">{{ item.duration_sec }}&nbsp;&nbsp;&nbsp;</td>
                  <td v-if="item.duration_sec>=600" align="right" style="color:#ff19ff;">{{ item.duration_sec }}&nbsp;&nbsp;&nbsp;</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="col-6"> <!-- etl daily job table -->
          <div v-if="dailyEtlError" class="row">
            <div class="col-12" style="color:#ff19ff;">ERROR IN DAILY ETL!<br />{{ hourlyEtlErrorDescr }}</div>
          </div>
          <div class="row">
            <div class="col-12">
              <table class="table lab-table table-borderless">
                <tr>
                  <th style="text-align:left;">JOB NAME</th><th style="text-align:left;">STARTED</th><th style="text-align:left;">ENDED</th><th style="text-align:left;">DURATION [s]</th>
                </tr>                            
                <tr v-for="item in etlDailyData" :key="item.ended">
                  <td align="left" class="lab-td-elipsis">{{ item.job_name }}</td>
                  <td align="left">{{ item.started }}</td>
                  <td align="left">{{ item.ended }}</td>
                  <td v-if="item.duration_sec<1800" align="right" style="color:#fff;">{{ item.duration_sec }}&nbsp;&nbsp;&nbsp;</td>
                  <td v-if="item.duration_sec>=1800 && item.duration_sec<3600" align="right" style="color:#ffcc19;">{{ item.duration_sec }}&nbsp;&nbsp;&nbsp;</td>
                  <td v-if="item.duration_sec>=3600" align="right" style="color:#ff19ff;">{{ item.duration_sec }}&nbsp;&nbsp;&nbsp;</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="isChatEnabled">
      <!--
      <button part="button" class="chat-button fixed shadow-md rounded-full hover:scale-110 active:scale-95 transition-transform duration-200 flex justify-center items-center animate-fade-in w-12 h-12" style="z-index: 42424242; background-color: rgb(59, 129, 246); right: 20px; bottom: 20px;">
        <img src="https://raw.githubusercontent.com/walkxcode/dashboard-icons/main/svg/google-messages.svg" class="rounded-full object-coverscale-100 opacity-100 w-7 h-7" />
        <svg viewBox="0 0 24 24" class="absolute duration-200 transition scale-0 -rotate-180 opacity-0 w-7" style="fill: white;">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M18.601 8.39897C18.269 8.06702 17.7309 8.06702 17.3989 8.39897L12 13.7979L6.60099 8.39897C6.26904 8.06702 5.73086 8.06702 5.39891 8.39897C5.06696 8.73091 5.06696 9.2691 5.39891 9.60105L11.3989 15.601C11.7309 15.933 12.269 15.933 12.601 15.601L18.601 9.60105C18.9329 9.2691 18.9329 8.73091 18.601 8.39897Z"></path>
        </svg>
      </button>
      -->
      <button part="button" class="chat-button"><i class="fa fa-eye" style="font-size:large;"></i></button>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
//import ModuleHeader from '@/App/components/Common/ModuleHeader'
import DashboardKpiWidgetLab22 from './DashboardKpiWidgetLab22'

let lastWeek = new Date();
    lastWeek.setDate(lastWeek.getDate() - 7);

export default {
  name: 'DashboardLab22',
  components: {
    //ModuleHeader,
    DashboardKpiWidgetLab22
  },
  data: function() {
    return {
      timerId: null,
      loading: false,
      dateFrom: this.$app.formatDate(lastWeek, 'YYYY-MM-DDTHH:mm'),
      dateTo: this.$app.formatDate(new Date(), 'YYYY-MM-DDTHH:mm'),
      msgQuota: 400000, //default value from Azure account
      msgPercentage: null,
      msgPercentageTxt: '',
      msgStats: null,
      quotaColor: null,
      quotaChart: null,
      eventDelayTxt: '',
      eventDelayColor: null,
      eventDelayDevice: null,
      etlDailyData: null,
      etlHourlyData: null,
      hourlyEtlError: false,
      hourlyEtlErrorDescr: '',
      aggregationEtlError: false,
      aggregationEtlErrorDescr: '',
      dailyEtlError: false,
      dailyEtlErrorDescr: '',
      isChatEnabled: false,
    }
  },
  mounted() {
    
    let _this = this;

    _this.loadReports();

    _this.timerId = setInterval(function() {
      _this.loadReports(true);
    }, 59 * 1000);
  },
  unmounted() {
    clearInterval(this.timerId);
  },
  methods: {

    formatUtcDate(date) {
        let format = 'DD.MM.YY HH:mm:ss',
            d = dayjs(date);
            d = d.add(d.utcOffset(), "minute");
        return d.format(format);
    },

    getMsgQouta(msgUsed) {
      this.msgPercentage = (msgUsed/this.msgQuota)*100.00;
      this.msgPercentageTxt = parseInt(this.msgPercentage).toString()+'%';
      this.msgStats = '~' + parseInt(msgUsed/1000) + 'k of ' + parseInt(this.msgQuota/1000) + 'k msg/day used';
      if(this.msgPercentage<50) this.quotaColor = 'green2';
      if(this.msgPercentage>=50 && this.msgPercentage<=90) this.quotaColor = 'yellow';
      if(this.msgPercentage>90) this.quotaColor = 'purple';
    },

    getEventDelay(delay) {
      let split = delay.split('#');
      this.eventDelayDevice = split[0];
      delay = parseInt(split[1]);
      if(delay>=60)
        this.eventDelayTxt =  parseInt(delay/60).toString() + 'min ' + parseInt(delay % 60).toString() + 'sec';
      else
        this.eventDelayTxt =  delay + 'sec';
      if(delay<30) this.eventDelayColor = 'green2';
      if(delay>=30 && delay<=60) this.eventDelayColor = 'yellow';
      if(delay>60) this.eventDelayColor = 'purple';
    },

    loadReports(silent = false) {

      let loader = null;

      if (!silent) {
        this.loading = true;
        loader = this.$loading.show();
      }

      const _this = this;

      this.$app.$api.get('Dashboard/GetDashboardValues', { params: {customerId:-1, dateFrom:this.dateFrom, dateTo:this.dateTo } }).then(
        result => {
          const data = result.data;
          
          for(let i=0; i<data.length; i++) {
            switch(data[i].key) {
              case 'MSG_QUOTA': _this.getMsgQouta(data[i].value); break;
              case 'QUOTA_CHART': 
                    _this.quotaChart = [{
                        data: (data[i].value).split(',').map(i => { return parseInt(i); })
                    }];
                    break;
              case 'EVENT_DELAY': _this.getEventDelay(data[i].value); break;
              default: break;
            }
          }

          Promise.all([
            this.$app.$api.get('Dashboard/GetDashboardTable', { params: {dateFrom:this.dateFrom, dateTo:this.dateTo, table:'ETL_DAILY' } }),
            this.$app.$api.get('Dashboard/GetDashboardTable', { params: {dateFrom:this.dateFrom, dateTo:this.dateTo, table:'ETL_HOURLY' } }),
            this.$app.$api.get('Dashboard/GetDashboardTable', { params: {dateFrom:this.dateFrom, dateTo:this.dateTo, table:'ETL_ERROR_HOURLY' } }),
            this.$app.$api.get('Dashboard/GetDashboardTable', { params: {dateFrom:this.dateFrom, dateTo:this.dateTo, table:'ETL_ERROR_AGGREGATION' } }),
            this.$app.$api.get('Dashboard/GetDashboardTable', { params: {dateFrom:this.dateFrom, dateTo:this.dateTo, table:'ETL_ERROR_DAILY' } }),
          ])  
          .then(results => {
            //fetch datasets
            
            this.etlDailyData = results[0].data;

            this.etlHourlyData = results[1].data;

            this.hourlyEtlError = (results[2].data.length>0) ? true : false;
            this.hourlyEtlErrorDescr = (results[2].data.length>0) ? results[2].data[0].error_descr : '';

            this.aggregationEtlError = (results[3].data.length>0) ? true : false;
            this.aggregationEtlErrorDescr = (results[3].data.length>0) ? results[3].data[0].error_descr : '';

            this.dailyEtlError = (results[4].data.length>0) ? true : false;
            this.dailyEtlErrorDescr = (results[4].data.length>0) ? results[4].data[0].error_descr : '';

            for(let i=0; i<this.etlDailyData.length; i++) {
              this.etlDailyData[i].started = this.formatUtcDate(this.etlDailyData[i].started);
              this.etlDailyData[i].ended = this.formatUtcDate(this.etlDailyData[i].ended);
            }

            for(let i=0; i<this.etlHourlyData.length; i++) {
              this.etlHourlyData[i].started = this.formatUtcDate(this.etlHourlyData[i].started);
              this.etlHourlyData[i].ended = this.formatUtcDate(this.etlHourlyData[i].ended);
            }
            
            _this.loading = false;
            if (loader) loader.hide();

          });

        },
        error => {
          _this.loading = false;
          if (loader) loader.hide();
          _this.$app.handleError(error);          
        }
      );
    },

    routeMe(widget) {
      switch(widget) {
          //case 'devices': this.$router.push({ name: 'devices', params: {} }); break;
          case 'device': this.$router.push('/devices/' + this.eventDelayDevice + '/status'); break;
          case 'azure': window.open('https://learn.microsoft.com/en-us/azure/iot-central/core/concepts-quotas-limits', '_blank'); break;
          default: console.log('Router for '+widget+' does not exist.'); break;
      }
    },
  }
};
</script>

<style scoped>
@keyframes pulsate {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
  
.chat-button {
  width: 40px;
  height: 40px;
  animation: pulsate 2s infinite;
  position: fixed;
  bottom: 20px;
  right: 20px;
  border-radius: 50% !important;
  /*cursor: pointer !important;
  z-index: 999999999 !important;*/
}
.chat-button, .chat-button:focus, .chat-button:active {
  box-shadow:none !important;
  outline:0px !important;
  border-style: none !important;
}

.lab-table {
    margin-top:10px; 
    color:#ccc; 
    width:100%; 
    font-size:0.8rem;
}

.card-body {
    cursor: pointer !important;
    border-radius: 0.01rem !important;
}

.lab-td-elipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 120px;
}
</style>

<style>
section.card {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}
</style>