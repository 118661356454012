<template>
<div class="not-found-content">
  <!-- <div class="illustration-wrapper">
    <app-aspect-ratio [ratio]="{w: 1, h: 1}">
      <app-image-shell [src]="'./assets/sample-images/page-not-found.svg'" [alt]="'not found page'"></app-image-shell>
    </app-aspect-ratio>
  </div> -->
  <div class="not-found-title">404</div>
  <div class="not-found-text">Page not found</div>
  <button class="btn btn-primary btn-block" @click="this.$router.back()">Go Back</button>
</div>

</template>

<script>

export default {
  name: 'PageNotFound'
}
</script>
