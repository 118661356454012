<template>  
  <div class="toolbar d-flex flex-row align-items-center">
    <div class="d-flex flex-row align-items-center">
      <slot></slot>
    </div>    
    <div v-if="this.$slots.end" class="flex-grow-1"></div>
    <div class="d-flex flex-row align-items-center">
      <slot name="end"></slot>
    </div>
  </div>
</template>

<script>
  // import _app from '@/App/App.js';

  export default {
    name: 'ToolbBar',
    props: {

    },
    emits: [],
    components: {
  
    },    
    data() {
      return {
      }
    },
    methods: {

    }
  }
</script>

<style scoped>

form > * {
  margin-right: 5px;
}

</style>

<style>
.toolbar {
  border-top: 1px solid rgb(222, 226, 230);
  border-bottom: 1px solid rgb(222, 226, 230);
  padding: 10px 0 10px 5px;
  margin-bottom: 10px;
}

.toolbar .label {
  padding-right: 5px;
  font-size:14px;
  color: #adadad; 
  line-height:38px;
  white-space: nowrap;
  vertical-align: middle;
}

</style>