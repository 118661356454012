<template>
  <div class="lab22-mv-padding">

    <!--<ModuleHeader iconClass="fa-solid fa-bell" title="Alerts">-->
    <ModuleHeader title="Alerts" iconClass="inf-module-header-alerts inf-module-header-img">
      <button v-if="checkedRecords.length > 0" @click="closeSelected" type="button" class="btn btn-primary mx-2">Close selected ({{checkedRecords.length}})</button>
      <div class="btn-group">
        <!--<button type="button" class="btn btn-outline-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="fa-solid fa-gear"></i></button>-->
        <button type="button" class="btn btn-outline-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="svg-settings"></i></button>
        <ul class="dropdown-menu dropdown-menu-end">
          <li><a class="dropdown-item" @click.prevent="exportList('csv')">Export (csv)</a></li>
        </ul>
      </div>
    </ModuleHeader>

    <FilterBar v-slot="{state}" :filterState="getFilterState()" @reload="load()" showReload showReset :loading="loading">
      <BaseField v-if="!this.$app.user.isCustomerUser()" v-model="state.customerId" name="customerId" placeholder="Customer" as="select" :data="this.$app.$data.customers" addClearOption />
      <BaseField v-model="state.statusId" name="statusId" placeholder="Alert status" as="select" :data="this.statusOptions" />
      <!--<BaseField v-model="state.conditionStateId" name="conditionStateId" placeholder="Condition state" as="select" :data="this.conditionStatusOptions" addClearOption />-->
      <BaseField v-model="state.conditionStateId" name="conditionStateId" placeholder="Alert state" as="select" :data="this.conditionStatusOptions" addClearOption />
      <BaseField v-model="state.deviceId" name="deviceId" placeholder="Device" as="select" :data="this.$app.$data.devices" addClearOption />
      <BaseField v-model="state.id" name="name" placeholder="Alert id" />
      <BaseField v-model="state.alertText" name="alertText" placeholder="Alert text" />
    </FilterBar>

    <AlertListBase ref="alertListBase" :filterState="getFilterState()" @checkchange="onCheckChange" @loading="(loadingStatus) => loading = loadingStatus"></AlertListBase>
  </div>
</template>

<script>
import ModuleHeader from '@/App/components/Common/ModuleHeader'
import FilterBar from '@/App/components/Common/FilterBar'
import BaseField from '@/App/components/Form/BaseField'

import AlertListBase from '@/Modules/Alerts/AlertListBase'

const PAGE_SIZE = 100;

export default {
  name: 'AlertList',
  components: {
    ModuleHeader,
    FilterBar,
    BaseField,
    AlertListBase
  },
  data() {
    return {
      loading: false,
      statusOptions: [
        { id: 1, name: 'Opened'},
        { id: 2, name: 'Closed'}
      ],
      conditionStatusOptions: [
        { id: 1, name: 'Active'},
        { id: 2, name: 'Not Active'}        
      ],
      checkedRecords: [],
      listStoreKey: 'ALERT_LIST'
    }
  },
  mounted() {
    if (this.$app.$data.storeLoaded(this.listStoreKey))
      this.$refs.alertListBase.load(false);  

    this.load();
  },
  methods: {
    getFilterState() {
      return this.$app.$data.getState('AlertListFilter', { 
        rowNum: 1, 
        pageSize: PAGE_SIZE, limit: 100 ,
        statusId: 1
      });
    },
    load() {
      this.$refs.alertListBase.setFilter(this.getFilterState());
    },
    exportList(type) {
      this.$refs.alertListBase.exportList(type);
    },
    onCheckChange(checkedRecords) {
      this.checkedRecords = checkedRecords;
    },
    closeSelected() {
      this.$refs.alertListBase.closeSelected();
    }
  }
}
</script>
