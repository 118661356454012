<template>

  <base-modal :name="modalName" :title="modalName" v-bind="$attrs" :width="720" @close="close" style="z-index:9999;">
    <template #body> 
      <div style="max-height:400px !important; overflow-y:scroll;">
        <div>
          You are adding filter "<span class="yellow">{{this.column.expr.id}} equals {{value}}</span>" to your query.
          <br />
          <span v-if="selectedField==''">Next, select a column to replace column "<span class="yellow">{{this.column.expr.id}}</span>" in query configuration</span>
          <span v-if="selectedField!==''">You selected "<span class="yellow">{{selectedField}}</span>" to replace column "<span class="yellow">{{this.column.expr.id}}</span>" in query configuration</span>
        </div>
        <nav class="nav nav-pills flex-column flex-sm-row" style="margin-top:15px; margin-bottom:10px;">
          <a v-for="(entity,index) in entities" :key="index" class="flex-sm-fill text-sm-center nav-link nav-link-drilldown-entities" :class="{ active:(index==0) }" aria-current="page" href="#" @click.prevent="showEntities(index)">{{entity.caption}}</a>
        </nav>
        <div v-for="(entity,index) in entities" :key="index" class="div-drilldown-entities" :class="{ visibleSubsList:(index==0), hiddenSubsList:(index>0) }" style="min-height:100px;">
          <div v-for="sub in entities[index].attributes" :key="sub.id" class="form-check">
            <input class="form-check-input lab22-chk-drilldown" type="radio" name="subs" :id="sub.id" :value="sub.id" v-model="selectedField">
            <label class="form-check-label" :for="sub.id">{{sub.caption}}</label>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <button type="button" @click.prevent="run" class="btn btn-primary">Run query</button>
      <button type="button" @click.prevent="edit" class="btn btn-outline-primary">Edit query</button>
      <button type="button" @click.prevent="close" class="btn btn-secondary">Cancel</button>
    </template>
  </base-modal>

</template>

<script>
import BaseModal from '@/App/components/Modal/BaseModal';
import _app from '@/App/App'

export default {
  name: 'ReportingDrillDown',
  inheritAttrs: false,
  emits: ['run','edit'],
  props: {
    title: String,
    Column: Object,
    value: String,
    Query: Object,
    Entities: Object,
    autoRefresh: Boolean
  },
  components: {
    BaseModal,
  }, 
  data() {
    return {
      modalName: this.title,
      column: this.Column,
      query: this.Query,
      entities: this.Entities,
      currentSub: 0,
      selectedField: '',
    };
  },
  mounted() {
    this.showEntities(0);
  },
  methods: {
    showEntities(index) {
      let inputs = document.getElementsByClassName('lab22-chk-drilldown');
      for(let i=0; i<inputs.length; i++) {
        if(inputs[i].id==this.column.expr.id)
          inputs[i].disabled = true;
      }

      if(this.currentSub==index)
        return false;
      
      this.currentSub = index;

      let navs = document.getElementsByClassName('nav-link-drilldown-entities');
      for(let i=0; i<navs.length; i++) {
        if(i==index)
          navs[i].classList.add('active');
        else
          navs[i].classList.remove('active');
      }

      let divs = document.getElementsByClassName('div-drilldown-entities');
      for(let i=0; i<divs.length; i++) {
        if(i==index) {
          divs[i].classList.remove('hiddenSubsList');
          divs[i].classList.add('visibleSubsList');
        } else {
          divs[i].classList.add('hiddenSubsList');
          divs[i].classList.remove('visibleSubsList');
        }
      }
    },
    run() {
      if(this.selectedField==this.column.expr.id) { //not necessary any more, because i handled disabled flag in such cases
        _app.$helper.notifyInfo('You selected the same column to replace the previous one. Please, select a different column.');
        return false;
      }
      this.$emit('run', this.selectedField);
      this.$vfm.hide(this.modalName);
    },
    edit() {
      if(this.selectedField==this.column.expr.id) { //not necessary any more, because i handled disabled flag in such cases
        _app.$helper.notifyInfo('You selected the same column to replace the previous one. Please, select a different column.');
        return false;
      }
      this.$emit('edit', this.selectedField);
      this.$vfm.hide(this.modalName);
    },
    close() {
      this.$vfm.hide(this.modalName);
    },
  }
}
</script>

<style scoped>
.yellow {
  color: #FFCE19;
}
</style>

<style>
.visibleSubsList {
  display: block;
}
.hiddenSubsList {
  display: none;
}
</style>