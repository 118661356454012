<template>
  <div ref="wrapEl" :class="{'tableFixHead': enableVScroll}">
    <table class="table" :class="getClass()">
      <thead :class="{ 'sticky-top bg-white': stickyHeader }">
        <tr>
          <slot name="header">
            <template v-for="(col, colIndex) in columns" :key="col.dataIndex">
              <th @click="onHdCellClick(colIndex, $event)" v-if="col.skipRender !== true && col.hidden != true" class="text-truncate" :class="getHdCellClass(colIndex)" :style="this.getHdCellStyle(colIndex)">
              <div class="hd-cell-div">
                <div class="hd-cell-text">
                  <template v-if="col.type=='checkbox'">
                    <input type="checkbox" :checked="col.checked" @change="col.changeHandler($event, col)" class="form-check-input" />
                  </template>
                  <template v-else>
                    {{col.title}}
                  </template>
                </div>   
              </div>
              </th>            
            </template>
          </slot>  
        </tr>
      </thead>
      <tbody>
        <tr v-for="row in computedRows" :key="row[keyField]" @dblclick="this.$emit('row-dblclick', row, $event)" @contextmenu="this.$emit('row-contextmenu', row, $event)">
          <slot :row="row" :columns="columns">
            <BaseGridCell v-for="(col, index) in columns" :key="index" :col="col" :row="row" @cell-click="onCellClick" @value-change="onValueChange" @cell-contextmenu="onCellContextMenu" />
          </slot>
        </tr>
      </tbody>
    </table> 
  </div>
</template>

<script>
  import App from '@/App/App';
  import BaseGridCell from '@/App/components/Grid/BaseGridCell';
  import { handleError } from 'vue';

  export default {
    name: 'BaseGrid',
    props: {
      columns: Array, 
      rows: Array, 
      keyField: {
        type: String,
        default: 'id'
      },
      stickyHeader: {
        type: Boolean,
        default: true
      }, 
      striped: {
        type: Boolean,
        default: true
      },    
      enableVScroll: Boolean,
      class: String,
      sortable: {
        type: Boolean,
        default: true
      },
      startSortBy: {
        type: [ String, Number ],
        default: -1
      },
      startSortByDesc: {
        type: Boolean,
        default: false
      }
    },
    emits: ['cell-click', 'row-contextmenu', 'row-dblclick', 'cell-contextmenu', 'value-change'],
    components: {
      BaseGridCell
    },
    data() {
      let sortByColIndex = this.startSortBy;
      if (App.$helper.isString(sortByColIndex))
        sortByColIndex = this.columns.findIndex((i) => { return i.dataIndex === sortByColIndex});

      return {
        sortByColIndex: sortByColIndex,
        sortByAsc: !this.startSortByDesc
      }
    },
    mounted() {
      if (this.enableVScroll) {
        this.doLayout();
        window.addEventListener('resize', this.doLayout);

        setTimeout(() => {this.doLayout();}, 10);
      }
    },
    unmounted() {
      window.removeEventListener("resize", this.doLayout);
    },
    watch: {
      enableVScroll() {
        this.doLayout();
      },
      columns() {
        if(this.sortByColIndex > this.columns.length-1)
          this.sortByColIndex = -1;
      }
    },     
    methods: {
      getClass() {
        let cls = this.class || '';
        if (this.striped)
          cls += ' table-striped'
        return cls;
      },
      getHdCellStyle(colIndex) {
        
        const colConfig = this.columns[colIndex];

        let style = "";

        if (colConfig.width)
          style += "width:" + colConfig.width + "px;"
        else if (colConfig.type == 'DateTime')
          style += "min-width:130px;";

        if (colConfig.align || colConfig.hdAlign)
          style += "text-align:" + (colConfig.align || colConfig.hdAlign) + ';';
        else if (colConfig.type == 'DateTime')
          style += "text-align:center;";

        if (colConfig.hdStyle)
          style += colConfig.hdStyle;

        return style;
      },
      getHdCellClass(colIndex) {

        const colConfig = this.columns[colIndex];

        let cssClass = '';

        if (colConfig.type === 'checkbox')
          cssClass = 'hd-checkbox-col ';

        if (this.isColumnSortable(colConfig))
          cssClass += 'sortable ';

        if (this.sortByColIndex === colIndex) {          
          if (this.sortByAsc) {
            cssClass += 'sort-asc ';
          } else {
            cssClass += 'sort-desc ';
          }
        }

        return cssClass;
      },
      doLayout() {

        const wrapEl = this.$refs.wrapEl;

        if (!wrapEl)
          return;

        if (this.enableVScroll)
          wrapEl.style.height = (window.innerHeight - (wrapEl.getBoundingClientRect().top + document.documentElement.scrollTop)) + "px";
        else if (this.$refs.wrapEl.style.height)
          wrapEl.style.height = 'auto';
      },
      onCellClick(row, col, event) {
        this.$emit('cell-click', row, col, event);
      },
      onCellContextMenu(row, col, event) {
        this.$emit('cell-contextmenu', row, col, event);
      },
      onValueChange(row, col, value) {
        this.$emit('value-change', row, col, value);
      },
      onHdCellClick(colIndex, e) {

        var colConfig = this.columns[colIndex];

        if (this.isColumnSortable(colConfig)) {
          if (this.sortByColIndex === colIndex && this.sortByAsc == false) {
            this.sortByAsc = true;
            this.sortByColIndex = -1;
          } else {
            this.sortByAsc = this.sortByColIndex !== colIndex || !this.sortByAsc;
            this.sortByColIndex = colIndex;
          }
        }
      },
      isColumnSortable(colConfig) {
        return ((this.sortable && colConfig.sortable !== false) || colConfig.sortable === true) && (colConfig.dataIndex || colConfig.sortFn) && colConfig.type !== 'checkbox';
      }
    },
    computed: {
      computedRows: function() {

        let rows = this.rows;

        if (this.sortByColIndex != -1) {

          const colConfig = this.columns[this.sortByColIndex];

          if (colConfig.sortFn) {
            // console.log('Sort by function ' + (this.sortByAsc ? 'ASC' : 'DESC') , colConfig);
            rows = rows.slice().sort((aRec, bRec) => {
              return colConfig.sortFn(aRec, bRec, this.sortByAsc);
            });  
          } else if (colConfig.dataIndex) {
            // console.log('Sort by ' + (this.sortByAsc ? 'ASC' : 'DESC') , colConfig);
            rows = rows.slice().sort((aRec, bRec) => {
              const aVal = aRec[colConfig.dataIndex];
              const bVal = bRec[colConfig.dataIndex];
              if (aVal < bVal) return this.sortByAsc ? -1 : 1;
              if (bVal < aVal) return this.sortByAsc ? 1 : -1;
              return 0;
            });  
          }          
        }

        return rows;
      }
    }
  }
</script>

<style scoped>

  .tableFixHead { overflow: auto; }

  .sticky-top {
    z-index: 990;
  }

  .table > :not(:first-child) {
    border-top: 0;
  }

  .table > :not(caption) TH  {
    padding: 0px !important;
  }

  .hd-cell-div {
    border-bottom: 2px solid #333;
    padding: 8px;
  }

  .hd-checkbox-col .hd-cell-div {
    padding-bottom: 5px;
  }

  .table .sortable .hd-cell-div {
    padding-right: 17px;
    cursor: pointer;
  }
  
  .table .hd-cell-text {
    display: inline;
    position: relative;
  }  

  /* .table .sortable:hover .hd-cell-text:after {
    content: "\f063";
    font-family: FontAwesome;
    right:-15px;
    top: -2px !important;
    position:absolute;
    color:#eee;
    top:0;
    font-size: 11px;
  } */

  .table .sortable.sort-asc .hd-cell-text:after {
    content: "\f063";  /* \f0dd */
    font-family: FontAwesome;
    right:-15px;
    top: -2px !important;
    position:absolute;
    color:#00ffd7;
    top:0;
    font-size: 11px;
  }  

  .table .sortable.sort-desc .hd-cell-text:after {
    content: "\f062";  /* \f0de */
    font-family: FontAwesome;
    right:-15px;
    top: -2px !important;
    position:absolute;
    color:#00ffd7;
    top:0;
    font-size: 11px;
  }
</style>

<style>
  .table tr td {
    vertical-align: middle;
  }
</style>

<!--
<BaseGrid v-slot="{row, columns}" :columns="gridData.columns" :rows="gridData.rows">
  <BaseGridCell v-for="index in columns.length - 1" :key="index" :col="columns[index-1]" :row="row" />
  <BaseGridCell :col="columns[columns.length - 1]" :row="row">
    <button type="button" @click="showDetails(row)" class="btn btn-sm btn-light">Details</button>
  </BaseGridCell>  
</BaseGrid> 
-->