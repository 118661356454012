<template>
  <button 
    :type="type" 
    :disabled="loading"
    class="btn text-nowrap"
    :class="{ 'btn-primary': !$attrs.class }"
    v-bind="$attrs"
  >
    <i v-if="!loading && iconClass" :class="iconClass"></i> 
    <span v-if="loading" class="spinner-border spinner-border-sm"></span>
    <span v-if="!loading || !loadingText">{{text}}</span>
    <span v-else>{{loadingText}}</span>
  </button>  
</template>

<script>
  export default {
    name: 'BaseButton',
    inheritAttrs: false,
    props: {
      type: {
        type: String,
        default: 'button'
      },
      text: String,
      iconClass: String,
      loadingText: String, 
      loading: Boolean
    }    
  }

</script>

<style scoped>
  span.spinner-border {
    vertical-align: middle;
    margin-right: 5px;
  }

  i {
    width:18px;
    margin-right: 3px;
  }

</style>

