<template>
  <div class="lab22-mv-padding">

    <!--<ModuleHeader title="Alert Thresholds" iconClass="fa-solid fa-arrows-left-right-to-line">-->
    <ModuleHeader title="Alert Thresholds" iconClass="inf-module-header-thresholds inf-module-header-img">
      <BaseButton @click="showConditionModal()" text="Add condition" />
    </ModuleHeader>

    <ToolbBar>    
      <label v-if="state.activeView == eType.DEVICE" class="label">Device:</label>
      <BaseField v-if="state.activeView == eType.DEVICE" v-model="state.deviceId" @change="filterChange(true)" name="deviceId" placeholder="Select device" as="select" :data="this.$app.$data.devices" style="width:300px" :disabled="loading" />
      <label class="label ms-3">Field group:</label>
      <BaseField v-model="state.fieldGroupId" @change="filterChange(false)" placeholder="Any" as="select" :data="this.EvTypeList" addClearOption :disabled="loading" style="width:200px" />
    </ToolbBar>

    <div style="width:100%;overflow-x: auto;">
      <BaseGrid :columns="gridData.columns" :rows="gridData.rows" :enableVScroll="true" />
    </div>    
  </div>
</template>

<script>
import _app from '@/App/App'
import ModuleHeader from '@/App/components/Common/ModuleHeader'
import ConditionModal from './ConditionModal'
import ToolbBar from '@/App/components/Common/ToolBar'
import BaseField from '@/App/components/Form/BaseField'
import BaseButton from '@/App/components/Common/BaseButton'
import BaseGrid from '@/App/components/Grid/BaseGrid'

const DeviceEventType = 3; //all devices have at least "device" component

const TYPE = {
  //GENERAL: 'GENERAL',
  DEVICE: 'DEVICE'
};

export default {
  name: 'ThresholdList',
  components: {
    ModuleHeader,
    ToolbBar,
    BaseField,
    BaseButton,
    BaseGrid
  },
  data() {
    return {
      components: null,
      EvTypeListFull: [],
      EvTypeList: [],
      gridData: {
        columns: [],
        rows: []
      },
      state: this.$app.$data.getState('AlertThresholds', { 
        activeView: TYPE.DEVICE,
        modelId: this.$app.$data.deviceModelLkp[0]?.id,
        conditionList: [], 
        conditionDic: {} }),
      loading: false,
      eType: TYPE
    };
  },
  mounted() {
    this.updateGridColumns();
    this.getEtList(true);
  },
  methods: {
    clearComponents() {
      this.components = null;
      this.EvTypeListFull = [];
      this.EvTypeList = [];
    },
    setDefaultEtType(stateEvTypeId, allEvTypeIds) {
      let found = false;
      for(let i=0; i<allEvTypeIds.length; i++) {
        if(allEvTypeIds[i].id==stateEvTypeId)
          found = true;
      }
      if(!found)
        this.state.fieldGroupId = null;
    },
    getEtList(doLoad) {
        let _this = this;

        const progress = _this.$progress.start();

        this.clearComponents();

        Promise.all([
          _this.$app.$api.get('Device/GetDeviceComponentsExt', { params: {deviceId: _this.state.deviceId} }),
          _this.$app.$data.getEventTypeList()
        ]).then(
          result => {
            _this.loading = false;
            progress.finish();

            _this.components = result[0].data;
            _this.EvTypeListFull = result[1];

            if(_this.state.deviceId)
              _this.EvTypeList.push({id:DeviceEventType, name:'Device'});

            let removeEtTypeForDevice = false;

            for(let i=0; i<_this.components.length; i++) {
              if( _this.components[i].event_type_id!==null ) {
                let _name = _this.components[i].label;

                if(_name!=='Device' || !_this.state.deviceId) {
                  _this.EvTypeList.push({id:_this.components[i].event_type_id, name:_name});
                  
                  if( _this.components[i].event_type_id==DeviceEventType )
                    removeEtTypeForDevice = true;
                }
              }
            }

            if(removeEtTypeForDevice)
              _this.EvTypeList = _this.EvTypeList.filter(item => !(item.id==DeviceEventType && item.name=='Device'));

            if(doLoad) {
              _this.updateGridView();
              _this.load();
            }
            else {
              //it can happen that newly selected device does not have previously selected component in combobox "Field group"
              //in this case set combobox value to default placeholder "Any"
              _this.setDefaultEtType(_this.state.fieldGroupId, _this.EvTypeList);

              _this.updateGridView();
            }
          },
          error => {
            _this.loading = false;
            progress.finish();

            _this.$app.handleError(error);
          }
        );
    },
    filterChange(getEtList) {
      if(getEtList && this.state.deviceId)
        this.getEtList(false);
      else
        this.updateGridView();
    },
    async load() {

      try {
        this.loading = true;

        const result = await _app.$api.get('Alert/GetAlertConditionList');

        this.loading = false;

        this.state.conditionList = result.data;

        this.state.conditionDic = Object.assign({}, ...this.state.conditionList.map((i) => ({[i.eventTypeFieldId + '_' + (i.deviceId || '')]: i})));

        this.updateGridView();
      }
      catch(err) {
        this.loading = false;

        _app.handleError(err);
      }
    },
    updateGridView() {

      if (!this.state.deviceId) {
        this.gridData.rows = [];
        return;
      }

      this.gridData.rows = this.state.conditionList.filter(i => {
        if (this.state.activeView == TYPE.DEVICE && i.deviceId !== this.state.deviceId) return false;
        if (this.state.fieldGroupId && i.fieldGroupId !== this.state.fieldGroupId) return false;

        return true;
      });
    },
    renderRecValue(rec) {
      if (rec.fieldType == 'NUMBER') {
        return rec.valueDec;
      } else {
        return rec.valueText;
      }
    },
    updateGridColumns() {

      const columns = [
        {
          title: 'Field',
          dataIndex: 'fieldName',
          width: 200
        },
        {
          title: 'Operator',
          dataIndex: 'operatorName',
          width: 100,
          align: 'center'
        }      
      ];

      columns.push({
        title: 'Value',
        renderer: (v, rec) => {
          return this.renderRecValue(rec);
        }       
      }); 
      
      columns.push({
        width:140,
        align:'right',
        actions: [
          {
            text: 'Edit',
            handler: this.showConditionModal
          },          
          {
            text: 'Remove',
            handler: this.removeCondition
          }
        ]
      });

      this.gridData.columns = columns;
    },
    removeCondition(rec) {
      _app.$modal.confirm('Remove condition', `Are you sure you want to remove "${rec.fieldName}" condition?`, (confirmed) => {
        
        if (!confirmed) 
            return;
      
        const index = this.gridData.rows.indexOf(rec);
        if (index !== -1)
          this.gridData.rows.splice(index, 1);

        this.loading = true;

        this.$app.$api.get('Alert/DelAlertCondition', { params: { id: rec.id } }).then(
          () => {
            this.loading = false;
          },
          () => {
            this.loading = false;
            this.load();
          }
        );  
      });
    },
    showConditionModal(rec) {

      let initialData = null;

      if (rec) {
        rec.type = this.state.activeView;
      }        
      else {
        initialData = { 
          type: this.state.activeView
        };

        if (this.state.activeView == TYPE.DEVICE)
          initialData.deviceId = this.state.deviceId;
      }

      this.$vfm.show({
        component: ConditionModal,
        bind: {
          'editRec': rec || null,
          'initialData': initialData,
        },        
        on: {
          ok: (data) => {
            if (!rec)
              this.load();
            else
              this.load();
          }
        }
      });
    }
  }
}
</script>

<style scoped>
.btn-group label.btn {
  min-width: 100px;
}
</style>