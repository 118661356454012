<template>
  <FormFieldGroup :fieldName="name" :label="label" :topLabel="topLabel">
    <div :class="topLabel ? '' : 'col-sm-8'">
      <BaseDualSliderField
        :min="min"
        :max="max"
        :fromValue="fromValue"
        :fromLabel="fromLabel"
        :toValue="toValue"
        :toLabel="toLabel"
        :showInputs="showInputs"
        :inputWidth="inputWidth"
        :currentValue="currentValue"
        :scaleType="scaleType"        
        v-bind="$attrs"
      ><!--class="form-control"-->
      </BaseDualSliderField>     
      <ErrorMessage :name="name" class="error-feedback" />
    </div>  
  </FormFieldGroup>
</template>

<script>

  import { ErrorMessage } from "vee-validate";
  import FormFieldGroup from '@/App/components/Form/FormFieldGroup';
  import BaseDualSliderField from "@/App/components/Form/BaseDualSliderField.vue"

  export default {
    name: 'FormDualSliderField',
    inheritAttrs: false,
    props: {
      name: String, 
      label: String, 
      topLabel: Boolean,
      min: {
        type: Number,
        default: 0
      },
      max: {
        type: Number,
        default: 100
      },    
      fromValue: Number,
      fromLabel: String,
      toValue: Number,
      toLabel: String,
      showInputs: {
        type: Boolean,
        default: true
      },
      inputWidth: {
        type: Number,
        default: 65
      },
      currentValue: Number,
      scaleType: {
        type: String
      }
    },
    components: {
      BaseDualSliderField,
      ErrorMessage,
      FormFieldGroup
    }    
  }

</script>

<style scoped>

</style>
