<template>
    <section class="card mb-4">
        <div v-if="headerTitle!==''" class="card-header bg-quaternary text-center"><a style="color:#fff; opacity:0.8;">{{ headerTitle }}</a></div>
        <!-- our widget can hold KPI info (passed with "bigNumber" prop) or any other content -->
        <div v-if="bigNumber!==''" class="card-body bg-quaternary" style="background-color:#262626 !important;">
            <div v-if="chartData && chartData.length>0" class="widget-summary">
                <div class="widget-summary-col">
                    <div class="row">
                        <div class="col-6">
                            <div class="summary text-center" style="color:#fff; margin-top:30px;">
                                <span :class="classList" style="font-size:64px;">{{ bigNumber }}</span><span v-if="smallNumber">/ {{ smallNumber }}</span>
                            </div>
                            <div v-if="showFooter" class="summary-footer text-center">
                                <a>{{ footerText }}</a>
                            </div>
                        </div>
                        <div class="col-6 text-center">
                            <apexchart
                                type="area"
                                ref="chart" 
                                height="80%"
                                :series="chartData"
                                :options="this.chartOptions" 
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="widget-summary">
                <div class="widget-summary-col">
                    <div class="summary text-center" style="color:#fff; margin-top:30px;">
                        <span :class="classList" style="font-size:64px;">{{ bigNumber }}</span><span v-if="smallNumber">/ {{ smallNumber }}</span>
                    </div>
                    <div v-if="showFooter" class="summary-footer text-center">
                        <a>{{ footerText }}</a>
                    </div>
                </div>
            </div>
        </div>
        <!-- else -->
        <div v-else class="card-body lab-card-body bg-quaternary lab-scroll" style="background-color:#262626 !important;">
            <div class="widget-summary">
                <div class="widget-summary-col">
                    <slot name="dashContent"></slot>
                </div>
            </div>
        </div>
        <!-- end -->
    </section>
</template>

<script>
export default {
    name: 'DashboardKpiWidgetLab22',
    props: {
      headerTitle: String,
      showFooter: Boolean,
      footerText: String,
      bigNumber: String,
      smallNumber: Number,
      classList: String,
      chartData: Object
    },
    data: function() {
        return {
            chartOptions: {
                chart: {
                sparkline: {
                    enabled: true,
                }
                },
                stroke: {
                curve: 'smooth',
                width: 1,
                colors: ['#12B39A'],
                },
                fill: {
                colors: ['#12B39A']
                },
                tooltip: {
                enabled: false
                }
            },
        }
    },
    mounted() {
        
    }
}
</script>

<style scoped>
.green1 {
    color: #12B39A;
}
.green2 {
    color: #00FFD7
}
.yellow {
    color: #ffcc19;
}
.purple {
    color: #ff19ff
}

.lab-card-body {
    /*padding-bottom: 0px !important;*/
    min-height: 260px !important;
}

.lab-scroll {
  overflow-y: scroll !important;
  overflow-x: hidden !important;
}

.card-body {
    cursor: pointer !important;
    border-radius: 0.01rem !important;
}
</style>