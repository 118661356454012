<template>

<Form @submit="save" ref="form" :validation-schema="schema" class="form-horizontal form-bordered">
  <base-modal :name="modalName" :title="getTitle()" v-bind="$attrs" :width="300" @close="close">
    <template #body>  
      <FormField name="deviceId" v-model="deviceId"  @change="onDeviceChange" label="Device" placeholder="Select device" :topLabel="true" as="select" :data="this.$app.$data.devices" />
      <FormField name="metricId" v-model="metricId" label="Metric" placeholder="Select metric" :topLabel="true" :disabled="loadingMetrics" as="select" :data="this.metricList" valueField="mea_id" displayField="mea_name" />
    </template>
    <template #footer>
      <button @click="close" type="button" class="btn btn-default" data-dismiss="modal">Cancel</button>
      <button type="submit" class="btn btn-primary">Ok</button>
    </template>
  </base-modal>
</Form>

</template>

<script>

import BaseModal from '@/App/components/Modal/BaseModal'
import FormField from '@/App/components/Form/FormField';
import { Form } from "vee-validate";

import _app from '@/App/App'

export default {
  name: 'AddMetricModal',
  props: {
    editRec: Object,
    initialData: Object
  },
  inheritAttrs: false,
  emits: ['ok'],
  components: {
    BaseModal,
    FormField,
    Form
  },   
  data() {
    return {
      modalName: 'AddMetricModal',
      metricList: [],
      schema: {
        deviceId: 'required',
        metricId: 'required'
      },
      deviceId: null,
      metricId: null,
      loadingMetrics: false,
      state: this.$app.$data.getState('AddMetricModal', { deviceId: null }),
    };
  },
  mounted() {
    if (this.editRec) {

      this.deviceId = this.editRec.deviceId;
      this.metricId = this.editRec.metricId;

      this.loadMetrics();
    } 
    else if (this.initialData) {
      this.deviceId = this.initialData.deviceId || this.state.deviceId || null;
      this.metricId = this.initialData.metricId;

      this.loadMetrics();
    } 
    else if (this.state.deviceId) {
      this.deviceId = this.state.deviceId;

      this.loadMetrics();
    }
  },
  methods: {
    getTitle() {
      return this.editRec ? 'Edit metric' : 'Add metric';
    },
    onDeviceChange() {
      this.state.deviceId = this.deviceId;
      this.loadMetrics();
    },
    loadMetrics() {
      if (this.deviceId) {
        this.loadingMetrics = true;

        Promise.all([
          _app.$api.get('Analytics/GetDeviceMeasurementList', { params: { deviceId: this.deviceId } }),
          _app.$api.get('Device/GetDeviceById', { params: { id: this.deviceId } })
        ]).then(
          results => {
            this.loadingMetrics = false;

            let list = results[0].data || [];
                list = list.filter(obj => {
                        return obj.mea_name!=='Fan 1 speed target (%)' && obj.mea_name!=='Fan 2 speed target (%)' && obj.mea_name!=='Fan 3 speed target (%)' && obj.mea_name!=='Fan 4 speed target (%)';
                      });
            this.metricList = list || [];

            let device = results[1].data || [];
            
            if (!this.metricList.find(i => i.mea_id === this.metricId))
              this.metricId = null;

            //#region BZ hardcoded
            switch(device.modelId) {
              case 1: //stari skyss model
                  this.metricList = this.metricList.filter(obj => {
                                return (obj.mea_group === 'Device' || obj.mea_group === 'Display' || obj.mea_group === 'Ups');
                              });
                  break;
              case 15: //novi G7 model
                  this.metricList = this.metricList.filter(obj => {
                                return (obj.mea_group === 'Yocto buttons' || obj.mea_group === 'Device' || obj.mea_group === 'Display' || obj.mea_group === 'Display 2' || obj.mea_group === 'Display 3' || obj.mea_group === 'Camera' || obj.mea_group === 'Camera 2');
                              });
                  break;
              case 16: //novi FLOW safety model
                  this.metricList = this.metricList.filter(obj => {
                                return (obj.mea_group === 'Yocto buttons' || obj.mea_group === 'Device' || obj.mea_group === 'Display' || obj.mea_group === 'Display 2' || obj.mea_group === 'Display 3' || obj.mea_group === 'Camera' || obj.mea_group === 'Camera 2');
                              });
                  break;
              case 22: //novi skyss model
                  this.metricList = this.metricList.filter(obj => {
                                return (obj.mea_group === 'Device' || obj.mea_group === 'Display' || obj.mea_group === 'Ups');
                              });
                  break;
            }
            //#endregion
          }, 
          (error) => {
            this.loadingMetrics = false;
            _app.handleError(error);
          });
        /*
        _app.$api.get('Analytics/GetDeviceMeasurementList', { params: { deviceId: this.deviceId } }).then(result => {
          this.loadingMetrics = false;
          this.metricList = result.data || [];
          if (!this.metricList.find(i => i.mea_id === this.metricId))
            this.metricId = null;
        }, (error) => {
          this.loadingMetrics = false;
          _app.handleError(error);
        });
        */
      } else {
        this.metricList = [];
      }
    },
    save() {  

      var data = this.editRec || {};

      if (this.deviceId) {
        data.deviceId = parseInt(this.deviceId);
        data.deviceName = this.$app.$data.devices.find(i => i.id == data.deviceId)?.name
      }

      if (this.metricId) {
        data.metricId = parseInt(this.metricId);
        let metric = this.metricList.find(i => i.mea_id == data.metricId);
        if (metric) {
          data.metricName = metric.mea_name;
          data.evTypeId = metric.event_type_id;
        }
      }      

      this.$emit('ok', data);

      this.$vfm.hide(this.modalName);
    },
    close() {
      this.$vfm.hide(this.modalName);
    }
  }
}
</script>

<style scoped>
</style>