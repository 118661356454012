<template>

  <div ref="bodyEl" class="lab22-mv-padding">

    <FilterBar v-if="!this.$app.user.isCustomerUser()" style="background-color: #161616 !important;">
      <BaseField v-model="customerId" name="customerId" ref="fltCustomer" placeholder="Customer" as="select" @change="changeCustomer" :data="customers" :disabled="disableCustomer" style="width:250px;" addClearOption />
      <!--
      <BaseField name="dateFrom" type="date" :value="dateFrom" style="width:150px;" />
      <BaseField name="dateTo" type="date" :value="dateTo" style="width:150px;" />
      <BaseButton @click="loadReports" text="Refresh" class="btn-default" loadingText="Loading..." style="margin-left:20px;" :disabled="disableButton" :loading="loading"></BaseButton>
      -->
    </FilterBar>

    <div class="d-flex flex-column align-items-stretch" style="max-width:1330px !important; float:left !important;">
      <div style="height:100%;padding-top:0px;padding-bottom:20px;">
        <div class="row">
          <div class="col lab-pd-r-0">
            <DashboardKpiWidget 
              ref="devicesActiveConnected" 
              :bigNumber="this.devicesActiveConnected" 
              bigNumberColor="#fff" 
              :smallNumber="this.devicesAll" 
              showFooter 
              footerText="Connected synced devices" 
              @click="this.routeMe('devicesActiveConnected')"
            />
          </div>
          <div class="col lab-pd-r-0">
            <DashboardKpiWidget 
              ref="devicesPending" 
              :bigNumber="this.devicesPending" 
              :smallNumber="this.devicesAll" 
              classList="green1" 
              showFooter 
              footerText="Pending devices" 
              @click="this.routeMe('devicesPending')"
            />
          </div>
          <div class="col lab-pd-r-0">
            <DashboardKpiWidget 
              ref="devicesMaintenance" 
              :bigNumber="this.devicesMaintenance" 
              :smallNumber="this.devicesAll" 
              classList="yellow" 
              showFooter 
              footerText="Maintenance devices" 
              @click="this.routeMe('devicesMaintenance')"
            />
          </div>
          <div class="col lab-pd-r-0">
            <DashboardKpiWidget 
              ref="devicesOutOfOrder" 
              :bigNumber="this.devicesOutOfOrder" 
              :smallNumber="this.devicesAll" 
              classList="green2" 
              showFooter 
              footerText="Out of order devices" 
              @click="this.routeMe('devicesOutOfOrder')"
            />
          </div>
          <div class="col lab-pd-r-0">
            <DashboardKpiWidget 
              ref="devicesWithAlerts24h" 
              :bigNumber="this.devicesWithAlerts24h" 
              classList="purple" 
              showFooter 
              footerText="Devices with alerts in last 24h" 
              @click="this.routeMe('devicesWithAlerts24h')"
            />
          </div>
        </div>
        <!--<div class="row row-lab d-none d-lg-block d-xl-block d-xxl-block">-->
        <div ref="chartWidgets" class="row row-lab">
          <div class="col-8">
            <div class="row">
              <div class="col-6 lab-pd-r-0">
                <DashboardKpiWidget 
                  ref="innerTemperatures" 
                  :bigNumber="-1" 
                >
                  <template v-slot:dashContent>
                    <div class="summary text-center" style="color:#ccc; margin-top:5px;">
                      Top ten devices with highest inner temperature
                      <div class="text-right" style="float:right;">
                        <i class="fa fa-temperature-low" style="color:#00FFD7;"></i>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <table class="table lab-table table-borderless">
                            <tr>
                              <th align="left"></th><th style="min-width:50px;">INTERN.</th><th style="min-width:50px;">INTAKE</th><th style="color:#00FFD7; min-width:50px;">DIFF.</th><th style="text-align:left; min-width:100px;">TEMP. &#176;C</th>
                            </tr>
                            <tr v-for="item in highIntTempData" :key="item.device">
                              <td align="left" class="lab-td-elipsis">{{ item.device }}</td>
                              <td>{{ item.internal_temp }}</td>
                              <td>{{ item.intake_temp }}</td>
                              <td style="color:#00FFD7;">{{ getHighIntTempDiff(item) }}</td>
                              <td align="left">
                                <div class="progress" style="background-color:#12B39A;"><div class="progress-bar" role="progressbar" aria-valuemin="0" aria-valuemax="200" :style="'background-color:rgb(0, 255, 217); margin-left:-7px; margin-top:-10px; min-height:20px; width:'+parseInt(getHighIntTempDiff(item)*5)+'%; color:#ccc;'"></div></div>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                  </template>
                </DashboardKpiWidget>
              </div>
              <div class="col-6 lab-pd-r-0">
                <DashboardKpiWidget 
                  ref="openedAlerts" 
                  :bigNumber="-1" 
                  @click="this.routeMe('openedAlerts')"
                >
                  <template v-slot:dashContent>
                    <div class="summary text-center" style="color:#ccc; margin-top:5px;">
                      Opened alerts
                      <div class="text-right" style="float:right;">
                        <i class="fa fa-bell" style="color:#ff19ff;"></i>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <table class="table lab-table table-borderless">
                            <tr>
                              <th style="text-align:left;">ALARM</th><th style="text-align:left;">STATUS</th><th style="color:#ff19ff;">DEVICES</th>
                            </tr>                            
                            <tr v-for="item in openedAlerts" :key="item.condition_field">
                              <td align="left" class="lab-td-elipsis">{{ item.condition_field }}</td>
                              <td align="left" style="min-width:80px">{{ item.condition_text }}</td>
                              <td style="color:#ff19ff; min-width:50px;">{{ item.count_devices }}</td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                  </template>
                </DashboardKpiWidget>
              </div>
            </div>
            <div class="row row-lab">
              <div class="col-12 lab-pd-r-0">
                <DashboardKpiWidget 
                  ref="lineChartTemperatures" 
                  :bigNumber="-1" 
                >
                  <template v-slot:dashContent>
                    <div class="summary text-center" style="color:#ccc; margin-top:5px;">
                      Internal temperatures - Last 24h
                      <div class="row">
                        <div class="col-12">
                          <apexchart type="line" ref="chartLine" class="analytics-chart" height="240" style="margin-top:-20px" :options="chartOptionsLine" :series="chartSeriesLine"></apexchart>
                        </div>
                      </div>
                    </div>
                  </template>
                </DashboardKpiWidget>
              </div>
            </div>
          </div>
          <div class="col-4 lab-pd-r-0">
            <div class="row">
              <div class="col-12">
                <DashboardKpiWidget 
                  ref="heatMapAlerts" 
                  :bigNumber="-1" 
                >
                  <template v-slot:dashContent>
                    <div class="summary text-center" style="color:#ccc; margin-top:5px;">
                      Alerts by time of day - Last year
                      <div class="row">
                        <div class="col-12">
                          <apexchart type="heatmap" height="350" style="margin-top:-20px" :options="chartOptionsHeat" :series="chartSeriesHeat"></apexchart>
                        </div>
                      </div>
                    </div>
                  </template>
                </DashboardKpiWidget>
              </div>
            </div>
            <div class="row row-lab">
              <div class="col-12">
                <section class="card mb-4" @click="this.routeMe('support')">
                  <div class="card-body lab-card-body bg-quaternary" style="min-height:154px !important; background-color:#262626 !important;">
                    <div class="widget-summary">
                      <div class="widget-summary-col">
                        <div class="summary text-left" style="color:#12B39A; margin-top:5px;">
                          <strong style="color:#1485CC;">Insights</strong>
                          <div class="text-right" style="float:right;">
                            <i class="fa fa-info-circle" style="color:#1485CC;"></i>
                          </div>
                          <div class="text-break" style="color:#ccc; font-size:11px;">
                            <br />
                            Brightness setting is not the same as backlight setting. If the screen is too bright change the backlight value. 
                            More questions regarding maintenance and technical issues can be found on Q&A Infinitus Support Website.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import FilterBar from '@/App/components/Common/FilterBar';
import BaseField from '@/App/components/Form/BaseField';
//import BaseButton from '@/App/components/Common/BaseButton.vue';
import DashboardKpiWidget from './DashboardKpiWidget'

let lastWeek = new Date();
    lastWeek.setDate(lastWeek.getDate() - 7);

export default {
  name: 'DashboardView',
  components: {
    FilterBar,
    BaseField,
    //BaseButton,
    DashboardKpiWidget
  },
  data: function() {
    return {
      fullWidth: 0,
      timeOutFunctionId: null,
      timerId: null,
      dateFrom: this.$app.formatDate(lastWeek, 'YYYY-MM-DDTHH:mm'),
      dateTo: this.$app.formatDate(new Date(), 'YYYY-MM-DDTHH:mm'),
      loading: false,
      disableCustomer: false,
      disableButton: false,
      customerId: null,
      customers: [],
      devicesActiveConnected: null,
      devicesAll: null,
      devicesMaintenance: null,
      devicesOutOfOrder: null,
      devicesPending: null,
      devicesWithAlerts24h: null,
      chartSeriesHeat: [],
      chartOptionsHeat: {
        legend: {
          position: 'bottom',
          labels: {
              colors: ['#ccc']
          },
          itemMargin: {
            horizontal: 10,
            vertical: 0
          },
        },
        chart: {
          toolbar: {
            show: false,
          },
          animations: {
            enabled: false
          }
        },
        grid: {
          show: true,
        },
        xaxis: {
          type: 'category',
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false
          },
          labels: {
            show: true,
            style: {
                colors: ['#ccc','#ccc','#ccc','#ccc','#ccc','#ccc','#ccc']
            },
          },
        },
        tooltip: {
          enabled: true
        },
        yaxis: {
          show: true,
          opposite: true,
          reversed: true,
          labels: {
            show: true,
            style: {
                colors: ['#ccc']
            },
          }
        },
        plotOptions: {
          heatmap: {
            radius: 0,
            enableShades: false,
            useFillColorAsStroke: false,
            colorScale: {
              ranges: [{
                  from: 0.00,
                  to: 3.99,
                  name: '0-3',
                  color: '#666666'
                },
                {
                  from: 4.00,
                  to: 7.99,
                  name: '4-7',
                  color: '#108C74'
                },
                {
                  from: 8.00,
                  to: 14.99,
                  name: '8-15',
                  color: '#12B39A'
                },
                {
                  from: 15.00,
                  to: 99999.99,
                  name: '15+',
                  color: '#00FFD7'
                }
              ]
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 0.5,
          colors: ['#262626'],
        },
        title: {
          text: ''
        },
      },
      chartSeriesLine: [],
      chartOptionsLine: {
        legend: {
          position: 'bottom',
          labels: {
              colors: ['#fff','#ff19ff','#12B39A'],
          },
          itemMargin: {
            horizontal: 10,
            vertical: 20
          },
        },
        chart: {
          stacked: false,
          zoom: {
            type: 'x',
            enabled: true,
            autoScaleYaxis: true
          },
          toolbar: {
            show: false
          },
          animations: {
            enabled: true
          }
        },
        colors: ['#fff','#ff19ff','#12B39A'],
        dataLabels: {
          enabled: false
        },
        markers: {
          size: 0,
        },
        stroke: {
          width: 2,
          curve: 'smooth',
          colors: ['#fff','#ff19ff','#12B39A']
        },
        title: {
          text: '',
        }, 
        yaxis: {
          labels: {
            show: true,
            style: {
                colors: ['#ccc']
            },
            formatter: function (val) {
              return parseInt(val);
            },
          },
          //min: 0,
          forceNiceScale: true
        },
        xaxis: {
          type: 'datetime',
          labels: {
            datetimeUTC: false,
            show: true,
            style: {
                colors: ['#ccc','#ccc','#ccc','#ccc','#ccc','#ccc','#ccc','#ccc','#ccc','#ccc','#ccc','#ccc','#ccc','#ccc','#ccc','#ccc','#ccc','#ccc','#ccc','#ccc','#ccc','#ccc','#ccc','#ccc']
            },
            rotate: 0,
            hideOverlappingLabels: true,/*
            formatter: function (val) {
              let date = new Date(val),
                  h = date.getHours();
              return h.toString() + ':00';
            },*/
          }
        },
        tooltip: {
          enabled: false
        }
      },
      highIntTempData: null,
      openedAlerts: null
    }
  },
  mounted() {
    this.fullWidth = this.$refs.bodyEl.clientWidth;

    this.doLayout(false);

    window.addEventListener('resize', this.doLayout(true));

    let _this = this;

    /*this.$app.$api.get('AppUser/GetAppUser', { params: {id:this.$store.state.auth.user.id} }).then(
      result => {
        let userDto = result.data;

        if(userDto.customerId) {
          let _customers = _this.$app.$data.customers;

          for(let i=0; i<_customers.length; i++) {
            if(_customers[i].id==userDto.customerId)
              _this.customers.push(_customers[i]);
          }

          _this.customerId = userDto.customerId;
          _this.disableCustomer = true;
          
          let flt = this.$refs.fltCustomer;
              flt.style.display = "none";

        } else
          _this.customers = this.$app.$data.customers;

        this.loading = false;
        _this.loadReports();
      },
      error => {
        _this.disableCustomer = true;
        _this.disableButton = true;
        this.loading = false;
        this.$app.handleError(error);          
      }
    );*/
    
    if( !this.$app.user.isCustomerUser() )
      _this.customers = _this.$app.$data.customers;

    _this.loadReports();

    _this.timerId = setInterval(function() {
      _this.loadReports(true);
    }, 59 * 1000);
  },
  unmounted() {
    clearInterval(this.timerId);
    window.removeEventListener("resize", function(){});
    this.$emit('set-main-view-class', '');
    let el = document.getElementById("main-view-container");
    el.style.backgroundColor = '';
  },
  methods: {
    getHighIntTempDiff(item) {
      return Math.round( Math.abs( (item.internal_temp - item.intake_temp) ) * 10 ) / 10;      
    },
    changeCustomer() {
      if(this.customerId=='')
        this.customerId = null;
      this.loadReports();
    },
    loadReports(silent = false) {

      let loader = null;

      if (!silent) {
        this.loading = true;
        loader = this.$loading.show();
      }

      const _this = this;

      this.$app.$api.get('Dashboard/GetDashboardValues', { params: {customerId:this.customerId, dateFrom:this.dateFrom, dateTo:this.dateTo } }).then(
        result => {
          const data = result.data;
          
          for(let i=0; i<data.length; i++) {
            switch(data[i].key) {
              case 'DEVICES_ACTIVE': _this.devicesActiveConnected = data[i].value; break;
              case 'DEVICES_MAINTENANCE': _this.devicesMaintenance = data[i].value; break;
              case 'DEVICES_OUT_OF_ORDER': _this.devicesOutOfOrder = data[i].value; break;
              case 'DEVICES_PENDING': _this.devicesPending = data[i].value; break; 
              case 'DEVICES_ALL': _this.devicesAll = data[i].value; break;
              case 'DEVICES_WITH_ALERTS_24H': _this.devicesWithAlerts24h = data[i].value; break;
              default: break;
            }
          }

          Promise.all([
            this.$app.$api.get('Dashboard/GetDashboardTable', { params: {customerId:this.customerId, dateFrom:this.dateFrom, dateTo:this.dateTo, table:'INTERNAL_TEMPERATURE' } }),
            this.$app.$api.get('Dashboard/GetDashboardTable', { params: {customerId:this.customerId, dateFrom:this.dateFrom, dateTo:this.dateTo, table:'OPENED_ALERTS' } }),
            this.$app.$api.get('Dashboard/GetDashboardTable', { params: {customerId:this.customerId, dateFrom:this.dateFrom, dateTo:this.dateTo, table:'HEATMAP_ALERTS' } }),
            this.$app.$api.get('Dashboard/GetDashboardTable', { params: {customerId:this.customerId, dateFrom:this.dateFrom, dateTo:this.dateTo, table:'LAST_ALERTS' } }),
            this.$app.$api.get('Dashboard/GetDashboardTable', { params: {customerId:this.customerId, dateFrom:this.dateFrom, dateTo:this.dateTo, table:'AVG_TEMPERATURE' } })
          ])  
          .then(results => {
            //fetch datasets
            
            this.highIntTempData = results[0].data;
            this.openedAlerts = results[1].data;

            let tblHeatmapAlerts = results[2].data,
                divLastAlerts = results[3].data,
                lineAvgTemp = results[4].data;


            //heatmap chart for alerts created
            _this.chartSeriesHeat = [];
            for(let h=1; h<=24; h++) {
              let hours = (h<10) ? "0"+h+"h" : h+"h",
                  hoursArray = tblHeatmapAlerts.filter(function(itm){
                    return hours.indexOf(itm.hn) > -1;
                  });
              _this.chartSeriesHeat.push({
                name: ( Array.from(hoursArray[0].hn)[0]=='0' ) ? hoursArray[0].hn.split('0')[1] : hoursArray[0].hn,
                data: [
                    {x:hoursArray[0].dwn, y:hoursArray[0].count_alerts},
                    {x:hoursArray[1].dwn, y:hoursArray[1].count_alerts},
                    {x:hoursArray[2].dwn, y:hoursArray[2].count_alerts},
                    {x:hoursArray[3].dwn, y:hoursArray[3].count_alerts},
                    {x:hoursArray[4].dwn, y:hoursArray[4].count_alerts},
                    {x:hoursArray[5].dwn, y:hoursArray[5].count_alerts},
                    {x:hoursArray[6].dwn, y:hoursArray[6].count_alerts}
                  ]
              });
            }

            //3 series line chart for temperatures in last 24h
            _this.chartSeriesLine = [];
            if(lineAvgTemp.length==72) { //we need exactly 3x24h records (72 records) -> otherwise data does not make any sense
              let dt = [],
                  device1 = lineAvgTemp[0].device,
                  device2 = lineAvgTemp[24].device,
                  device3 = lineAvgTemp[48].device,
                  array1 = lineAvgTemp.filter(function(itm){
                      return device1.indexOf(itm.device) > -1;
                    }),
                  array2 = lineAvgTemp.filter(function(itm){
                      return device2.indexOf(itm.device) > -1;
                    }),
                  array3 = lineAvgTemp.filter(function(itm){
                      return device3.indexOf(itm.device) > -1;
                    }),
                  series1 = {},
                  series2 = {},
                  series3 = {},
                  data1 = [],
                  data2 = [],
                  data3 = [];
              for(let i=0; i<array1.length; i++) {
                if( i<24 ) {
                  //dt.push(array1[i].device_time);
                  dt.push( new Date(array1[i].device_time + '.000Z').getTime() );
                  data1[i] = {x:dt[i], y:array1[i].internal_temp};
                }
              }
              for(let i=0; i<array2.length; i++) {
                if( i<24 ) {
                  data2[i] = {x:dt[i], y:array2[i].internal_temp};
                }
              }
              for(let i=0; i<array3.length; i++) {
                if( i<24 ) {
                  data3[i] = {x:dt[i], y:array3[i].internal_temp};
                }
              }
              series1 = {name: device1, data: data1};
              series2 = {name: device2, data: data2};
              series3 = {name: device3, data: data3};
              _this.chartSeriesLine[0] = (data1.length==24) ? series1 : {};
              _this.chartSeriesLine[1] = (data2.length==24) ? series2 : {};
              _this.chartSeriesLine[2] = (data3.length==24) ? series3 : {};
            }
            
            //finish loading
            setTimeout(() => {
              _this.loading = false;
              if (loader) loader.hide();
              this.doLayout(true);
            }, 300);

          });
        },
        error => {
          _this.loading = false;
          if (loader) loader.hide();
          _this.$app.handleError(error);          
        }
      );
    },
    emptyTable(table) {
      for (let i=table.rows.length-1; i>1; i--) {
          table.deleteRow(i);
      }
    },
    routeMe(widget) {
      switch(widget) {
          case 'devicesActiveConnected': this.$router.push({ name: 'devices', params: { filter: 'activeConnected' } }); break;
          case 'devicesPending': this.$router.push({ name: 'devices', params: { filter: 'pending' } }); break;
          case 'devicesMaintenance': this.$router.push({ name: 'devices', params: { filter: 'maintenance' } }); break;
          case 'devicesOutOfOrder': this.$router.push({ name: 'devices', params: { filter: 'outOfOrder' } }); break;
          case 'devicesWithAlerts24h': this.$router.push({ name: 'alerts', params: { filter: 'last24h' } }); break;
          case 'openedAlerts': this.$router.push({ name: 'alerts', params: { filter: 'opened' } }); break;
          case 'support': window.open('https://www.infinitus-outdoor.com/en/qa-support', '_blank'); break;
          default: console.log('Router for '+widget+' does not exist.'); break;
      }
    },
    doLayout(hasData) {
      let _this = this;

      _this.fullWidth = _this.$refs.bodyEl.clientWidth;

      _this.$refs.chartWidgets.style.display = "";

      if(hasData==true) {
        setTimeout(() => {
          let w1 = document.getElementsByClassName("lab-widget-card")[0],
              w2 = document.getElementsByClassName("lab-widget-card")[1],
              w3 = document.getElementsByClassName("lab-widget-card")[2],
              w4 = document.getElementsByClassName("lab-widget-card")[3],
              w5 = document.getElementsByClassName("lab-widget-card")[4],
              wH = w5.clientHeight;

          w1.style.height = wH + 'px';
          w2.style.height = wH + 'px';
          w3.style.height = wH + 'px';
          w4.style.height = wH + 'px';

          let el1 = document.getElementsByClassName("lab-card-body")[0],
              el2 = document.getElementsByClassName("lab-card-body")[1],
              el3 = document.getElementsByClassName("lab-card-body")[2],
              el4 = document.getElementsByClassName("lab-card-body")[3],
              el5 = document.getElementsByClassName("lab-card-body")[4],
              h1 = el1.clientHeight,
              h2 = el2.clientHeight,
              hRight = el4.clientHeight + el5.clientHeight;

          if(h1>h2)
            el1.style.height = h2 + 'px';
          else
            el2.style.height = h1 + 'px';

          let w1Footer = w1.getElementsByClassName("summary-footer")[0].clientHeight,
              w2Footer = w2.getElementsByClassName("summary-footer")[0].clientHeight,
              w3Footer = w3.getElementsByClassName("summary-footer")[0].clientHeight,
              w4Footer = w4.getElementsByClassName("summary-footer")[0].clientHeight,
              w5Footer = w5.getElementsByClassName("summary-footer")[0].clientHeight;
          if(w1Footer>30 || w2Footer>30 || w3Footer>30 || w4Footer>30 || w5Footer>30) {
            w1.style.height = (w5Footer + 125) + 'px';
            w2.style.height = (w5Footer + 125) + 'px';
            w3.style.height = (w5Footer + 125) + 'px';
            w4.style.height = (w5Footer + 125) + 'px';
            w5.style.height = (w5Footer + 125) + 'px';
          } else {
            w1.style.height = '155px';
            w2.style.height = '155px';
            w3.style.height = '155px';
            w4.style.height = '155px';
            w5.style.height = '155px';
          }

          let appDiv = document.getElementById("app"),
              sidebarDiv = appDiv.getElementsByClassName("v-sidebar-menu")[0],
              //allowedWitdh = ( sidebarDiv.classList.contains("vsm_expanded") ) ? 762 : 947;  //bz: 947pixels with open sidebar, 762px with closed sidebar when on 1024px resolution width
              allowedWitdh = ( sidebarDiv.classList.contains("vsm_expanded") ) ? 729 : 914;  //bz: 947pixels with open sidebar, 762px with closed sidebar when on 1024px resolution width
          if(_this.fullWidth<allowedWitdh) {
            let chartsDiv = _this.$refs.chartWidgets;
            chartsDiv.style.display = 'none';
          } else {
            let legendHeat = document.getElementsByClassName("apexcharts-legend")[1];
            legendHeat.style.minHeight = '16px';
            legendHeat.style.overflowY = 'hidden';

            setTimeout(() => {
              el3.style.height = parseInt(hRight - el2.clientHeight) + 'px';
            }, 200);
          }

          //this.$refs.bodyEl.style.height = (window.innerHeight - (this.$refs.bodyEl.getBoundingClientRect().top + document.documentElement.scrollTop)) + "px";
        }, 200);
      }
    }
  }
};
</script>

<style scoped>
.lab22-mv-padding {
  padding-right:25px;
}

.row-lab {
  margin-top: -25px !important;
}

.lab-pd-r-0 {
  padding-right:0px !important;
}

.lab-table {
    margin-top:10px; 
    color:#ccc; 
    width:100%; 
    font-size:0.7rem;
}

.card-body {
    cursor: pointer !important;
    border-radius: 0.01rem !important;
}

.lab-td-elipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 120px;
}
</style>

<style>
.filterbar {
  border-top: 0 !important;
  border-bottom: 0 !important;
}

section.card {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}
</style>