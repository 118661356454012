<template>
  <div ref="container" class="lab22-mv-padding">

    <!--<ModuleHeader title="Devices" iconClass="fa-solid fa-tablet-screen-button">-->
    <ModuleHeader title="Devices" iconClass="inf-module-header-devices inf-module-header-img">
      <button v-if="!this.updateClientMode && authUser.isAdmin()" @click="setUpdateClientMode(true)" type="button" class="btn btn-outline-secondary">Update client</button>
      <button v-if="this.updateClientMode && authUser.isAdmin()" @click="setUpdateClientMode(false)" type="button" class="btn btn-outline-secondary">Cancel update</button>
      <button v-if="this.updateClientMode && authUser.isAdmin()" @click="updateCheckedDevices" type="button" class="btn btn-danger ms-2">Update selected devices</button>
      <button v-if="!this.updateClientMode && (authUser.isAdmin() || authUser.hasCreateDeviceFunction())" @click="addNewDevice" type="button" class="btn btn-primary ms-2">Add new</button>
    </ModuleHeader>

    <FilterBar v-slot="{state}" :filterState="getFilterState()" @reload="load(true)" showReload showReset :loading="loading">
      <BaseField v-if="!this.$app.user.isCustomerUser()" v-model="state.customerId" name="customerId" placeholder="Customer" as="select" :data="this.$app.$data.customers" addClearOption />
      <BaseField v-model="state.name" name="name" placeholder="Device name" />
      <BaseField v-model="state.modelId" name="modelId" placeholder="Device model" as="select" :data="this.$app.$data.deviceModelLkp" addClearOption />
      <BaseField v-model="state.attrSerial" name="attrSerial" placeholder="Serial number" />
      <BaseField v-model="state.statusId" name="statusId" placeholder="Status" as="select" :data="this.$app.$data.deviceStatusList" addClearOption />
    </FilterBar>

    <BaseGrid v-slot="{row, columns}" :columns="gridData.columns" :rows="gridData.rows" @row-dblclick="onGridRowDblclick" @cell-click="onGridCellClick" :enableVScroll="true">
      <td v-if="this.updateClientMode">
        <input type="checkbox" @change="checkChange($event, row)" :value="row.id" v-model="checkedRecords" class="form-check-input big-checkbox" />
      </td>    
      <BaseGridCell :col="columns[1]" :row="row" />
      <BaseGridCell :col="columns[2]" :row="row" />
      <BaseGridCell :col="columns[3]" :row="row" />
      <BaseGridCell :col="columns[4]" :row="row" />
      <BaseGridCell :col="columns[5]" :row="row" />
      <BaseGridCell :col="columns[6]" :row="row" />
      <BaseGridCell :col="columns[7]" :row="row" />
    </BaseGrid>
  </div>
</template>

<script>
import _app from '@/App/App'
import ModuleHeader from '@/App/components/Common/ModuleHeader'
import FilterBar from '@/App/components/Common/FilterBar'
import AddDeviceModal from './AddDeviceModal'
import BaseField from '@/App/components/Form/BaseField'
import BaseGrid from '@/App/components/Grid/BaseGrid'
import BaseGridCell from '@/App/components/Grid/BaseGridCell'

export default {
  name: 'DeviceList',
  components: {
    ModuleHeader,
    FilterBar,
    BaseField,
    BaseGrid,
    BaseGridCell
  },
  data() {
    return {
      gridData: {
        columns: [
          {
            title: '',
            dataIndex: 'selected',
            type: 'checkbox',
            changeHandler: (e) => {              
              this.checkAll(e.target.checked);
            },
            hidden: !this.updateClientMode
          },          
          {
            title: 'Model',
            dataIndex: 'modelId',
            lookup: _app.toLookup(this.$app.$data.deviceModelLkp)
          },
          {
            title: 'Name',
            dataIndex: 'name'
          },
          {
            title: 'Address',
            dataIndex: 'address'
          },          
          {
            title: 'Status',
            dataIndex: 'statusId',
            lookup: _app.toLookup(this.$app.$data.deviceStatusList),
            align: 'center'
          },
          {
            title: 'Connected',
            dataIndex: 'connStatusId',
            lookup: _app.toLookup(this.$app.$data.connectedStatusList),
            align: 'center'
          },
          {
            title: 'Alerts',
            dataIndex: 'activeAlerts',
            align: 'center',
            style: 'color: #FF19FF',
            renderer: (v) => {
              return v || '';
            }
          },
          // actions
          {
            width:90,
            align:'right',
            actions: [
              {
                text: 'Details',
                handler: this.showDetails
              }
            ]
          }
        ],
        rows: []
      },
      data: [],
      loading: false,
      authUser: this.$app.user,
      updateClientMode: false,
      checkedRecords: []
    };
  },
  mounted() {
    if(this.$route.params.filter) { //bz: from Overview we can pass the following filter requests based on clicked dashboard widget
      let filter = this.$route.params.filter;
      switch(filter) {
        case 'activeConnected': this.getFilterState().statusId = 2; break;
        case 'pending': this.getFilterState().statusId = 1; break;
        case 'maintenance': this.getFilterState().statusId = 3; break;
        case 'outOfOrder': this.getFilterState().statusId = 5; break;
        default: break;
      }
    }

    if (_app.$data.devices?.length)
      this.load(false);
      
    this.load(true);  // this.$route.params.reload
  },
  methods: {
    getFilterState() {
      return _app.$data.getState('DeviceListFilter');
    },    
    load(reload) {
      if (reload) this.loading = true;
      _app.$data.getDevices(reload).then(data => {
        this.loading = false;
        this.data = data;
        this.updateGridView();
      }, () => { this.loading = false; });
    },
    showDetails(dto, tab) {
      _app.$data.setSelectedDevice(dto);
      this.$router.push('/devices/' + dto.guid + (tab ? ('/' + tab) : '/details'));      
    },
    addNewDevice() {
      this.$vfm.show({
        component: AddDeviceModal,
        on: {
          create: (dto) => {
            this.load(true);
            this.showDetails(dto);  // , '/components'
          }
        }
      });
    },
    updateGridView() {

      const filter = this.getFilterState();

      this.gridData.rows = this.data.filter((rec) => { 

        if (filter.customerId && rec.customerId != filter.customerId) return false;
        if (filter.name && rec.name.toLowerCase().indexOf(filter.name.toLowerCase()) == -1) return false;
        if (filter.modelId && rec.modelId != filter.modelId) return false;
        if (filter.attrSerial && rec.attrSerial.toLowerCase().indexOf(filter.attrSerial.toLowerCase()) == -1) return false;
        if (filter.statusId && rec.statusId != filter.statusId) return false;

        return true;
      });
    },
    onGridCellClick(row, col) {
      this.showDetails(row, 'status');
    },
    onGridRowDblclick(row) {
      this.showDetails(row, 'status');
    },
    setUpdateClientMode(enable) {

      this.updateClientMode = enable;

      if (!this.updateClientMode) 
        this.checkedRecords = [];

      this.gridData.columns[0].hidden = !this.updateClientMode;
    },
    updateCheckedDevices() {

      if (this.checkedRecords.length == 0) {
        this.$app.notify('No devices selected');
        return;
      }

      this.$app.$modal.confirm('Confirm', `Are you sure you want to update devic client on ${this.checkedRecords.length} selected devices?`, (confirmed) => {

        if (confirmed) {

          let loader = this.$loading.show({container: this.$refs.container.$el });

          _app.$api.post('Device/UpdateClientVersion', this.checkedRecords).then(
            (result) => {              
              loader.hide();
              this.setUpdateClientMode(false);
              this.load(true);
              if (result.data.length > 0) {
                for(let i = 0; i < result.data.length; i++) {
                  _app.notifyError(result.data[i]);
                }
              }              
            },
            (error) => {
              loader.hide();
              this.setUpdateClientMode(false);
              this.load(true);              
              _app.handleError(error);
            }            
          );        
        }

      });
    },
    checkAll(checked) {
      if (checked) {
        this.checkedRecords = this.gridData.rows.map(r => { return r.id });
      } else {
        this.checkedRecords = [];
      }
    },
    checkChange(e) {
      if (!e.target.checked) {
        this.gridData.columns[0].checked = false;
      } else if (this.checkedRecords.length == this.gridData.rows.length) {
        this.gridData.columns[0].checked = true;
      }
    },
  }
}
</script>
