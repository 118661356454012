<template>
  <div class="lab22-mv-padding">
    <!--<ModuleHeader iconClass="fa-solid fa-tablet-screen-button" :title="data?.name" :subtitle="data?.address" @titleClick="load(true)">-->
    <ModuleHeader :title="data?.name" :subtitle="data?.address" @titleClick="load(true)"  @click.shift.ctrl.prevent="deviceInfo(data?.id)" iconClass="inf-module-header-devices inf-module-header-img">
      <div class="connected-status">
        <div class="text-nowrap">
          <label>Connected:</label>{{ this.$app.$data.getConnectedStatusName(data?.connStatusId)}}
        </div>
        <div class="text-nowrap">
          <label>Last event:</label>{{ data?.lastEvent ? this.$app.formatDate(data?.lastEvent) : '-' }}
        </div>
      </div>    
      <div class="d-flex">
        <div class="btn-group mx-2">
          <button type="button" class="btn btn-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">{{statusName}}</button>
          <ul class="dropdown-menu dropdown-menu-end">
            <li v-for="status in statusOptions" :key="status.id"><a class="dropdown-item" :class="{ active: status.id == data?.statusId }" @click.prevent="setStatus(status.id)">{{status.name}}</a></li>
          </ul>
        </div>
        <div class="btn-group">
          <!--<button type="button" class="btn btn-outline-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="fa-solid fa-gear"></i></button>-->
          <button type="button" class="btn btn-outline-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="svg-settings"></i></button>
          <ul class="dropdown-menu dropdown-menu-end">
            <h6 class="dropdown-item-text" style="white-space: nowrap;"><label>Client version:</label> {{ clientVersion }}</h6>
            <div class="dropdown-divider"></div>
            <li><a class="dropdown-item" @click.prevent="downloadConfiguration()"><i class="fa-solid fa-download"></i>Download configuration</a></li>
            <li><a class="dropdown-item" @click.prevent="showOnMap()"><i class="fa-solid fa-location-dot"></i>Show on map</a></li>            
            <li><hr class="dropdown-divider"></li>
            <li><a class="dropdown-item" @click.prevent="deleteDevice()"><i class="fa-solid fa-trash-can"></i>Delete</a></li>
          </ul>
        </div>
      </div>
    </ModuleHeader>
    <div class="tabs">
      <ul class="nav nav-tabs">
        <li class="nav-item" v-for="tab in tabs" :key="tab">
          <router-link class="nav-link" active-class="active" :to="tab.route">{{tab.name}}</router-link>
        </li>
      </ul>    
    </div>
    <router-view ref="routerView" :device="data" @refreshStatus="refreshStatus"></router-view>
    
    <button v-if="isLabUser" part="button" class="chat-button" @click.stop.prevent="triggerChat">
      <a id="bz-a-chat-button" data-bs-toggle="offcanvas" href="#chatAgent" role="button" aria-controls="chatAgent" style="color:#161616;"></a>
      <i class="fa fa-eye" style="font-size:large;"></i>
    </button>
    <div v-if="isLabUser" class="offcanvas offcanvas-end offcanvas-dark" data-bs-scroll="true" tabindex="-1" id="chatAgent" aria-labelledby="chatAgentLabel" style="width:98%; margin-top:60px;">
      <DeviceDetailsChat :key="ChatConfig.name" 
        :Config="ChatConfig" 
      />
    </div>
  </div>
</template>

<script>
import _app from '@/App/App'
import ModuleHeader from '@/App/components/Common/ModuleHeader'
import DeviceDetailsChat from './DeviceDetailsChat'

export default {
  name: 'DeviceDetails',
  props:['guid'],
  components: {
    ModuleHeader,
    DeviceDetailsChat,
  },    
  data() {
    return {
      data: null,
      statusOptions: [
        { id: _app.$data.DeviceStatus.Active, name: 'Paired'},
        { id: _app.$data.DeviceStatus.Maintenance, name: 'Maintenance'},
        { id: _app.$data.DeviceStatus.Deactivated, name: 'Deactivated'},
        { id: _app.$data.DeviceStatus.OutOfOrder, name: 'Out of order'}
      ],
      statusName: '',
      checkedAlerts: [],
      clientVersion: null,
      isLabUser: false,
      ChatConfig: [],
    }
  },
  watch: {
    guid: {
      immediate: true,
      handler(value) {
        this.load(true);
      }
    }
  },
  mounted() {
      let _user = this.$store.state.auth.user; 
      if (_user) {
        this.isLabUser = ( this.$app.$data.Lab22Users.indexOf(_user.email) == -1 ) ? false : true;
      }

      if (!this.data && _app.$data.getSelectedDevice()?.guid == this.guid)
        this.updateData(_app.$data.getSelectedDevice());
        
      this.load();
  },
  computed: {
    tabs: function() {
      let setupRoute =  this.data?.modelId == 1 ? './setup' : './generic-setup';

      var tabs = [
        { name: 'Details', route: './details'},
        { name: 'Setup', route: setupRoute},
        { name: 'Status', route: './status'}
      ];

      if (this.$app.user.isAdmin()) // this.data?.modelId != 1
        tabs.push({ name: 'Components', route: './device-components'});

      tabs.push(
        { name: 'Alerts', route: './alerts'},        
        { name: 'Maintenance', route: './maintenance'}
      );

      return tabs;
    }
  },
  methods: {

    getSetupRoute() {
      console.log(this.data)
      return this.data?.modelId == 1 ? './setup' : './generic-setup';
    },

    addDeviceToHistory(guid, name) {
      let history = JSON.parse(localStorage.getItem('history')) || [],
          historyNew = [];
      if(history.length>0) { //remove current item if exists
        for(let i=0; i<history.length; i++) {
          if(history[i].guid!==guid && i<15)
            historyNew.push(history[i]);
        }
      }
      historyNew.unshift({'guid':guid, 'name':name}); //add current item to top of history
      localStorage.removeItem('history');
      localStorage.setItem('history', JSON.stringify(historyNew));
      _app.$data.viewHistory = [];
      _app.$data.viewHistory = history.filter(obj => {
                      return true;
                    });
    },

    load(reload) {
      const progress = reload ? this.$progress.start() : null;
      _app.$api.get('Device/GetDevice', { params: { guid: this.guid } }).then(
        result => {
          if (progress) progress.finish();

          this.updateData(result.data);

          this.getEvTypeStateData();
        },
        error => {
          if (progress) progress.finish();

          _app.handleError(error);
        }
      );
    },
    getEvTypeStateData() {      
      _app.$api.get('Device/GetDeviceEvTypeState', { params: { deviceId: this.data.id } }).then(
        result => {
          this.clientVersion = result.data.client_version;
        }
      );      
    },
    updateData(data) {

      this.data = data;

      //add to view history
      this.addDeviceToHistory(this.guid, data.name);

      const char = '`';
      this.ChatConfig = {
        'name': this.data.name + ' - Chat assistant',
        'storageItem':'lab22-device-chat-history',
        'initPrompts': [
            {'model':'alerts','prompt':'Find last 10 alerts for $d that have status is Open. Display only device id, device name and condition text'},
            {'model':'alerts','prompt':'Find all alerts where status is Open. Sort alerts by newest first. Display only device name, id, condition text and when alert was inserted.'},
            //{'model':'rawData','prompt':'Show temperatures chart for $d in the last 24 hours'},
            //{'model':'rawData','prompt':'Show current front display parameters for $d'},
            //{'model':'analytics','prompt':'Show camera line crossings in the last 24 hours for $d'},
        ],
        'sysPrompts': [
            {'prompt':'You are a data analyst and MySQL professional. Your job is to write a MySQL select statement (I will refer to it as "the query" from now on) from text `'},
            {'prompt':'` using the following database schema: '},
            {'prompt':` If you cannot create the query then write a response explaining the user why you could not create the query. 
                If you manage to create the query then follow the next 8 instructions strictly! 
                1) I want you to respond with the query only in a single line and nothing else. 
                2) Ensure that the query does not contain any newline characters. 
                3) Do not write explanations. 
                4) Never add a ${char};${char} character at the end of the query. 
                5) If you receive somewhere inside user's prompt exact two characters $d it reffers to current device_id which is ${this.data.id}. 
                   Only in case when user sends characters $d together you can add this to condition part of the query. 
                   For example, user could ask "find all alerts for $d" and you would generate the query like 
                   "SELECT * FROM v_dwh_fact_alerts WHERE device_id = ${this.data.id}". 
                6) For column aliases use comments in database schema and enclose these aliases with ${char} character. 
                7) If you add a condition in WHERE clause and this condition is of text value then you should always make comparison with lower cased values.
                  For example, user could ask "find all alerts where status is open" and you would generate the query like 
                  "SELECT * FROM v_dwh_fact_alerts WHERE lower(STATUS_NAME) = lower('Open')". 
                8) If user does not limit the number of rows that they want to receive from the query, you always add LIMIT 999 at the end of the generated query.`
          }    
        ]
      };

      //_app.$helper.resetObject(this.data, data);
      //this.$refs.routerView.device = this.data;
      this.statusName = _app.$data.DeviceStatus.getStatusName(data.statusId);

      if (_app.$data.deviceEditState?.id != this.data?.id) {
        _app.$data.deviceEditState = Object.assign({}, this.data);
        
        //_app.$data.setState('DeviceSetupState', {});
        _app.$helper.resetObject(_app.$data.getState('DeviceSetupState'), {
          deviceId: this.data.id,
          displayOn: true,
          touchScreenEnabled: true,          
          backlightLevel: 50,
          blueColorBalance: 50,
          brightness: 50,
          displayContrast: 50,
          gammaValue: 2.2,
          greenColorBalance: 50,
          redColorBalance: 50,
          
          //brightnessLevel: 7.5,
          backlightAutomaticStatus: true,
          ambientSensorMinBacklight: 50,
          ambientSensorMaxBacklight: 100,          
          ambientSensorMinAmbientLight: 0,
          ambientSensorMaxAmbientLight: 10000,
        
          powerSwitch1Status: true,
          powerSwitch2Status: true
        });

        if (_app.$data.hasState('DeviceStatus'))
          _app.$data.getState('DeviceStatus').dashboardGroups = [];
      }
      
      //_app.$helper.resetObject(this.$app.$data.deviceEditState, data);
      this.$app.$data.deviceEditState = data;

      this.$app.$data.clearStore('DEVICE_ALERT_LIST');
    },
    setStatus(statusId) {

      var statusName = _app.$data.DeviceStatus.getStatusName(statusId);
      
      _app.$modal.confirm('Change device status', `Are you sure you want to change status to ${statusName}?`, (confirmed) => {
        
        if (!confirmed) 
            return;
        
        let loader = this.$loading.show(); 
        
        this.$app.$api.get('Device/SetDeviceStatus', { params: { id: this.data.id, statusId: statusId } }).then(
          () => {
            loader.hide();
            this.data.statusId = statusId; 
            this.updateData(this.data);
            this.getEvTypeStateData();
          },
          () => {
            loader.hide();
          }
        );  
      });
    },
    deleteDevice() {
      _app.$modal.confirm('Delete device', `Are you sure you want to delete this device?`, (confirmed) => {
        
        if (!confirmed) 
            return;
        
        let loader = this.$loading.show(); 
        
        this.$app.$api.get('Device/DelDevice', { params: { id: this.data.id } }).then(
          () => {
            loader.hide();
            this.$router.replace({
              name: 'devices',
              params: { 
                reload: true
              }
            })
          },
          () => {
            loader.hide();
          }
        );  
      });
    },      
    downloadConfiguration() {
      //this.viewState.downloadingConfiguration = true;
      _app.downloadFile(`Device/DownloadConfiguration?id=${this.data.id}`).then(() => {
        //this.viewState.downloadingConfiguration = false;
      });
    },
    showOnMap() {
      this.$router.push({
        name: 'locations',
        params: { 
          filter: JSON.stringify({
            name: this.data.name
          })
        }
      })
    },
    refreshStatus() {
      
      _app.$api.get('Device/GetDevice', { params: { guid: this.guid } }).then(
        result => {
          this.data.statusId = result.data.statusId;
          this.statusName = _app.$data.DeviceStatus.getStatusName(result.data.statusId);
          this.data.connStatusId = result.data.connStatusId;
          this.data.lastEvent = result.data.lastEvent;
        },
        error => {
          _app.handleError(error);
        }
      );
    },
    deviceInfo(id) {
      console.log('Device id = ' + id);
    },
    triggerChat() {
      let a = document.getElementById('bz-a-chat-button');
          a.click();
    }
  } 
}
</script>

<style scoped>

.tabs {
  margin-top:20px;
}

.connected-status {
  width: 200px;
  margin-top: -12px;
}

.connected-status label {
  color: #bbc5d6;
  width: 70px;
}

/* floating chat button */
@keyframes pulsate {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.chat-button {
  width: 40px;
  height: 40px;
  animation: pulsate 2s infinite;
  position: fixed;
  bottom: 20px;
  right: 60px;
  border-radius: 50% !important;
  /*cursor: pointer !important;
  z-index: 999999999 !important;*/
}
.chat-button, .chat-button:focus, .chat-button:active {
  box-shadow:none !important;
  outline:0px !important;
  border-style: none !important;
}
.dropdown-menu, .offcanvas {
  z-index: 10001 !important;
}
.offcanvas-dark {
  color: #ffffff;
  background-color: #262626;
}
</style>