<template>
<section>
  <div class="container py-5 d-flex justify-content-center align-items-center" st>
    <div class="row row-login-dark" style="margin-top:100px;">
      <div class="">
        <section class="card card-login-dark">
          <header class="card-header">
            <h2 class="card-title">Login</h2>
            <!-- <p class="card-subtitle">LAB22 Admin</p> -->
          </header>
          <div class="card-body card-body-login-dark">
            
            <Form @submit="handleLogin" :validation-schema="schema">
              <div class="form-group">
                <label for="username">Username</label>
                <Field name="username" type="text" label="Username" autocomplete="on" class="form-control" />
                <ErrorMessage name="username" class="error-feedback" />
              </div>
              <div class="form-group">
                <label for="password">Password</label>
                <Field name="password" type="password" label="Password" autocomplete="on" class="form-control" />              
                <ErrorMessage name="password" class="error-feedback" />
              </div>
              <div v-if="message" class="alert alert-danger" role="alert">
                  {{ message }}
              </div>
              <!-- <p class="small"><a class="text-muted" href="#!">Forgot password?</a></p> -->
              <div class="form-group d-flex">
                <button class="btn btn-primary btn-block flex-grow-1" :disabled="loading">
                  <span
                    v-show="loading"
                    class="spinner-border spinner-border-sm"
                  ></span>
                  <span>Login</span>
                </button>
              </div>
              
            </Form>
          </div>
        </section>
      </div>
    </div>
  </div>
</section>
</template>
<script>

import { Form, Field, ErrorMessage } from "vee-validate";

export default {
  name: "LoginPage",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      loading: false,
      message: "",
      schema: {
        username: 'required',
        password: 'required'
      }
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/");
    }
  },
  methods: {
    handleLogin(user) {
      this.loading = true;
      this.$store.dispatch("auth/login", user).then(
        () => {
          this.$router.go("/");
        },
        (error) => {
          this.loading = false;
          this.message = error.response?.status == 401 ? 'Login Failed: Your username or password is incorrect' : error.response?.data?.message || error.message || error.toString();

        }
      );
    },
  },
};
</script>

<style scoped>
  .card {
    margin-top:100px;
    width:260px;
  }

  .alert-danger {
      margin-top: 20px;
  }

</style>