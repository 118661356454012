<template>
  <div class="lab22-mv-padding">

    <ModuleHeader :title="wsNameFull" :subtitle="'workspace'+( this.$store.getters.getCurrentWs.isPending ? ' pending' : '' )" iconClass="inf-module-header-analytics inf-module-header-img">
        <div class="etl-status">
          <div class="text-nowrap">
            <label>ETL status:</label> {{store.state.Reporting.etl.dbStatus}}
          </div>
          <div class="text-nowrap">
            <label>Last refresh:</label> {{store.state.Reporting.etl.dbTimestamp}}
          </div>
        </div>    
        <div class="d-flex">
          <div class="btn-group mx-2">
            <button type="button" :disabled="isReportViewWorking" class="btn btn-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">{{wsName}}</button>
            <ul class="dropdown-menu dropdown-menu-end">
              <li v-for="ws in this.$store.state.Reporting.workspaces" :key="ws.id"><a class="dropdown-item" @click.prevent="loadWs(ws.id, true)">{{ws.name}}</a></li>
            </ul>
          </div>
          <div v-show="isWsDirty()" class="btn-group" style="margin-right:8px;">
            <button type="button" @click.prevent="saveWs(this.$store.getters.getCurrentWs,true)" :disabled="isReportViewWorking" :class="clsSaveDirty"><i class="fa fa-save"></i></button>
          </div>
          <!--<div class="btn-group" style="margin-right:8px;">-->
          <div class="btn-group">
            <button type="button" :disabled="isReportViewWorking" class="btn btn-outline-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="svg-settings"></i></button>
            <ul class="dropdown-menu dropdown-menu-end">
              <li><a class="dropdown-item" @click.prevent="saveWs(this.$store.getters.getCurrentWs,true)"><i class="fa-solid fa-save"></i>Save</a></li>
              <li><a class="dropdown-item" @click.prevent="renameWs(this.$store.getters.getCurrentWs)"><i class="fa-solid fa-dummy-lab22"></i>Rename</a></li>
              <li><a class="dropdown-item" @click.prevent="createWs(this.$store.getters.getCurrentWs)"><i class="fa-solid fa-copy"></i>Duplicate</a></li>
              <!--<li><a class="dropdown-item" @click.prevent="shareWs(this.$store.getters.getCurrentWs)"><i class="fa-solid fa-share-alt"></i>Share</a></li>-->
              <li v-if="!isWsDefault()"><a class="dropdown-item" @click.prevent="setWsAsDefault(this.$store.getters.getCurrentWs)"><i class="fa fa-dummy-lab22"></i>Set as default</a></li>
              <li><a class="dropdown-item" @click.prevent="createWs({})"><i class="fa-solid fa-plus"></i>New</a></li>
              <!--<li><a class="dropdown-item" @click.prevent="toggleAutoSaveWs"><i :class="clsAutoSave"></i>Auto save</a></li>-->
              <li><hr class="dropdown-divider"></li>
              <li><a class="dropdown-item" @click.prevent="deleteWs(this.$store.getters.getCurrentWs)"><i class="fa-solid fa-trash-can"></i>Delete</a></li>
            </ul>
          </div>
          <!--
          <div class="btn-group">
            <button type="button" @click="triggerHref('bz-a-help-button')" class="btn btn-outline-secondary" style="background-color: rgba(0, 0, 0, 0) !important;">
              <a id="bz-a-help-button" data-bs-toggle="offcanvas" href="#helpReporting" role="button" aria-controls="helpReporting">
                <i class="fa fa-question" style="color:#fff;"></i>
              </a>
            </button>
          </div>
          -->
        </div>
    </ModuleHeader>

    <div class="tabs">
      <ul class="nav nav-tabs" ref="ulTabs" role="tablist" style="border-bottom-color:#666666 !important;">
        <li class="nav-item nav-item-reactive" :id="'rep-li-'+tab.tabId" v-for="tab in tabs" :key="tab.tabId">
          <a :href="'#'+tab.name" class="nav-link nav-link-tab-href" @click.prevent="activateTab(tab.tabId, false)" :id="'rep-tab-'+tab.tabId" :title="tab.name" @contextmenu.prevent="onTabRightClick(item, $event)" style="border-bottom: none !important;">
            <i :class="tab.icon"></i>
            &nbsp;&nbsp;{{shortenName(tab.name,17)}}
            &nbsp;&nbsp;<i class="fa fa-times" @click.prevent.stop="closeReport('rep-tab-'+tab.tabId)" style="cursor:pointer;"></i>
          </a>
        </li>
        <li id="rep-li-9999999999" class="nav-item" :key="9999999999">
          <a href="#" class="nav-link" @click.prevent="addReportOptions(item, $event)" title="Add report" @contextmenu.prevent="onTabRightClickReject($event)" style="background: #161616 !important; border-top: solid 1px #262626 !important; border-right-color: #262626 !important; border-left-color: #161616 !important; border-bottom: solid 1px #666666 !important;">
            <i class="fa fa-plus"></i>
          </a>
        </li>
      </ul>
      <div class="tab-content tab-content-report" style="padding-left:0px; padding-right:0px;">
        <div class="tab-pane active" ref="divRepContent">
          <!-- this.report only changes when tab is activated -> "this.report" is this.tabs[x] member -->
          <ReportView
            v-if="this.report"  
            ref="reportView" 
            :key="this.tabChangeCounter" 
            :report="this.report" 
            :models="store.state.Reporting.models" 
            :displayOption="this.displayOption" 
            :ulHeight="this.ulHeight" 
            @reportChange="reportChange(report)" 
            @isReportInProgress="isReportInProgress()" 
          />
        </div>
      </div>
    </div>

    <ContextMenu ref="repTabContextMenu"></ContextMenu>

    <div class="offcanvas offcanvas-end help-dark" data-bs-scroll="true" tabindex="-1" id="helpReporting" aria-labelledby="helpReportingLabel" style="width:50%;">
        <div class="offcanvas-header">
            <h3 class="offcanvas-title" id="helpReportingLabel" style="padding-bottom:0px !important; margin-top: 0px !important;">Reporting help</h3>
            <button type="button" id="bz-offcanvas-help-close" class="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body" style="padding-top:0px !important;">
            <div class="row" style="height:1px; border-bottom: solid 1px #282d36;"></div>
            <div class="row" style="margin-top:20px; padding-left:15px; padding-right:5px;">
              <div :class="'col-'+chatHelpCols">
                In our reporting tool users can create ad hoc reports and organize them into workspaces. 
                Each workspace can hold one or more reports. Simply put, a worskpace is a collection of saved and ad hoc reports.<br /><br />
                A report is configured within a model (cube) which is, in most cases, a (materialized) database view connected to a datetime dimension. 
                In some cases additional dimensions can be joined within a model (e.g. location).<br /><br />
                Models provide a comprehensive view of the data that enable users to analyze information from different perspectives, 
                perform trend analysis, and identify patterns and relationships that may not be immediately apparent in a flat, tabular data format. 
                In this application you can use models "Devices" and "Alerts" which means that you can analyze the forementioned entities in detail over time.<br /><br />
                If you have any questions regarding the use of our Reporting tools simply ask our chat-based assistant by clicking the chat button on your right. 
                For additional new features or reporting a bug contact us via ticketing app. For other inquiries reach us by dropping us a note via company contact channels.
                <br />
                <h3>BASICS</h3>
                <span @click="showVideo('Overview', 'https://www.loom.com/embed/736123da33ba463c9f2f3a50740f6dcb?sid=27bfd13b-8b76-4f82-9f5c-6fc766148558')" class="help-link">Basic functionalities explained</span>
                <br />
                <h3>FAQ</h3>
                <span @click="showVideo('Share', 'https://www.loom.com/embed/a67171deb4e6469db0be28e3bca0ca29?sid=4b2775b7-6ca6-418d-bbd9-8a1b3f2369fe')" class="help-link">How do I share a report with other user(s)?</span><br />
                <span @click="showVideo('Paste', 'https://www.loom.com/embed/ae7dc5128145432a8b7215d0756c9b75?sid=40948d7c-eb37-4bb0-87ec-29f4446ab105')" class="help-link">How do I load a report that was shared with me from another user?</span>
                <br />
              </div>
              <div v-if="chatHelpCols==7" class="col-5 text-end">
                <iframe :src="helpBaseUrl+helpQueryString" id="iframe-help" name="iframe-help" frameBorder="0" seamless="seamless" style="min-height:550px;"></iframe>
              </div>
            </div>
        </div>
    </div>

  </div>
</template>

<script>
import _app from '@/App/App'
import {AUTH_STORE_KEY} from '../../App/Auth/auth.service'
import dayjs from 'dayjs';
import ModuleHeader from '@/App/components/Common/ModuleHeader'
import ContextMenu from '@/App/components/Common/ContextMenu'
import ReportingReports from './ReportingReports'
import ReportView from './ReportView'
import ReportingNameModal from './ReportingNameModal'
import ReportingVideoModal from './ReportingVideoModal'

let _authData = JSON.parse(localStorage.getItem(AUTH_STORE_KEY)),
    _token = '';

if (_authData?.accessToken)
  _token = _authData.accessToken;

export default {
  name: 'ReportingUi',
  beforeUnmount() {
    this.unsubscribe();
  },
  components: {
    ModuleHeader,
    ContextMenu,
    ReportView
  },    
  data() {
    return {
      user: this.$store.state.auth.user,
      store: this.$store,
      currentWs: this.$store.getters.getCurrentWs,
      wsName: '',
      wsNameFull: '',
      tabs: null,
      activeTabId: null,
      tabChangeCounter: -1,
      ulHeight: 40, //normal tabs (<ul>) height when tabs are in 1 row
      report: null, //json representation of report/tab that is propagated to ReportView component (can be saved or non-saved report)
      displayOption: 'table', //initial display option for new reports
      //autoSave: null,
      timerId: null,
      isReportViewWorking: null,
      firstTimeTabActivate: true,
      helpBaseUrl: process.env.VUE_APP_CHAT_HELP_URL,
      helpQueryString: '?token='+_token,
      chatHelpCols: 12, //if 12 then chat is NOT displayed; if 7 then chat IS displayed (change only if flowise service is running)
    }
  },
  watch: {
    wsName() {
      this.wsNameFull = 'Reporting - ' + this.wsName;
    },
    /*tabs: {
        handler(newValue, oldValue) {
          let _old = (oldValue) ? oldValue : {},
              _new = (newValue) ? newValue : {};
        },
        deep: true
    },*/
  },
  unmounted() {
    clearInterval(this.timerId);
  },
  created() {
      // https://dev.to/viniciuskneves/watch-for-vuex-state-changes-2mgj
      this.unsubscribe = this.$store.subscribe((mutation, state) => {
          //console.log(mutation.type);
          if (mutation.type==='currentWsDirty' || mutation.type==='currentWsNotDirty') {
              setTimeout(() => {
                this.currentWs = this.$store.getters.getCurrentWs;
                this.wsName = this.currentWs.name;
              }, 50); //reactive is too fast, so wait until reloading current workspace data :)
          }
      });
  },
  mounted() {

    /*let _autoSave = localStorage.getItem('lab22-reporting-auto-save-ws');

    if(_autoSave==null) {
      this.autoSave = false;
      localStorage.setItem('lab22-reporting-auto-save-ws','0');
    } 
    else
      this.autoSave = (_autoSave=='1') ? true : false*/

    this.fetchEtlStatus();

    if( this.store.state.Reporting.workspaces.length>0 ) { //we already have state
      this.wsName = this.currentWs.name;
      this.updateView(this.currentWs);
    }
    else //init new state
      this.load();

    let _this = this;
    _this.timerId = setInterval(function() {
      _this.fetchEtlStatus();
    }, 60 * 1000);
  },
  computed: {
    /*clsAutoSave() {
      return (this.autoSave) ? 'fa fa-check-square' : 'fa fa-dummy-lab22';
    },*/
    clsSaveDirty() {
      return (this.isWsDirty()) ? 'btn btn-save-dirty' : 'btn btn-outline-secondary';
    },
  },
  methods: {
    
    //#region Main
    fetchEtlStatus() {
      this.store.dispatch('fetchEtlStatus', {customerId:-1, dateFrom:'2000-01-01', dateTo:'2999-12-31', table:'ETL_DB_INFO' });
    },
    load() {
      let _this = this;
      
      const progress = _this.$progress.start();

      Promise.all([
          _this.$app.$api.get('Reporting/GetWorkspaceList', { params: {} }),
          _this.$app.$api.get('Reporting/GetReportList', { params: { ownerId:_this.user.id, modelId:null, isDeleted:0 } }),
          _this.$app.$api.get('Reporting/GetModelList', { params: {} })
        ]).then(
          results => {
            let userWorkspaces = results[0].data,
                allReports = results[1].data,
                userReports = [],
                userModels = results[2].data;
  
            _this.reinitModels(userModels); //commit models to vuex

            //remove user reports if user lost privileges to a model
            for(let i=0; i<userModels.length; i++) {
              for(let j=0; j<allReports.length; j++) {
                if(allReports[j].modelId==userModels[i].id)
                  userReports.push(allReports[j]);
              }
            }
            
            _this.reinitReports(userReports); //commit reports to vuex

            if( userWorkspaces.length>0 ) { //user has saved workspace(s)
              progress.finish();

              _this.reinitWorkspaces(userWorkspaces); //commit workspaces to vuex

              for(let i=0; i<userWorkspaces.length; i++) {
                if(i==0) {
                  _this.currentWs = userWorkspaces[i]; //1st workspace is currently active workspace (order by in db procedure makes sure of that)
                  _this.store.state.Reporting.workspaces[i] = { ..._this.store.state.Reporting.workspaces[i], isCurrent: 1 };
                } else
                  _this.store.state.Reporting.workspaces[i] = { ..._this.store.state.Reporting.workspaces[i], isCurrent: 0 };
              }

              _this.wsName = _this.currentWs.name; //current workspace name

              _this.updateView(_this.currentWs);
            }
            else { //user does not have saved workspaces
              progress.finish();

              _this.newWs(false);
            }
          },
          error => {
            progress.finish();
            _app.handleError(error);
            this.emptyState();
          }
        );
    },
    updateView(ws) { //build tabs/reports for current workspace
      let _this = this,
          _json = JSON.parse(ws.stateJson);
  
      _this.tabs = _json.tabs;
      
      setTimeout(() => {
        let _activeTabId = null;
        for(let i=0; i<_this.tabs.length; i++) {
          if(_this.tabs[i].isActive==1) {
            _activeTabId = _this.tabs[i].tabId;
            _this.activateTab(_activeTabId, true);
          }
        }
      }, 50);
    },
    activateTab(aId, force) { //activate ReportView component
      let _a = document.getElementById('rep-tab-'+aId);
      
      if(_a.classList.contains("active") && !force)
        return false;

      this.activeTabId = aId;

      let _this = this,
          _tabs = document.querySelectorAll("ul.nav-tabs > li > a");

      //inactivate all tabs
      Object.keys(_tabs).map((item)=> {
                  _tabs[item].classList.remove("active");
              });
      
      //activate clicked tab
      _a.classList.add("active");

      _this.report = _this.tabs.filter(obj => {
                        return obj.tabId==aId;
                      })[0];
      
      //change isActive param on all tabs
      let arg = {"tabId":aId, "ws":this.currentWs, "init":this.firstTimeTabActivate};
      _this.store.commit('activateWsReport', arg);
      this.firstTimeTabActivate = false;

      let ulList = this.$refs.ulTabs;
      _this.ulHeight = ulList.offsetHeight;

      _this.tabChangeCounter++; //this changes the ":key" of ReportView component and forces it to reload
    },
    //#endregion

    //#region Actions
    loadWs(id, shouldCheck) { //user changed to new workspace
      if(id==this.currentWs.id) //no change detected
        return false;
      
      let _this = this,
          _newWs = _this.store.getters.getWorkspaces.filter(obj => {
                    return obj.id==id;
                  })[0];

      if(_this.isWsDirty()) {
        _app.$modal.confirm('Change workspace', `Current workspace has been changed. Are you sure you want to change current workspace before saving your workspace?`, (confirmed) => {
        
          if (!confirmed) 
              return;
          
          finishChange(_newWs);
        });
      }
      else {
        if(shouldCheck) {
          _app.$modal.confirm('Change workspace', 'When switching/closing a workspace some of the reports may not yet be saved. Such reports will be included in workspace as-is, but they will not be saved to database as reports individually. Do you wish to continue, anyway?', (confirmed) => {
            if (!confirmed) 
                return;
            
            finishChange(_newWs);
          });
        }
        else
          finishChange(_newWs);
      }

      function finishChange(ws) {
        _this.firstTimeTabActivate = true;
        _this.currentWs = ws;
        _this.toggleCurrentWs(_this.currentWs);
        _this.wsName = _this.currentWs.name;
        _this.updateView(_this.currentWs);
      }
    },
    addBlankReport() {
      let _num = this.getNewTabId(),
          _name = '*New report '+_num.toString(),
          _icon = '';

      let newTab = {tabId:_num, icon:_icon, name:_name, reportId:(-1)*_num, querySql:'', queryJson:'', queryFilters:'', isActive:0};
      this.tabs.push(newTab);

      let arg = {"tab":newTab, "ws":this.currentWs};
      this.store.commit('addWsReport', arg);

      setTimeout(() => {
        this.activateTab(_num, true);
      }, 50);
    },
    addDuplicatedReport(aId) {
      let tab = this.tabs.filter(obj => {
                        return obj.tabId==aId.split('-')[2];
                    })[0],
          _num = this.getNewTabId();
            
      let newTab = {
          tabId:        _num, 
          icon:         tab.icon, 
          name:         '*Copy '+tab.name, 
          reportId:     (-1)*_num, 
          querySql:     tab.querySql, 
          queryJson:    tab.queryJson,
          queryFilters: tab.queryFilters, 
          isActive:     0
        };
      this.tabs.push(newTab);

      let arg = {"tab":newTab, "ws":this.currentWs};
      this.store.commit('addWsReport', arg);

      setTimeout(() => {
        this.activateTab(_num, true);
      }, 50);
    },
    addExistingReport(report) {
      let _num = this.getNewTabId(),
          _model = this.store.state.Reporting.models.filter(obj => {
                        return obj.id==report.modelId;
                    })[0],
          _icon = _model.icon_cls || '';
      
      let newTab = {
          tabId:        _num, 
          icon:         _icon, 
          name:         '*Copy '+report.name, 
          reportId:     report.id, 
          querySql:     '', 
          queryJson:    JSON.parse(report.queryJson),
          queryFilters: '', 
          isActive:     0
        };
      this.tabs.push(newTab);

      let arg = {"tab":newTab, "ws":this.currentWs};
      this.store.commit('addWsReport', arg);

      setTimeout(() => {
        this.activateTab(_num, true);
      }, 50);
    },
    changeTabInfo(id, name, icon) {
      let _this = this;
      for(let i=0; i<this.tabs.length; i++) {
        if(this.tabs[i].tabId==id) {
          this.tabs[i].name = name;
          this.tabs[i].icon = icon;
        }
      }
    },
    closeReport(id) {
      let _href = document.getElementById(id),
          _isActive = (_href.classList.contains("active")) ? true : false;

      _app.$modal.confirm('Close report', `Are you sure you want to close this report? Unsaved data may be lost. Do you wish to continue?`, (confirmed) => {
        
        if (!confirmed) 
            return;

        this.tabs = this.tabs.filter(obj => {
                      return ("rep-tab-"+obj.tabId===id) ? false : true;
                    });
        
        //activate 1st tab if we closed active tab
        setTimeout(() => {
          if(_isActive) {
            let _hrefs = document.getElementsByClassName('nav-link-tab-href');
              _hrefs[0].click();
          }

          let arg = {"tabs":this.tabs, "ws":this.currentWs};
          this.store.commit('updateWsStateJson', arg);
        }, 50);
      });
    },
    closeReports() {
      _app.$modal.confirm('Close reports', `Are you sure you want to close all reports? Only currently active report will remain open. Do you wish to continue?`, (confirmed) => {
        
        if (!confirmed) 
            return;

        this.tabs = this.tabs.filter(obj => {
                      let _href = document.getElementById("rep-tab-"+obj.tabId);
                      return (_href.classList.contains("active")) ? true : false;
                    });

        let arg = {"tabs":this.tabs, "ws":this.currentWs};
        this.store.commit('updateWsStateJson', arg);
      });
    },
    /*shareWs(ws) {
      console.log('TODO share Workspace');
    },*/
    moveTab(tabId, newPos, dir) {
      let _this = this,
          tabIdNum = parseInt(tabId.split('-')[2]),
          ulElement = _this.$refs.ulTabs,
          liElements = ulElement.querySelectorAll("li.nav-item-reactive"), //all tabs, except the "add" (+) tab
          liLength = liElements.length,
          liIdx = -1;

      //find index of workable tab
      for(let i=0; i<liLength; i++) {
        let liId = parseInt(liElements[i].id.split('-')[2]);
        if( liId==tabIdNum)
          liIdx = i;
      }

      if(liIdx>=0) {

        if(newPos>=0) {
          if(newPos==0) { //move to beginning
            if(liIdx > 0) {
              let el = Array.prototype.slice.call( liElements, liIdx, liIdx+1);
              ulElement.insertBefore(el.shift(), ulElement.children[0]);
            }
          } else { //move to end
            if(liIdx < (liLength-1)) {
              let el = Array.prototype.slice.call( liElements, liIdx, liIdx+1);
              ulElement.appendChild(el.shift());
            }
          }
        } else {
          if(dir=='left') { //move left
            if(liIdx > 0) {
              let el = Array.prototype.slice.call( liElements, liIdx, liIdx+1);
              ulElement.insertBefore(el.shift(), ulElement.children[liIdx-1]);
            }
          } else { //move right
            if(liIdx < (liLength-1)) { 
              let el = Array.prototype.slice.call( liElements, liIdx, liIdx+1);
              ulElement.insertBefore(el.shift(), ulElement.children[liIdx+2]);
            }
          }
        }

        //if tab is moved to the right or to the end of the list, we need to reposition the '+' button to the end
        if( (dir=='right' || newPos>0) ) {
          //setTimeout(() => {
            let newUlElement = _this.$refs.ulTabs,
                allLiElements = newUlElement.querySelectorAll("li"),
                allLiLength = allLiElements.length,
                addIdx = -1;

            //find index of "add" (+) tab
            for(let i=0; i<allLiLength; i++) {
              let liId = parseInt(allLiElements[i].id.split('-')[2]);
              if( liId==9999999999)
                addIdx = i;
            }
            
            if(addIdx>=0) {
              let addEl = Array.prototype.slice.call( allLiElements, addIdx, (addIdx+1));
              newUlElement.appendChild(addEl.shift());
            }
          //}, 100);
        }

        //change this.tabs to represent correct order
        let members = _this.$refs.ulTabs.querySelectorAll("li.nav-item-reactive");
        for(let i=0; i<members.length; i++) {
          let idMember = members[i].id.split('-')[2];
          for(let j=0; j<_this.tabs.length; j++) {
            if(idMember==_this.tabs[j].tabId) {
              const element = _this.tabs.splice(j,1)[0];
              _this.tabs.splice(i, 0, element);
            }
          }
        }
        
        let arg = {"tabs":_this.tabs, "ws":_this.currentWs};
        _this.store.commit('updateWsStateJson', arg);
      }
    },
    /*toggleAutoSaveWs() {
      this.autoSave = !this.autoSave;
      let val = (this.autoSave) ? '1' : '0';
      localStorage.removeItem('lab22-reporting-auto-save-ws');
      localStorage.setItem('lab22-reporting-auto-save-ws', val);
    },*/
    setWsAsDefault(ws) {
      this.store.commit('toggleDefaultWs', ws);
    },
    async saveWs(ws, notify) {
      /*if(!this.isWsDirty())
        return false;*/

      let oldId = ws.id;

      const progress = this.$progress.start();

      try {
        const res = await this.store.dispatch('setWorkspace', {ws:ws});
        
        if(res && res.id>0) {
          //reload current workspace data
          const res2 = await this.store.dispatch('fetchWorkspace', {id:res.id});

          progress.finish();
          
          if(notify) {
            if(oldId==null)
              _app.$helper.notifyInfo('Workspace '+ws.name+' created');
            else
              _app.$helper.notifyInfo('Workspace '+ws.name+' saved');
          }
          
          this.currentWs = this.$store.getters.getCurrentWs;

          this.isReportViewWorking = false;
          this.firstTimeTabActivate = true;
          //this.load();
          //return true;
          this.loadWs(res.id, false);

        } else {
          progress.finish();
          _app.$helper.notifyError('Unknown error in saving workspace! Please, try again.');
          return false;
        }
      }
      catch(err) {
        progress.finish();
        _app.handleError(err);
        _app.$helper.notifyError(`Error in saving workspace => ${err.message} Please, try again.`);
        return false;
      }
    },
    createWs(ws) {
      if( ws.id !== undefined ) {
        let _wsList = this.$store.state.Reporting.workspaces.filter(obj => {
                          return obj.id==null;
                      });
        
        if(_wsList.length>0) {
          _app.$helper.notifyInfo(`
              Currently, there is already a workspace with nullable id in your collection. 
              You can open and save it or delete it before a new pending workspace can be created.
            `);
          return false;
        }

        let _this = this;

        this.$vfm.show({
            component: ReportingNameModal,
            bind: {
              title: 'Save as... workspace',
              oldName: ws.name,
              action: 'saveAsWs'
            },
            on: {
                ok: (data) => {
                  _this.isReportViewWorking = true;

                  //create workspace and save it, then reload view
                  let _newWs = {
                    id:         null,
                    name:       data.name,
                    isCurrent:  1,
                    isPending:  false,
                    isDefault:  data.isDefault,
                    created:    null,
                    updated:    null,
                    deleted:    null,
                    userId:     _this.currentWs.userId,
                    stateJson:  _this.currentWs.stateJson
                  };
                  
                  _this.store.commit('insertWorkspace', _newWs);
                  
                  _this.saveWs(_newWs, true);
                }
            }
        });
      }
      else {
        this.newWs(true);
      }
    },
    renameWs(ws) {
      this.$vfm.show({
          component: ReportingNameModal,
          bind: {
            title: 'Rename workspace',
            oldName: ws.name,
            action: 'renameWs'
          },
          on: {
              ok: (data) => {
                  let payload = {ws:ws, name:data.name};
                  this.store.commit('renameWs', payload);
              }
          }
      });
    },
    newWs(confirm) {

      if(confirm) {
        _app.$modal.confirm('New workspace', `Are you sure you want to close current workspace and create a new one?
                                              Any unsaved changes will be lost. If you have a pending workspace open it will be overwritten?
                                              Continue?`, (confirmed) => {
          if (!confirmed) 
              return;

          keepWorking(this,false);

        });
      } 
      else
        keepWorking(this,true);

      function keepWorking(_scope, isFirst) {
        _scope.isReportViewWorking = true;

        //create pending workspace and save it, then reload view
        const num = _scope.getNewTabId(),
              dt = new Date(),
              dtStr = _app.$helper.formatDateToDb(dt),
              dtStrCompact = _app.$helper.formatDateToDbCompact(dt);

        let _newWs = _scope.getNewWsDto(num, dtStr, dtStrCompact, isFirst);
        _scope.store.commit('insertWorkspace', _newWs);

        _scope.saveWs(_newWs, true);
      }
    },
    deleteWs(ws) {
      let _this = this,
          _wsId = ws.id,
          _msg = 'Are you sure you want to delete selected workspace?';

      if( this.$store.getters.getWorkspaces.length==1 )
        _msg += ' ' + ws.name + ' is your only workspace. If you delete it, the system will automatically create new workspace for you. Do you want to continue?'

      _app.$modal.confirm('Delete workspace', _msg, (confirmed) => {
        
        if (!confirmed) 
            return;
        
        const progress = _this.$progress.start();
        
        this.$app.$api.get('Reporting/DelWorkspace', { params: { id: _wsId } }).then(
          () => {
            progress.finish();
            _this.emptyState(); //reinit Vuex
            _this.load(); //let's reload the view
          },
          () => {
            progress.finish();
          }
        );
      });
    },
    //#endregion

    //#region ReportViewEmits
    reportChange(report) {
      let payload = {"report":report, "ws":this.currentWs, cb:{"tabId":null,"tabName":null,"tabIcon":null}};
      this.store.commit('updateWsReport', payload);
      //setTimeout(() => {
        if(payload.cb.tabId!==null)
          this.changeTabInfo(payload.cb.tabId, payload.cb.tabName, payload.cb.tabIcon);
      //}, 200);
    },
    isReportInProgress() {
      this.isReportViewWorking = (this.isReportViewWorking==null) ? true : !this.isReportViewWorking;
      let aElements = this.$refs.ulTabs.querySelectorAll("li > a"),
          _this = this;
      for(let i=0; i<aElements.length; i++) {
        aElements[i].classList.remove("a-disabled");
        if(_this.isReportViewWorking)
          aElements[i].classList.add("a-disabled");
      }
    },
    //#endregion

    //#region VuexController
    toggleCurrentWs(ws) {
      this.store.commit('toggleCurrentWs', ws);
    },
    isWsDirty() {
      return this.$store.getters.isWsDirty;
    },
    emptyState() {
      this.reinitWorkspaces([]);
      this.reinitReports([]);
    },
    reinitWorkspaces(workspaces) {
      this.store.commit('reinitWorkspaces', workspaces);
    },
    reinitModels(models) {
      this.store.commit('reinitModels', models);
    },
    reinitReports(reports) {
      this.store.commit('reinitReports', reports);
    },
    //#endregion

    //#region Helpers
    getNewWsDto(num, dtStr, dtStrCompact, _isDefault) {
      let _name = '*New report '+num.toString(),
          _json = JSON.parse(`
              {
                "ts": "${dtStr}",
                "tabs": [
                  {
                    "tabId": ${num},
                    "icon": "",
                    "name": "${_name}",
                    "reportId": -1,
                    "querySql": "",
                    "queryJson": "",
                    "queryFilters": "",
                    "isActive": 1
                  }
                ]
              }
            `);

      this.tabs = [{tabId:num, icon:'', name:_name, reportId:(-1)*num, querySql:'', queryJson:'', queryFilters:'', isActive:1}];

      return {
                id: null,
                name: 'New workspace '+dtStrCompact.substring(8),
                isDefault: _isDefault,
                stateJson: JSON.stringify(_json),
                isPending: true,
                userId: this.user.id,
                created: null,
                updated: null,
                deleted: null,
                isCurrent: 1
              };
    },
    addReportOptions(item, e) {
      let menuItems = [];

      menuItems.push({
        text: 'New (blank) report', 
        iconCls: 'fa-solid fa-file', 
        handler: (ctx) => {
          this.addBlankReport(false);
        }
      });

      menuItems.push({
        text: 'Open (saved) report', 
        iconCls: 'fa-solid fa-file-alt', 
        handler: (ctx) => {
          this.showReportsModal();
        }
      });

      this.$refs.repTabContextMenu.open(menuItems, e, item);

    },
    showReportsModal() {
      let allReports = this.store.state.Reporting.reports;
      if(allReports.length==0) {
        _app.$helper.notifyInfo(`Currently, you don't have any saved reports.`);
        return false;
      }

      let models = this.store.state.Reporting.models;

      for(let i=0; i<models.length; i++) {
        let modelReports = this.store.state.Reporting.reports.filter(obj => {
                          return obj.modelId==models[i].id;
                      });
        models[i] = {
          ...models[i],
          reports: modelReports
        }
      }
      
      this.$vfm.show({
          component: ReportingReports,
          bind: {
              'title': 'User reports',
              'Tabs': this.tabs,
              'Entities': models,
              'autoRefresh': this.autoRefresh
          },
          on: {
              ok: (reportId) => {
                let report = this.store.state.Reporting.reports.filter(obj => {
                          return obj.id==reportId;
                      })[0];

                this.addExistingReport(report);
              },
          }
      });
    },
    isWsDefault() {
      if(this.currentWs) {
        let _isDefault = (this.currentWs.isDefault) ? true : false;
        if( _app.$helper.isEmpty(_isDefault, false) )
          return true;
        else
          return _isDefault;
      } else
        return false;
    },
    getModelCode(modelId) {
      return this.$store.getters.getModelCodeById(modelId);
    },
    objMembersCount(obj, min) {
      if( !_app.$helper.isEmpty(obj, false) )
        return (obj.length>min) ? true : false;
      else
        return false;
    },
    formatUtcDate(date) { //convert db time to utc
        let format = 'DD.MM.YY HH:mm:ss',
            d = dayjs(date);
            d = d.add(d.utcOffset(), "minute");
        return d.format(format);
    },
    getNewTabId() {
      let num = 1,
          tabs = this.tabs;

      if(tabs) {
        for(let i=0; i<tabs.length; i++) {
          if(tabs[i].tabId>=num)
            num = tabs[i].tabId + 1;
        }
      }

      return num;
    },
    onTabRightClick(item, e) {
      let _href = document.getElementById(e.target.id),
          _isActive = (_href.classList.contains("active")) ? true : false;

      let menuItems = [];

      if(_isActive) {
        menuItems.push({
          text: 'Duplicate report', 
          iconCls: 'fa-solid fa-copy', 
          handler: (ctx) => {
            this.addDuplicatedReport(e.target.id);
          }
        });
      }

      menuItems.push({
        text: 'Move to beginning', 
        iconCls: '', 
        handler: (ctx) => {
          this.moveTab(e.target.id,0,null);
        }
      });

      menuItems.push({
        text: 'Move left', 
        iconCls: '', 
        handler: (ctx) => {
          this.moveTab(e.target.id,-1,'left');
        }
      });

      menuItems.push({
        text: 'Move right', 
        iconCls: '', 
        handler: (ctx) => {
          this.moveTab(e.target.id,-1,'right');
        }
      });

      menuItems.push({
        text: 'Move to end', 
        iconCls: '', 
        handler: (ctx) => {
          this.moveTab(e.target.id,this.tabs.length, null);
        }
      });

      if(this.tabs.length>1) {
        menuItems.push({
          text: 'Close report', 
          iconCls: 'fa-solid fa-times', 
          handler: (ctx) => {
            this.closeReport(e.target.id);
          }
        });

        if(_isActive) {
          menuItems.push({
            text: 'Close all but this', 
            iconCls: '', 
            handler: (ctx) => {
              this.closeReports();
            }
          });
        }
      }

      this.$refs.repTabContextMenu.open(menuItems, e, item);
    },
    onTabRightClickReject(e) {
      return false;
    },
    shortenName(str, max) {
      if(str.length>max)
        return str.substring(0,max)+'...';
      else
        return str;
    },
    showVideo(name, src) {
      let offcanvasCloseBtn = document.getElementById('bz-offcanvas-help-close');
          offcanvasCloseBtn.click();

      this.$vfm.show({
          component: ReportingVideoModal,
          bind: {
            title: 'Reporting help - ' + name,
            videoSrc: src
          }
      });
    },
    triggerHref(ahref) {
      let link = document.getElementById(ahref);
      link.click();
    },
    //#endregion

  } 
}
</script>

<style scoped>
.help-link {
  color:#1485CC;
  cursor: pointer;
}
.dropdown-menu,
.offcanvas {
  z-index: 10001 !important;
}

.tabs {
  margin-top:20px;
}

.etl-status {
  width: 200px;
  margin-top: -12px;
  color: #ffffff !important;
}

.etl-status label {
  color: #bbc5d6 !important;
  width: 75px;
}

.tab-content-report {
  background-color: #161616 !important;
  border: 0 !important;
}

/*li.nav-item {
  margin-right:-2px;
}*/

li.nav-item > a {
  font-size:0.9em;
  /*overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 180px;*/
  max-width: 180px;
}

.btn-save-dirty {
  background-color: #FF19FF !important;
  border-color: #FF19FF !important;
}

.btn-save-dirty > i {
  color: #ffffff !important;
}

.btn-borderless {
  border: 0px !important;
  /*font-size: 0.8rem !important;*/
}

.btn-primary:active, .btn-secondary:active {
    box-shadow: none !important;
}

html.dark .nav-tabs li .nav-link {
    border-bottom: 1px solid #666666 !important;
}

html.dark .dropdown-divider {
  border-top: solid 1px #666666 !important;
}

.help-dark {
  color: #ffffff;
  background-color: #262626;
}
</style>

<style scoped>
.a-disabled {
  pointer-events:none;
}
</style>