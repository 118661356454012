<template>

<Form @submit="save" ref="form" :validation-schema="schema" class="form-horizontal form-bordered">
  <base-modal :name="modalName" :title="getTitle()" v-bind="$attrs" :width="350" @close="close">
    <template #body>
      <!-- <div class="mb-2">  
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" @change="onTypeChange" name="type" v-model="type" id="generalRadio" :value="eType.General" :disabled="isEditMode()">
          <label class="form-check-label" for="generalRadio">General</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" @change="onTypeChange" name="type" v-model="type" id="deviceRadio" :value="eType.Device" :disabled="isEditMode()">
          <label class="form-check-label" for="deviceRadio">Device specific</label>
        </div>
      </div>    -->
      <FormField v-if="type == eType.General" name="modelId" v-model="modelId" @change="onDeviceModelChange" label="Device model" placeholder="Device model" :topLabel="true" as="select" :data="this.$app.$data.deviceModelLkp" :disabled="isEditMode()" />
      <FormField v-if="type == eType.Device" name="deviceId" v-model="deviceId"  @change="onDeviceChange" label="Device" placeholder="Device" :topLabel="true" as="select" :data="this.$app.$data.devices" :disabled="isEditMode()" />
      <FormField name="eventTypeFieldId" v-model="eventTypeFieldId" @change="onFieldChange" label="Field" :topLabel="true" as="select" :data="this.fieldList" :disabled="isEditMode()" />
      <div class="form-group d-flex">
        <div style="width:60px" class="me-3">
          <FormField ref="operator" name="operatorId" v-model="operatorId" label="Operator" placeholder="Operator" :topLabel="true" as="select" :data="this.operatorList" />
        </div>
        <div class="flex-grow-1">
          <FormField v-if="!isTextValue" name="valueDec" type="number" v-model="valueDec" label="Value" placeholder="Value" :topLabel="true" />
          <FormField v-if="isTextValue" name="valueText" v-model="valueText" label="Value" placeholder="Value" :topLabel="true" />
        </div>
      </div>  
      <div class="form-check mt-4">
      <input class="form-check-input" type="checkbox" v-model="sendNotification" id="sendNotification">
      <label class="form-check-label" for="sendNotification">
        Send notification
      </label>
    </div>
    </template>
    <template #footer>
      <button @click="close" type="button" class="btn btn-default" data-dismiss="modal">Cancel</button>
      <button type="submit" class="btn btn-primary">Ok</button>
    </template>
  </base-modal>
</Form>

</template>

<script>

import BaseModal from '@/App/components/Modal/BaseModal'
import FormField from '@/App/components/Form/FormField';
import { Form } from "vee-validate";

import _app from '@/App/App'

const Type = {
  General: 'GENERAL',
  Device: 'DEVICE'
}

const ActionType = {
  SendNotification: 1,
  NoAction: 2
}

export default {
  name: 'ConditionModal',
  props: {
    editRec: Object,
    initialData: Object,
  },
  inheritAttrs: false,
  emits: ['ok'],
  components: {
    BaseModal,
    FormField,
    Form
  },   
  data() {
    return {
      modalName: 'ConditionModal',      
      schema: {        
        eventTypeFieldId: 'required',
        operatorId: 'required'
      },
      eType: Type,
      type: null,
      deviceId: null,
      modelId: null,
      eventTypeFieldId: null,
      operatorId: null,
      valueDec: null,
      valueText: null,
      periodId: 1,
      sendNotification: null,
      fieldList: [],
      fieldDic: {},
      operatorList: [],
      periodList: [],
      actionTypeList: [],
      loadingFields: false,
      isTextValue: false    
    };
  },
  mounted() {
    if (this.editRec) {
      this.setFieldList([{ id: this.editRec.eventTypeFieldId, name: this.editRec.fieldName, attTypeId: this.editRec.attTypeId}]);
      this.operatorList = [{ id: this.editRec.operatorId, code: this.editRec.operatorCode, name: this.editRec.operatorName }]
      this.type = this.editRec.type;
      this.modelId = this.editRec.modelId;      
      this.deviceId = this.editRec.deviceId;
      this.eventTypeFieldId = this.editRec.eventTypeFieldId;
      this.operatorId = this.editRec.operatorId;
      this.valueDec = this.editRec.valueDec;
      this.valueText = this.editRec.valueText;
      this.periodId = this.editRec.periodId
      this.sendNotification = this.editRec.actionTypeId == ActionType.SendNotification;
    } else if (this.initialData) {
      this.type = this.initialData.type;
      this.modelId = this.initialData.modelId;
      this.deviceId = this.initialData.deviceId;
      this.sendNotification = true;
    }

    this.updateView();
    this.loadFields();    
  },
  methods: {
    isEditMode() {
      return !_app.isEmpty(this.editRec);
    },
    getTitle() {
      return this.isEditMode() ? 'Edit condition' : 'Add condition';
    },
    onTypeChange() {
      this.updateView();
    },
    onDeviceModelChange() {
      this.loadFields();
    },
    onDeviceChange() {
      this.loadFields();
    },
    onFieldChange() {
      this.loadOperators();
      this.updateView();
    },
    loadOperators() {           
      if (this.eventTypeFieldId) {
        const attTypeId = this.fieldDic[this.eventTypeFieldId].attTypeId;    
        _app.$api.get('Alert/GetOperatorList', { params: { attTypeId: attTypeId }}).then(
          (result) => {
            this.operatorList = result.data;
          },
          (error) => {
            _app.handleError(error);
          }
        );
      } else {
        this.operatorList = [];
      }
    },
    setFieldList(data) {
      this.fieldList = data || [];
      this.fieldDic = Object.assign({}, ...this.fieldList.map((i) => ({[i.id]: i})));
    },
    updateView() {
      this.isTextValue = this.eventTypeFieldId && this.fieldDic[this.eventTypeFieldId]?.attTypeId != _app.$data.AttType.Number;
      if (!this.isTextValue) {
        this.schema.valueDec = 'required';        
      } else if (this.schema.valueDec) {
        delete (this.schema.valueDec);
      }
      if (this.type == Type.Device) {
        this.schema.deviceId = 'required';        
      } else if (this.schema.deviceId) {
        delete (this.schema.deviceId);
      }
    },
    loadFields() {
      
      if (this.type == Type.Device && this.deviceId) {
        
        this.loadingFields = true;

        const apiFn = 'EventType/GetDeviceEtFieldList';
        //const apiFn = 'EventType/GetDeviceEtFieldListV2';
        const apiFnParams = { deviceId: this.deviceId };
        
        _app.$api.get(apiFn, { params: apiFnParams }).then(result => {

          this.loadingFields = false;

          let list = result.data.filter(obj => {
                        return obj.dbField!=='e_fan1_speed_target_ratio' && obj.dbField!=='e_fan2_speed_target_ratio' 
                              && obj.dbField!=='e_fan3_speed_target_ratio' && obj.dbField!=='e_fan4_speed_target_ratio'
                              //bz (31.7.2023): Asana https://app.asana.com/0/1203118346088051/1204870516131788/f
                              && obj.dbField!=='d_out_of_order_status' && obj.dbField!=='d_touch_screen_status' 
                              && obj.dbField!=='d2_out_of_order_status' && obj.dbField!=='d3_out_of_order_status' 
                              && obj.dbField!=='d_source_search_status';
                      });
          this.setFieldList(list);

          if (this.eventTypeFieldId && !this.fieldDic[this.eventTypeFieldId])
            this.eventTypeFieldId = null;

          this.loadOperators();
          this.updateView();
        }, (error) => {
          this.loadingFields = false;
          _app.handleError(error);
        });
      } else {
        this.fieldList = [];
      }
    },
    save() {  

      var data = this.isEditMode() ? Object.assign({}, this.editRec) : {};

      data.eventTypeFieldId = this.eventTypeFieldId;
      data.periodId = this.periodId;
      data.operatorId = this.operatorId;
      data.actionTypeId = this.sendNotification ? ActionType.SendNotification : ActionType.NoAction;
      if (this.isTextValue) {
        data.valueText = this.valueText;
      } else {
        data.valueDec = parseFloat(this.valueDec);
      }      
      if (!this.isEditMode()) {
        data.deviceId = this.deviceId;
      }

      const loader = this.$loading.show(); 

      this.$app.$api.post('Alert/SetAlertCondition', data).then(
        result => {
          loader.hide();

          this.$emit('ok', result.data);

          this.$vfm.hide(this.modalName);
        },
        () => {
          loader.hide();      
        }
      );
    },
    close() {
      this.$vfm.hide(this.modalName);
    }
  }
}
</script>

<style scoped>
</style>