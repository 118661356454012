<template>
  <div v-bind="$attrs" class="form-group row pb-3">
    <div class="col-sm-2"></div>
    <div class="col-sm-8">
      <slot></slot>
    </div>      
  </div>
</template>

<script>
  export default {
    name: 'FormFooter',
    inheritAttrs: false,
    props: {

    }    
  }
</script>
