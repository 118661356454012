<template>
    <section class="card mb-4">
        <!-- our widget can hold KPI info (passed with "bigNumber" prop) or any other content -->
        <div v-if="bigNumber>=0" class="card-body bg-quaternary lab-widget-card" style="background-color:#262626 !important;">
            <div class="widget-summary">
                <div class="widget-summary-col">
                    <div class="summary text-center" style="color:#fff; margin-top:30px;">
                        <span :class="classList" style="font-size:100px;">{{ bigNumber }}</span><span v-if="smallNumber">/ {{ smallNumber }}</span>
                    </div>
                    <div v-if="showFooter" class="summary-footer text-center">
                        <a>{{ footerText }}</a>
                    </div>
                </div>
            </div>
        </div>
        <!-- else -->
        <div v-else class="card-body lab-card-body bg-quaternary lab-scroll" style="background-color:#262626 !important;">
            <div class="widget-summary">
                <div class="widget-summary-col">
                    <slot name="dashContent"></slot>
                </div>
            </div>
        </div>
        <!-- end -->
    </section>
</template>

<script>
export default {
    name: 'DashboardKpiWidget',
    props: {
      showFooter: Boolean,
      footerText: String,
      bigNumber: Number,
      bigNumberColor: String,
      smallNumber: Number,
      classList: String
    },
    data() {
        return {};
    },
    mounted() {
        
    }
}
</script>

<style scoped>
.green1 {
    color: #12B39A;
}
.green2 {
    color: #00FFD7
}
.yellow {
    color: #ffcc19;
}
.purple {
    color: #ff19ff
}

.lab-card-body {
    /*padding-bottom: 0px !important;*/
    min-height: 260px !important;
}

.lab-scroll {
  overflow-y: scroll !important;
  overflow-x: hidden !important;
}

.card-body {
    cursor: pointer !important;
    border-radius: 0.01rem !important;
}
</style>