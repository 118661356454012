import _app from '@/App/App'
import dayjs from 'dayjs';
//import Vue from 'vue'
import { createStore } from 'vuex'
import { auth } from "./auth.module";

const _helper = {

  formatUtcDate: function (date) {
    let format = 'DD.MM.YY HH:mm:ss',
        d = dayjs(date);
        d = d.add(d.utcOffset(), "minute");
    return d.format(format);  
  },

  getNewJsonState: function(tabs) {
    let dt = new Date(),
        dtStr = _app.$helper.formatDateToDb(dt);
        
    return JSON.parse(`
        {
          "ts": "${dtStr}",
          "tabs": ${JSON.stringify(tabs)}
        }
      `);
  },

  findWsIndexById: function(id, ws) {
    let ret;

    if(id!==null)
      ret = ws.findIndex(x => x.id == id);
    else { //find idx where ws.id==null
      for(let i=0; i<ws.length; i++) {
        if(ws[i].id==null)
          ret = i;
      }
    }

    return ret;
  }

};

const store = createStore({
    state: {
      Reporting: {
          etl: {
            dbStatus: '',
            dbTimestamp: ''
          },
          workspaces: [],
          models: [],
          reports: [],
          currentWsDirty: false,
          scrollOffset: 0,
      },
    },
    mutations: {
      FETCH_ETL_STATUS (state, payload) {
        state.Reporting.etl.dbStatus = payload.dbStatus;
        state.Reporting.etl.dbTimestamp = payload.dbTimestamp;
      },
      SET_WORKSPACE_ID (state, payload) {
        let oldId = payload.oldId,
            newId = payload.newId;

        let idx = _helper.findWsIndexById(oldId, state.Reporting.workspaces);
        
        state.Reporting.workspaces[idx].id = newId;
      },
      UPDATE_WORKSPACE (state, payload) {
        let idx = state.Reporting.workspaces.findIndex(x => x.id == payload.id);
        
        state.Reporting.workspaces[idx].id = payload.id;
        state.Reporting.workspaces[idx].name = payload.name;
        state.Reporting.workspaces[idx].userId = payload.userId;
        state.Reporting.workspaces[idx].isDefault = payload.isDefault;
        state.Reporting.workspaces[idx].isPending = payload.isPending;
        state.Reporting.workspaces[idx].stateJson = payload.stateJson;
        state.Reporting.workspaces[idx].created = payload.created;
        state.Reporting.workspaces[idx].updated = payload.updated;
        state.Reporting.workspaces[idx].deleted = payload.deleted;

        this.commit("currentWsNotDirty");
      },
      INSERT_REPORT (state, report) {
        state.Reporting.reports.push(report);
      },
      UPDATE_REPORT (state, payload) {
        let idx = state.Reporting.reports.findIndex(x => x.id == payload.id);
        
        state.Reporting.reports[idx].id = payload.id;
        state.Reporting.reports[idx].userId = payload.userId;
        state.Reporting.reports[idx].modelId = payload.modelId;
        state.Reporting.reports[idx].name = payload.name;
        state.Reporting.reports[idx].guid = payload.guid;
        state.Reporting.reports[idx].queryJson = payload.queryJson;
        state.Reporting.reports[idx].created = payload.created;
        state.Reporting.reports[idx].updated = payload.updated;
        state.Reporting.reports[idx].deleted = payload.deleted;
      },
      reinitWorkspaces (state, workspaces) {
          state.Reporting.workspaces.splice(0, state.Reporting.workspaces.length);
          state.Reporting.workspaces = workspaces.filter(obj => {
                                          return obj.id==obj.id;
                                        });
          this.commit("currentWsNotDirty");
      },
      reinitReports (state, reports) {
          state.Reporting.reports.splice(0, state.Reporting.reports.length);
          state.Reporting.reports = reports.filter(obj => {
                                          return obj.id==obj.id;
                                        });
      },
      reinitModels (state, models) {
        state.Reporting.models.splice(0, state.Reporting.models.length);
        state.Reporting.models = models.filter(obj => {
                                        return obj.id==obj.id;
                                      });
      },
      insertWorkspace (state, ws) {
        state.Reporting.workspaces.push(ws);
        
        if(ws.isDefault) {
          for(let i=0; i<state.Reporting.workspaces.length; i++) {
            if(state.Reporting.workspaces[i].id==ws.id)
              state.Reporting.workspaces[i].isDefault = true; //Vue.set( state.Reporting.workspaces[idx], 'isDefault', true );
            else
              state.Reporting.workspaces[i].isDefault = false; //Vue.set( state.Reporting.workspaces[idx], 'isDefault', false );
          }
        }
      },
      toggleCurrentWs (state, ws) {
        for(let i=0; i<state.Reporting.workspaces.length; i++) {
          if(state.Reporting.workspaces[i].id==ws.id)
            state.Reporting.workspaces[i].isCurrent = 1; //Vue.set( state.Reporting.workspaces[idx], 'isCurrent', 1 );
          else
            state.Reporting.workspaces[i].isCurrent = 0; //Vue.set( state.Reporting.workspaces[idx], 'isCurrent', 0 );
        }
        this.commit("currentWsNotDirty");
      },
      toggleDefaultWs (state, ws) {
        for(let i=0; i<state.Reporting.workspaces.length; i++) {
          if(state.Reporting.workspaces[i].id==ws.id)
            state.Reporting.workspaces[i].isDefault = true; //Vue.set( state.Reporting.workspaces[idx], 'isDefault', true );
          else
            state.Reporting.workspaces[i].isDefault = false; //Vue.set( state.Reporting.workspaces[idx], 'isDefault', false );
        }

        this.commit("updateCurrentWsTimestamp", ws);
      },
      currentWsDirty (state) {
        state.Reporting.currentWsDirty = true;
      },
      currentWsNotDirty (state) {
        state.Reporting.currentWsDirty = false;
      },
      updateCurrentWsTimestamp (state, ws) {
        let //idx = 0,
            upd = new Date();
        
        /*if(ws.id==null)
          idx = state.Reporting.workspaces.findIndex(x => x.id == ws.id);*/
        let idx = _helper.findWsIndexById(ws.id, state.Reporting.workspaces);

        //Vue.set( state.Reporting.workspaces[idx], 'updated', upd );
        state.Reporting.workspaces[idx].updated = upd;

        this.commit("currentWsDirty");
      },
      activateWsReport (state, payload) {
        let tabId = payload.tabId,
            ws = payload.ws,
            init = payload.init/*,
            idx = 0*/;

        /*if(ws.id!==null)
          idx = state.Reporting.workspaces.findIndex(x => x.id == ws.id);*/
        let idx = _helper.findWsIndexById(ws.id, state.Reporting.workspaces);
        
        let tabs = JSON.parse(state.Reporting.workspaces[idx].stateJson).tabs;

        for(let i=0; i<tabs.length; i++) {
          if(tabs[i].tabId==tabId)
            tabs[i].isActive = 1;
          else
            tabs[i].isActive = 0;
        }

        let newJsonState = _helper.getNewJsonState(tabs);
        state.Reporting.workspaces[idx].stateJson = JSON.stringify(newJsonState);

        if(!init)
          this.commit("updateCurrentWsTimestamp", ws);
      },
      addWsReport (state, payload) {
        let tab = payload.tab,
            ws = payload.ws;

        /*let idx = 0;
        if(ws.id!==null)
          idx = state.Reporting.workspaces.findIndex(x => x.id == ws.id);*/
        let idx = _helper.findWsIndexById(ws.id, state.Reporting.workspaces);
        
        let tabs = JSON.parse(state.Reporting.workspaces[idx].stateJson).tabs;

        tabs.push(tab);

        let newJsonState = _helper.getNewJsonState(tabs);
        state.Reporting.workspaces[idx].stateJson = JSON.stringify(newJsonState);

        this.commit("updateCurrentWsTimestamp", ws);
      },
      updateWsReport (state, payload) {
        let report = payload.report,
            ws = payload.ws;

        /*let idx = 0;
        if(ws.id!==null)
          idx = state.Reporting.workspaces.findIndex(x => x.id == ws.id);*/
        let idx = _helper.findWsIndexById(ws.id, state.Reporting.workspaces);

        let tabs = JSON.parse(state.Reporting.workspaces[idx].stateJson).tabs,
            changed = false;

        for(let i=0; i<tabs.length; i++) {
          if(tabs[i].tabId==report.tabId) {
            if( tabs[i].querySql.replace(/\s/g, '')!==report.querySql.replace(/\s/g, '') || tabs[i].name!==report.name || tabs[i].reportId!==report.reportId ) {
              changed = true;
              //
              tabs[i].icon = report.icon; //id can change when report is initially mapped to model
              tabs[i].isActive = report.isActive;
              tabs[i].name = report.name;
              tabs[i].queryFilters = report.queryFilters;
              tabs[i].queryJson = report.queryJson;
              tabs[i].querySql = report.querySql;
              tabs[i].reportId = report.reportId; //id can change when report is initially saved to database
              //
              //out params
              payload.cb = {
                tabId: report.tabId,
                tabName: report.name,
                tabIcon: report.icon
              };
            }
          }
        }

        if(changed) {
          let newJsonState = _helper.getNewJsonState(tabs);
          state.Reporting.workspaces[idx].stateJson = JSON.stringify(newJsonState);

          this.commit("updateCurrentWsTimestamp", ws);
        }
      },
      updateWsStateJson (state, payload)  {
        let tabs = payload.tabs,
            ws = payload.ws;

        /*let idx = 0;
        if(ws.id!==null)
          idx = state.Reporting.workspaces.findIndex(x => x.id == ws.id);*/
        let idx = _helper.findWsIndexById(ws.id, state.Reporting.workspaces);

        let newJsonState = _helper.getNewJsonState(tabs);
        state.Reporting.workspaces[idx].stateJson = JSON.stringify(newJsonState);
        
        this.commit("updateCurrentWsTimestamp", ws);
      },
      updateMainScrollOffset (state, offset) {
        state.Reporting.scrollOffset = offset;
      },
      renameWs (state, payload) {
        let ws = payload.ws,
            name = payload.name/*,
            idx = 0*/;

        /*if(ws.id==null)
          idx = state.Reporting.workspaces.findIndex(x => x.id == ws.id);*/
        let idx = _helper.findWsIndexById(ws.id, state.Reporting.workspaces);

        state.Reporting.workspaces[idx].name = name;

        this.commit("updateCurrentWsTimestamp", ws);
      },
    },
    actions: {
      fetchEtlStatus({commit}, payload) { //get etl status info
        _app.$api.get('Dashboard/GetDashboardTable', { params: payload }).then(
          result => {
            const _res = result.data[0],
                  _data = {dbStatus:_res.etl_status, dbTimestamp:_helper.formatUtcDate(_res.etl_last_refresh)};
            commit("FETCH_ETL_STATUS", _data);
          },
          error => {
            _app.handleError(error);
          }
        );
      },
      setWorkspace({commit}, payload) { //insert/update workspace
        let _ws = {
              id:         payload.ws.id,
              name:       payload.ws.name,
              userId:     payload.ws.userId,
              isDefault:  payload.ws.isDefault,
              isPending:  payload.ws.isPending,
              stateJson:  payload.ws.stateJson || '',
              created:    payload.ws.created,
              updated:    payload.ws.updated,
              deleted:    payload.ws.deleted
            },
            _oldId = _ws.id;

        return _app.$api.post('Reporting/SetWorkspace', _ws).then(res => {
          if(_oldId==null) //we have a new workspace
            commit("SET_WORKSPACE_ID", {oldId:_oldId, newId:parseInt(res.data)});

          return {id:parseInt(res.data), error:''};
        }, (error) => {
          _app.handleError(error);
          return {id:0, error:error.message};
        });
      },
      fetchWorkspace({commit}, payload) { //get workspace by id
        _app.$api.get('Reporting/GetWorkspace', { params: {id:payload.id} }).then(
          result => {
            commit("UPDATE_WORKSPACE", result.data);
            return {id:parseInt(result.data.id), error:''};
          },
          error => {
            _app.handleError(error);
            return {id:0, error:error.message};
          }
        );
      },
      setReport({commit, getters, state}, payload) { //insert/update report
        let _query = payload.report,
            _guid = payload.guid,
            _report = [],
            _userId = auth.state.user.id,
            _modelId = getters.getModelIdByCode(_query.queryJson.modelId),
            _oldId = _query.reportId;

        if(_modelId && _modelId>0) {
          if(_oldId<=0) { //new report
            _report = {
              id:         null,
              userId:     _userId,
              modelId:    _modelId,
              name:       _query.name,
              guid:       _guid,
              queryJson:  JSON.stringify(_query.queryJson) || '',
              created:    null,
              updated:    null,
              deleted:    null
            };
          }
          else {
            _report = getters.getReportById(_oldId);
            _report.name = _query.name;
            _report.queryJson = JSON.stringify(_query.queryJson) || '';
          }

          return _app.$api.post('Reporting/SetReport', _report).then(res => {
            if(_oldId==null || _oldId<=0) { //we have a new report
              //add report to store
              _report.id = parseInt(res.data);
              commit("INSERT_REPORT", _report);
            }

            return {id:parseInt(res.data), error:''};
          }, (error) => {
            _app.handleError(error);
            return {id:0, error:error.message};
          });
        }
        else
          return {id:0, error:'Model Id not found. Edit query to sync it and try again.'};
      },
      fetchReport({commit}, payload) { //get report by id
        _app.$api.get('Reporting/GetReport', { params: {id:payload.id} }).then(
          result => {
            commit("UPDATE_REPORT", result.data);
            return {id:parseInt(result.data.id), error:''};
          },
          error => {
            _app.handleError(error);
            return {id:0, error:error.message};
          }
        );
      },
    },
    getters: {
        getCurrentWs(state) {
          if(state.Reporting.workspaces.length>0) {
            let idx = state.Reporting.workspaces.findIndex(x => x.isCurrent == 1)
            return state.Reporting.workspaces[idx];
          } else
            return {};
        },
        getWorkspaces(state) {
          return state.Reporting.workspaces
        },
        getReports(state) {
          return state.Reporting.reports
        },
        getReportById: (state) => (reportId) => {
          let reports = state.Reporting.reports,
              _ret = null;
          for(let i=0; i<reports.length; i++) {
            if(reports[i].id==reportId)
              _ret = reports[i];
          }
          return _ret;
        },
        getWorkspaceById: (state) => (wsId) => {
          let workspaces = state.Reporting.workspaces,
              _ret = null;
          for(let i=0; i<workspaces.length; i++) {
            if(workspaces[i].id==wsId)
              _ret = workspaces[i];
          }
          return _ret;
        },
        getModels(state) {
          return state.Reporting.models
        },
        getModelCodeById: (state) => (modelId) => {
          let _indexOf = state.Reporting.models.map((o) => o.id).indexOf(modelId.toString());
          
          if(_indexOf>=0) //model exists
              return state.Reporting.models[_indexOf].modelId;
          else
              return null;
        },
        getModelIdByCode: (state) => (modelCode) => {
          let models = state.Reporting.models,
              _ret = null;
          for(let i=0; i<models.length; i++) {
            if(models[i].model_id==modelCode)
              _ret = models[i].id;
          }
          return _ret;
        },
        isWsDirty(state) {
          return state.Reporting.currentWsDirty
        },
        getScrollOffset(state) {
          return state.Reporting.scrollOffset;
        }
    },
    modules: {
        auth
    },
});

export default store;