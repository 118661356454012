<template>
  <sidebar-menu
    ref='sideBar'
    v-model:collapsed="collapsed"
    :menu="menu"
    :width="width" 
    :theme="theme"
    @update:collapsed="onToggleCollapse"
  >
    <template v-slot:toggle-icon>
      <i class="fa-solid fa-bars"></i>
    </template>
  </sidebar-menu>
  <div v-if="isOnMobile && !collapsed" class="sidebar-overlay" @click="setCollapsed(true)" />
</template>

<script>
// import { SidebarMenu } from 'vue-sidebar-menu'
import { SidebarMenu } from '@/App/components/SideBar'
import { markRaw } from 'vue'
import _app from '@/App/App'

const separator = {
  template: '<hr style="border-color: rgba(0, 0, 0, 0.1); margin: 20px;">'
}

const DEV_MODE = _app.isDevelopment && _app.debugModeEnabled;

const _user = _app.user;

const _isLab22 = ( _user && _app.$data.Lab22Users.indexOf(_user.email) == -1 ) ? false : true;

const _isAdmin = _user && _user.isAdmin();
const _isDeviceManager = _user && _user?.idDeviceManager();
const _isAnalyticsUser = _user && _user?.isAnalyticsUser();

export default {
  name: 'AppSideBar',
  components: {
    SidebarMenu
  },
  emits: ['setCollapsed', 'setIsOnMobile'],
  data() {    
    return {
      menu: [
        {
          //header: 'DEVICES',
          header: 'CONTROL PANEL',
          hiddenOnCollapse: true,
          hidden: !_isAdmin && !_isDeviceManager && !_isAnalyticsUser
        },
        {
          href: '/overview',
          title: 'Overview',
          //icon: 'fa-solid fa-chart-line',
          icon: 'inf-sidebar-overview inf-sidebar-img',
          /*icon: {
            class: 'inf-sidebar-overview inf-sidebar-img',
            element: 'i',
          },*/
          hidden: !_isAdmin && !_isDeviceManager
        }, 
        // {
        //   href: '/overview-white',
        //   title: 'Overview 2',
        //   icon: 'fa-solid fa-chart-line',
        //   hidden: !_isAdmin && !_isDeviceManager
        // },              
        {
          href: '/devices',
          title: 'Devices',
          //icon: 'fa-solid fa-tablet-screen-button',
          icon: 'inf-sidebar-devices inf-sidebar-img',
          hidden: !_isAdmin && !_isDeviceManager
          // badge: {
          //   text: '15',
          //   class: 'vsm--badge_default'
          // }
        }, 
        {
          href: '/locations',
          title: 'Locations',
          //icon: 'fa fa-location-dot',
          icon: 'inf-sidebar-locations inf-sidebar-img',
          hidden: !_isAdmin && !_isDeviceManager
        },
        {
          href: '/alerts',
          title: 'Alerts',
          //icon: 'fa-solid fa-bell',
          icon: 'inf-sidebar-alerts inf-sidebar-img',
          hidden: !_isAdmin && !_isDeviceManager
        },
        {
          href: '/analytics',
          title: 'Analytics',
          //icon: 'fa fa-square-poll-vertical',
          icon: 'inf-sidebar-analytics inf-sidebar-img',
          hidden: !_isAdmin && !_isAnalyticsUser
        }, 
        {
          href: '/reporting',
          title: 'Reporting',
          icon: 'fa-solid fa-line-chart',
          hidden: !_isLab22
        },
        {
          href: '/analytics-prototype',
          title: 'Analytics prototype',
          icon: 'fa fa-square-poll-vertical',
          hidden: !DEV_MODE || !_isAdmin
        }, 
        {
          href: '/audit-log',
          title: 'Audit log',
          //icon: 'fa fa-list-ul',
          icon: 'inf-sidebar-auditlog inf-sidebar-img',
          hidden: !_isAdmin && !_isDeviceManager
        },                  
        {
          header: 'Administration',
          hiddenOnCollapse: true,
          hidden: !_isAdmin
        },
        {
          href: '/dashboard-lab22',
          title: 'System health',
          icon: 'fa-solid fa-heartbeat',
          hidden: !_isLab22
        },
        {
          href: '/thresholds',
          title: 'Alert Thresholds',
          //icon: 'fa-solid fa-arrows-left-right-to-line',
          icon: 'inf-sidebar-thresholds inf-sidebar-img',
          hidden: !_isAdmin
        },        
        {
          href: '/users',
          title: 'Users',
          //icon: 'fa-solid fa-user-group',
          icon: 'inf-sidebar-users inf-sidebar-img',
          hidden: !_isAdmin
        },
        {
          href: '/roles',
          title: 'Roles',
          //icon: 'fa-solid fa-user-lock',
          icon: 'inf-sidebar-roles inf-sidebar-img',
          hidden: !_isAdmin
        }, 
        {
          component: markRaw(separator),
          hidden: !DEV_MODE
        },  
        /*{
          href: '/reporting',
          title: 'Reporting',
          icon: 'fa fa-bar-chart',
          hidden: !DEV_MODE || !_isAdmin
        },*/            
        {
          href: '/profile',
          title: 'Profile',
          icon: 'fa fa-solid fa-address-card',
          hidden: !DEV_MODE || !_isAdmin
        },            
        {
          header: 'Test & examples',
          hiddenOnCollapse: true,
          hidden: !DEV_MODE || !_isAdmin
        }, 
        {
          title: 'Forms',
          icon: 'fa fa-list-ul',
          child: [
            {
              href: '/forms-basic',
              title: 'Basic',
              icon: 'fa fa-solid fa-user-group'
            },
            {
              href: '/forms-advanced',
              title: 'Advanced',
              icon: 'fa fa-solid fa-address-card'
            },
            {
              href: '/forms-test',
              title: 'Test',
              icon: 'fa fa-solid fa-user-lock'
            }
          ],
          hidden: !DEV_MODE || !_isAdmin
        }, 
        {
          title: 'Tables',
          icon: 'fa fa-list-ul',
          child: [
            {
              href: '/tables-basic',
              title: 'Basic',
              icon: 'fa fa-solid fa-user-group'
            },
            {
              href: '/tables-advanced',
              title: 'Advanced',
              icon: 'fa fa-solid fa-address-card'
            },
            {
              href: '/tables-test',
              title: 'Test',
              icon: 'fa fa-solid fa-user-lock'
            }
          ],
          hidden: !DEV_MODE || !_isAdmin
        },   
        {
          title: 'UI Elements',
          icon: 'fa fa-list-ul',
          child: [
            {
              href: '/ui-elements-tabs',
              title: 'Tabs',
              icon: 'fa fa-solid fa-user-group'
            }
          ],
          hidden: !DEV_MODE || !_isAdmin
        }              
      ],
      width: '250px',
      collapsed: true,
      isOnMobile: false,
      theme: _app.getTheme(),
      showChild: true
    }
  },
  mounted () {    
    // console.log(this.$refs.sideBar)
      // this.$refs.sideBar.sidebarMenuRef
      // vsm--link_active
      // ul.vsm--menu li.vsm--item a.vsm--link.vsm--link_active
    this.onResize();
    if (!this.collapsed && this.userCollapsed()) {
        this.setCollapsed(true);
    }
    window.addEventListener('resize', this.onResize);
  },
  // watch: {
  //   $route() {
  //     console.log('route change', this.$route.path);
  //     if (this.$route.path.startsWith('/devices'))
  //       console.log('devices')
  //   }
  // },     
  methods: {
    userCollapsed() {
        return JSON.parse(localStorage.getItem('SIDENAV_COLLAPSED')) === true;
    },
    onResize () {
      if (window.innerWidth <= 767) {
        this.setIsOnMobile(true);
        this.setCollapsed(true);
      } else {
        this.setIsOnMobile(false);        
        this.setCollapsed(this.userCollapsed());
      }
    },
    onToggleCollapse (collapsed) {
      localStorage.setItem('SIDENAV_COLLAPSED', collapsed);
      this.setCollapsed(collapsed);
    },
    setCollapsed(collapsed) {
      this.collapsed = collapsed;
      this.$emit('setCollapsed', collapsed);
    },
    setIsOnMobile(isOnMobile) {
      this.isOnMobile = isOnMobile;
      this.$emit('setIsOnMobile', isOnMobile);
    }
  }  
}
</script>

<style>

#main-view.sidebar {
  padding-left: 250px;
  transition: 0.3s ease;
}

#main-view.sidebar.collapsed {
  padding-left: 65px;
}

#main-view.sidebar.onmobile {
  padding-left: 65px;
}

.sidebar-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #000;
  opacity: 0.5;
  z-index: 900;
}

.v-sidebar-menu {
  margin-top: 60px;
  padding-top: 10px;  
}

.v-sidebar-menu.vsm_white-theme {
  border-right: 1px solid rgb(216, 222, 228);
}

.v-sidebar-menu .vsm--toggle-btn {  
  height:50px !important;
}

.v-sidebar-menu .vsm--link_level-1.vsm--link_active {
    -webkit-box-shadow: inset 3px 0 0 0 #0088CC !important;
    box-shadow: inset 3px 0 0 0 #0088CC !important;
}

.v-sidebar-menu .vsm--mobile-bg {
  background-color: #0088CC !important;
  z-index:20 !important;
}

.v-sidebar-menu .vsm--mobile .vsm--icon {
  background-color: #0088CC !important;
}

.v-sidebar-menu.vsm_white-theme .vsm--link_mobile.vsm--link_hover .vsm--icon {
  background-color: #0088CC !important;
}

.v-sidebar-menu.vsm_collapsed .vsm--link_level-1.vsm--link_hover .vsm--icon {
	/*background-color: #0088CC !important;*/
  background-color: #00a2f2 !important;
}

.v-sidebar-menu.vsm_white-theme .vsm--icon {
  color: #2e353e;
}

.v-sidebar-menu.vsm_dark-theme {
  background: #282d36;
}

.v-sidebar-menu .vsm--link_hover {
  background-color: #262626;
}
</style>