import { $vfm } from 'vue-final-modal'

import AlertModal from '@/App/components/Modal/AlertModal'
import ConfirmModal from '@/App/components/Modal/ConfirmModal'

import VContent from '@/App/components/Modal/Test/VContent.vue'
import CustomModal from '@/App/components/Modal/Test/CustomModal'
import VTitle from '@/App/components/Modal/Test/VTitle'

class AppModal {

  alert(title, msg, callback) {
    $vfm.show({
      component: AlertModal,
      bind: {
        title: title,
        msg: msg
      },
      on: {
        callback: callback
      }
    });
  }

  confirm(title, msg, callback) {
    $vfm.show({
      component: ConfirmModal,
      bind: {
        title: title,
        msg: msg
      },
      on: {
        callback: callback
      }
    });
  }

  advancedDynamicExample() {
    $vfm.show({
      component: CustomModal,
      bind: {
        name: 'VDynamicAdvacedModal'
      },
      on: {
        // event by custom-modal
        confirm(close) {
          console.log('confirm')
          close()
        },
        cancel(close) {
          console.log('cancel')
          close()
        },
        // event by vue-final-modal
        'click-outside'() {
          console.log('@click-outside')
        },
        'before-open'() {
          console.log('@before-open')
        },
        opened() {
          console.log('@opened')
        },
        'before-close'() {
          console.log('@before-close')
        },
        closed() {
          console.log('@closed')
        }
      },
      slots: {
        title: {
          component: VTitle,
          bind: {
            text: 'Hello, vue-final-modal'
          }
        },
        default: {
          component: VContent,
          bind: {
            content:
              'Vue Final Modal is a renderless, stackable, detachable and lightweight modal component.'
          }
        }
      }
    });   
  }
}

export default new AppModal();