<template>
    
    <div id="report-wrapper">
        <div id="report-sidebar-wrapper" class="row report-sidebar">
            <div v-if="modelId===''" class="col-12">loading query builder editor...</div>
            <div v-if="modelId!==''" style="float:right;">
                <iframe :src="baseUrl+queryString" id="iframe-qb" name="iframe-qb" :width="frameWidth" :height="frameHeight" frameBorder="0" seamless="seamless"></iframe>
            </div>
        </div>
    </div>
    <div id="button-wrapper">
        <div id="button-sidebar-wrapper" class="button-sidebar">
            <button v-show="gearing" type="button" @click.prevent="toggleEditQuery" class="btn btn-outline-secondary-transparent icon-btn" style="border-right:0 !important; height:40px !important;">
                <!--<span class="spinner-grow spinner-grow-sm text-warning"></span>-->
                <i class="svg-back" style="opacity:0.8;"></i>
            </button>
        </div>
    </div>

    <div id="filters-wrapper">
        <div id="filters-sidebar-wrapper" class="row filters-sidebar">
            <h4 style="padding-top:5px; padding-bottom:5px;">
                Report conditions
                <div style="display:inline-block; margin-left:5px;">
                    <a href="#" @click.prevent="toggleEditQuery" title="Edit report configuration"><i class="svg-edit"></i></a>
                </div>
            </h4>
            <p id="p-conditions-html">{{conditionsParsedHtml}}</p>
        </div>
    </div>
    <div id="button2-wrapper">
        <div id="button2-sidebar-wrapper" class="button2-sidebar">
            <button v-show="gearing2" type="button" @click.prevent="toggleFiltersSidebar" class="btn btn-outline-secondary-transparent icon-btn" style="border-right:0 !important;">
                <i class="svg-back" style="opacity:0.8;"></i>
            </button>
        </div>
    </div>
    
    <div class="btn-toolbar justify-content-between" ref="btnToolbar" role="toolbar" style="margin-bottom:10px;">
        <div class="btn-group" role="group" >
            <button type="button" :disabled="loading || disabledRefresh" @click.prevent="runQuery" class="btn btn-secondary icon-btn ms-1" style="margin-left:1px !important; margin-right:5px; box-shadow:none !important; outline:0px !important;">
                <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                <i v-show="!loading" class="fa-solid fa-rotate-right"></i>
            </button>
            <button type="button" :disabled="disabledEdit" class="btn btn-outline-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                <i class="fa-solid fa-bars"></i>
            </button>
            <ul class="dropdown-menu dropdown-menu-end" style="z-index:1000;">
                <div class="row" style="min-width:650px;">
                    <div class="col-4">
                        <h6 class="dropdown-header dropdown-header-small" style="color:#bbc5d6;">Report</h6>
                        <li><a class="dropdown-item dropdown-item-small" @click.prevent="saveReport(this.currentReport)"><i class="fa-solid fa-save dropdown-icon-small"></i>Save</a></li>
                        <li><a class="dropdown-item dropdown-item-small" @click.prevent="renameReport(this.currentReport)"><i class="fa-solid fa-dummy-lab22 dropdown-icon-small"></i>Rename</a></li>
                        <!--TODO <li><a class="dropdown-item dropdown-item-small" @click.prevent="toggleFreeze"><i :class="clsFreeze"></i>Freeze report</a></li>-->
                        <h6 class="dropdown-header dropdown-header-small" style="color:#bbc5d6;">Display options</h6>
                        <li><a class="dropdown-item dropdown-item-small" @click.prevent="changeDisplayOption('table')"><i class="fa-solid fa-table dropdown-icon-small"></i>Table</a></li>
                        <li><a class="dropdown-item dropdown-item-small" @click.prevent="changeDisplayOption('pie')"><i class="fa-solid fa-chart-pie dropdown-icon-small"></i>Pie chart</a></li>
                        <li><a class="dropdown-item dropdown-item-small" @click.prevent="changeDisplayOption('line')"><i class="fa-solid fa-chart-line dropdown-icon-small"></i>Line chart</a></li>
                        <li><a class="dropdown-item dropdown-item-small" @click.prevent="changeDisplayOption('bar')"><i class="fa-solid fa-chart-bar dropdown-icon-small"></i>Bar chart</a></li>
                    </div>
                    <div class="col-4">
                        <h6 class="dropdown-header dropdown-header-small" style="color:#bbc5d6;">Export</h6>
                        <li><a class="dropdown-item dropdown-item-small" @click.prevent="exportCsv(this.gridData.columns, this.allRows,';')"><i class="fa-solid fa-file-csv dropdown-icon-small"></i>CSV</a></li>
                        <li><a class="dropdown-item dropdown-item-small" @click.prevent="exportPng"><i class="fa-solid fa-file-image dropdown-icon-small"></i>PNG</a></li>
                        <h6 class="dropdown-header dropdown-header-small" style="color:#bbc5d6;">Sharing</h6>
                        <li><a class="dropdown-item dropdown-item-small" @click.prevent="postShareConfig"><i class="fa-solid fa-share-alt-square dropdown-icon-small"></i>Share configuration</a></li>
                        <li v-if="isLabUser"><a class="dropdown-item dropdown-item-small" @click.prevent="copyConfig"><i class="fa-solid fa-dummy-lab22 dropdown-icon-small"></i>Copy configuration</a></li>
                    </div>
                    <div class="col-4">
                        <h6 class="dropdown-header dropdown-header-small" style="color:#bbc5d6;">Info</h6>
                        <li><a class="dropdown-item" @click.prevent="showInfoModal('Model '+(this.queryJson.modelName||this.selectedModel), this.modelInfo, 'model-info')"><i class="fa-solid fa-circle-info"></i>Model info</a></li>
                        <li v-if="isLabUser"><a class="dropdown-item" @click.prevent="showInfoModal('Report SQL', (this.querySql||''), 'model-sql')"><i class="fa-solid fa-database"></i>Report SQL</a></li>
                        <li v-if="isLabUser"><a class="dropdown-item" @click.prevent="showInfoModal('Report SQL raw', (this.querySql||''), 'model-sql-raw')"><i class="fa fa-dummy-lab22"></i>Report SQL raw</a></li>
                        <li v-if="isLabUser"><a class="dropdown-item" @click.prevent="showInfoModal('Report JSON', this.queryJson, 'model-json')"><i class="fa-solid fa-code"></i>Report JSON</a></li>
                        <li v-if="isLabUser"><a class="dropdown-item" @click.prevent="showInfoModal('Report JSON raw', this.queryJson, 'model-json-raw')"><i class="fa fa-dummy-lab22"></i>Report JSON raw</a></li>
                        <h6 class="dropdown-header dropdown-header-small" style="color:#bbc5d6;">Settings</h6>
                        <li><a class="dropdown-item dropdown-item-small" @click.prevent="toggleAutoRefresh"><i :class="clsAutoRefresh"></i>Auto refresh</a></li>
                    </div>
                </div>
            </ul>
            <!--
            <span v-if="objMembersCount(this.queryFilters,0)" v-show="showFiltersDescr" class="toolbar-filters-descr" :title="queryFilters" @click.prevent="toggleFiltersSidebar" style="color:#bbc5d6 !important;">CONDITIONS: {{this.queryFilters}}</span>
            -->
            <div v-if="noModelId" style="display:inline-block; margin-left:20px;">
                <span v-if="this.selectedModel=='Select model'" class="toolbar-no-model-descr">{{this.missingModelWarning}}</span>
                <span v-else class="toolbar-no-model-descr">Selected model: </span>
                <div class="btn-group mx-2">
                    <button type="button" class="btn btn-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" style="min-width:160px;">{{selectedModel}}</button>
                    <ul class="dropdown-menu dropdown-menu-end">
                        <li v-for="model in userModels" :key="model.id"><a class="dropdown-item" @click.prevent="selectModel(model.name)">{{model.name}}</a></li>
                    </ul>
                </div>
                <div class="btn-group">
                    <button type="button" class="btn btn-save-dirty dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">Actions</button>
                    <ul class="dropdown-menu dropdown-menu-end">
                        <li><a class="dropdown-item" @click.prevent="changeModel"><i class="fa-solid fa-dummy-lab22"></i>Blank report</a></li>
                        <li><a class="dropdown-item" @click.prevent="showConfigModal"><i class="fa-solid fa-dummy-lab22"></i>Paste report</a></li>
                        <!--<li><a class="dropdown-item" @click.prevent="startChatGpt"><i class="fa-solid fa-dummy-lab22"></i>ChatGPT</a></li>-->
                    </ul>
                </div>
                <!--
                <button type="button" @click.prevent="changeModel" class="btn btn-primary">Blank report</button>
                &nbsp;or&nbsp;
                <button type="button" @click.prevent="showConfigModal" class="btn btn-outline-secondary">Paste report</button>
                &nbsp;or&nbsp;
                <button type="button" @click.prevent="startChatGpt" class="btn btn-save-dirty">ChatGPT</button>
                -->
            </div>
        </div>
        <div class="btn-group">
            <button v-show="!gearing && !gearing2" type="button" :disabled="disabledEdit" @click.prevent="toggleEditQuery" class="btn btn-secondary icon-btn ms-1" style="box-shadow:none !important; outline:0px !important;">
                <i class="fa-solid fa-edit"></i>
            </button>
            <button v-if="objMembersCount(this.queryFilters,0)" v-show="!gearing && !gearing2" :disabled="disabledEdit" type="button" @click.prevent="toggleFiltersSidebar" class="btn btn-outline-secondary icon-btn ms-1">
                <i class="fa-solid fa-filter"></i>
            </button>
        </div>
    </div>
    
    <!--<div :class="clsDivider"></div>-->
    
    <div id="displayTableGrid">
        <div class="flex-grow-1 d-flex align-items-center">
            <BaseGrid ref="reportGrid" v-if="tableDone" :columns="gridData.columns" :rows="gridData.rows" :enableVScroll="false" @row-contextmenu="onRowContextMenu" />
        </div>
        <!--<div v-if="!tableDone && !placeholderTable" style="width:99%; margin-top:10px;">{{ (this.queryJson) ? this.queryJson.modelName + ' TABLE' : this.getTempModelName(this.selectedModel) || '' }}</div>-->
        <div v-if="placeholderTable" class="card" style="width:99%; margin-top:10px;">
            <div class="card-body">
                <div class="row">
                    <div class="col-12">
                        <h5 class="card-title placeholder-glow" style="opacity:0.2 !important;">
                            <span class="placeholder col-12"></span>
                        </h5>
                        <p class="card-text placeholder-glow" style="opacity:0.2 !important;">
                            <span class="placeholder col-12"></span>
                            <span class="placeholder col-12"></span>
                            <span class="placeholder col-12"></span>
                            <span class="placeholder col-12"></span>
                            <span class="placeholder col-12"></span>
                            <span class="placeholder col-12"></span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <!--<div v-if="!tableDone && !placeholderTable" class="row text-center" style="height:100%; opacity:0.1;"><i :class="modelIconCls" style="font-size:12rem;"></i></div>-->
        <div v-if="allRows.length>0 && tableDone" id="displayTablePaging" class="flex-grow-1 d-flex align-items-center">
            <button v-if="showBackButton" @click="backPage()" type="button" class="btn icon-btn btn-outline-secondary mx-2"><i class="fa-solid fa-arrow-left"></i></button>
            <button v-if="showNextButton" @click="nextPage()" type="button" class="btn icon-btn btn-outline-secondary"><i class="fa-solid fa-arrow-right"></i></button>
            <div class="pages-info">{{this.pagesInfo}}</div>
        </div>
    </div>

    <div id="displayPieChart">
        <div v-if="columnsLabel.length>0 && columnsData.length>0" class="row" style="margin-top:20px;">
            <div v-if="querySql!==''" class="col-3">
                Select column for pie chart label:
                <div class="form-check" v-for="col in columnsLabel" :key="'div-pie-column-'+col.idx">
                    <input class="form-check-input form-check-pie-columns" :data-idx="col.idx" @click="inputChecked('pie', 'form-check-pie-columns', 'pie-column-'+col.idx, $event)" type="checkbox" :id="'pie-column-'+col.idx" >
                    <label class="form-check-label" :for="'pie-column-'+col.idx">{{col.name}}</label>
                </div>
                <br />
                Select column for pie chart data:
                <div class="form-check" v-for="col in columnsData" :key="'div-pie-data-'+col.idx">
                    <input class="form-check-input form-check-pie-data" :data-idx="col.idx" @click="inputChecked('pie', 'form-check-pie-data', 'pie-data-'+col.idx, $event)" type="checkbox" :id="'pie-data-'+col.idx" >
                    <label class="form-check-label" :for="'pie-data-'+col.idx">{{col.name}}</label>
                </div>
            </div>
            <div v-if="querySql==''" class="col-3">&nbsp;</div>
            <div v-if="pieChartDone" class="col-9">
                <apexchart 
                    type="pie" 
                    ref="chartPie"
                    :series="pieSeries" 
                    :options="pieOptions" 
                />
            </div>
        </div>
        <div v-else class="row" style="margin-left:15px; margin-top:20px;">
            For pie charts your query configuration should include:<br />
            &nbsp;&nbsp;&nbsp;- a label column that represents a series name (e.g. device model)<br />
            &nbsp;&nbsp;&nbsp;- a column with aggregate function (e.g. average internal temperature)
        </div>
    </div>

    <div id="displayLineChart" style="font-family:'IBM Plex Sans' !important; color:#bbc5d6 !important;">
        <div v-if="columnsDt.length>0 && columnsLabel.length>0 && columnsData.length>0" class="row" style="margin-top:20px;">
            <div v-if="querySql!==''" class="col-3">
                <div class="row">
                    <div class="col-12">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="form-check-line-y-auto-scale" >
                            <label class="form-check-label" for="form-check-line-y-auto-scale">auto scale y-axis</label>
                        </div>
                        <br />
                        Select column for line chart timeline:
                        <div class="form-check" v-for="col in columnsDt" :key="'div-line-dt-'+col.idx">
                            <input class="form-check-input form-check-line-dt" :data-idx="col.idx" @click="inputChecked('line', 'form-check-line-dt', 'line-dt-'+col.idx, $event)" type="checkbox" :id="'line-dt-'+col.idx" >
                            <label class="form-check-label" :for="'line-cdt-'+col.idx">{{col.name}}</label>
                        </div>
                        <br />
                        Select column for line chart label:
                        <div class="form-check" v-for="col in columnsLabel" :key="'div-line-column-'+col.idx">
                            <input class="form-check-input form-check-line-columns" :data-idx="col.idx" @click="inputChecked('line', 'form-check-line-columns', 'line-column-'+col.idx, $event)" type="checkbox" :id="'line-column-'+col.idx" >
                            <label class="form-check-label" :for="'line-column-'+col.idx">{{col.name}}</label>
                        </div>
                        <br />
                        Select column(s) for line chart data:
                        <div class="form-check" v-for="col in columnsData" :key="'div-line-data-'+col.idx">
                            <!--<input class="form-check-input form-check-line-data" :data-idx="col.idx" @click="inputChecked('line', 'form-check-line-data', 'line-data-'+col.idx, $event)" type="checkbox" :id="'line-data-'+col.idx" >-->
                            <input class="form-check-input form-check-line-data" :data-idx="col.idx" type="checkbox" :id="'line-data-'+col.idx" >
                            <label class="form-check-label" :for="'line-data-'+col.idx">{{col.name}}</label>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="querySql==''" class="col-3">&nbsp;</div>
            <div v-if="lineChartDone" class="col-9">
                <apexchart 
                    type="line" 
                    ref="chartLine"
                    :series="lineSeries" 
                    :options="lineOptions" 
                />
            </div>
        </div>
        <div v-else class="row" style="margin-left:15px; margin-top:20px;">
            For timeline line charts your query configuration should include:<br />
            &nbsp;&nbsp;&nbsp;- a datetime column that represents x-axis (timeline)<br />
            &nbsp;&nbsp;&nbsp;- a label column that represents a series name (e.g. device model)<br />
            &nbsp;&nbsp;&nbsp;- one or more columns with aggregate function (e.g. average internal temperature)
        </div>
    </div>

    <div id="displayBarChart" style="font-family:'IBM Plex Sans' !important; color:#bbc5d6 !important;">
        <div class="row" style="margin-top:20px;">
            <div v-if="querySql!==''" class="col-3">
                <div class="row">
                    <div class="col-12">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="form-check-line-y-auto-scale" >
                            <label class="form-check-label" for="form-check-line-y-auto-scale">auto scale y-axis</label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="form-check-line-horizontal" >
                            <label class="form-check-label" for="form-check-line-horizontal">horizontal</label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="form-check-line-stacked-columns" >
                            <label class="form-check-label" for="form-check-line-stacked-columns">stacked columns</label>
                        </div>
                        <br />
                        Select column for bar chart label:
                        <div class="form-check" v-for="col in columnsLabel" :key="'div-bar-column-'+col.idx">
                            <input class="form-check-input form-check-bar-columns" :data-idx="col.idx" @click="inputChecked('bar', 'form-check-bar-columns', 'bar-column-'+col.idx, $event)" type="checkbox" :id="'bar-column-'+col.idx" >
                            <label class="form-check-label" :for="'bar-column-'+col.idx">{{col.name}}</label>
                        </div>
                        <br />
                        Select column(s) for bar chart data:
                        <div class="form-check" v-for="col in columnsData" :key="'div-bar-data-'+col.idx">
                            <!--<input class="form-check-input form-check-bar-data" :data-idx="col.idx" @click="inputChecked('bar', 'form-check-bar-data', 'bar-data-'+col.idx, $event)" type="checkbox" :id="'bar-data-'+col.idx" >-->
                            <input class="form-check-input form-check-bar-data" :data-idx="col.idx" type="checkbox" :id="'bar-data-'+col.idx" >
                            <label class="form-check-label" :for="'bar-data-'+col.idx">{{col.name}}</label>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="querySql==''" class="col-3">&nbsp;</div>
            <div v-if="barChartDone" class="col-9">
                <apexchart 
                    type="bar" 
                    ref="chartBar"
                    :series="barSeries" 
                    :options="barOptions" 
                />
            </div>
        </div>
    </div>

    <ContextMenu ref="gridRowContextMenu"></ContextMenu>

    <ContextMenu ref="gridTHeadContextMenu"></ContextMenu>

</template>

<script>
import _app from '@/App/App'
import {AUTH_STORE_KEY} from '../../App/Auth/auth.service'
import BaseGrid from '@/App/components/Grid/BaseGrid'
import ReportingInfoModal from './ReportingInfoModal'
import ReportingConfigModal from './ReportingConfigModal'
import ReportingChatGptModal from './ReportingChatGptModal'
import ReportingDrillDown from './ReportingDrillDown'
import ReportingNameModal from './ReportingNameModal'
import ContextMenu from '@/App/components/Common/ContextMenu'
import domtoimage from 'dom-to-image-more';

let _authData = JSON.parse(localStorage.getItem(AUTH_STORE_KEY)),
    _token = '';

if (_authData?.accessToken)
  _token = _authData.accessToken;

class Message { //communication class for sending messages to query builder iframe
  constructor(type, body) {
    this.type = type;
    this.body = body;
  }
}

const DTYPE = {
    String: 1,
    Word: 3,
    Int32: 4,
    Int64: 5,
    Float: 7,
    Datetime: 10,
    getDTypeList() {
      return [
        { id: this.String, name: 'String'},
        { id: this.Word, name: 'Word/Text'},
        { id: this.Int32, name: 'Integer'},
        { id: this.Int64, name: 'Long'},
        { id: this.Float, name: 'Float'},
        { id: this.Datetime, name: 'Datetime'},
      ];
    }
  };

let _gloScope = null;
function handleContextMenu(event, col) {
    event.preventDefault();

    if(col.innerText!=='#') {
        let row = col.parentElement,
            idx = 0,
            queryCol = null;

        for (let th of row.getElementsByTagName("th")) {
            let name = th.getElementsByClassName("hd-cell-text")[0].innerText;
            if( name==col.innerText )
                queryCol = _gloScope.queryJson.cols[idx];
            else {
                if( name!=="#" )
                    idx++;
            }
        }

        _gloScope.onTHeadContextMenu(queryCol);
    }
}

const PAGE_SIZE = 100,
      COLORS = ['#00FFD7','#FFCE19','#FF19FF','#1485CC'],
      TABS_HEIGHT = 40; //normal tab list height when tabs do not break to next line

export default {
    name: 'ReportView',
    props: {
      report: Object,
      models: Object,
      displayOption: String,
      ulHeight: Number,
    },
    emits: ['reportChange','isReportInProgress'],
    components: {
        BaseGrid,
        ContextMenu,
    },
    data() {
      return {
        store: this.$store,
        currentReport: this.report, //only json/model report representation
        waitingForModelInfo: true,
        reportFromPaste: false,
        modelId: null,
        queryJson: null,
        querySql: null,
        queryFilters: null,
        //
        initialLoad: true,
        placeholderTable: false,
        canEmit: true,
        displayOptionNew: this.displayOption,
        isLabUser: false,
        loading: false,
        autoRefresh: false,
        autoRefreshPause: false,
        freeze: false, //TODO read property from this.currentReport
        opacity: 0.8,
        disabledEdit: true,
        disabledRefresh: (this.report.querySql=='') ? true : false,
        userModels: this.models,
        selectedModel: 'Select model',
        missingModelWarning: 'Model for current report not defined.',
        gearing: false,
        gearing2: false,
        //showFiltersDescr: true,
        gridData: {
            columns: [],
            rows: []
        },
        allRows: [],
        allSumsPage: [],
        allSumsTotal: [],
        currentPage: 0,
        showBackButton: false,
        showNextButton: false,
        pagesInfo: '',
        reportEditorVisible: false,
        filtersSidebarVisible: false,
        baseUrl: process.env.VUE_APP_REPORTING_URL,
        queryString: '',
        //
        queryConditionsHtml: '',
        conditionsParsedHtml: '',
        modelInfo: '',
        modelEntities: null,
        noModelId: false,
        columnsDt: [],
        columnsLabel: [],
        columnsData: [],
        tableDone: false,
        //#region EditorLayout
        innerWidth: null,
        innerHeight: null,
        frameWidth: null,
        frameWidthPx: null,
        frameHeight: null,
        frameHeightPx: null,
        frameTopOffsetPx: null,
        frameTopOffsetPxButton: null,
        frameLeftOffsetPxButton: null,
        maxLengthFilterDescr: '255px',
        //#endregion
        //#region Charts
        pieSeries: [],
        pieChartDone: false,
        pieOptions: {
            chart: {
                animations: {
                    enabled: false,
                },
                toolbar: {
                    show: false
                }
            },
            legend: {
                position: 'right',
                labels: {
                    colors: ['#bbc5d6'],
                    useSeriesColors: false
                }
            },
            colors: COLORS,
            stroke: {
                colors: ['#bbc5d6']
            },
            /*fill: {
                opacity: 0.8
            },*/
        },
        //
        lineSeries: [],
        lineChartDone: false,
        lineOptions: {
            chart: {
                animations: {
                    enabled: false,
                },
                toolbar: {
                    show: false
                }
            },
            colors: COLORS,
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'straight'
            },
            yaxis: {
                labels: {          
                    style: {
                        colors: '#bbc5d6'
                    }
                }
            },
            xaxis: {
                type: 'datetime',
                labels: {
                    datetimeUTC: true,
                    style: {
                        colors: ['#bbc5d6']
                    }
                },
                axisBorder: {
                    color: '#bbc5d6'
                },
                axisTicks: {
                    color: '#bbc5d6'
                }
            },
            legend: {
                position: 'top',
                horizontalAlign: 'left',
                offsetY: 0,
                offsetX: 60,
                showForSingleSeries: true,
                showForNullSeries: true,
                showForZeroSeries: true,
                labels: {
                    colors: ['#bbc5d6']
                }
            },
            grid: {
                borderColor: '#3e424a'
            },
            noData: { text: 'No data' }
        },
        //
        barSeries: [],
        barChartDone: false,
        barOptions: {

        },
        //#endregion
      };
    },
    watch: {
        currentPage(){ //when this.currentPage changes
            this.changePage();
        },
        modelInfo(){
            this.disabledEdit = (this.modelId=='') ? true : false;
        },
        queryJson: {
            handler(newValue, oldValue) {
                this.currentReport.queryJson = this.queryJson;
            },
            deep: true
        },
        querySql: { //when sql is changed this means that querybuilder successfully synched and broadcasted new sql statement -> emit to parent
            handler(newValue, oldValue) {
                let _old = (oldValue) ? oldValue : '',
                    _new = (newValue) ? newValue : '';

                if( _new.replace(/\s/g, '') !== _old.replace(/\s/g, '') ) {
                    
                    /*console.log(`oldValue: ${_old}`);
                    console.log(`newValue: ${_new}`);*/
                    
                    if(!this.initialLoad || this.reportFromPaste) {
                        if(this.canEmit) {
                            this.reportFromPaste = false;

                            //update report object and emit change to parent view
                            this.currentReport.queryJson = this.queryJson;
                            this.currentReport.querySql = this.querySql;
                            this.currentReport.queryFilters = this.queryFilters;
                            this.currentReport.name = this.dirtyName(this.currentReport.name, true);
                            
                            this.$emit("reportChange", this.currentReport);

                            this.disabledRefresh = (this.querySql=='') ? true : false;
                            
                            this.getJsonInfo(this.queryJson);
                            
                            this.noModelId = false;

                            this.initInputChecked('pie');
                            this.initInputChecked('line');
                            this.initInputChecked('bar');

                            if(this.autoRefresh && !this.autoRefreshPause) {
                                setTimeout(() => {
                                    this.runQuery();
                                }, 100);
                            }
                        }
                    }
                    else {
                        this.initialLoad = false;
                        if(_new!=='') {
                            this.disabledRefresh = false;
                            this.getJsonInfo(this.queryJson);
                            this.noModelId = false;
                            this.initInputChecked('pie');
                            this.initInputChecked('line');
                            this.initInputChecked('bar');
                            if(this.autoRefresh && !this.autoRefreshPause) {
                                setTimeout(() => {
                                    this.runQuery();
                                }, 100);
                            }
                        }
                        
                    }
                } else { //loaded new report after model selection
                    this.initialLoad = false;
                    this.noModelId = false;
                }
            }
        },
        queryConditionsHtml(){
            this.parseConditionsHtml(this.queryConditionsHtml);
        }
    },
    computed: {
        clsAutoRefresh() {
            return (this.autoRefresh) ? 'fa fa-check-square dropdown-icon-small': 'fa fa-check-square bw dropdown-icon-small';
        },
        clsFreeze() {
            return (this.freeze) ? 'fa fa-check-square dropdown-icon-small': 'fa fa-check-square bw dropdown-icon-small';
        },
        /*clsDivider() {
            return (!this.gearing && !this.gearing2) ? 'row visible-divider' : 'row hidden-divider';
        },*/
        modelIconCls() {
            return this.currentReport.icon;
        },
    },
    created() {
        // https://dev.to/viniciuskneves/watch-for-vuex-state-changes-2mgj
        this.unsubscribe = this.$store.subscribe((mutation, state) => {
            if (mutation.type === 'updateMainScrollOffset') {
                if(this.frameTopOffsetPx!==null) {
                    
                    let _scrollOffset = state.Reporting.scrollOffset;
                    
                    if(_scrollOffset>130) {
                        this.frameTopOffsetPx = '115px';
                        this.frameTopOffsetPxButton = '115px';
                    } else {
                        const FRAME_TOP_OFFSET = 209-TABS_HEIGHT+this.ulHeight-_scrollOffset;
                        this.frameTopOffsetPx = FRAME_TOP_OFFSET.toString()+'px';
                        this.frameTopOffsetPxButton = (FRAME_TOP_OFFSET+26).toString()+'px';
                    }
                }
            }
        });
    },
    mounted() {
        this.autoRefresh = (localStorage.getItem('lab22-reporting-autorefresh')==='true') || false;

        _gloScope = this;

        const currentUser = _app.user;
        if(currentUser)
            this.isLabUser = ( _app.$data.Lab22Users.indexOf(currentUser.email) == -1 ) ? false : true;
        
        if(this.currentReport.queryFilters=='NO FILTERS') //legacy
            this.currentReport.queryFilters = '';

        this.getDimensions();
        
        this.currentReport.isActive = 1;

        if( typeof this.currentReport.queryJson.modelId === 'undefined' || this.currentReport==null ) {
            this.changeDisplayOption('table');
            this.noModelId = true;
        }
        else {
            this.changeDisplayOption(this.displayOptionNew);

            this.noModelId = false;
            this.queryJson = this.currentReport.queryJson;
            this.querySql = this.currentReport.querySql;
            this.queryFilters = this.currentReport.queryFilters;

            this.getJsonInfo(this.currentReport.queryJson);

            //console.log(this.currentReport);
            this.loadReport(this.currentReport, this.currentReport.queryJson.modelId, false);
        }
    },
    beforeUnmount() {
        this.unsubscribe();
    },
    unmounted() {
        window.removeEventListener("keydown", function(){});
        window.removeEventListener("message", function(){});
    },
    methods: {
        //#region Main
        loadReport(report, modelId, isNew) {
            
            this.$emit("isReportInProgress");

            this.modelId = '';
            
            let _this = this,
                frame = document.getElementById("iframe-qb");
                frame = frame ? frame.contentWindow : null;

            const progress = _this.$progress.start();

            _this.queryString = '?width='+_this.frameWidth+'&height='+_this.frameHeight+'&modelId='+modelId+'&token='+_token;
            
            _this.modelId = modelId; //this will trigger iframe to mount

            //https://dev-bay.com/iframe-and-parent-window-postmessage-communication
            //setTimeout(() => {
                
                //RECEIVE MESSAGE FROM IFRAME
                window.addEventListener("message", (e) => {
                    let data = e.data,
                        type = data.type,
                        body = data.body;
                    //console.log("RECEIVED message from IFRAME", data);

                    let _initJson = JSON.parse(JSON.stringify(report.queryJson)); //need to stringify and then parse to json again otherwise object cannot be cloned

                    if(type === "shakehand" && body) {
                        /*console.log("SHAKEHAND RECEIVED FROM IFRAME, SENDING queryJson TO IFRAME...")
                        console.log(new Date());*/
                        _this.sendMessage(frame, new Message("data", _initJson));
                    } else if (type === "text-queryJson" && body) {
                        /* ------------------------------------------- */
                        //https://www.npmjs.com/package/json-formatter-js
                        /* ------------------------------------------- */
                        /*console.log("text-queryJson FROM IFRAME: " + body);
                        console.log(new Date());*/
                        _this.queryJson = JSON.parse(body);
                    } else if (type === "text-querySql" && (body||body=='')) {
                        /* --------------------------------------- */
                        //https://www.npmjs.com/package/sql-formatter
                        /* --------------------------------------- */
                        /*console.log("text-querySql FROM IFRAME: " + body);
                        console.log(new Date());*/
                        _this.querySql = body;
                    } else if (type === "text-queryFilters" && (body||body=='')) {
                        /*console.log("text-queryConditions FROM IFRAME: " + body);
                        console.log(new Date());*/
                        _this.queryFilters = body;
                    } else if (type === "text-queryConditionsHtml" && (body||body=='')) {
                        /*console.log("text-queryConditionsHtml FROM IFRAME: " + body);
                        console.log(new Date());*/
                        _this.queryConditionsHtml = body;
                    } else if (type === "text-error" && body) {
                        /*console.log("text-error FROM IFRAME: " + body);
                        console.log(new Date());*/
                        _app.$helper.notifyError(body);
                    } else if (type === "text-warning" && body) {
                        /*console.log("text-warning FROM IFRAME: " + body);
                        console.log(new Date());*/
                        _app.$helper.notifyWarning(body);
                    } else if (type === "text-info" && body) {
                        /*console.log("text-info FROM IFRAME: " + body);
                        console.log(new Date());*/
                        _app.$helper.notifyInfo(body);
                    } else if (type === "edit-query" && body) {
                        /*console.log("edit-query FROM IFRAME: " + body);
                        console.log(new Date());*/
                        _this.toggleEditQuery();
                        setTimeout(() => {
                            _this.autoRefreshPause = false;
                        }, 3000);
                    } else if (type === "run-query" && body) {
                        /*console.log("run-query FROM IFRAME: " + body);
                        console.log(new Date());*/
                        if(!_this.autoRefresh || _this.autoRefreshPause)
                            _this.runQuery();
                    } else if (type === "text-modelEntities" && body) {
                        /*console.log("text-modelEntities FROM IFRAME:");
                        console.log(body);
                        console.log(new Date());*/
                        _this.modelEntities = body;
                    } else if (type === "text-modelInfo" && body && _this.waitingForModelInfo) {
                        /*console.log("text-modelInfo FROM IFRAME: " + body.substring(0,100));
                        console.log(new Date());*/

                        _this.waitingForModelInfo = false;

                        //this is the final step of query builder sync, so we stop progress bar
                        progress.finish();

                        _this.$emit("isReportInProgress");

                        //window.removeEventListener("keydown", function(){});

                        document.addEventListener('keydown', function(event) {
                            if (event.ctrlKey && event.shiftKey && event.key === 'X' && _this.gearing) {
                                _this.sendMessage(frame, new Message("msg", 'clear-query'));
                            }
                            else if (event.ctrlKey && event.shiftKey && event.key === 'E') {
                                _this.toggleEditQuery();
                            }
                            else if (event.ctrlKey && event.shiftKey && event.key === 'F') {
                                if(_this.reportEditorVisible)
                                    _this.toggleEditQuery();
                                _this.toggleFiltersSidebar();
                            }
                            else if (event.ctrlKey && event.shiftKey && event.key === 'O') {
                                //_this.$emit("openReport", this, event);
                            }
                        });
                        
                        _this.modelInfo = body; //html for model description
                        
                        if(!isNew) { //loaded existing report from workspace
                            if( _this.queryJson.queryJson=='' || _this.queryJson.length==0 )
                                _this.toggleEditQuery();
                            else {
                                if(_this.queryFilters!=='') {
                                    if(!this.filtersSidebarVisible)
                                        _this.toggleFiltersSidebar();
                                } else {
                                    if(!this.reportEditorVisible && !_this.tableDone)
                                        _this.toggleEditQuery();
                                }
                            }
                        } else { //loaded from shared json/link or duplicated or from model selection
                            if(_this.queryJson==null)
                                _this.toggleEditQuery();
                            else {
                                if(_this.queryJson.root.conds.length>0)
                                    _this.toggleFiltersSidebar();
                                else {
                                    if(!_this.tableDone)
                                        _this.toggleEditQuery();
                                }
                            }
                        }
                    }
                });

            //}, 200);
        },
        changeModel() {
            if(this.selectedModel=='Select model') {
                _app.$helper.notifyWarning(this.missingModelWarning);
                return false;
            }
            else {
                let model = this.userModels.filter(obj => {
                            return obj.name==this.selectedModel;
                        })[0],
                    modelId = model.model_id;
                
                //gui reinit
                this.noModelId = false;
                //load report
                this.queryJson = null; //triggers watch and updated this.currentReport.queryJson

                this.currentReport.icon = model.icon_cls

                //setTimeout(() => {
                    this.loadReport(this.currentReport, modelId, true);
                //}, 100);
            }
        },
        runQuery() {
            let _this = this;

            const _query = _this.querySql;
            
            _this.autoRefreshPause = false;

            if(_query!=='') {
                if(!_this.validateQuery(_this.displayOptionNew))
                    return false;

                _this.loading = true;

                const progress = _this.$progress.start();

                if(_this.displayOptionNew=='table') {
                    _this.placeholderTable = true;
                    _this.removeSumsRow();
                    _this.clearGrid();
                }
                
                _app.$api.get('Reporting/FetchReportData', { params: {query:_query } }).then(
                    result => {
                        _this.loading = false;
                        progress.finish();

                        const _res = result.data;

                        if(_res.length>0) {
                            _this.allRows = _res;
                            _this.placeholderTable = false;
                            
                            switch(_this.displayOptionNew) {
                                case 'table':
                                    _this.updateTable(_this.allRows);
                                    /*if(_this.pieChartDone)
                                        _this.updatePieChart(_this.allRows);
                                    if(_this.lineChartDone)
                                        _this.updateLineChart(_this.allRows);
                                    if(_this.barChartDone)
                                        _this.updateBarChart(_this.allRows);*/
                                    break;
                                case 'pie':
                                    _this.updatePieChart(_this.allRows);
                                    /*if(_this.tableDone)
                                        _this.updateTable(_this.allRows);
                                    if(_this.lineChartDone)
                                        _this.updateLineChart(_this.allRows);
                                    if(_this.barChartDone)
                                        _this.updateBarChart(_this.allRows);*/
                                    break;
                                case 'line':
                                    _this.updateLineChart(_this.allRows);
                                    /*if(_this.tableDone)
                                        _this.updateTable(_this.allRows);
                                    if(_this.pieChartDone)
                                        _this.updatePieChart(_this.allRows);
                                    if(_this.barChartDone)
                                        _this.updateBarChart(_this.allRows);*/
                                    break;
                                case 'bar':
                                    _this.updateBarChart(_this.allRows);
                                    /*if(_this.tableDone)
                                        _this.updateTable(_this.allRows);
                                    if(_this.pieChartDone)
                                        _this.updatePieChart(_this.allRows);
                                    if(_this.lineChartDone)
                                        _this.updateLineChart(_this.allRows);*/
                                    break;
                            }
                        }
                        else {
                            _this.placeholderTable = false;
                            _app.$helper.notifyInfo('Your query returned no data.');
                        }
                    },
                    error => {
                        _this.loading = false;
                        _this.placeholderTable = false;
                        progress.finish();
                        _app.handleError(error);
                    }
                );
            }
            else
                _app.$helper.notifyWarning('Sql statement not defined');
        },
        validateQuery(view) {
            let ret, inputsDt, inputsColumns, inputsData,
                checkedDt = 0,
                checkedColumns = 0,
                checkedData = 0;
            switch(view) {
                case 'table':
                    ret = true;
                    break;
                case 'line':
                    inputsDt = document.getElementsByClassName(`form-check-${view}-dt`),
                    inputsColumns = document.getElementsByClassName(`form-check-${view}-columns`),
                    inputsData = document.getElementsByClassName(`form-check-${view}-data`);
                    for(let i=0; i<inputsDt.length; i++) {
                        if(inputsDt[i].checked)
                            checkedDt++;
                    }
                    for(let i=0; i<inputsColumns.length; i++) {
                        if(inputsColumns[i].checked)
                            checkedColumns++;
                    }
                    for(let i=0; i<inputsData.length; i++) {
                        if(inputsData[i].checked)
                            checkedData++;
                    }
                    if(checkedDt.length==0 || checkedColumns.length==0 || checkedData==0) {
                        _app.$helper.notifyInfo('Missing column for timeline/label/data. Reconfigure your query and try again.');
                        ret = false;
                    } else
                        ret = true;
                    break;
                case 'pie':
                case 'bar':
                    inputsColumns = document.getElementsByClassName(`form-check-${view}-columns`),
                    inputsData = document.getElementsByClassName(`form-check-${view}-data`);
                    for(let i=0; i<inputsColumns.length; i++) {
                        if(inputsColumns[i].checked)
                            checkedColumns++;
                    }
                    for(let i=0; i<inputsData.length; i++) {
                        if(inputsData[i].checked)
                            checkedData++;
                    }
                    if(checkedColumns.length==0 || checkedData==0) {
                        _app.$helper.notifyInfo('Missing column for label/data. Reconfigure your query and try again.');
                        ret = false;
                    } else
                        ret = true;
                    break;
                default:
                    ret = true;
                    break;
            }
            
            return ret;
        },
        updateTable(data) {
            let idx = 0,
                _this = this;

            _this.tableDone = false;
            _this.allSumsTotal = [];

            for (const [key, value] of Object.entries(data[0])) {
                let _key = (key=='row_num') ? '#' : key;
                
                if(_key=='#') {
                    this.gridData.columns.push({title:_key, dataIndex:key});
                    _this.allSumsTotal[key] = null;
                }
                else {
                    let _currentCol = _this.queryJson.cols[idx];
                    
                    /*if( ('enabled' in _currentCol) && _currentCol.enabled==false )
                        noSums = true;*/

                    if(_currentCol.dtype==10)
                        _this.gridData.columns.push({title:_key, dataIndex:key, type:'DateTime'});
                    else
                        _this.gridData.columns.push({title:_key, dataIndex:key});

                    let _func = _currentCol.func;
                    if(_func!=='') {
                        if(_func=='AVG') {
                            const sum = data.reduce((accumulator, object) => {
                                return accumulator + object[key];
                            }, 0);
                            _this.allSumsTotal[key] = (data.length==0) ? 0 : (Math.round(((parseFloat(sum/data.length)) + Number.EPSILON) * 100) / 100) //parseFloat(sum/data.length);
                        }
                        else if (_func=='SUM' || _func=='COUNT' || _func=='CNTDST') {
                            const sum = data.reduce((accumulator, object) => {
                                return accumulator + object[key];
                            }, 0);
                            _this.allSumsTotal[key] = Math.round((sum + Number.EPSILON) * 100) / 100;
                        }
                        else
                            _this.allSumsTotal[key] = null;
                    }
                    else
                        _this.allSumsTotal[key] = null;
                    
                    idx++;
                }
            }

            _this.currentPage = 1;
            _this.tableDone = true;

            //add context menu override on table head
            function checkTable() {
                let trow = document.getElementsByTagName("thead")[0];
                if(!trow)
                    window.setTimeout(checkTable, 50); //check for table every 50 milliseconds
                else {
                    setTimeout(() => {
                        let cols = trow.children[0].getElementsByTagName("th");
                        for (let col of cols) {
                            col.removeEventListener('contextmenu', (event) => { handleContextMenu(event, col) });
                            col.addEventListener('contextmenu', (event) => { handleContextMenu(event, col) });
                        }

                        if(data.length>1) {
                            let table = document.getElementsByTagName("table")[0],
                                row = table.rows[ table.rows.length - 1 ],
                                clone = row.cloneNode(true);
                            
                            clone.id = 'lab22-grid-sum';
                            clone.classList.add("grid-sums");

                            let cells = clone.getElementsByTagName("td"),
                                numOfSums = 0,
                                values = [],
                                valueTmp = '';

                            for(let i=0; i<cells.length; i++) {
                                let cellTitle = cells[i].getAttribute('data-title') || '';
                                valueTmp = '';
                                for (const [key, value] of Object.entries(_this.allSumsTotal)) {
                                    let totalValue = _this.allSumsTotal[key];
                                    if(totalValue!==null && cellTitle.toLowerCase()==key.toLowerCase() && cellTitle!=='#') {
                                        valueTmp = totalValue.toString();
                                        numOfSums++;
                                    }
                                    cells[i].classList.add("grid-sums-cell");
                                }
                                values.push({sum:valueTmp});
                            }
                            
                            if(numOfSums>0) {
                                let footer = table.createTFoot();
                                    footer.id = 'grid-sums-footer';
                                    footer.appendChild(clone);

                                setTimeout(() => {
                                    for(let y=0; y<cells.length; y++) {
                                        cells[y].style.cssText = 'background-color:#666666 !important; color:#ffffff !important';
                                        cells[y].innerText = values[y].sum;
                                    }
                                }, 100);
                            }
                        }
                    }, 100);
                }
            }
            checkTable();
        },
        updatePieChart(data) {
            this.pieChartDone = false;
            
            let _this = this,
                checksCols = document.getElementsByClassName('form-check-pie-columns'),
                selectedColIdx = -1,
                checksData = document.getElementsByClassName('form-check-pie-data'),
                selectedDataIdx = -1;

            for(let i=0; i<checksCols.length; i++) {
                if(checksCols[i].checked) {
                    let _obj = _this.columnsLabel.filter(obj => {
                                    return obj.idx==checksCols[i].getAttribute('data-idx');
                                })[0];
                    selectedColIdx = _obj.idx;
                }
            }

            for(let i=0; i<checksData.length; i++) {
                if(checksData[i].checked) {
                    let _obj = _this.columnsData.filter(obj => {
                                    return obj.idx==checksData[i].getAttribute('data-idx');
                                })[0];
                    selectedDataIdx = _obj.idx;
                }
            }

            let c = [], d = [];
            for(let i=0; i<data.length; i++) {
                let x=0, y=0;
                for (const [key, value] of Object.entries(data[i])) {
                    if(x==selectedColIdx)
                        c.push( data[i][key] );
                    x++;
                    if(y==selectedDataIdx)
                        d.push( data[i][key] );
                    y++;
                }
            }

            if(d.length>0) {
                _this.pieSeries = [];
                _this.pieSeries = d;
                _this.pieChartDone = true;

                setTimeout(() => {
                    //https://github.com/apexcharts/vue-apexcharts/issues/142
                    _this.$refs.chartPie.updateOptions({ labels: c, });
                    _this.$refs.chartPie.updateOptions({ chart: {width:'65%', height:'65%'}, });
                }, 200);
            }
        },
        updateLineChart(data) {
            this.lineChartDone = false;

            let _this = this,
                checksDt = document.getElementsByClassName('form-check-line-dt'),
                selectedDtIdx = -1,
                selectedDtName = '',
                checksCols = document.getElementsByClassName('form-check-line-columns'),
                selectedColIdx = -1,
                selectedColName = '',
                checksData = document.getElementsByClassName('form-check-line-data'),
                selectedDataCollection = [];

            //get selected column index for timeline datetime columns
            for(let i=0; i<checksDt.length; i++) {
                if(checksDt[i].checked) {
                    let _obj = _this.columnsLabel.filter(obj => {
                                    return obj.idx==checksDt[i].getAttribute('data-idx');
                                })[0];
                    selectedDtIdx = _obj.idx;
                    selectedDtName = _obj.name;
                }
            }

            //get selected column index for series names
            for(let i=0; i<checksCols.length; i++) {
                if(checksCols[i].checked) {
                    let _obj = _this.columnsLabel.filter(obj => {
                                    return obj.idx==checksCols[i].getAttribute('data-idx');
                                })[0];
                    selectedColIdx = _obj.idx;
                    selectedColName = _obj.name;
                }
            }

            if(selectedDtIdx!==selectedColIdx) {

                //get selected columns for measurements
                for(let i=0; i<checksData.length; i++) {
                    if(checksData[i].checked) {
                        let _obj = _this.columnsData.filter(obj => {
                                        return obj.idx==checksData[i].getAttribute('data-idx');
                                    })[0];
                        selectedDataCollection.push({idx:_obj.idx, name:_obj.name});
                    }
                }

                //sort data by label column, so we can create series, accordingly
                let _data = JSON.parse(JSON.stringify(data));
                _data = _data.sort(function(a, b) {
                    const nameA = a[selectedColName].toUpperCase(); // ignore upper and lowercase
                    const nameB = b[selectedColName].toUpperCase(); // ignore upper and lowercase
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }
                    // names must be equal
                    return 0;
                });

                let colNum = 0,
                    //c = [],
                    //x = [],
                    d = [],
                    seriesName = _data[0][selectedColName], //get 1st value for series
                    dtErrors = 0,
                    dtError = '';

                for (const [key, value] of Object.entries(_data[0])) {
                    if(colNum==selectedDtIdx) { //check if timeline data is ok (valid datetime)
                        for(let i=0; i<_data.length; i++) {
                            try {
                                let val = _data[i][key],
                                    dt = new Date(val+'Z');

                                if(_this.isValidDate(dt)) {
                                    //c.push(dt);
                                } else {
                                    dtErrors++;
                                    if(dtError=='') //catch 1st error
                                        dtError = val;
                                }
                            } catch(err) {
                                _app.handleError(err);
                            }
                        }
                        //x.categories = c;
                        //x.title = {text: key};
                    }
                    colNum++;
                }
                colNum = 0;

                if(dtErrors>0)
                    _app.$helper.notifyError(`Error converting value ${dtError} to Date. Please, select a different column for line chart label.`);
                else {
                    //let colorsNum = 0;

                    for (const [key, value] of Object.entries(_data[0])) {

                        for(let j=0; j<selectedDataCollection.length; j++) {

                            let selectedDataIdx = selectedDataCollection[j].idx,
                                selectedDataName = selectedDataCollection[j].name;

                            if(colNum==selectedDataIdx) { //we found (one of) selected measurement(s)

                                let serie = [];
                                for(let i=0; i<_data.length; i++) {
                                    let currentSeriesName = _data[i][selectedColName];
                                    if(currentSeriesName!==seriesName) {
                                        if(serie.length>0) {
                                            d.push({
                                                'name': seriesName + ' ' + selectedDataName,
                                                'data': serie
                                            });
                                        }
                                        //colorsNum++;
                                        seriesName = currentSeriesName;
                                        serie = [];
                                    }

                                    /*serie.push(
                                        { x: x.categories[i], y: _data[i][key] }
                                    );*/
                                    serie.push({
                                        x: _data[i][selectedDtName], 
                                        y: _data[i][key]
                                    });
                                }
                                if(serie.length>0) {
                                    d.push({
                                        'name': seriesName + ' ' + selectedDataName,
                                        'data': serie
                                    });
                                    //colorsNum++;
                                }
                            }
                        }

                        colNum++;
                    }
                    //console.log(d);

                    if(d.length>0) {
                        _this.lineSeries = [];
                        _this.lineSeries = d;
                        _this.lineChartDone = true;

                        let autoScaleYsetting = document.getElementById('form-check-line-y-auto-scale'),
                            autoScaleYAxis = (autoScaleYsetting.checked) ? undefined : 0;

                        /*let _colors = [];
                        for(let c=0; c<colorsNum;c++) {
                            _colors.push('#ffffff');
                        }*/

                        setTimeout(() => {
                            //https://github.com/apexcharts/vue-apexcharts/issues/142
                            _this.$refs.chartLine.updateOptions({ yaxis:{min:autoScaleYAxis} })
                            //_this.$refs.chartLine.updateOptions({ chart: {width:'95%', height:'80%', legend:{labels:{colors:_colors}}}, });
                            _this.$refs.chartLine.updateOptions({ chart: {width:'95%', height:'80%'}, });
                        }, 200);
                    }
                }
            }
            else
                _app.$helper.notifyError(`You selected the same columns for timeline and label. Please, reconfigure you selection.`);
        },
        updateBarChart(data) {
            this.barChartDone = false;

            let _this = this,
                checksCols = document.getElementsByClassName('form-check-bar-columns'),
                selectedColIdx = -1,
                checksData = document.getElementsByClassName('form-check-bar-data'),
                selectedDataCollection = [];

            for(let i=0; i<checksCols.length; i++) {
                if(checksCols[i].checked) {
                    let _obj = _this.columnsLabel.filter(obj => {
                                    return obj.idx==checksCols[i].getAttribute('data-idx');
                                })[0];
                    selectedColIdx = _obj.idx;
                }
            }

            for(let i=0; i<checksData.length; i++) {
                if(checksData[i].checked) {
                    let _obj = _this.columnsData.filter(obj => {
                                    return obj.idx==checksData[i].getAttribute('data-idx');
                                })[0];
                    selectedDataCollection.push(_obj.idx);
                }
            }
            
            if(selectedDataCollection.length>0) { //just in case (to emphasize the logic) -> gui does not allow unchecked options, anyway
                console.log('todo render bar chart');
            }
        },
        removeSumsRow() {
            let sumRowId = 'lab22-grid-sum',
                oldRow = document.getElementById(sumRowId);
            if(oldRow)
                oldRow.remove();
        },
        clearGrid() {
            this.gridData.columns = [];
            this.gridData.rows = [];
            this.currentPage = 0;
        },
        toggleEditQuery() {
            let timeout = 0;
            if(this.filtersSidebarVisible) {
                this.toggleFiltersSidebar();
                timeout = 200;
            }
            
            setTimeout(() => {
                let x = document.getElementById("report-sidebar-wrapper"),
                    y = document.getElementById("button-sidebar-wrapper");

                if(this.reportEditorVisible) {
                    this.reportEditorVisible = false;
                    this.gearing = false;
                    //this.showFiltersDescr = true;

                    y.classList.add('button-sidebar-hidden');
                    y.classList.remove('button-sidebar-shown');

                    x.classList.add('report-sidebar-hidden');
                    x.classList.remove('report-sidebar-shown');
                }
                else {
                    //this.showFiltersDescr = false;
                    this.reportEditorVisible = true;
                    this.gearing = true;

                    y.classList.add('button-sidebar-shown');
                    y.classList.remove('button-sidebar-hidden');

                    x.classList.add('report-sidebar-shown');
                    x.classList.remove('report-sidebar-hidden');
                }
            }, timeout);
        },
        toggleFiltersSidebar(){
            let x = document.getElementById("filters-sidebar-wrapper"),
                y = document.getElementById("button2-sidebar-wrapper");

            if(this.filtersSidebarVisible) {
                this.filtersSidebarVisible = false;
                this.gearing2 = false;
                //this.showFiltersDescr = true;
                
                y.classList.add('button2-sidebar-hidden');
                y.classList.remove('button2-sidebar-shown');

                x.classList.add('filters-sidebar-hidden');
                x.classList.remove('filters-sidebar-shown');
            }
            else {
                //this.showFiltersDescr = false;
                this.filtersSidebarVisible = true;
                this.gearing2 = true;
                
                y.classList.add('button2-sidebar-shown');
                y.classList.remove('button2-sidebar-hidden');

                x.classList.add('filters-sidebar-shown');
                x.classList.remove('filters-sidebar-hidden');
            }
        },
        /*hideAllSlidingMenus() {
            //hide filters info
            let x = document.getElementById("filters-sidebar-wrapper"),
                y = document.getElementById("button2-sidebar-wrapper");

            //if(this.filtersSidebarVisible) {
                this.filtersSidebarVisible = false;
                this.gearing2 = false;
                //this.showFiltersDescr = true;
                
                y.classList.add('button2-sidebar-hidden');
                y.classList.remove('button2-sidebar-shown');

                x.classList.add('filters-sidebar-hidden');
                x.classList.remove('filters-sidebar-shown');
            //}

            //hide report editor
            x = document.getElementById("report-sidebar-wrapper");
            y = document.getElementById("button-sidebar-wrapper");

            //if(this.reportEditorVisible) {
                this.reportEditorVisible = false;
                this.gearing = false;
                //this.showFiltersDescr = true;

                y.classList.add('button-sidebar-hidden');
                y.classList.remove('button-sidebar-shown');
                
                x.classList.add('report-sidebar-hidden');
                x.classList.remove('report-sidebar-shown');
            //}
        },*/
        changePage() {
            if(this.currentPage>0) {
                const _allRows = this.allRows.length,
                    _page = this.currentPage,
                    _addPage = ((_allRows % PAGE_SIZE)==0) ? 0 : 1,
                    _allPages = parseInt(_allRows/PAGE_SIZE)+_addPage,
                    _recStart = ((_page-1)*PAGE_SIZE)+1,
                    _recEnd = ((_page*PAGE_SIZE)>_allRows) ? _allRows : (_page*PAGE_SIZE);
                
                this.showBackButton = (_page==1) ? false : true;
                this.showNextButton = (_page==_allPages) ? false : true;

                let i = 0;
                this.gridData.rows = this.allRows.filter(obj => {
                                            i++;
                                            return (i>=_recStart && i<=_recEnd);
                                        });
        
                this.pagesInfo = `${_page}/${_allPages} pages, records ${_recStart}-${_recEnd}, total records ${_allRows}`;
            }
            else {
                this.showBackButton = false;
                this.showNextButton = false;
                this.pagesInfo = '';
            }
        },
        backPage() {
            this.currentPage--;
        },
        nextPage() {
            this.currentPage++;
        },
        toggleAutoRefresh() {
            this.autoRefresh = !this.autoRefresh;

            let val = (this.autoRefresh) ? 'true' : 'false';
            localStorage.setItem('lab22-reporting-autorefresh',val);
        },
        toggleFreeze() {
            this.freeze = !this.freeze;
            console.log('TODO save freeze setting to Report');
        },
        showInfoModal(title, html, objDescr) {
            let _html = html;
            if(objDescr=='model-json' || objDescr=='model-json-raw')
                _html = (html) ? JSON.stringify(html) : JSON.stringify('');

            this.$vfm.show({
                component: ReportingInfoModal,
                bind: {
                    'title': title,
                    'html': _html,
                    'objDescr': objDescr
                }
            });
        },
        showConfigModal() {
            this.$vfm.show({
                component: ReportingConfigModal,
                bind: {
                    //
                },
                on: {
                    ok: (json) => {
                        //gui reinit
                        this.noModelId = false;
                        this.selectedModel = json.modelName;

                        let model = this.userModels.filter(obj => {
                                        return obj.name==this.selectedModel;
                                    })[0];
                        this.currentReport.icon = model.icon_cls

                        //load report
                        this.queryJson = json; //triggers watch and updated this.currentReport.queryJson
                        //setTimeout(() => {
                            this.reportFromPaste = true;
                            this.loadReport(this.currentReport, json.modelId, true);
                        //}, 200);
                    }
                }
            });
        },
        showDrilDownModal(title, column, value, query) {
            let frame = document.getElementById("iframe-qb"),
                _this = this;
            frame = frame ? frame.contentWindow : null;

            let idx = this.getColumnIndexByKey(column.id);

            let _timeout = 1;
            if(_this.gearing) {
                _this.showEdittoggleEditQueryQuery();
                _timeout = 200;
            }
            
            if(_this.gearing2) {
                _this.toggleFiltersSidebar();
                _timeout = 200;
            }

            setTimeout(() => {
                this.$vfm.show({
                    component: ReportingDrillDown,
                    bind: {
                        'title': title,
                        'Column': column,
                        'value': value,
                        'Query': query,
                        'Entities': this.modelEntities.filter(obj => {
                                        return obj.caption!=='Aggr. conditions'; //special entity group for using HAVING clause 
                                                                                //(this group does not contain valid fields/columns)
                                    }),
                        'autoRefresh': this.autoRefresh
                    },
                    on: {
                        run: (newField) => {
                            if(!_this.disabledEdit) {
                                if(_this.autoRefresh)
                                    _this.autoRefreshPause = true;
                                 _app.$helper.notify('Reloading data...','info',2000);
                                _this.canEmit = false;
                                _this.sendMessage(frame, new Message("msg", 'addFilter;'+value+';none;'+JSON.stringify(column))); //add drill-down filter
                                _this.sendMessage(frame, new Message("msg", 'removeColumn;'+value+';none;'+JSON.stringify(column))); //remove drill-down column
                                _this.canEmit = true;
                                _this.sendMessage(frame, new Message("msg", 'replaceColumn;'+value+';run;'+newField, idx));//add new column
                                _this.loading = true;
                            } else
                                _app.$helper.notifyInfo('Report model has not reloaded yet. Please, try again.');
                        },
                        edit: (newField) => {
                            if(!_this.disabledEdit) {
                                _this.autoRefreshPause = true;
                                 _app.$helper.notify('Preparing query editor...','info',2000);
                                _this.canEmit = false;
                                _this.sendMessage(frame, new Message("msg", 'addFilter;'+value+';none;'+JSON.stringify(column))); //add drill-down filter
                                _this.sendMessage(frame, new Message("msg", 'removeColumn;'+value+';none;'+JSON.stringify(column))); //remove drill-down column
                                _this.canEmit = true;
                                _this.sendMessage(frame, new Message("msg", 'replaceColumn;'+value+';edit;'+newField, idx));//add new column
                            } else
                                _app.$helper.notifyInfo('Report model has not reloaded yet. Please, try again.');
                        }
                    }
                });
            }, _timeout);
        },
        selectModel(name) {
            this.selectedModel = name;
        },
        changeDisplayOption(option) {
            this.displayOptionNew = option;
            switch(option) {
                case 'table':
                    document.getElementById("displayPieChart").style.display = "none";
                    document.getElementById("displayLineChart").style.display = "none";
                    document.getElementById("displayBarChart").style.display = "none";
                    document.getElementById("displayTableGrid").style.display = "block";
                    break;
                case 'pie':
                    document.getElementById("displayTableGrid").style.display = "none";
                    document.getElementById("displayLineChart").style.display = "none";
                    document.getElementById("displayBarChart").style.display = "none";
                    document.getElementById("displayPieChart").style.display = "block";
                    break;
                case 'line':
                    document.getElementById("displayTableGrid").style.display = "none";
                    document.getElementById("displayPieChart").style.display = "none";
                    document.getElementById("displayBarChart").style.display = "none";
                    document.getElementById("displayLineChart").style.display = "block";
                    break;
                case 'bar':
                    document.getElementById("displayTableGrid").style.display = "none";
                    document.getElementById("displayPieChart").style.display = "none";
                    document.getElementById("displayLineChart").style.display = "none";
                    document.getElementById("displayBarChart").style.display = "block";
                    break;
            }

            /*if(this.autoRefresh) {
                setTimeout(() => {
                    console.log('changeDisplayOption');
                    this.runQuery();
                }, 1000);
            }*/
        },
        getJsonInfo(json) {
            let _this = this,
                cols = json.cols,
                idx = (_this.queryJson.showRowNum.toString()==='1') ? 1 : 0; //if row_num column exists we add 1 to col index
            
            _this.columnsDt = [];
            _this.columnsLabel = [];
            _this.columnsData = [];

            for(let i=0; i<cols.length; i++) {
                if( typeof cols[i].expr.func === 'undefined' || cols[i].expr.func=='' ) { //not aggregate column
                    if(cols[i].dtype==10) //datetime for timeline chart(s)
                        _this.columnsDt.push({"idx":idx, "name":cols[i].cptn});
                    _this.columnsLabel.push({"idx":idx, "name":cols[i].cptn});
                    idx++;
                } else { //aggregate column
                    this.columnsData.push({"idx":idx, "name":cols[i].cptn});
                    idx++;
                }
            }
        },
        inputChecked(type, cls, id, event=null) {
            /*if(event)
                event.preventDefault();*/

            setTimeout(() => {
                let inputs = document.getElementsByClassName(cls);
                
                if(inputs.length==0)
                    return false;
                else {
                    for(let i=0; i<inputs.length; i++) {
                        if(id!==inputs[i].id)
                        inputs[i].checked = false;
                    }
                    if(event==null) {
                        let input = document.getElementById(id);
                            input.checked = true;
                    }
                }
            }, 10);
        },
        initInputChecked(type) {
            setTimeout(() => { //timeout due to slow messaging to query builder iframe
                let inputsDt = document.getElementsByClassName('form-check-'+type+'-dt'),
                    inputsColumns = document.getElementsByClassName('form-check-'+type+'-columns'),
                    inputsData = document.getElementsByClassName('form-check-'+type+'-data');
                
                if(inputsDt.length > 0) {
                    let firstIdColumns = inputsDt[0].getAttribute('data-idx');
                    this.inputChecked(type, 'form-check-'+type+'-dt', type+'-dt-'+firstIdColumns);
                }

                if(inputsColumns.length > 0) {
                    let firstIdColumns = inputsColumns[0].getAttribute('data-idx');
                    this.inputChecked(type, 'form-check-'+type+'-columns', type+'-column-'+firstIdColumns);
                }

                if(inputsData.length > 0) {
                    let firstIdData = inputsData[0].getAttribute('data-idx');                
                    this.inputChecked(type, 'form-check-'+type+'-data', type+'-data-'+firstIdData);
                }
            }, 200);
        },
        onTHeadContextMenu(col) {
            let _this = this;

            let dtypes = DTYPE.getDTypeList(),
                dtype = dtypes.filter(obj => {
                        return obj.id==col.dtype;
                      }),
                _html = `
                        Description: <span style="color:#bbc5d6;">${col.descr}</span>
                        <br />
                        Data type: <span style="color:#bbc5d6;">${dtype[0].name}</span>
                        <br />
                        DB field: <span style="color:#bbc5d6;">${col.dbId}</span>
                    `;
                _html+= (col.custom=='') ? '' : `<br />Custom formula: <span style="color:#bbc5d6;">${col.custom}</span>`;
                _html+= (col.func=='') ? '' : `<br />Aggr. function: <span style="color:#bbc5d6;">${col.func}</span>`;
            
            let menuItems = [
                {
                    text: 'Column info',
                    iconCls: 'fa-solid fa-circle-info',
                    handler: (ctx) => {
                        _this.showInfoModal('Column "'+col.cptn+'"', _html, 'column-info');
                    }
                },
                /*TODO {
                    text: 'Format data',
                    iconCls: 'fa-solid fa-dummy-lab22',
                    handler: (ctx) => {
                        console.log('TODO open formatting options modal');
                    }
                },*/
                /*{
                    text: 'Model info',
                    //iconCls: 'fa-solid fa-info',
                    handler: (ctx) => {
                        _this.showInfoModal('Model '+(_this.queryJson.modelName||_this.selectedModel), _this.modelInfo, 'model-info');
                    }
                },*/ 
            ];

            _this.$refs.gridTHeadContextMenu.open(menuItems, event);
        },
        onRowContextMenu(item, event) { //item = data row
            event.preventDefault();

            //no context menu in following cases:
            //   1. model not yet loaded
            //   2. column = row_num or 
            //   3. is aggregate column
            //   4. is custom formula column
            //   5. is datetime column (filters cannot be applied to "equals")
            //
            //1.
            if(this.disabledEdit)
                return false;
            //2.
            let fieldName = event.srcElement.dataset.title;
            if(fieldName.toLowerCase()=='row_num' || fieldName.toLowerCase()=='#')
                return false;
            //3. & 4.
            let _query = this.queryJson.cols,
                _cols = _query,
                _col = null;
            for(let i=0; i<_cols.length; i++) {
                let _expr = _cols[i].expr;
                if(_cols[i].cptn.toLowerCase()==fieldName.toLowerCase()) {
                    if("args" in _expr) //in "args" there is definition for aggregate function
                        return false;
                    else if (_cols[i].custom!=='') //column has custom formula which we cannot use in drilldown or filtering
                        return false;
                    else if (_cols[i].dtype==10) //column is datetime
                        return false;
                    else
                        _col = _cols[i];
                }
            }
            
            let fieldKey = '',
                fieldVal = '',
                frame = document.getElementById("iframe-qb"),
                _this = this;
            
            frame = frame ? frame.contentWindow : null;

            let menuItems = [
                {
                    text: 'Drill down',
                    iconCls: 'fa-solid fa-search-plus',
                    handler: (ctx) => { //ctx = grid row
                        fieldKey = event.srcElement.dataset.title;
                        
                        for (const [key, value] of Object.entries(item)) {
                            if(key==fieldKey)
                                fieldVal = value;
                        }

                        let _getColumnInfoByName = _this.getColumnInfoByName(fieldName);
                        if(_getColumnInfoByName)
                            _this.showDrilDownModal('Drill down selection', _getColumnInfoByName, fieldVal, _query);
                        else {
                            console.log(`issue in function getColumnInfoByName(${fieldName})`);
                            _app.$helper.notifyInfo('Report configuration was not correctly updated. Please, try again.');
                        }
                    }
                }, 
                {
                    text: 'Filter by value and run',
                    iconCls: 'fa-solid fa-refresh',
                    handler: (ctx) => {
                        fieldKey = event.srcElement.dataset.title;
                        
                        for (const [key, value] of Object.entries(item)) {
                            if(key==fieldKey)
                                fieldVal = value;
                        }
                        if(!_this.disabledEdit) {
                            _this.sendMessage(frame, new Message("msg", 'addFilter;'+fieldVal+';run;'+JSON.stringify(_col)));
                            _this.loading = true;
                        } else {
                            console.log(`issue with this.disabledEdit flag`);
                            _app.$helper.notifyInfo('Report model has not reloaded yet. Please, try again.');
                        }
                    }
                },
                {
                    text: 'Filter by value and edit',
                    //iconCls: 'fa-solid fa-edit',
                    handler: (ctx) => {
                        fieldKey = event.srcElement.dataset.title;
                        
                        for (const [key, value] of Object.entries(item)) {
                            if(key==fieldKey)
                                fieldVal = value;
                        }

                        if(!_this.disabledEdit)
                            _this.sendMessage(frame, new Message("msg", 'addFilter;'+fieldVal+';edit;'+JSON.stringify(_col)));
                        else
                            _app.$helper.notifyInfo('Report model has not loaded yet. Please, try again.');
                    }
                }, 
            ];

            _this.$refs.gridRowContextMenu.open(menuItems, event, item);
        },
        postShareConfig() {
            let json = this.currentReport.queryJson;
            if(json==null || json.length==0) {
                _app.$helper.notifyInfo('Current report configuration is not valid to share');
                return false;
            } else {
                const progress = this.$progress.start();

                //post data to db and show user the guid
                let _guid = this.guid(),
                    _data = {
                        guid: _guid,
                        queryJson: JSON.stringify(json)
                    }; 

                this.$app.$api.post('Reporting/InsReportShareLink', _data).then(result => {
                    if( result.data && parseInt(result.data)>0 ) {
                        //console.log('id='+result.data);
                    } else {
                        this.$app.notify('There was no error but Share Link Id was not returned. You can continue, anyway...');
                    }
                    progress.finish();
                    this.showInfoModal('Report configuration share', _guid, 'share-config');
                }, (error) => {
                    progress.finish();
                    _app.handleError(error);
                });
            }
        },
        copyConfig() {
            let json = this.currentReport.queryJson;
            if(json==null || json.length==0) {
                _app.$helper.notifyInfo('Current report configuration is not valid to share');
                return false;
            } else
                _app.$helper.copyToClipboard( JSON.stringify(json) );
        },
        async saveReport(report) {
            let _this = this;

            _this.$emit("isReportInProgress");            
            _this.canEmit = false;
            _this.disabledRefresh = true;
            _this.disabledEdit = true;

            const progress = _this.$progress.start();

            try {
                report.name = _this.dirtyName(report.name, false)

                const res = await _this.store.dispatch('setReport', {report:report, guid:_this.guid()});
            
                if(res && res.id>0) {
                    //reload report
                    const res2 = await _this.store.dispatch('fetchReport', {id:res.id});

                    let ws = _this.store.getters.getCurrentWs,
                        payload = {"report":report, "ws":ws, cb:{"tabId":null,"tabName":null,"tabIcon":null}};
                    _this.store.commit('updateWsReport', payload);

                    progress.finish();

                    /*let oldId = report.id;
                    if(oldId<=0)
                        _app.$helper.notifyInfo('Report '+_this.currentReport.name+' created');
                    else
                        _app.$helper.notifyInfo('Report '+_this.currentReport.name+' saved');*/
                    _app.$helper.notifyInfo('Report saved');

                    _this.currentReport.reportId = res.id
                    _this.currentReport.name = _this.dirtyName(_this.currentReport.name, false);
                    _this.$emit("isReportInProgress");
                    _this.disabledEdit = false;
                    _this.disabledRefresh = false;
                    _this.canEmit = true;

                    //update reportId in workspace & tabs
                    _this.$emit("reportChange", _this.currentReport);

                    return true;
                } 
                else {
                    progress.finish();
                    _app.$helper.notifyError('Unknown error in saving report! Please, try again.');
                    return false;
                }
            }
            catch(err) {
                progress.finish();
                _app.handleError(err);
                _app.$helper.notifyError(`Error in saving report => ${err.message} Please, try again.`);
                return false;
            }
        },
        renameReport(report) {
            this.$vfm.show({
                component: ReportingNameModal,
                bind: {
                    title: 'Rename report',
                    oldName: this.currentReport.name,
                    action: 'renameReport'
                },
                on: {
                    ok: (data) => {
                        this.currentReport.name = this.dirtyName(data.name, true)
                        this.$emit("reportChange", this.currentReport);
                    }
                }
            });
        },
        exportPng() {
            let canvasEl = '';
            switch(this.displayOptionNew) {
                case 'table': canvasEl='displayTableGrid'; break;
                case 'pie': canvasEl='displayPieChart'; break;
                case 'line': canvasEl='displayLineChart'; break;
                case 'bar': canvasEl='displayBarChart'; break;
                default: break;
            }
            
            if(canvasEl=='' || this.allRows.length==0)
                _app.$helper.notifyInfo('No data to export');
            else {
                const progress = this.$progress.start();
                try {
                    let link = document.createElement("a");
                        link.download = 'reporting_export_' + new Date().toLocaleDateString() + '.png';
                    domtoimage.toBlob(document.getElementById(canvasEl)).then(function (blob) { //https://github.com/1904labs/dom-to-image-more
                        progress.finish();
                        link.href = URL.createObjectURL(blob);
                        link.click();
                    });

                } catch(err) {
                    _app.$helper.notifyError('Error exporting PNG => '+err.message+'. Please, try again.');
                    progress.finish();
                    _app.handleError(err);
                }
            }
        },
        exportCsv(cols, rows, separator=';') {
            if(cols.length==0) {
                _app.$helper.notifyInfo('Columns definition not available');
                return false;
            }

            if(rows.length==0) {
                _app.$helper.notifyInfo('No data available to export');
                return false;
            }
            
            let col = [],
                csv = [];

            for (let i=0; i < cols.length; i++) {
                let val = cols[i].title.replace(/(\r\n|\n|\r)/gm, '').replace(/(\s\s)/gm, ' ')
                val = val.replace(/"/g, '""');
                col.push('"' + val + '"');
            }
            csv.push(col.join(separator));

            for (let i=0; i < rows.length; i++) {
                let row = [];
                for (const [key, value] of Object.entries(rows[i])) {
                    let val = value.toString().replace(/(\r\n|\n|\r)/gm, '').replace(/(\s\s)/gm, ' ')
                    val = val.replace(/"/g, '""');
                    row.push('"' + val + '"');
                }
                csv.push(row.join(separator));
            }

            let csv_string = csv.join('\n'),
                filename = 'reporting_export_' + new Date().toLocaleDateString() + '.csv',
                link = document.createElement('a');
            
            link.style.display = 'none';
            link.setAttribute('target', '_blank');
            link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv_string));
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        //#endregion

        //#region AI
        startChatGpt(prompt) {
            if(this.selectedModel=='Select model') {
                _app.$helper.notifyWarning(this.missingModelWarning);
                return false;
            }
            else {
                this.$vfm.show({
                    component: ReportingChatGptModal,
                    bind: {
                        'modelName': this.selectedModel,
                    },
                    on: {
                        ok: (json) => {
                            //gui reinit
                            this.noModelId = false;
                            this.selectedModel = json.modelName;

                            let model = this.userModels.filter(obj => {
                                            return obj.name==this.selectedModel;
                                        })[0];
                            this.currentReport.icon = model.icon_cls

                            //load report
                            this.queryJson = json; //triggers watch and updated this.currentReport.queryJson
                            //setTimeout(() => {
                                this.reportFromPaste = true;
                                this.loadReport(this.currentReport, json.modelId, true);
                            //}, 200);
                        }
                    }
                });
            }
        },
        //#endregion

        //#region Helpers
        sendMessage(windowObj, payload) {
            //console.log(payload);
            if(windowObj)
                windowObj.postMessage(payload, "*");
        },
        getColumnInfoByKey(fieldKey) {
            let _cols = this.queryJson.cols,
                _col = null;
            for(let i=0; i<_cols.length; i++) {
                if(_cols[i].id.toLowerCase()==fieldKey.toLowerCase())
                    _col = _cols[i]
            }
            return _col;
        },
        getColumnInfoByName(fieldName) {
            let _cols = this.queryJson.cols,
                _col = null;
            for(let i=0; i<_cols.length; i++) {
                if(_cols[i].cptn.toLowerCase()==fieldName.toLowerCase())
                    _col = _cols[i]
            }
            return _col;
        },
        getColumnIndexByKey(fieldKey) {
            let _cols = this.queryJson.cols,
                _idx = null;
            for(let i=0; i<_cols.length; i++) {
                if(_cols[i].id.toLowerCase()==fieldKey.toLowerCase())
                    _idx = i;
            }
            return _idx;
        },
        parseConditionsHtml(html) {
            let _html = html.replaceAll('class="eqjs-query-text-conj"', 'class="eqjs-query-text-purple"');
                _html = _html.replaceAll('class="eqjs-query-text-attr"', 'class="eqjs-query-text-yellow"');
                _html = _html.replaceAll('class="eqjs-query-text-op"', 'class="eqjs-query-text-underline"');
                _html = _html.replaceAll('>&lt;&gt;<', '><');

            let parent = document.getElementById('p-conditions-html');
                parent.innerHTML = _html;

            let child = parent.children[0],
                children = child.children;
            
            for(let i=0; i<children.length; i++) {
            if( children[i].classList.contains("eqjs-query-text-purple") )
                children[i].innerHTML = "<br />&nbsp;&nbsp;&nbsp;" + children[i].innerHTML + "<br />";
            }
        },
        objMembersCount(obj, min) {
            if( !_app.$helper.isEmpty(obj, false) )
                return (obj.length>min) ? true : false;
            else
                return false;
        },
        getDimensions() {
            this.innerWidth = document.documentElement.clientWidth;
            this.innerHeight = document.documentElement.clientHeight;
            this.resizeFrame(this.innerWidth, this.innerHeight);
        },
        resizeFrame(width, height) {
            const MIN_WIDTH = 850,
                  MAX_WIDTH = 850; //currently, we do not let the query builder editor to be wider
            width = (width>MAX_WIDTH)  ? MAX_WIDTH : width;

            const MIN_HEIGHT = 500,
                  WIDTH_CORRECTION = 650,
                  HEIGHT_CORRECTION = 220,
                  FRAME_WIDTH = (MIN_WIDTH>(width-WIDTH_CORRECTION)) ? MIN_WIDTH : (width-WIDTH_CORRECTION),  //min width  for report editor iframe to get full functionality (not mobile!)
                  FRAME_HEIGHT = (MIN_HEIGHT>height-HEIGHT_CORRECTION) ? MIN_HEIGHT : height-HEIGHT_CORRECTION, //min height for report editor iframe to get full functionality (not mobile!) on no sliders
                  FRAME_TOP_OFFSET = 209-TABS_HEIGHT+this.ulHeight;

            this.frameWidth = FRAME_WIDTH.toString();
            this.frameWidthPx = (FRAME_WIDTH+10).toString()+'px';
            this.frameHeight = (FRAME_HEIGHT).toString();
            this.frameHeightPx = (FRAME_HEIGHT).toString()+'px';
            this.frameTopOffsetPx = FRAME_TOP_OFFSET.toString()+'px';
            this.frameTopOffsetPxButton = (FRAME_TOP_OFFSET+26).toString()+'px';
            this.frameLeftOffsetPxButton = (FRAME_WIDTH+247).toString()+'px';
            this.maxLengthFilterDescr = (FRAME_WIDTH>512) ? '512px' : FRAME_WIDTH.toString+'px';
        },
        getTempModelName(name) {
            return (name=='Select model') ? '' : name+' TABLE';
        },
        guid() {
            function s4() {
                return Math.floor((1 + Math.random()) * 0x10000)
                    .toString(16)
                    .substring(1);
            }

            return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
        },
        dirtyName(name, isDirty) {
            if(isDirty)
                return ( name.substring(0,1)=='*' ) ? name : '*'+name;
            else
                return ( name.substring(0,1)=='*' ) ? name.slice(1) : name;
        },
        isValidDate(d) {
            return d instanceof Date && !isNaN(d);
        },
        //#endregion
    }
}
</script>

<style scoped>
/*.visible-divider {
    height:1px; 
    border-bottom: solid 1px #282d36;
}
.hidden-divider {
    height:1px; 
    border-bottom: solid 1px #161616;
}

.dropdown-header-small, 
.dropdown-item-small,
.dropdown-icon-small {
    font-size: 0.7rem !important;
}
.dropdown-header-small {
    padding-top: 0.1rem !important
}
.dropdown-item-small {
    padding-top: 0px !important
}*/

.btn-save-dirty {
  background-color: #FF19FF !important;
  border-color: #FF19FF !important;
  color: #ffffff !important;
}

.bw {
    filter: brightness(0) invert(1);
    opacity: 0.2;
}

.btn-outline-secondary-transparent {
    color: #ffffff;
    border-color: #666666;
}
.btn-outline-secondary-transparent:hover {
    background-color: #161616;
}

#iframe-qb {
    opacity: v-bind(opacity);
    padding: 0 0 0 0; 
    margin: 0 0 0 0;
}

div.pages-info {
    display: inline-block !important;
    margin-left: 20px;
}

.toolbar-filters-descr {
  margin-left: 20px;
  margin-top: 8px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: v-bind(maxLengthFilterDescr);
  color: #808697;
  opacity: 0.7;
}

.toolbar-no-model-descr {
  margin-left: 20px;
  margin-top: 8px;
}

.toolbar-filters-descr:hover {
  cursor: pointer;
}

#report-wrapper {
    padding-left: 0;
    height: 100%;
}

#report-sidebar-wrapper {
    z-index: 999;
    position: fixed;
    top: v-bind(frameTopOffsetPx);
    right: 250px;
    width: v-bind(frameWidthPx);
    margin-right: -250px;
    overflow-y: auto;
    overflow-x: hidden;
    background: #161616;
    transform: translateX(100%);
    -webkit-transform: translateX(100%);
}

.report-sidebar-hidden {
    animation: slide-out 0.5s backwards;
    -webkit-animation: slide-out 0.5s backwards;
}

.report-sidebar-shown {
    height: 100%;
    animation: slide-in 0.5s forwards;
    -webkit-animation: slide-in 0.5s forwards;
}

#button-wrapper {
    padding-left: 0;
    height: 100%;
}

#button-sidebar-wrapper {
    /*opacity: v-bind(opacity);*/
    z-index: 999;
    position: fixed;
    top: v-bind(frameTopOffsetPxButton);
    right: v-bind(frameLeftOffsetPxButton);
    margin-right: -250px;
    overflow-y: auto;
    overflow-x: hidden;
    background: #161616;
    transform: translateX(100%);
    -webkit-transform: translateX(100%);
}

.button-sidebar-hidden {
    animation: slide-out 0.5s backwards;
    -webkit-animation: slide-out 0.5s backwards;
}

.button-sidebar-shown {
    max-height: 45px;
    animation: slide-in 0.5s forwards;
    -webkit-animation: slide-in 0.5s forwards;
}

#filters-wrapper {
    padding-left: 0;
    height: 100%;
}

#filters-sidebar-wrapper {
    height: auto;
    z-index: 999;
    position: fixed;
    /*opacity: v-bind(opacity);*/
    top: v-bind(frameTopOffsetPx);
    right: 250px;
    width: 500px;
    margin-right: -250px;
    overflow-y: scroll;
    overflow-x: hidden;
    background: #161616;
    border:1px solid #666666;
    transform: translateX(100%);
    -webkit-transform: translateX(100%);
}

#filters-sidebar-wrapper > h4,
#filters-sidebar-wrapper > p {
    opacity: v-bind(opacity);
}

.filters-sidebar-hidden {
    animation: slide-out 0.5s backwards;
    -webkit-animation: slide-out 0.5s backwards;
}

.filters-sidebar-shown {
    height: 100%;
    animation: slide-in 0.5s forwards;
    -webkit-animation: slide-in 0.5s forwards;
}

#button2-sidebar-wrapper {
    /*opacity: v-bind(opacity);*/
    z-index: 999;
    position: fixed;
    top: v-bind(frameTopOffsetPxButton);
    right: 749px;
    margin-right: -250px;
    overflow-y: auto;
    overflow-x: hidden;
    background: #161616;
    transform: translateX(100%);
    -webkit-transform: translateX(100%);
}

.button2-sidebar-hidden {
    animation: slide-out 0.5s backwards;
    -webkit-animation: slide-out 0.5s backwards;
}

.button2-sidebar-shown {
    max-height: 45px;
    animation: slide-in 0.5s forwards;
    -webkit-animation: slide-in 0.5s forwards;
}

@keyframes slide-in {
  0% {
    -webkit-transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(0%);
  }
}

@-webkit-keyframes slide-in {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes slide-out {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
}

@-webkit-keyframes slide-out {
  0% {
    -webkit-transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(100%);
  }
}
</style>

<style>
.eqjs-query-text-yellow {
    color: #FFCE19;
}
.eqjs-query-text-purple {
    color: #FF19FF;
}
.eqjs-query-text-underline {
    text-decoration: underline;
    color: #bbc5d6;
}
.eqjs-query-text-expr {
    text-decoration: underline;
    color: #ffffff;
}

.grid-sums {
    /*background-color: rgb(0, 0, 0);*/
    /*color: rgb(128, 134, 151);*/
    font-weight: 500;
}
.grid-sums-cell {
    padding-left: 0.5rem;
}

.apexcharts-xaxis-label,
.apexcharts-yaxis-label {
    fill: #bbc5d6 !important;
}
</style>