<template>
  <div class="form-group row" :class="this.class ? this.class : ''">
    <slot></slot>
  </div>  
</template>

<script>
  export default {
    name: 'FormRow',
    inheritAttrs: false,
    props: {
      class: String
    }    
  }
</script>
