<template>

  <ToolbBar>
    <BaseButton @click="addComponent" text="Add new" class="btn-primary"></BaseButton>    
  </ToolbBar>

  <VScroll>
    <BaseForm @submit="onSave" class="form-horizontal inf-card">
      
      <template v-if="ambientSensorComponents.length > 0">
        <FormSection class="device-comp-section" title="Ambient sensor">
          <template #body>
            <FormRow v-for="item in ambientSensorComponents" :key="item.id">
              <BaseField :name="item.id + '_number'" required v-model.number="item.number" type="number" min="1" max="3" :selectOnFocus="true" class="device-comp-num-field no-arrows" />
              <BaseField :name="item.id + '_component'" :value="getModelName(item.modelId)" readonly class="device-comp-field"></BaseField>
              <BaseButton text="Remove" @click="removeComponent(item)" class="btn-outline-secondary" style="width:auto;margin-left:auto;"></BaseButton>
            </FormRow>
          </template>
        </FormSection>        
      </template>

      <template v-if="displayComponents.length > 0">
        <FormSection class="device-comp-section" title="Display controllers">
          <template #body>
            <FormRow v-for="item in displayComponents" :key="item.id">
              <BaseField :name="item.id + '_number'" required v-model.number="item.number" type="number" min="1" :max="3" :selectOnFocus="true" class="device-comp-num-field no-arrows" />
              <BaseField :name="item.id + '_component'" v-model.number="item.modelId" as="select" :data="displayModelOptions" class="combo-field" style="width:230px"></BaseField>
              <BaseField :name="item.id + '_label'" placeholder="Display label" required v-model="item.label" style="margin-left: 15px;width:180px;"></BaseField>
              <!-- <label style="width:48px;padding:9px 0px 0px 15px;">ALS</label> -->
              <BaseField :name="item.id + '_als'" v-model.number="item.als" required placeholder="ALS" as="select" :data="alsComboOptions" class="combo-field" style="margin-left: 15px;width:80px;"></BaseField>
              <BaseField :name="item.id + '_touchMatrix'" v-model.number="item.touchMatrix" required placeholder="No touch screen" as="select" :data="touchMatrixComboOptions" class="combo-field" style="margin-left: 15px;width:150px;"></BaseField>              
              <BaseButton text="Remove" @click="removeComponent(item)" class="btn-outline-secondary" style="width:auto;margin-left:auto;"></BaseButton>
            </FormRow>
          </template>
        </FormSection>        
      </template>

      <template v-if="ecuComponents.length > 0">
        <FormSection class="device-comp-section" title="ECU">
          <template #body>
            <FormRow v-for="item in ecuComponents" :key="item.id">
              <BaseField :name="item.id + '_number'" required v-model.number="item.number" type="number" min="1" max="3" :selectOnFocus="true" class="device-comp-num-field no-arrows" />
              <BaseField :name="item.id + '_component'" :value="getModelName(item.modelId)" readonly class="device-comp-field"></BaseField>
              <BaseButton text="Remove" @click="removeComponent(item)" class="btn-outline-secondary" style="width:auto;margin-left:auto;"></BaseButton>
            </FormRow>
          </template>
        </FormSection>        
      </template>     
      
      <template v-if="networkRelayComponents.length > 0">
        <FormSection v-for="(item, index) in networkRelayComponents" :key="item.id" class="device-comp-section" :title="index == 0 ? 'Network relay' : ''">
          <template #body>
            <FormRow>
              <BaseField :name="item.id + '_number'" required v-model.number="item.number" type="number" min="1" max="3" :selectOnFocus="true" class="device-comp-num-field no-arrows" />
              <BaseField :name="item.id + '_component'" :value="getModelName(item.modelId)" readonly class="device-comp-field"></BaseField>
              <BaseField :name="item.id + '_label'" required placeholder="Relay label" v-model="item.label" style="margin-left: 15px;width:180px;"></BaseField>
              <BaseButton text="Remove" @click="removeComponent(item)" class="btn-outline-secondary" style="width:auto;margin-left:auto;"></BaseButton>
            </FormRow>
            <FormRow>
              <label style="width:80px;padding:8px 0px 0px 4px;">Port 1</label>
              <BaseField :name="item.id + '_optionPort1'" v-model="item.optionPort1" required placeholder="Mode" as="select" :data="netowrkRelayPortOptions" class="combo-field" style="width:80px" />              
              <BaseField :name="item.id + '_labelPort1'" v-model="item.labelPort1" required placeholder="Port label" style="margin-left: 15px;width:300px;"></BaseField>
            </FormRow>
            <FormRow>
              <label style="width:80px;padding:8px 0px 0px 4px;">Port 2</label>
              <BaseField :name="item.id + '_optionPort2'" v-model="item.optionPort2" required placeholder="Mode" as="select" :data="netowrkRelayPortOptions" class="combo-field" style="width:80px" />              
              <BaseField :name="item.id + '_labelPort2'" v-model="item.labelPort2" required placeholder="Port label" style="margin-left: 15px;width:300px;"></BaseField>
            </FormRow>            
          </template>
        </FormSection>        
      </template>      

      <template v-if="networkRouterWithRelayComponents.length > 0">
        <FormSection v-for="(item, index) in networkRouterWithRelayComponents" :key="item.id" class="device-comp-section" :title="index == 0 ? 'Network router with power relay' : ''">
          <template #body>
            <FormRow>
              <BaseField :name="item.id + '_number'" required v-model.number="item.number" type="number" min="1" max="3" :selectOnFocus="true" class="device-comp-num-field no-arrows" />
              <BaseField :name="item.id + '_component'" :value="getModelName(item.modelId)" readonly class="device-comp-field"></BaseField>
              <BaseField :name="item.id + '_label'" placeholder="Router label" required v-model="item.label" style="margin-left: 15px;width:180px;"></BaseField>
              <BaseButton text="Remove" @click="removeComponent(item)" class="btn-outline-secondary" style="width:auto;margin-left:auto;"></BaseButton>
            </FormRow>
            <FormRow>
              <label style="width:80px;padding:8px 0px 0px 4px;">Ruter reset</label>              
              <BaseField :name="item.id + '_labelReset'" v-model="item.labelReset" required placeholder="Reset label" style="margin-left: 15px;width:380px;"></BaseField>
            </FormRow>  
            <FormRow>
              <label style="width:80px;padding:8px 0px 0px 4px;">Port 1</label>              
              <BaseField :name="item.id + '_labelPort1'" v-model="item.labelPort1" required placeholder="Port label" style="margin-left: 15px;width:380px;"></BaseField>
            </FormRow>                          
          </template>
        </FormSection>        
      </template>   

      <template v-if="networkSwitchComponents.length > 0">
        <FormSection v-for="(item, index) in networkSwitchComponents" :key="item.id" class="device-comp-section" :title="index == 0 ? 'Network switch' : ''">
          <template #body>
            <FormRow>
              <BaseField :name="item.id + '_number'" required v-model.number="item.number" type="number" min="1" max="3" :selectOnFocus="true" class="device-comp-num-field no-arrows" />
              <BaseField :name="item.id + '_component'" :value="getModelName(item.modelId)" readonly class="device-comp-field"></BaseField>
              <BaseField :name="item.id + '_label'" placeholder="Switch label" required v-model="item.label" style="margin-left: 15px;width:180px;"></BaseField>
              <BaseButton text="Remove" @click="removeComponent(item)" class="btn-outline-secondary" style="width:auto;margin-left:auto;"></BaseButton>
            </FormRow>
            <FormRow>
              <label style="width:80px;padding:8px 0px 0px 4px;">Switch reset</label>              
              <BaseField :name="item.id + '_labelReset'" v-model="item.labelReset" required placeholder="Reset label" style="margin-left: 15px;width:380px;"></BaseField>
            </FormRow>            
          </template>
        </FormSection>        
      </template>   

      <template v-if="cameraComponents.length > 0">
        <FormSection class="device-comp-section" title="IP Camera">
          <template #body>
            <FormRow v-for="item in cameraComponents" :key="item.id">
              <BaseField :name="item.id + '_number'" required v-model.number="item.number" type="number" min="1" max="3" :selectOnFocus="true" class="device-comp-num-field no-arrows" />
              <BaseField :name="item.id + '_component'" :value="getModelName(item.modelId)" readonly class="device-comp-field"></BaseField>
              <BaseField :name="item.id + '_label'" placeholder="Camera label" required v-model="item.label" style="margin-left: 15px;width:180px;"></BaseField>
              <BaseButton text="Remove" @click="removeComponent(item)" class="btn-outline-secondary" style="width:auto;margin-left:auto;"></BaseButton>
            </FormRow>
          </template>
        </FormSection>        
      </template>      

      <template v-if="yocto5Buttons.length > 0">
        <FormSection  v-for="yocto5BtnComp in yocto5Buttons" :key="yocto5BtnComp.number" class="device-comp-section" title="Yocto button">
          <template #body>
            <FormRow v-for="item in yocto5BtnComp.settings.items" :key="item.number" :class="{ 'inactive': !item.active }">
              <BaseField :name="item.number + '_number'" v-model.number="item.number" type="number" :disabled="!item.active" class="device-comp-num-field no-arrows" />
              <label style="width:auto;padding:9px 0px 0px 0px;margin-right:10px;">If</label>
              <BaseField :name="item.number + '_operator'" v-model="item.operator" :disabled="!item.active" as="select" :data="this.operatorList" valueField="code" class="combo-field" style="width:60px;" />
              <label style="width:auto;padding:9px 0px 0px 0px;margin:0px 10px">of</label>
              <BaseField :name="item.number + '_conditionValue'" v-model="item.conditionValue" placeholder="value" :disabled="!item.active" style="width:60px"></BaseField>
              <label style="width:auto;padding:9px 0px 0px 0px;margin:0px 10px">then</label>
              <BaseField :name="item.number + '_thenValue'" v-model="item.thenValue" placeholder="text" :disabled="!item.active" style="width:120px"></BaseField>
              <label style="width:auto;padding:9px 0px 0px 0px;margin:0px 10px">else</label>
              <BaseField :name="item.number + '_elseValue'" v-model="item.elseValue" placeholder="text" :disabled="!item.active" style="width:120px"></BaseField>
              <!-- <label style="width:auto;padding:9px 0px 0px 0px;margin:0px 10px">Mea</label> -->
              <BaseField :name="item.number + '_measurement'" v-model="item.measurement" placeholder="Measurement" :disabled="!item.active" style="width:120px;margin-left:25px;"></BaseField>  
              <!-- <label style="width:auto;padding:9px 0px 0px 0px;margin:0px 10px">Logical name</label> -->
              <BaseField :name="item.number + '_logicalName'" v-model="item.logicalName" placeholder="Logical name" :disabled="!item.active" style="width:120px;margin-left:15px;"></BaseField>                             
              <BaseButton :text="item.active ? 'Remove' : 'Set'" @click="toggleYoctoButtonActive(item)" class="btn-outline-secondary" style="width:auto;margin-left:auto;"></BaseButton>
            </FormRow>
          </template>
        </FormSection>        
      </template>  

      <template v-if="upsComponents.length > 0">
        <FormSection class="device-comp-section" title="UPS">
          <template #body>
            <FormRow v-for="item in upsComponents" :key="item.id">
              <BaseField :name="item.id + '_number'" required v-model.number="item.number" type="number" min="1" max="3" :selectOnFocus="true" class="device-comp-num-field no-arrows" />
              <BaseButton text="Remove" @click="removeComponent(item)" class="btn-outline-secondary" style="width:auto;margin-left:auto;"></BaseButton>
            </FormRow>
          </template>
        </FormSection>        
      </template>
      
      <template v-if="otherComponents.length > 0">
        <FormSection v-for="item in otherComponents" :key="item.id" class="device-comp-section" :title="getModelName(item.modelId)" readonly>
          <template #body>
            <FormRow>
              <BaseField :name="item.id + '_number'" required v-model.number="item.number" type="number" min="1" max="3" :selectOnFocus="true" class="device-comp-num-field no-arrows" />
              <BaseButton text="Remove" @click="removeComponent(item)" class="btn-outline-secondary" style="width:auto;margin-left:auto;"></BaseButton>
            </FormRow>
          </template>
        </FormSection>        
      </template> 
      
      <ToolbBar style="margin-bottom:30px;">
        <button type="button" class="btn btn-outline-secondary" @click="load()" style="margin-right:10px;">Cancel</button>
        <button type="submit" class="btn btn-primary">Save</button>    
      </ToolbBar> 
    </BaseForm> 

  </VScroll>
</template>

<script>
  import _app from '@/App/App.js';
  import ToolbBar from '@/App/components/Common/ToolBar'
  import FormRow from '@/App/components/Form/FormRow';
  import BaseField from '@/App/components/Form/BaseField';
  import FormSection from '@/App/components/Form/FormSection';
  import BaseButton from '@/App/components/Common/BaseButton';
  import BaseForm from '@/App/components/Form/BaseForm';
  import AddDeviceComponentModal from './AddDeviceComponentModal'
  import VScroll from '@/App/components/Common/VScroll'

  export default {
    name: 'DeviceComponents',
    props:['device'],   
    components: {
      ToolbBar,
      BaseForm,
      FormRow,
      BaseField,
      FormSection,
      BaseButton,
      VScroll
    }, 
    emits: ['refreshStatus'],   
    data() {
      return {
        schema: {
          // name: 'required'
        },
        get viewState() {
          return _app.$data.getState('DeviceComponentsState', {
            deviceId: null,
            deviceComponents: [],
            yocto5Buttons: null
          });
        },
        loading: false,
        operatorList: [{"id":6,"name":"!=","code":"NEQ"},{"id":2,"name":"<","code":"LT"},{"id":4,"name":"<=","code":"LTE"},{"id":5,"name":"=","code":"EQ"},{"id":1,"name":">","code":"GT"},{"id":3,"name":">=","code":"GTE"}],
        netowrkRelayPortOptions: [{ id:"FULL", name:"Full" }, { id:"RESET", name:"Reset" }],
        displayModelOptions: [
          { id: _app.$data.DeviceComponentModel.Display_ALT_1920, name: this.getModelName(_app.$data.DeviceComponentModel.Display_ALT_1920) }, 
          { id: _app.$data.DeviceComponentModel.Display_SP_4096, name: this.getModelName(_app.$data.DeviceComponentModel.Display_SP_4096) }
        ],
      };
    }, 
    mounted() {

      if (this.device?.id != this.viewState.deviceId) {
        this.viewState.deviceId = null;
        this.viewState.deviceComponents = [];
      }
        
      if (this.device && this.device.id != this.viewState.deviceId) 
        this.load();        
    },
    watch: {
      device() {
        if (this.device && this.device.id != this.viewState.deviceId) 
          this.load(); 
      }
    },  
    computed: {
      ambientSensorComponents: function() {
        return this.viewState.deviceComponents?.filter(obj => !obj.removed && obj.categoryId === _app.$data.DeviceComponentCategory.AmbientSensor) || [];
      },
      displayComponents: function() {
        return this.viewState.deviceComponents?.filter(obj => !obj.removed && obj.categoryId === _app.$data.DeviceComponentCategory.Display) || [];
      },
      ecuComponents: function() {
        return this.viewState.deviceComponents?.filter(obj => !obj.removed && obj.categoryId === _app.$data.DeviceComponentCategory.ECU) || [];
      },     
      networkRelayComponents: function() {
        return this.viewState.deviceComponents?.filter(obj => !obj.removed && obj.categoryId === _app.$data.DeviceComponentCategory.NetworkRelay) || [];
      },  
      networkRouterWithRelayComponents: function() {
        return this.viewState.deviceComponents?.filter(obj => !obj.removed && obj.categoryId === _app.$data.DeviceComponentCategory.NetworkRouterWithRelay) || [];
      },
      networkSwitchComponents: function() {
        return this.viewState.deviceComponents?.filter(obj => !obj.removed && obj.categoryId === _app.$data.DeviceComponentCategory.NetworkSwitch) || [];
      },                 
      cameraComponents: function() {
        return this.viewState.deviceComponents?.filter(obj => !obj.removed && obj.categoryId === _app.$data.DeviceComponentCategory.Camera) || [];
      },                 
      upsComponents: function() {
        return this.viewState.deviceComponents?.filter(obj => !obj.removed && obj.categoryId === _app.$data.DeviceComponentCategory.UPS) || [];
      },
      yocto5Buttons: function() {
        return this.viewState.deviceComponents?.filter(obj => !obj.removed && obj.categoryId === _app.$data.DeviceComponentCategory.Yocto5Buttons) || [];
      },
      touchMatrixComponents: function() {
        return this.viewState.deviceComponents?.filter(obj => !obj.removed && obj.categoryId === _app.$data.DeviceComponentCategory.TouchMatrix) || [];        
      },      
      otherComponents: function() {
        return this.viewState.deviceComponents?.filter(
          obj => !obj.removed && 
          obj.categoryId !== _app.$data.DeviceComponentCategory.AmbientSensor &&
          obj.categoryId !== _app.$data.DeviceComponentCategory.Display &&
          obj.categoryId !== _app.$data.DeviceComponentCategory.ECU &&
          obj.categoryId !== _app.$data.DeviceComponentCategory.NetworkRelay &&
          obj.categoryId !== _app.$data.DeviceComponentCategory.NetworkRouterWithRelay &&
          obj.categoryId !== _app.$data.DeviceComponentCategory.NetworkSwitch &&
          obj.categoryId !== _app.$data.DeviceComponentCategory.Camera &&
          obj.categoryId !== _app.$data.DeviceComponentCategory.UPS &&
          obj.categoryId !== _app.$data.DeviceComponentCategory.Yocto5Buttons          
        ) || [];
      },
      alsComboOptions: function() {
        return this.ambientSensorComponents.map(obj => { return { id: obj.number, name: `ALS ${obj.number}` } });
      },
      touchMatrixComboOptions: function() {
        return [{ id: null, name: 'No touch screen'}, ...this.touchMatrixComponents.map(obj => { return { id: obj.number, name: `Touch screen ${obj.number}` } })];
      },            
    },        
    methods: {   
      load() {

        this.viewState.deviceId = this.device.id;

        this.loading = true;
        const progress = this.$progress.start();

        _app.$api.get('Device/GetDeviceComponents', { 
          params: { 
            deviceId: this.device.id
          } 
        }).then(
          result => {
            this.loading = false;
            progress.finish();

            this.viewState.deviceComponents = result.data;

            this.yocto5Buttons.forEach(i => {
              i.settings = i.jsonSettings ? JSON.parse(i.jsonSettings) : null;
              if (!i.settings)
                i.settings = this.newYocto5ButtonsSettings(); 
            });
          },
          error => {
            this.loading = false;
            progress.finish();

            this.$app.handleError(error);
          }
        );
      },       
      onSave() {

        if (!this.validate())
          return;

        // set json settings
        this.yocto5Buttons.forEach(i => {
          i.jsonSettings = JSON.stringify(i.settings);
        });

        _app.$modal.confirm('Save components', 'Are you sure you want to save this device component settings?', (confirmed) => {
          if (confirmed) {
            let loader = this.$loading.show(); 
            _app.$api.post('Device/SetDeviceComponents', this.viewState.deviceComponents).then(
              (result) => {
                loader.hide();
                this.load();          
              },
              (error) => {
                loader.hide();
                _app.handleError(error);
              }            
            );
          }
        });
      },
      validate() {        
        // validate component numbers
        const uniqueNumKeys = {};

        for(let i=0; i < this.viewState.deviceComponents.length; i++) {
          const component = this.viewState.deviceComponents[i];
          if (component.removed)
            continue;

          const maxComponentNumber = this.getMaxComponentNumber(component.modelId);

          if (!component.number || component.number < 1 || component.number > maxComponentNumber) {
            _app.$modal.alert('Invalid component number', `Component numbers must start with 1 and maximum number for ${this.getCategoryName(component.categoryId)} is ${maxComponentNumber}.`, () => {});
            return false;
          }

          var key = component.categoryId + '_' + component.number;
          
          if (uniqueNumKeys[key]) {
            _app.$modal.alert('Invalid component number', `Component number ${component.number} is already used in ${this.getCategoryName(component.categoryId)}!`, () => {});
            return false;
          }

          uniqueNumKeys[key] = true;          
        }

        // validate unique touch screen set on displays
        const uniqueTouchMatrixKeys = {};

        for (let i=0; i < this.displayComponents.length; i++) {
          const display = this.displayComponents[i];
          if (display.removed)
            continue;

          if (display.touchMatrix) {
            const existingTouchMatrixDisplay = uniqueTouchMatrixKeys[display.touchMatrix];
            if (existingTouchMatrixDisplay) {
              _app.$modal.alert('Invalid display touch screen setting', `Touch screen ${display.touchMatrix} is set in display ${existingTouchMatrixDisplay} and ${display.number}, one touch screen can be linked only to one display!`, () => {});
              return false;          
            } else {
              uniqueTouchMatrixKeys[display.touchMatrix] = display.number;
            }
          }
        }
        return true;
      },    
      addComponent() {
        this.$vfm.show({
          component: AddDeviceComponentModal,
          on: {
            addComponent: (dto) => {
              
              const model = _app.$data.DeviceComponentModel.getModel(dto.modelId);

              // get max component number in category
              const maxComponentNumber = this.getMaxComponentNumber(model.id);

              // get next component number in category
              const nextComponentNumber = this.getNextComponentNumber(model.modelCategoryId);

              // validate max components per device
              if (nextComponentNumber > maxComponentNumber) {
                if (maxComponentNumber == 1) {
                  _app.$modal.alert('Add component failed', `Only one ${this.getCategoryName(model.modelCategoryId)} component is allowed per device.`, () => {});
                } else {
                  _app.$modal.alert('Add component failed', `Only ${maxComponentNumber} ${this.getCategoryName(model.modelCategoryId)} components are allowed per device.`, () => {});
                }
                return;
              }
              
              // add new component
              const component = { 
                id: _app.nextNegativeId(),                 
                number: nextComponentNumber,
                modelId: model.id,
                categoryId: model.modelCategoryId,
                deviceId: this.device.id
              }

              // add button items for Yocto5Buttons component (jsonSettings)
              if (component.modelId === _app.$data.DeviceComponentModel.Yocto5Buttons) 
                component.settings = this.newYocto5ButtonsSettings();

              this.viewState.deviceComponents.push(component);
            }
          }
        });        
      },
      removeComponent(component) {
        if (component.id < 0) {
          this.viewState.deviceComponents = this.viewState.deviceComponents.filter(obj => obj.id !== component.id);
        } else {
          component.removed = true;
        }
      },
      newYocto5ButtonsSettings: function() {
        const settings = {
          items: []
        };
        for(let i = 1; i <= 5; i++) {
          settings.items.push({
            number: i,
            operator: 'GT',
            active: false
          });
        }
        return settings;
      },
      getNextComponentNumber(categoryId) {
        return this.viewState.deviceComponents.filter(obj => !obj.removed && obj.categoryId === categoryId).length + 1;
      },
      getMaxComponentNumber(modelId) {
        switch(modelId) {
          case _app.$data.DeviceComponentModel.UPS:                                
          case _app.$data.DeviceComponentModel.LiquidSensor:                
          case _app.$data.DeviceComponentModel.Yocto5Buttons:
            return 1;
          case _app.$data.DeviceComponentModel.NetworkRelay:                
          case _app.$data.DeviceComponentModel.NetworkSwitch:
          case _app.$data.DeviceComponentModel.NetworkRouterWithRelay:
          case _app.$data.DeviceComponentModel.Camera:
            return 2;
          case _app.$data.DeviceComponentModel.AmbientSensor:
          case _app.$data.DeviceComponentModel.ECU:   
          case _app.$data.DeviceComponentModel.Display_ALT_1920:
          case _app.$data.DeviceComponentModel.Display_SP_4096:
          case _app.$data.DeviceComponentModel.TouchMatrix:
            return 3;
          default:
            return 1;          
        }
      },
      getModelName(modelId) {
        return _app.$data.DeviceComponentModel.getName(modelId);
      },
      getCategoryName(categoryId) {
        return _app.$data.DeviceComponentCategory.getName(categoryId);
      },
      isDisplayComponent(c) {
        return c.categoryId === _app.$data.DeviceComponentCategory.Display;
      },
      toggleYoctoButtonActive(item) {
        item.active = !item.active;
      },
    } 
  }
</script>

<style scoped>
.toolbar {
  border-top-width: 0px;
  margin-top: 2px;
  padding-left:0px;  
}

.inf-card {    
  min-width: 960px;
  max-width: 960px;
}

</style>

<style>

.device-comp-num-field {
  width: 34px !important;
  margin-right: 15px;
}

.inf-card input.form-control.device-comp-field {
  width: 230px !important;
  background-color: transparent !important;
  border: 1px solid #666666 !important;
  cursor: default;
}

section.device-comp-section .card-body {
  padding-left: 28px !important;
  padding-right: 28px !important;
  padding-top: 0px !important;
  padding-bottom: 5px !important;  
}

section.device-comp-section .card-body .row {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

html.dark .inf-card  .combo-field {
  background-color: #666666 !important;
  background-image: linear-gradient(45deg, transparent 50%, white 50%),
  linear-gradient(135deg, white 50%, transparent 50%),
  linear-gradient(to right, white, white);
}

html.dark .inf-card select option[value=""], html.dark .inf-card select:invalid {
  color: #999;
}

html.dark .inf-card ::placeholder {
  color: #999;
}

html.dark .inf-card input.form-control, html.dark .inf-card select.form-control {
  /* border: 1px solid transparent !important; */
  box-sizing: border-box !important;
}

html.dark .inf-card input:disabled, html.dark .inf-card select:disabled {
  background-color: transparent !important;
  border: 1px solid #666666 !important;
  color: #666666 !important;  
}

html.dark .inf-card input:disabled::placeholder, html.dark .inf-card select:disabled::placeholder {
  color: #666666 !important;
}

html.dark .inf-card .inactive label {
  color: #666666 !important;
}

html.dark .inf-card .combo-field:disabled {
  background-image: linear-gradient(45deg, transparent 50%, #666666 50%),
  linear-gradient(135deg, #666666 50%, transparent 50%),
  linear-gradient(to right, #666666, #666666);
}

</style>