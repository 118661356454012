<template>
  <div 
    ref="scrollEl" 
    class="v-scroll"
    v-bind="$attrs"
  >
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: 'VScroll',
    inheritAttrs: false,
    mounted() {

      this.doLayout();

      window.addEventListener('resize', this.doLayout);

      setTimeout(() => {this.doLayout();}, 10);
    },
    unmounted() {
      window.removeEventListener("resize", this.doLayout);
    },
    methods: {
      doLayout() {
        this.$refs.scrollEl.style.height = (window.innerHeight - (this.$refs.scrollEl.getBoundingClientRect().top + document.documentElement.scrollTop)) + "px";
      }      
    }    
  }
</script>

<style scoped>
.v-scroll {
  overflow: auto;
  /* margin-right:-12px; */
}
</style>