import { createApp, h } from 'vue/dist/vue.esm-bundler.js'

import App from './App.vue'
import router from './router'
import store from './store'

import "bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"

import VueSidebarMenu from './App/components/SideBar' //import VueSidebarMenu from 'vue-sidebar-menu'
// import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'

import VueApexCharts from "vue3-apexcharts"

import Toaster from "@meforma/vue-toaster";

import {Vue3ProgressPlugin} from '@marcoschulte/vue3-progress';
import "@marcoschulte/vue3-progress/dist/index.css";

import VeeValidatePlugin from "./plugins/vee-validation";

import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import { vfmPlugin } from 'vue-final-modal'

import LAB22App from './App/App'

import './assets/css/theme.css';
import './assets/css/custom-skin.css';
import './assets/css/theme-overrides.css';
import './assets/css/dark-theme.css';
import './assets/css/dark-theme-infinitus.css?v=202207261140';

// import './assets/components/bs5menu/bs5menu.js';
// import './assets/components/bs5menu/bs5menu.css';

const app = createApp(App);

app
  .use(store)
  .use(router)
  .use(VueSidebarMenu)
  .use(VueApexCharts)
  .use(Toaster)
  .use(VeeValidatePlugin)
  .use(VueLoading, {
    color: '#12B39A',
    backgroundColor: '#262626',
    loader: 'dots'
  })
  .use(Vue3ProgressPlugin)
  .use(vfmPlugin)
  .use(LAB22App)
  .mount('#app');

  export default app;


  
