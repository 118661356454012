<template>
  <div class="lab22-mv-padding">

    <!--<ModuleHeader title="Audit log" iconClass="fa fa-list-ul">-->
    <ModuleHeader title="Audit log" iconClass="inf-module-header-auditlog inf-module-header-img">
      <button v-if="showBackButton" @click="backPage()" type="button" class="btn icon-btn btn-outline-secondary mx-2"><i class="fa-solid fa-arrow-left"></i></button>
      <button :disabled="disableNextButton"  @click="nextPage()" type="button" class="btn icon-btn btn-outline-secondary"><i class="fa-solid fa-arrow-right"></i></button>
    </ModuleHeader>

    <FilterBar v-slot="{state}" :filterState="getFilterState()" @reload="load(true, true)" showReload showReset :loading="loading">
      <BaseField v-model="state.actionId" name="actionId" placeholder="Action" as="select" :data="this.actionList" addClearOption />
      <BaseField v-model="state.entityTypeId" name="entityTypeId" placeholder="Entity" as="select" :data="this.entityTypeList" addClearOption />
      <BaseField v-model="state.entityName" name="entityName" placeholder="Name" />
      <BaseField v-model="state.userId" name="userId" placeholder="User" as="select" :data="this.userList" displayField="username" addClearOption />
    </FilterBar>

    <BaseGrid :columns="gridData.columns" :rows="gridData.rows" :enableVScroll="true" />
  </div>
</template>

<script>
import _app from '@/App/App'
import ModuleHeader from '@/App/components/Common/ModuleHeader'
import FilterBar from '@/App/components/Common/FilterBar'
import BaseField from '@/App/components/Form/BaseField'
import BaseGrid from '@/App/components/Grid/BaseGrid'

const PAGE_SIZE = 50;

export default {
  name: 'AuditLog',
  components: {
    ModuleHeader,
    FilterBar,
    BaseField,
    BaseGrid
  },
  data() {
    return {
      gridData: {
        columns: [
          {
            title: 'Timestamp',
            dataIndex: 'inserted',
            type: 'DateTime'
          },
          {
            title: 'Action',
            dataIndex: 'actionId',
            renderer: (value) => {
              return this.actionDic[value] || value;
            }
          },
          {
            title: 'Entity',
            dataIndex: 'entityTypeId',
            renderer: (value) => {
              return this.entityTypeDic[value] || value;
            }
          },
          {
            title: 'Name',
            dataIndex: 'entityName'
          },
          {
            title: 'User',
            dataIndex: 'userId',
            renderer: (value) => {
              return this.userDic[value] || value;
            }
          },
          {
            title: 'Details',
            dataIndex: 'details'
          }
        ],
        rows: []
      },
      actionList: [],
      actionDic: {},
      entityTypeList: [],
      entityTypeDic: {},
      userList: [],
      userDic: {},
      showBackButton: false,
      disableNextButton: true,
      loading: false
    };
  },
  mounted() {

    this.loading = true;

    Promise.all([
      this.$app.$data.getStore('AUDIT_LOG_ACTION', 'Audit/GetActionList'),
      this.$app.$data.getStore('AUDIT_LOG_ENTITY_TYPE', 'Audit/GetEntityTypeList'),
      this.$app.$data.getUsers()
    ]).then(
      (results) => {
        this.actionList = results[0];
        this.actionDic = Object.assign({}, ...this.actionList.map((i) => ({[i.id]: i.name})));

        this.entityTypeList = results[1];
        this.entityTypeDic = Object.assign({}, ...this.entityTypeList.map((i) => ({[i.id]: i.name})));

        this.userList = results[2];
        this.userDic = Object.assign({}, ...this.userList.map((i) => ({[i.id]: i.username})));  

        if (this.$app.$data.storeLoaded('AUDIT_LOG_LIST'))
          this.load(false);

        this.load(true);
      },
      () => {
        this.loading = false;
      }
    );
  },
  methods: {
    getFilterState() {
      return _app.$data.getState('AuditListFilter', { rowNum: 1, pageSize: PAGE_SIZE });
    },         
    load(reload, resetPaging) {

      const filter = this.getFilterState();

      if (resetPaging)
        filter.rowNum = 1;

      this.showBackButton = filter.rowNum > 1;

      this.disableNextButton = true;
      
      this.loading = true;

      this.$app.$data.getStore('AUDIT_LOG_LIST', 'Audit/GetAuditLogList', reload, filter).then(
        (result) => {
          this.loading = false;

          this.gridData.rows = result;

          this.showBackButton = filter.rowNum > 1;

          this.disableNextButton = this.gridData.rows.length < PAGE_SIZE;

          //this.$forceUpdate();
        },
        (errors) => {
          this.loading = false;

          console.log(errors);
        }
      )
    },
    showDetails(dto) {
      // this.$router.push({
      //   name: 'audit-details',
      //   params: { 
      //     data: JSON.stringify(dto)
      //   }
      // });
      _app.notify('Audit details for ' + dto.name);
    },
    backPage() {
      const filter = this.getFilterState();

      filter.rowNum -= PAGE_SIZE;

      if (filter.rowNum < 1)
        filter.rowNum = 1;

      this.load(true);
    },
    nextPage() {
      const filter = this.getFilterState();

      filter.rowNum += PAGE_SIZE;

      this.load(true);
    }
  }
}
</script>
