<template>
  <div class="form-group align-items-center" :class="this.getFormGroupClass()">
    <label :for="fieldName" :class="topLabel ? '' : 'col-sm-2 control-label text-sm-end'">{{ label }}</label>
    <slot></slot>
  </div> 
</template>

<script>
  export default {
    name: 'FormFieldGroup',
    inheritAttrs: false,
    props: {
      fieldName: String, 
      label: String,
      topLabel: Boolean,
      pbClass: String
    },
    methods: {
      getFormGroupClass() {

        let cls = '';

        if (!this.topLabel) {
          cls = 'row ' + (this.pbClass ? this.pbClass : 'pb-3');
        } else if (this.pbClass) {
          cls = this.pbClass;
        }

        return cls;
      }
    }   
  }
</script>
