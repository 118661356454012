<template>
  <div class="lab22-mv-padding">
    
    <ModuleHeader title="Analytics" iconClass="fa fa-square-poll-vertical">
      <button type="button" class="btn btn-primary" data-bs-toggle="collapse" data-bs-target="#readme" aria-expanded="false" aria-controls="readme">Readme</button>
    </ModuleHeader>

    <div class="collapse" id="readme">
      <div class="card card-body">
        <ol>
          <li>User izbere eno ali več metrik. Pri vsaki metriki izbere ali jo dodamo na levo ali desno skalo (<a href="https://apexcharts.com/docs/chart-types/multiple-yaxis-scales/" target="_blank">multiple-yaxis-scales</a>). Na ta način si polni seznam metrik, ki jih želi analizirati.</li>
          <li>Barve se določajo avtomatsko, compare-a ni, samo en chart je narisan vedno.</li>
          <li>Query pokličeš tolikokrat, kolikor različnih device model-ov je na izbranih metrikah -> če so vse metrike vezane na en device model, potem query pokličeš samo 1x.</li>
          <li>Pri izbiri datumskega filtra se izbere od-do ure natančno, potem pa ponudimo še opcijo na koliko minut naj se dela group by -> predefinirane vrednosti v spustnem seznamu (1 minute, 5 minutes, 15 minutes, 1 hour (default value), 1 day).</li>
          <li>Ker za različne event type dobivamo različne data pointe na določen timestamp, je potrebno poklicati najprej proceduro <code>get_analytics_dt_labels</code>, ki vrne vse timestampe za izbrani filter od-do. Potem pa vnesemo manjkajoče datapointe za vsak data series (npr. vnesemo ničle).</li>
        </ol>
        <img src="@/assets/img/analytics-conceptual.png" />
      </div>
    </div>

    <FilterBar style="border-bottom:0;">
      <BaseField name="dateFrom" type="date" :value="dateFrom" style="width:150px; margin-left:20px;" />
      <BaseField name="hoursFrom" type="text" value="00" style="width:50px;" />
      <BaseField name="dateTo" type="date" :value="dateTo" style="width:150px; margin-left:20px;" />
      <BaseField name="hoursTo" type="text" value="23" style="width:50px;" />
      <BaseField name="minRound" placeholder="Round to x mins" type="number" value="60" style="width:140px; margin-left:20px;" />
    </FilterBar>

    <FilterBar style="border-top:0;">
      <!--
      ALEŠ TODO: why values are erasing in a field when you focus or change another field?

      <BaseField name="leftScaleMeas" placeholder="Left scale metrics" style="width:150px;" />
      <BaseField name="rightScaleMeas" placeholder="Right scale metrics" style="width:150px;" />
      <BaseField name="devicesIds" placeholder="Devices filter" style="width:170px; margin-left:20px;" />
      -->

      <input id="leftScaleMeas" placeholder="Left scale metrics" style="width:150px;" />
      <input id="rightScaleMeas" placeholder="Right scale metrics" style="width:150px;" />
      <input id="devicesIds" placeholder="Devices filter" style="width:170px; margin-left:20px;" />
      <BaseButton @click="fetchTimeSeries" text="Run query" class="btn-primary" loadingText="Fetching data..." style="margin-left:20px;" :loading="loading"></BaseButton>
      <BaseButton @click="fillData('normal')" text="Fill" class="btn-default" style="margin-left:20px;"></BaseButton>
      <BaseButton @click="fillData('2-scales')" text="Fill 2-scales" class="btn-default"></BaseButton>
    </FilterBar>

    <div class="row">
      <div id="lab22-chart-devices" class="col-2" style="margin-top:35px; color:#fff;">
        <!-- after query returns result all devices names are listed here with their applied colors -->
      </div>
      <div id="lab-chart-canvas" class="col-10">
        <LineAreaChart name="chart" ref="chart" height="200"></LineAreaChart>
      </div>
    </div>

  </div>
</template>

<script>
import _app from '@/App/App';
import ModuleHeader from '@/App/components/Common/ModuleHeader';
import FilterBar from '@/App/components/Common/FilterBar';
import BaseField from '@/App/components/Form/BaseField';
import BaseButton from '@/App/components/Common/BaseButton.vue';
import LineAreaChart from '@/App/components/Charts/LineAreaChart';

function formatDateDbStyle(date) {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

let _dt = new Date(),
    _dtToday = formatDateDbStyle(_dt);

function shadeColor(color, percent) {

    let R = parseInt(color.substring(1,3),16);
    let G = parseInt(color.substring(3,5),16);
    let B = parseInt(color.substring(5,7),16);
    
    if (R == 0) {R = 32;}
    if (G == 0) {G = 32;}
    if (B == 0) {B = 32;}

    R = parseInt(R * (100 + percent) / 100);
    G = parseInt(G * (100 + percent) / 100);
    B = parseInt(B * (100 + percent) / 100);

    R = (R<255)?R:255;  
    G = (G<255)?G:255;  
    B = (B<255)?B:255;  

    var RR = ((R.toString(16).length==1)?"0"+R.toString(16):R.toString(16));
    var GG = ((G.toString(16).length==1)?"0"+G.toString(16):G.toString(16));
    var BB = ((B.toString(16).length==1)?"0"+B.toString(16):B.toString(16));

    return "#"+RR+GG+BB;
}

const dbMetrics = [
    {'id':3,  'eventType': 3, 'name':'fan1 speed AVG'},
    {'id':6,  'eventType': 3, 'name':'fan2 speed AVG'},
    {'id':13, 'eventType': 3, 'name':'internal temp AVG'},
    {'id':14, 'eventType': 3, 'name':'intake temp AVG'},
    {'id':15, 'eventType': 5, 'name':'battery remaining MIN'},
    {'id':16, 'eventType': 5, 'name':'last reboot MAX'},
    {'id':17, 'eventType': 3, 'name':'humidity AVG'},
    {'id':18, 'eventType': 4, 'name':'ambient light AVG'}
  ];

const dbEventTypes = [
    {'id':3, 'name':'NORPAY_DEVICE'},
    {'id':4, 'name':'NORPAY_DISPLAY'},
    {'id':5, 'name':'NORPAY_UPS'}
  ];

/* APEX CHARTS basic colors:
      blue = rgb(0, 143, 251) || #008efb
      green = rgb(0, 227, 150) || #00e396
      orange = rgb(254, 176, 25) || #feb019
      red = rgb(255, 69, 96) || #ff4560
      purple = rgb(119, 93, 208) || #775dd0*/
const defaultColors = ['#008efb','#00e396','#feb019','#ff4560','#775dd0'];

export default {
  name: 'AnalyticsView',
  components: {
    ModuleHeader,
    FilterBar,
    BaseField,
    BaseButton,
    LineAreaChart
  },
  data: function() {
    return {
      dateFrom: '2022-05-29',
      dateTo: _dtToday,
      loading: false,
      chartSeries: [],
      chartDistinctDevices: [],
      chartColors: [],
      chartCategories: [],
      colorIndex: 0,
      leftMeas: '',
      rightMeas: ''
    }
  },
  mounted() {
    document.getElementsByTagName('label')[1].style.visibility = "hidden";
    document.getElementById('lab-chart-canvas').style.visibility = "hidden";

    this.fillData('normal');
  },
  methods: {
    //
    fillData(type) {
      if(type=='normal') {
        document.getElementById("leftScaleMeas").value = '15'; //Fan 1 AVG speed, Fan2 AVG speed, Battery MIN remaining
        document.getElementById("rightScaleMeas").value = '';
        document.getElementById("devicesIds").value = '10';
      }
      else if(type=='2-scales') {
        document.getElementById("leftScaleMeas").value = '15'; // Fan 1 AVG speed, Fan2 AVG speed
        document.getElementById("rightScaleMeas").value = '13,14'; //Internal AVG temp, Intake AVG temp
        document.getElementById("devicesIds").value = '10';
      }
    },
    formatDate(val) {
      const months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];

      const dt = new Date(val),
            y = dt.getFullYear().toString().substring(2),
            mN = dt.getMonth(),
            m = months[mN],
            d = dt.getDate(),
            h = dt.getHours();
      
      let mi = dt.getMinutes().toString();
          mi = (mi.length==2) ? mi : '0'+mi;

      return d + '-' + m + '-' + y + ' ' + h + ':' + mi;
    },
    async fetchTimeSeries() {
      try {
        this.loading = true;

        document.getElementById('lab-chart-canvas').style.visibility = "hidden";

        const _this = this,
              _minutesRound = (document.getElementById("minRound").value <= 0) ? null : parseInt(document.getElementById("minRound").value),
              _dateFrom     = formatDateDbStyle(document.getElementById("dateFrom").value) + ' ' + document.getElementById("hoursFrom").value + ':00:00.000',
              _dateTo       = formatDateDbStyle(document.getElementById("dateTo").value) + ' ' + document.getElementById("hoursTo").value + ':59:59.999';

        let _response = await _app.$api.get('Analytics/GetDatetimeLabels', { params: { 
            minutesRound:_minutesRound, dateFrom:_dateFrom, dateTo:_dateTo 
          } });

        let _res = _response.data;

        _this.chartCategories = [];

        for(let i=0; i<_res.length; i++) {
          let s =  _res[i].dt.split("T"),
              t = (s[0] + ' ' + s[1]).split(/[- :]/),
              d = new Date(Date.UTC(t[0], t[1]-1, t[2], t[3], t[4], t[5]));
          _this.chartCategories[i] = d;
        }

        _this.fetchData(_minutesRound, _dateFrom, _dateTo);

      } catch(err) {
        this.loading = false;
        console.error(err);
        _app.$helper.notify(err.message, 'danger', 4000);
      }
    },
    async fetchData(_minutesRound, _dateFrom, _dateTo) {
      const _meaCheck = ( _app.$helper.isEmpty(document.getElementById("leftScaleMeas").value, false) ) ? null : document.getElementById("leftScaleMeas").value;
            
      if( _app.$helper.isEmpty(_meaCheck, false) ) {
        _app.$helper.notify('Please, select some metrics', 'warning', 4000);
        return;
      }

      const _this               = this,
            _measurementsLeft   = ( _app.$helper.isEmpty(document.getElementById("leftScaleMeas").value, false) ) ? null : document.getElementById("leftScaleMeas").value,
            _groupDevices       = true,
            _limit              = null,
            _measurementsRight  = ( _app.$helper.isEmpty(document.getElementById("rightScaleMeas").value, false) ) ? null : document.getElementById("rightScaleMeas").value,
            _devices            = ( _app.$helper.isEmpty(document.getElementById("devicesIds").value, false) ) ? null : document.getElementById("devicesIds").value,
            _meas               = ( _app.$helper.isEmpty(document.getElementById("rightScaleMeas").value, false) ) ? _measurementsLeft.split(',') : (_measurementsLeft+','+_measurementsRight).split(',');

      this.chartSeries = [];
      this.chartDistinctDevices = [];
      this.chartColors = [];
      this.colorIndex = 0;
      this.leftMeas = _measurementsLeft;
      this.rightMeas = _measurementsRight;

      let _go = false;

      //If we have metrics from different event types we need to call DB multiple times (once per each event type)
      for(let i=0; i<dbEventTypes.length; i++) {
        let _queryMeas = '',
            _queryEventType = 0;

        for(let j=0; j<_meas.length; j++) {

          ///get event type for current mea
          for(let x=0; x<dbMetrics.length; x++) {
            if(parseInt(_meas[j])==dbMetrics[x].id)
              _queryEventType = dbMetrics[x].eventType;
          }
          
          //check if event type is ok and add to meas list within the same event type
          if( _queryEventType==dbEventTypes[i].id )
            _queryMeas += (_queryMeas!=='') ? ','+_meas[j] : _meas[j];
        }

        if(_queryEventType!==0 && _queryMeas!=='') {
          
          try {
            let _response = await _app.$api.get('Analytics/GetAnalyticsDataTableBz', { params: { 
                eventTypeId:dbEventTypes[i].id, measurements:_queryMeas, devices:_devices, 
                groupDevices:_groupDevices, minutesRound:_minutesRound, 
                dateFrom:_dateFrom, dateTo:_dateTo, limit:_limit 
              } });

            let _result = _response.data;

            if( _result.length > 0 ) {
              _go = true;

              //call draw chart for every device in the resultset
              let _distinctDevices = [...new Set(_result.map(item => item.device))]; //get distinct devices from resultset
              for (let d=0; d<_distinctDevices.length; d++) {
                let _filter = _result.filter(function(o){ 
                      return (_distinctDevices[d]==o.device);
                    }),
                    _addColor = ( d < defaultColors.length ) ? defaultColors[d] : '#'+Math.floor(Math.random()*16777215).toString(16);

                _this.chartDistinctDevices.push({'device':_distinctDevices[d], 'color':_addColor});

                _this.buildSeries(_filter, d, _addColor)
              }
            } else {
              console.log('No data found for event type '+dbEventTypes[i].id+' and metrics: '+_queryMeas);
              _this.loading = false;
            }
          } catch(err) {
              _this.loading = false;
              console.error(err);
              _app.$helper.notify(err.message, 'danger', 4000);
          }

        }
      }

      if(_go)
        this.drawChart();
    },
    drawChart() {
      const _this = this,
            _height = document.getElementById("main-view-container").style.height,
            _available = _height.split("px")[0]-250; //250px zasedejo modul header in filtri na mojem example-u

      let _colorsDiv = document.getElementById('lab22-chart-devices');
      _colorsDiv.innerHTML = '';
      
      //get distinct devices from resultset to assign colors
      const _devList = this.chartDistinctDevices,
            _newListDev = Array.from(new Set(_devList.map(a => a.device)))
              .map(device => {
                return _devList.find(a => a.device === device)
              });
      for(let d=0; d<_newListDev.length; d++) {
        _colorsDiv.innerHTML += '<div style="background-color:'+_newListDev[d].color+'">&nbsp;'+_newListDev[d].device+'&nbsp;</div>';
      }
      
      //COMMENT COMPLETE BLOCK IF YOU WANT TO DISPLAY LEGEND FOR GROUP COLORS BY DEVICE
      // start
      _colorsDiv.style.visibility = "hidden";
      _colorsDiv.classList.remove('col-2');
      let _chartDiv = document.getElementById('lab-chart-canvas');
      _chartDiv.classList.remove('col-10');
      _chartDiv.classList.add('col-12');
      _colorsDiv.style.height = "0px";
      _colorsDiv.style.marginTop = "0px";
      // end

      let _yaxis = [];

      if(this.rightMeas!=='' && this.rightMeas!==null) {
        let _spL = this.leftMeas+',',
            _spR = this.rightMeas+',',
            _series = _this.chartSeries,
            _i = 0,
            _j = 0;

        //for each data series we need to establish if it should be on left or right y-axis
        
        //LEFT AXIS
        for(let s=0; s<_series.length; s++) {
          let _id = 0;
          //get mea id
          for(let m=0; m<dbMetrics.length; m++) {
            if(dbMetrics[m].name==_series[s].name)
              _id = dbMetrics[m].id+',';
          }

          if( _spL.indexOf(_id)>=0 ) {
            let _show = (_i==0) ? true : false;
            _yaxis[_i] = {
                //title: { text: _series[s].name },
                seriesName: _series[s].name,
                show: _show
              };
            
            _i++;
            _j++;
          }
        }

        //RIGHT AXIS
        _i = 0;
        for(let s=0; s<_series.length; s++) {
          let _id = 0;
          //get mea id
          for(let m=0; m<dbMetrics.length; m++) {
            if(dbMetrics[m].name==_series[s].name)
              _id = dbMetrics[m].id+',';
          }

          if( _spR.indexOf(_id)>=0 ) {
            let _show = (_i==0) ? true : false;
            _yaxis[_j] = {
                //title: { text: _series[s].name },
                seriesName: _series[s].name,
                show: _show,
                opposite: true
              };
            
            _i++;
            _j++;
          }
        }
      }

      document.getElementById('lab-chart-canvas').style.visibility = "visible";

      _this.$refs.chart.chartOptions = {
        //colors: _this.chartColors, //UNCOMMENT IF YOU WANT TO GROUP COLORS BY DEVICE
        chart: {
          height: _available+'px'
        },
        series: _this.chartSeries,
        xaxis: {
          type: 'datetime', //https://apexcharts.com/docs/datetime/
          //https://apexcharts.com/javascript-chart-demos/area-charts/datetime-x-axis/
          //https://github.com/apexcharts/apexcharts.js/issues/93 => https://user-images.githubusercontent.com/17950663/45589887-613eee00-b94b-11e8-9677-cd6a6ea976b0.png
          categories: this.chartCategories,
          /*labels: {
            datetimeUTC: false
          },*/
          labels: {
              formatter: function(val) {
                return _this.formatDate(val);
              }
          }
        },
        yaxis: _yaxis
      };

       _this.loading = false;

    },
    buildSeries(data, deviceIdx, addColor) {
      /*console.log(data);
      console.log(device);
      console.log(deviceIdx);*/
      
      let _color = ( deviceIdx < defaultColors.length ) ? defaultColors[deviceIdx] : addColor,
          _keys = Object.keys(data[0]),
          _keyDt = _keys[0],
          _this = this;

      for(let x=2; x<_keys.length; x++) { //start with x=2 because 1st key is always "device_dt" and second key is always "device"
        if(x!==2)
          _color = shadeColor( _color, (x-2)*5 ); //each additional series gets 5% lighter in same color

        _this.chartColors[_this.colorIndex] = _color;

        let _keyMea = _keys[x],
            _seriesDt = [];

        for(let i=0; i<data.length; i++) {
          const _rec = data[i];

          let s =  _rec[_keyDt].split("T"),
              t = (s[0] + ' ' + s[1]).split(/[- :]/),
              d = new Date(Date.UTC(t[0], t[1]-1, t[2], t[3], t[4], t[5]));

          _seriesDt.push({
            'dt':d, 'val':_rec[_keyMea]
          });
        }

        _this.colorIndex++;
    
        //we need to manipulate chart series to include all missing data points
        //if data point is missing we are adding zero
        const _dts = _this.chartCategories;
        let _seriesFinal = [];

        for(let d=0; d<_dts.length; d++) {
          let _val = 0;
          for(let dx=0; dx<_seriesDt.length; dx++) {
            if(_seriesDt[dx].dt.toISOString()==_dts[d].toISOString())
              _val = _seriesDt[dx].val;
          }
          _seriesFinal[d] = _val;
        }

        _this.chartSeries.push({
          name: _keyMea,
          data: _seriesFinal
        });
      }
    }
  }
}
</script>

<style scoped>
  /* */
</style>