<template>
  <FormFieldGroup :fieldName="name" :label="label">
    <div class="btn-group align-items-center" role="group" v-bind="$attrs" @change="onChange">
      <input type="radio" class="btn-check" :name="name" :id="btnOnId" autocomplete="off" :disabled="loading" :checked="state">
      <label class="btn" :class="onBtnClass" :for="btnOnId"><span v-if="loading && state" class="spinner-border spinner-border-sm"></span>{{onBtnText}}</label>
      <input type="radio" class="btn-check" :name="name" :id="btnOffId" autocomplete="off" :disabled="loading" :checked="!state">
      <label class="btn" :class="offBtnClass" :for="btnOffId"><span v-if="loading && !state" class="spinner-border spinner-border-sm"></span>{{offBtnText}}</label>      
    </div>
    <slot></slot>    
  </FormFieldGroup>
</template>

<script>

  import FormFieldGroup from '@/App/components/Form/FormFieldGroup';

  export default {
    name: 'FormOnOffGroupField',
    inheritAttrs: false,
    props: {
      name: String, 
      label: String, 
      loading: Boolean,
      onBtnClass: {
        type: String,
        default: 'btn-outline-primary'
      },
      offBtnClass: {
        type: String,
        default: 'btn-outline-primary'
      },
      onBtnText: {
        type: String,
        default: 'On'
      },
      offBtnText: {
        type: String,
        default: 'Off'
      },
      value: Boolean
    },
    emits: ['change'],
    components: {
      FormFieldGroup
    },
    data() {
      return {
        state: this.value,
        btnOnId: this.name + '-on',
        btnOffId: this.name + '-off'
      }
    },
		watch:{
			value() {
				this.state = this.value
			}
		},
    methods: {
      onChange(e) {

        this.state = e.target.id == this.btnOnId;

        this.$emit('change', this.state, e);
      },
      setValue(value) {
        this.state = value;
      }
    }  
  }

</script>

<style scoped>
  .btn-group {
    width:200px;
  }
  span.spinner-border {
    margin-right: 5px;
  }

</style>
