<template>
  <div>
    <BaseCheckbox
      v-for="option in options"
      :checked="value.includes(option.id)"
      @checked="check(option.id, $event)"
      :fieldId="option.name"
      :label="option.name"
      :key="option"
    />
  </div>
</template>

<script>
import BaseCheckbox from "./BaseCheckbox";

export default {
  name: 'MultiCheckbox',
  emits: ["update:value"],
  props: {
    value: {
      type: Array,
      required: true,
    },
    options: {
      type: Array,
      required: true,
      validator: (value) => {
        const hasNameKey = value.every((option) =>
          Object.keys(option).includes("name")
        );
        const hasIdKey = value.every((option) =>
          Object.keys(option).includes("id")
        );
        return hasNameKey && hasIdKey;
      },
    },
  },
  setup(props, context) {
    const check = (optionId, checked) => {
      let updatedValue = [...props.value];
      if (checked) {
        updatedValue.push(optionId);
      } else {
        updatedValue.splice(updatedValue.indexOf(optionId), 1);
      }
      context.emit("update:value", updatedValue);
    };

    return {
      check,
    };
  },
  components: {
    BaseCheckbox
  },
};
</script>