<template>

<Form @submit="onSubmit" ref="form" :validation-schema="schema" class="form-horizontal form-bordered">
  <base-modal :name="modalName" :title="getTitle()" v-bind="$attrs" :width="600" @close="close">
    <template #body> 
      <div class="row">
        <div class="col-6">
          <FormField name="startDt" v-model="startDt" type="datetime-local" label="Start" :topLabel="true" />
          <FormField name="modelComponentId" v-model="modelComponentId" label="Component" :topLabel="true" as="select" :data="this.componentList" />
          <FormField name="reasonId" v-model="reasonId" label="Reason" :topLabel="true" as="select" :data="this.reasonList" />
          <FormField name="actionId" v-model="actionId" label="Action" :topLabel="true" as="select" :data="this.actionList" />
          <FormField name="resolutionId" v-model="resolutionId" label="Resolution" :topLabel="true" as="select" :data="this.resolutionList" />
        </div>
        <div class="col-6">
          <FormField name="actionComment" label="Comment" v-model="actionComment" as="textarea" style="height:350px;" :topLabel="true" />
        </div>
      </div>
    </template>
    <template #footer>
      <button @click="close" type="button" class="btn btn-default" data-dismiss="modal">Cancel</button>
      <button type="submit" class="btn btn-primary">Ok</button>
    </template>
  </base-modal>
</Form>

</template>

<script>

import BaseModal from '@/App/components/Modal/BaseModal';
import FormField from '@/App/components/Form/FormField';
import { Form } from "vee-validate";
import _app from '@/App/App'

export default {
  name: 'AddDeviceMaintenanceModal',
  props: {
    editRec: Object,
    device: Object,
  },
  inheritAttrs: false,
  emits: ['ok'],
  components: {
    BaseModal,
    FormField,
    Form
  }, 
  data() {
    return {
      modalName: 'AddDeviceMaintenanceModal',
      schema: {
        startDt: 'required',
        actionComment: 'required'
      },
      id: null,
      deviceId: null,
      startDt: null,
      actionComment: null,
      actionId: null,
      modelComponentId: null,
      reasonId: null,
      resolutionId: null,
      state: this.$app.$data.getState('AddDeviceMaintenanceModal', { id: null }),
      actionList: [],
      componentList: [],
      reasonList: [],
      resolutionList: [],
      dto: []
    };
  },
  mounted() {
    if (this.editRec) {
      this.id = this.editRec.id;
      this.actionComment = this.editRec.actionComment;
      this.deviceId = this.editRec.deviceId;
      this.startDt = this.$app.formatDate(this.editRec.startDt, 'YYYY-MM-DDTHH:mm');
    } else {
      this.deviceId = this.device.id;
      this.startDt = this.$app.formatDate(new Date(), 'YYYY-MM-DDTHH:mm', 'local');
    }

    let loader = this.$loading.show({ container: this.$refs.form.$el }),
        _this = this;

    Promise.all([
      this.$app.$api.get('DeviceMaintenance/GetMaintenanceActionList'),
      this.$app.$api.get('DeviceMaintenance/GetMaintenanceReasonList'),
      this.$app.$api.get('DeviceMaintenance/GetMaintenanceResolutionList'),
      this.$app.$api.get('DeviceMaintenance/GetMaintenanceDeviceComponentList', { params: { deviceId: _this.deviceId } })
    ])  
    .then(results => {
      _this.actionList = results[0].data;
      _this.reasonList = results[1].data;
      _this.resolutionList = results[2].data;
      _this.componentList = results[3].data;

      if (_this.editRec) {
        _app.$api.get('DeviceMaintenance/GetDeviceMaintenance', { params: { id: _this.id } }).then(result => {
            _this.dto = result.data;

            if(_this.dto.actionId !== null) {
              for(let i=0; i<_this.actionList.length; i++) {
                if(_this.actionList[i].id == _this.dto.actionId)
                  _this.actionId = _this.dto.actionId;
              }
            }

            if(_this.dto.reasonId !== null) {
              for(let i=0; i<_this.reasonList.length; i++) {
                if(_this.reasonList[i].id == _this.dto.reasonId)
                  _this.reasonId = _this.dto.reasonId;
              }
            }

            if(_this.dto.resolutionId !== null) {
              for(let i=0; i<_this.resolutionList.length; i++) {
                if(_this.resolutionList[i].id == _this.dto.resolutionId)
                  _this.resolutionId = _this.dto.resolutionId;
              }
            }

            if(_this.dto.modelComponentId !== null) {
              for(let i=0; i<_this.componentList.length; i++) {
                if(_this.componentList[i].id == _this.dto.modelComponentId)
                  _this.modelComponentId = _this.dto.modelComponentId;
              }
            }
        }, (error) => {
          loader.hide();
          _app.handleError(error);
          _app.notify('Could not load the selected maintenance record. Please, try again.');
          this.$vfm.hide(this.modalName);
        });
      }

      loader.hide();
    });
  },
  methods: {
    getTitle() {
      return this.editRec ? 'Edit maintenance' : 'Add maintenance';
    },
    onSubmit(values) {
      let loader = this.$loading.show({ container: this.$refs.form.$el }),
          _data = this.editRec || {},
          _this = this;

      if (!this.editRec) {
        _data = {
          id: null,
          startDt: _app.toUtcDate(values.startDt),
          actionComment: values.actionComment, 
          deviceId: _this.deviceId,
          reasonId: values.reasonId,
          alertId: values.alertId,
          actionId: values.actionId,
          resolutionId: values.resolutionId,
          modelComponentId: values.modelComponentId
        };
      } else {
        _data = _this.dto;
        _data.startDt = _app.toUtcDate(values.startDt);
        _data.actionComment = values.actionComment;
        _data.reasonId = values.reasonId;
        _data.alertId = values.alertId;
        _data.actionId = values.actionId;
        _data.resolutionId = values.resolutionId;
        _data.modelComponentId = values.modelComponentId;
      }

      this.$app.$api.post('DeviceMaintenance/SetDeviceMaintenance', _data).then(result => {
        if( result.data && parseInt(result.data)>0 ) {
          loader.hide();
          this.$emit('ok', _data);
          this.$vfm.hide(this.modalName);
        } else {
          this.$app.notify('There was no error but Maintenance Id was not returned. Please, try again.');
          loader.hide();
        }
      }, (error) => {
        loader.hide();
        _app.handleError(error);
      });
    },
    close() {
      this.$vfm.hide(this.modalName);
    }
  }
}
</script>

<style scoped>
 /* */
</style>