import VueApp from "@/main";
import dayjs from 'dayjs';

let _idSeed = 0;
let _negativeIdSeed = -1;

const _isoFormat = 'YYYY-MM-DDTHH:mm:ss';
class AppHelper {

  id (prefix) {
    return (prefix || "lab22-uid-") + (++_idSeed)
  }

  nextNegativeId () {
    return _negativeIdSeed--;
  }

  isEmpty(v, allowBlank) {
    return v === null || v === undefined || (!allowBlank ? v === '' : false);
  }

  isString(v) {
    return typeof v === 'string';
  }

  isObject(v) {
    return typeof v === 'object' && !Array.isArray(v) && v !== null;
  }

  isDefined(v) {
    return typeof v !== 'undefined';
  }

  clearObject(obj) {
    if (_this.isEmpty(obj))
      return;

    for (const key in obj) {
      delete obj[key];
    }
  }

  resetObject(obj, defaults) {
    _this.clearObject(obj);
    Object.assign(obj, defaults);
  }

  removeEmptyProps(obj) {
    Object.keys(obj).forEach(key => {
      if (obj[key] === '') {
        delete obj[key];
      }
    });
  }

  toDictionary(items, keyProperty) {
    return Object.assign({}, ...(items || []).map(i => ({[i[keyProperty || 'id']]: i})));
  }

  toLookup(items) {
    return Object.assign({}, ...(items || []).map(i => ({[i.id]: i.name})));
  }

  handleError(error) {
    if (process.env.NODE_ENV == 'development') {
      console.log(error);
    }
  }

  getErrorResponseData(error) {

    let responseData = error?.response?.data || null;

    console.log(typeof responseData);
    try {
      if (responseData && typeof responseData === "string") 
        responseData = JSON.parse(responseData);
    }
    catch(err) {
      console.log(err.message);
    }
    return responseData;
  }

  notify(msg, type, duration) {
    VueApp.$toast.show(msg, {
      type: type || 'default',         // One of success, info, warning, error, default
      position: 'top',    // One of top, bottom, top-right, bottom-right,top-left, bottom-left
      duration: duration || 4000,     // Visibility duration in milliseconds or false that disables duration
      dismissible: true,  // Allow user close by clicking
      // onClick: function() {}, // Do something when user clicks
      // onClose: function() {}, // Do something after toast gets dismissed
      queue: false,       // Wait for existing to close before showing new
      maxToasts: false,   // Defines the max of toasts showed in simultaneous
      pauseOnHover: true, // Pause the timer when mouse on over a toast
      useDefaultCss: true // User default css styles      
    });
  }

  notifyInfo(msg) {
    _this.notify(msg, 'info');   
  }

  notifyError(msg) {
    _this.notify(msg, 'error', 8000);   
  }

  notifyWarning(msg) {
    _this.notify(msg, 'warning');   
  }  

  formatDate(date, format, dateTimeKind) {

    if (!date)
      return '';

    try {    
      format = format || 'DD.MM.YY HH:mm:ss';

      if (format == 'ISO')
        format = _isoFormat;

      // if (dateTimeKind == 'UTC')
      //   date = date + '.000Z';

      var d = dayjs(date);
      
      if (dateTimeKind == 'UTC') {
        d = d.add(d.utcOffset(), "minute");
      } else if (dateTimeKind != 'local') {
        d = d.add(d.utcOffset(), "minute");
      }

      return d.format(format);

      // if (dateTimeKind == 'UTC') {
      //   return d.utc().format(format);
      // } else {
      //   return d.format(format);
      // }
      
    }
    catch(err) {
      console.log(err);
      return date;
    }
  }

  get ISO_FORMAT() {
    return _isoFormat;
  }

  formatMinutes(minutes) {

    if (minutes < 1) 
      return minutes;

    const hours = Math.floor(minutes / 60);
    
    minutes = minutes % 60;

    let s = '';

    if (hours == 1)
      s += hours + ' hour ';
    else if (hours > 1)
      s += hours + ' hours ';

    if (minutes == 1)
      s += minutes + ' minute';
    else if (minutes > 1)
      s += minutes +  ' minutes';

    return s;
  }

  formatSeconds(seconds, displaySeconds) {
    const minutes = Math.floor((seconds % 3600) / 60),
            hours = Math.floor((seconds % (3600 * 24)) / 3600),
            days = Math.floor(seconds / (3600 * 24)),
            secondsStr = (seconds % 60) + 's',
            minutesStr = (minutes>0) ? minutes + 'm ' : '',
            hoursStr = (hours>0) ? hours + 'h ' : '',
            daysStr = (days>0) ? days + 'd ' : '';

    if(displaySeconds)
      return `${daysStr}${hoursStr}${minutesStr}${secondsStr}`;
    else
      return `${daysStr}${hoursStr}${minutesStr}`;
  }

  toUtcDate(date) {
    
    if (_this.isString(date))
      return new Date(date).toISOString();

    return new Date(date.toISOString().replace('Z', ''));
  }

  formatDateToDb(dt) {
  
    const padL = (nr, len = 2, chr = `0`) => `${nr}`.padStart(2, chr);

    return `${
        padL(dt.getDate())}-${
        padL(dt.getMonth()+1)}-${
        dt.getFullYear()} ${
        padL(dt.getHours())}:${
        padL(dt.getMinutes())}:${
        padL(dt.getSeconds())}`
    ;
  }

  formatDateToDbCompact(dt) {
  
    const padL = (nr, len = 2, chr = `0`) => `${nr}`.padStart(2, chr);

    return `${
        padL(dt.getDate())}${
        padL(dt.getMonth()+1)}${
        dt.getFullYear()}${
        padL(dt.getHours())}${
        padL(dt.getMinutes())}${
        padL(dt.getSeconds())}`
    ;
  }

  copyToClipboard(text) {
    try {
      let copyText = document.createElement('input');
      document.body.appendChild(copyText);
      copyText.value = text;
      copyText.select();
      document.execCommand('copy');
      document.body.removeChild(copyText);

      _this.notifyInfo('Text copied to clipboard');
    }
    catch(ex) {
      _this.notifyWarning('Failed to copy text to clipboard');
    }    
  }
}

const _this = new AppHelper();

export default _this;