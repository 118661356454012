<template>
  <VScroll style="overflow-x:hidden">
    <Form @submit="save" :validation-schema="schema" class="form-horizontal form-bordered">

      <FormField v-if="!this.$app.user.isCustomerUser()" name="customerId" label="Customer" v-model="this.$app.$data.deviceEditState.customerId" as="select" :data="this.$app.$data.customers" />      
      <FormField name="name" label="Name" v-model="this.$app.$data.deviceEditState.name" />
      <FormField name="deviceId" label="Device ID" v-model="this.$app.$data.deviceEditState.iotDeviceId" readonly />
      <FormField name="description" label="Description" v-model="this.$app.$data.deviceEditState.description" as="textarea" rows="3" data-plugin-textarea-autosize />
      <FormField name="attrSerial" label="Serial Number" v-model="this.$app.$data.deviceEditState.attrSerial" />
      <FormField name="address" label="Address" v-model="this.$app.$data.deviceEditState.address" />
      <FormFieldGroup fieldId="latitude" label="Location (lat/lon)">
        <div class="col-sm-4">
          <Field v-model.number="this.$app.$data.deviceEditState.latitude" name="latitude" placeholder="Latitude" type="number" class="form-control" id="latitude" />
        </div>
        <div class="col-sm-4">
          <Field v-model.number="this.$app.$data.deviceEditState.longitude" name="longitude" placeholder="Longitude" type="number" class="form-control" id="longitude" />
        </div>
      </FormFieldGroup>

      <!-- <FormFieldGroup fieldId="internalIp" label="IP (internal/external)">
        <div class="col-sm-4">
          <Field v-model.number="this.$app.$data.deviceEditState.internalIp" name="internalIp" placeholder="Internal IP" class="form-control" id="internalIp" />
        </div>
        <div class="col-sm-4">
          <Field v-model.number="this.$app.$data.deviceEditState.externalIp" name="externalIp" placeholder="External IP" class="form-control" id="externalIp" />
        </div>
      </FormFieldGroup>       -->

      <FormField name="internalIp" label="Internal IP" v-model="this.$app.$data.deviceEditState.internalIp" />
      <FormField name="externalIp" label="External IP" v-model="this.$app.$data.deviceEditState.externalIp" />
      <FormFooter>
        <button type="submit" class="btn btn-primary">Save changes</button>   
      </FormFooter>   
    </Form>
  </VScroll>
</template>

<script>
import _app from '@/App/App'
import FormField from '@/App/components/Form/FormField';
import FormFieldGroup from '@/App/components/Form/FormFieldGroup';
import FormFooter from '@/App/components/Form/FormFooter';
import VScroll from '@/App/components/Common/VScroll'

import { Form, Field } from "vee-validate";

export default {
  name: 'DeviceGeneral',
  components: {
    Form,
    Field,
    FormField, 
    FormFieldGroup,
    FormFooter,
    VScroll
  },  
  props:['device'],
  data() {
    return {
      schema: {
        name: 'required',
        internalIp: 'ip',
        externalIp: 'ip'
      }
    };
  },
  methods: {
    save() {
      let loader = this.$loading.show(); 
      
      _app.$api.post('Device/SetDeviceDetails', this.$app.$data.deviceEditState).then(
        () => {
          loader.hide();
        },
        () => {
          loader.hide();
        }
      );
    }
  }
}
</script>

<style scoped>
form {
  padding-top:30px;
  padding-left:10px;
}
</style>