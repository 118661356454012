<template>
  <div class="form-group row">
    <div class="col-sm-2"></div>
    <div v-bind="$attrs" class="col-sm-8">
      <slot></slot>
    </div>      
  </div>  
</template>

<script>
  export default {
    name: 'FormGroupRow',
    inheritAttrs: false,
    props: {

    }    
  }
</script>
