<template>

  <div class="offcanvas-header">
      <h4 class="offcanvas-title" id="chatAgentLabel" style="padding-bottom:0px !important; margin-top: 0px !important;">{{ chatName }}</h4>
      <button type="button" class="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body" style="padding-top:0px !important; overflow-y: hidden;">
      <div class="row" style="position: relative;">
        <!-- result grid/chart/text -->
        <div v-if="allRows.length>0 && tableDone" class="floating-div">
          <!--<button type="button" class="btn button-xxs icon-btn btn-outline-secondary mx-2" @click.prevent="displayOption('table')"><i class="fa fa-table"></i></button><br />-->
          <!--<button type="button" class="btn button-xxs icon-btn btn-outline-secondary mx-2" @click.prevent="displayOption('pie')"><i class="fa fa-chart-pie"></i></button><br />-->
          <!--<button type="button" class="btn button-xxs icon-btn btn-outline-secondary mx-2" @click.prevent="displayOption('line')"><i class="fa fa-chart-line"></i></button><br />-->
          <!--<button type="button" class="btn button-xxs icon-btn btn-outline-secondary mx-2" @click.prevent="shareChat(this.deviceChat, this.gridData.columns, this.allRows,';')"><i class="fa fa-share"></i></button><br />-->
          <button type="button" class="btn button-xxs icon-btn btn-outline-secondary mx-2" @click.prevent="exportCsv(this.gridData.columns, this.allRows,';')"><i class="fa fa-file-csv"></i></button><br />
          <!--<button type="button" class="btn button-xxs icon-btn btn-outline-secondary mx-2" @click.prevent="exportPng('displayTableGrid')"><i class="fa fa-file-image"></i></button><br />-->
        </div>
        <div id="displayTableGrid" class="col-6" :style="'max-height:' + gridHeight + ' !important; overflow-x:scroll; overflow-y:scroll; padding: 0px 20px 0px 20px;'">
          <div class="flex-grow-1 d-flex align-items-center">
            <BaseGrid ref="reportGrid" v-if="tableDone" :columns="gridData.columns" :rows="gridData.rows" :enableVScroll="false" style="margin-left:34px;" />
          </div>
          <div v-if="placeholderTable" class="card" style="width:99%; margin-top:10px;">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <h5 class="card-title placeholder-glow" style="opacity:0.2 !important;">
                      <span class="placeholder col-12"></span>
                  </h5>
                  <p class="card-text placeholder-glow" style="opacity:0.2 !important;">
                      <span class="placeholder col-12"></span>
                      <span class="placeholder col-12"></span>
                      <span class="placeholder col-12"></span>
                      <span class="placeholder col-12"></span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div v-if="allRows.length>pageSize && tableDone" id="displayTablePaging" class="flex-grow-1 d-flex align-items-center" style="margin-left:34px;">
              <button v-if="showBackButton" @click="backPage()" type="button" class="btn button-xxs icon-btn btn-outline-secondary mx-2"><i class="fa-solid fa-arrow-left"></i></button>
              <button v-if="showNextButton" @click="nextPage()" type="button" class="btn button-xxs icon-btn btn-outline-secondary"><i class="fa-solid fa-arrow-right"></i></button>
              <div class="pages-info">{{this.pagesInfo}}</div>
          </div>  
        </div>
        <!-- chat/conversation -->
        <div class="col-4" style="border-left: 1px solid rgba(255,255,255,0.3);">
          <div class="row" :style="chatOffcanvasStyle">
            <div class="col-12">
              <div class="row" style="margin-top:0px; margin-left:0px;">
                <div class="col-12" style="padding-left:0px;">
                  <ul class="ul-chat" style="padding-left:0px;">
                    <li v-for="(chat, index) in deviceChat" :key="index" :class="(chat.user!=='assistant') ? 'text-start' : 'text-end'" :style="{ color: (chat.user!=='assistant') ? '#ffffff' : '#12B39A' , paddingBottom: '10px' }">
                      {{chat.str}}
                      <div v-if="chat.user!=='assistant'" class="btn-group" role="group" >
                        <a v-if="isOneBeforeLastItem(index)" @click="redoChat(index)" class="ms-1" style="color:#fff; cursor:pointer;"><i class="fa fa-redo"></i></a>
                        <a v-if="isOneBeforeLastItem(index)" class="ms-2" @click="removeChatItem(index)" style="color:#fff; cursor:pointer;"><i class="fa fa-close"></i></a>
                      </div>
                      <div v-if="chat.user=='assistant'" class="row">&nbsp;</div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="row" style="margin-top:20px; padding-left:15px; padding-right:5px; height:40px !important;">
            <div class="btn-group align-items-center" role="group" @change="changeModelSchema()">
              <input type="radio" v-model="state.model" class="btn-check" name="viewToggle" :disabled="deviceChat.length>0" id="alertsView" autocomplete="off" :value="model.alerts">
              <label class="btn btn-outline-primary" for="alertsView">Alerts</label>
              <input type="radio" v-model="state.model" class="btn-check" name="viewToggle" :disabled="deviceChat.length>0" id="rawDataView" autocomplete="off" :value="model.rawData">
              <label class="btn btn-outline-primary" for="rawDataView">Raw data</label>
              <input type="radio" v-model="state.model" class="btn-check" name="viewToggle" :disabled="deviceChat.length>0" id="analyticsView" autocomplete="off" :value="model.analytics">
              <label class="btn btn-outline-primary" for="analyticsView">Analytics</label>
            </div>
          </div>
          <div class="row" style="margin-top:5px; padding-left:15px; padding-right:5px; height:70px !important; margin-bottom:0px; padding-bottom:0px;">
            <div class="col-12" style="align-self: self-end;">
              <div class="row">
                <div class="col-6">
                  <FormField id="bzChatField" name="promptFld" v-model="promptFldValue" @keyup.enter="callChat" type="text" label="Write a question" :topLabel="true" style="border: 1px solid #fff !important;" />
                </div>
                <div class="col-6 text-end">
                  <button :disabled="promptFldValue=='' || state.model=='' || chatIsLoading" @click="callChat" type="button" class="btn btn-primary text-start" style="margin-top:21px;">Send</button>
                  <button type="button" class="btn btn-outline-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" style="margin-top:21px; margin-left:5px;">FAQ</button>
                  <ul class="dropdown-menu dropdown-menu-end" style="border: 2px solid rgba(0,0,0,.55) !important;">
                    <li v-for="item in initPrompts" :key="item.prompt">
                      <a class="dropdown-item" @click.prevent="addChatSystemPrompt(item)"><i class="fa-solid fa-dummy-lab22"></i>{{ item.prompt }}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- saved history -->
        <div class="col-2" id="div-saved-history" style="border-left: 1px solid rgba(255,255,255,0.3); overflow-y: scroll; overflow-x:hidden; padding-left:8px; padding-right:0px;">
            <ul class="ul-chat text-center" style="padding-left:0.5rem;">
              <li>
                <!--
                <div class="row">
                  <div class="col-6" style="padding-right:4px !important;">
                    <button type="button" class="btn btn-outline-transparent" @click="chatHistoryNew">
                      <div class="btn-outline-transparent-hover text-center" style="font-size:13px !important; margin-top:2px !important;">New chat</div>
                    </button>
                  </div>
                  <div class="col-6" style="padding-left:4px !important;">
                    <button type="button" class="btn btn-outline-transparent" @click="importChat">
                      <div class="btn-outline-transparent-hover text-center" @click="importChat" style="font-size:13px !important; margin-top:2px !important;">Import chat</div>
                    </button>
                  </div>
                </div>
                -->
                <button type="button" class="btn btn-outline-transparent" @click="chatHistoryNew">
                  <div class="row">
                    <div class="col-1 text-start" style="margin-top:-2px; padding-left:0px !important; padding-right:0px !important;"><i class="fa-solid fa-dummy-lab22" style="font-size:8px;"></i></div>
                    <div class="col-11 btn-outline-transparent-hover text-start" style="padding-left:0px !important; font-size:13px !important; margin-top:2px !important;">New chat</div>
                  </div>
                </button>
              </li>
              <li v-for="chat in deviceChatHistory" :key="chat.name">
                <button type="button" class="btn btn-outline-transparent">
                  <div class="row">
                    <div v-show="!chat.edit" class="col-1 btn-outline-transparent-hover text-start" style="margin-top:-2px; padding-left:10px !important; padding-right:0px !important;"><i :class="(chat.name==currentChatName) ? 'fa fa-circle' : 'fa-solid fa-dummy-lab22'" style="color:#12B39A; font-size:8px;"></i></div>
                    <div v-show="!chat.edit" class="col-9 btn-outline-transparent-hover text-start chat-full-text" @click="chatHistoryLoad(chat)" style="padding-left:3px !important; padding-right:6px !important; font-size:13px !important; margin-top:2px !important;">{{ chat.name }}</div>
                    <div v-show="!chat.edit" class="col-1 text-end btn-outline-transparent-hover" style="padding-left:0px; padding-right:9px;" @click="chatHistoryEdit(chat)"><i class="fa fa-edit"></i></div>
                    <div v-show="!chat.edit" class="col-1 text-end btn-outline-transparent-hover" style="padding-left:0px; padding-right:9px;" @click="chatHistoryDelete(chat)"><i class="fa fa-trash"></i></div>
                    <div v-show="chat.edit" class="col-12 btn-outline-transparent-hover text-start">
                      <input class="bzChatHistoryField" :id="removeSpaces(chat.name)" :value="chat.name" @keyup.enter="updateChatHistoryName(chat)" type="text" label="" />
                    </div>
                  </div>
                </button>
              </li>
            </ul>
        </div>
      </div>
  </div>

</template>

<script>
import _app from '@/App/App'
import FormField from '@/App/components/Form/FormField';
import BaseGrid from '@/App/components/Grid/BaseGrid';
import domtoimage from 'dom-to-image-more';

const PAGE_SIZE = 50;

const MODEL = {
  alerts: 'alerts',
  rawData: 'rawData',
  analytics: 'analytics'
};

export default {
  name: 'DeviceDetailsChat',
  inheritAttrs: false,
  emits: [],
  props: {
    Config: Object,
  },
  components: {
    FormField,
    BaseGrid,
  },   
  data() {
    return {
      user: this.$store.state.auth.user,
      state: this.$app.$data.getState('DeviceDetailsChat', {
        model: MODEL.alerts,
      }),
      model: MODEL,
      modelDbSchema: '',
      chatHeight: '',
      gridHeight: '',
      chatName: this.Config.name,
      maxLengthChatName: '350px',
      storageItem: this.Config.storageItem,
      initPrompts: this.Config.initPrompts,
      sysPrompts: this.Config.sysPrompts,
      currentChatName: null,
      chatOffcanvasStyle: '',
      chatIsLoading: false,
      deviceChat: [],
      deviceChatHistory: [],
      promptFldValue: '',
      gridData: {
        columns: [],
        rows: []
      },
      allRows: [],
      currentPage: 0,
      showBackButton: false,
      showNextButton: false,
      pagesInfo: '',
      tableDone: false,
      placeholderTable: false,
      pageSize: PAGE_SIZE,
    };
  },
  watch: {
    currentPage(){ //when this.currentPage changes
      this.changePage();
    },
    modelDbSchema(){ //when this.currentPage changes
      document.getElementById('bzChatField').focus();
    },
  },
  created() {

  },
  mounted() {

    let _this = this;

    _this.changeModelSchema();

    const div = document.getElementById('chatAgent');
    if(div) {
      _this.doLayout(div);
      window.addEventListener('resize', () => _this.doLayout(div));
    }
    else {
      _this.gridHeight = '450px';
      _this.chatOffcanvasStyle = 'overflow-y: scroll, margin-top:20px; padding-left:15px; padding-right:5px; height:70% !important;';
    }

    let _chatHistory = localStorage.getItem(_this.storageItem);
    if(_chatHistory!==null) {
      let _chat = JSON.parse(_chatHistory);
      for(let i=0; i<_chat.length; i++) {
        _this.deviceChatHistory.push( _chat[i] );
      }
    }
  },
  beforeUnmount() {
    window.removeEventListener("resize", function(){});
  },
  methods: {
    doLayout(div) {
      setTimeout(() => {
        this.chatHeight = (div.offsetHeight-120-55-70+15).toString()+'px'; //120 = height of chat header + other margins/paddings, 55 = height of models buttons, 70 = hardcoded height for chat input field row, 15 = additional hardcoded height
        this.gridHeight = (div.offsetHeight-120-55-70+15+170).toString()+'px';
        this.chatOffcanvasStyle = 'overflow-y: scroll; margin-top:20px; padding-left:15px; padding-right:5px; height:'+this.chatHeight+' !important;';
        
        const chatHistoryDiv = document.getElementById('div-saved-history');
        if(chatHistoryDiv && chatHistoryDiv.offsetWidth) {
          const chatHistoryWidth = chatHistoryDiv.offsetWidth * 0.68; //i decided that 68% is the correct ratio :)
          this.maxLengthChatName = chatHistoryWidth.toString()+'px';
        }
        else
          this.maxLengthChatName = '350px';
      }, 300);
    },
    addChatSystemPrompt(item) {
      this.chatHistoryNew();

      setTimeout(() => {
        this.promptFldValue = item.prompt;
        this.state.model = item.model;
        this.changeModelSchema();

        setTimeout(() => {
          this.callChat();
        }, 100);
      }, 100);
    },
    callChat() {
      let _val = this.promptFldValue,
          _this = this;

      if(!_val || _val=='') {
        _app.$helper.notifyWarning('No prompt provided');
        return false;
      }
      else {
        const prompt1 = _this.sysPrompts[0].prompt,
              prompt2 = _this.sysPrompts[1].prompt,
              prompt3 = _this.sysPrompts[2].prompt;

        let _promptTxt = (_this.deviceChat.length==0) ? prompt1 + _val + prompt2 + _this.modelDbSchema + prompt3 : _val;

        _this.deviceChat.push({"model":_this.state.model, "user":_this.user.username, "role":"user", "str":_val, "content":_promptTxt, edit:false});
        
        if(_this.currentChatName==null)
          _this.currentChatName = "New conversation "+_this.generateRandomLetters(6);
        
        _this.saveChatLocally(_this.currentChatName, _this.deviceChat);

        const progress = _this.$progress.start();
        _this.chatIsLoading = true;
        _this.placeholderTable = true;
        
        //console.log(JSON.stringify(_this.deviceChat));
        _this.$app.$api.post('Reporting/ProcessPrompt', {"PromptJson":JSON.stringify(_this.deviceChat)}).then(result => {
          progress.finish();

          let res = result.data

          if(res.indexOf('SELECT ')>=0) {
            _this.clearGrid();
            _this.updateTable(res, _this.state.model, _this.currentChatName, _this.deviceChat);
          }
          else {
            _this.chatIsLoading = false;
            _this.placeholderTable = false;
            _this.deviceChat.push({"model":_this.state.model, "user":"assistant", "role":"assistant", "str":res, "content":res});
            _this.saveChatLocally(_this.currentChatName, _this.deviceChat);
            _this.promptFldValue = '';
          }
        }, (error) => {
            progress.finish();
            _this.chatIsLoading = false;
            _this.placeholderTable = false;
            let updRes = error.message;
            _this.deviceChat.push({"model":_this.state.model, "user":"assistant", "role":"assistant", "str":updRes, "content":updRes});
            _this.saveChatLocally(_this.currentChatName, _this.deviceChat);
            _this.promptFldValue = '';
        });
      }
    },
    saveChatLocally(name, chat) {
      let _this = this,
          _update = false;
      
      for(let i=0; i<_this.deviceChatHistory.length; i++) {
        if( _update==false && _this.deviceChatHistory[i].name==name ) {
          _update = true;
          _this.deviceChatHistory[i].chat = chat;
        }
      }

      if(!_update)
        _this.deviceChatHistory.unshift( {"model":_this.state.model, "name":name, "chat":chat} );

      _this.updateLocalStorage();
    },
    chatHistoryNew() {
      this.promptFldValue = '';
      this.deviceChat = [];
      this.currentChatName = null;
      this.gridData.columns = [];
      this.gridData.rows = [];
      this.allRows = [];
      this.currentPage = 0;
      this.showBackButton = false;
      this.showNextButton = false;
      this.pagesInfo = '';
      this.tableDone = false;
      this.placeholderTable = false;
      document.getElementById('bzChatField').focus();
    },
    chatHistoryLoad(chat) {
      if(chat.name!==this.currentChatName) {
        this.chatHistoryNew();
        setTimeout(() => {
          this.deviceChat = chat.chat;
          this.currentChatName = chat.name;
          this.state.model = chat.model;
        }, 100);
      }
    },
    chatHistoryEdit(chat) {
      chat.edit = true;
      setTimeout(() => {
        document.getElementById(this.removeSpaces(chat.name)).focus();
      }, 100);
    },
    updateLocalStorage() {
      localStorage.removeItem(this.storageItem);

      let str = '['
      for(let i=0; i<this.deviceChatHistory.length; i++) {
        str += (i==0) ? '' : ',';
        str += `{"model":"${this.deviceChatHistory[i].model}", "name":"${this.deviceChatHistory[i].name}", "chat":${JSON.stringify(this.deviceChatHistory[i].chat)}}`;
      }
      str += ']';
      
      localStorage.setItem(this.storageItem, str);
    },
    updateChatHistoryName(chat) {
      let _this = this,
          _changeCurrentChatName = (_this.currentChatName==chat.name) ? true : false,
          _newValue = document.getElementById(_this.removeSpaces(chat.name)).value;
      for(let i=0; i<_this.deviceChatHistory.length; i++) {
        if( _this.deviceChatHistory[i].name==chat.name ) {
          _this.deviceChatHistory[i].name = _newValue;
        }
      }
      if(_changeCurrentChatName)
        _this.currentChatName = _newValue;
      chat.edit = false;

      _this.updateLocalStorage();
    },
    chatHistoryDelete(chat) {
      let _this = this;
      //_app.$modal.confirm('Delete conversation', `Are you sure you want to delete "${chat.name}"?`, (confirmed) => {
      //  if (!confirmed) 
      //      return;

          _this.deviceChatHistory = _this.deviceChatHistory.filter(obj => {
                                        return obj.name!==chat.name;
                                    });

          if(chat.name==_this.currentChatName || _this.deviceChatHistory.length==0)
            _this.chatHistoryNew();
      //});

      _this.updateLocalStorage();
    },
    generateRandomLetters(len) {
      const availableLetters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';
      let randomString = '';

      for (let i=0; i<len; i++) {
        const randomIndex = Math.floor(Math.random() * availableLetters.length);
        randomString += availableLetters[randomIndex];
      }

      return randomString;
    },
    /*shortenName(str, len) {
      return (str.length<=len) ? str : str.substring(0, len)+'...';
    },*/
    removeSpaces(str) {
      return str.replace(/\s/g, '');
    },
    changeModelSchema() {
      switch(this.state.model) {
        case 'alerts':
          this.modelDbSchema = `
              CREATE TABLE v_dwh_fact_alerts (
                ID bigint(20), -- id
                DEVICE_ID int(11), -- device id
                CONDITION_ID int(11), -- condition id
                CONDITION_TEXT text, -- condition txt
                STATUS_NAME varchar(250), -- status name
                STATUS_CODE varchar(50), -- status code
                CLOSE_RESOLUTION varchar(250), -- close resolution
                CLOSE_COMMENT text, -- close comment
                CONDITION_STATE_NAME varchar(250), -- condition state name
                CONDITION_STATE_CODE varchar(50), -- condition state code
                FACT_ID bigint(20), -- agg. id
                FACT_TABLE varchar(100), -- agg. table
                METRIC_VALUE_DEC decimal(18,6), -- metric value num
                METRIC_VALUE_TEXT varchar(512), --  metric value txt
                CURR_METRIC_VALUE_DEC decimal(18,6), -- current metric value num
                CURR_METRIC_VALUE_TEXT varchar(512), -- current metric value txt
                CREATED datetime, -- created ts
                UPDATED datetime, -- update ts
                IOT_DEVICE_ID varchar(200), -- device iot
                DEVICE_NAME varchar(250), -- device name
                DEVICE_SERIAL varchar(250), -- device serial
                LONGITUDE decimal(12,6), -- longitude
                LATITUDE decimal(12,6), -- latitude
                ADDRESS varchar(200), -- address
                CUSTOMER_NAME varchar(500), -- customer
                CUSTOMER_COUNTRY_ISO2 varchar(3), -- customer country abbr
                CUSTOMER_COUNTRY_NAME varchar(250), -- customer country
                DEVICE_STATUS_NAME varchar(250), -- device status
                CONNECTED_STATUS_NAME varchar(250), -- connected status
                MODEL_NAME text, -- model
                CREATED_DATE date, -- created
                UPDATED_DATE date -- updated
              );
            `;
          break;
        case 'rawData':
          this.modelDbSchema = "";
          break;
        case 'analytics':
          this.modelDbSchema = "";
          break;
        default:
          break;
      }

      document.getElementById('bzChatField').focus();
    },
    changePage() {
        if(this.currentPage>0) {
            const _allRows = this.allRows.length,
                _page = this.currentPage,
                _addPage = ((_allRows % PAGE_SIZE)==0) ? 0 : 1,
                _allPages = parseInt(_allRows/PAGE_SIZE)+_addPage,
                _recStart = ((_page-1)*PAGE_SIZE)+1,
                _recEnd = ((_page*PAGE_SIZE)>_allRows) ? _allRows : (_page*PAGE_SIZE);
            
            this.showBackButton = (_page==1) ? false : true;
            this.showNextButton = (_page==_allPages) ? false : true;

            let i = 0;
            this.gridData.rows = this.allRows.filter(obj => {
                                        i++;
                                        return (i>=_recStart && i<=_recEnd);
                                    });
    
            this.pagesInfo = `${_page}/${_allPages} pages, records ${_recStart}-${_recEnd}, total records ${_allRows}`;
        }
        else {
            this.showBackButton = false;
            this.showNextButton = false;
            this.pagesInfo = '';
        }
    },
    clearGrid() {
        this.gridData.columns = [];
        this.gridData.rows = [];
        this.currentPage = 0;
    },
    updateTable(query, model, currentChatName, deviceChat) {
      let _this = this,
          updRes = query;
      
      const progress = _this.$progress.start();

      _app.$api.get('Reporting/FetchReportData', { params: {query:query.replace(/\n/g, " ") } }).then(
          result => {
              progress.finish();
              _this.chatIsLoading = false;

              const _res = result.data;
              
              if(_res.length>0) {
                if(_res[0].denied)
                  updRes = _res[0].denied;
                else {
                  updRes = 'SQL query provided and table displayed';

                  _this.allRows = _res;

                  for (const [key, value] of Object.entries(_res[0])) {
                    _this.gridData.columns.push({title:key, dataIndex:key});
                  }

                  _this.currentPage = 1;
                  _this.placeholderTable = false;
                  _this.tableDone = true;
                }
              }
              else {
                _this.placeholderTable = false;
                updRes = 'Your query returned no data.';
              }

              _this.deviceChat.push({"model":model, "user":"assistant", "role":"assistant", "str":updRes, "content":query});
              _this.saveChatLocally(currentChatName, deviceChat);
              _this.promptFldValue = '';
          },
          error => {
              progress.finish();
              _this.chatIsLoading = false;
              _this.placeholderTable = false;
              updRes = error.message;
              _this.deviceChat.push({"model":model, "user":"assistant", "role":"assistant", "str":updRes, "content":query});
              _this.saveChatLocally(currentChatName, deviceChat);
              _this.promptFldValue = '';
          }
      );
    },
    displayOption(type) {
      console.log(`todo display ${type}`);
    },
    shareChat(chat, cols, rows, separator=';') {
      console.log('share chat:', chat);
    },
    exportPng(canvasEl) {
        if(this.allRows.length==0)
            _app.$helper.notifyInfo('No data to export');
        else {
            const progress = this.$progress.start();
            try {
                let link = document.createElement("a");
                    link.download = 'chat_export_' + new Date().toLocaleDateString() + '.png';
                domtoimage.toBlob(document.getElementById(canvasEl)).then(function (blob) { //https://github.com/1904labs/dom-to-image-more
                    progress.finish();
                    link.href = URL.createObjectURL(blob);
                    link.click();
                });

            } catch(err) {
                _app.$helper.notifyError('Error exporting PNG => '+err.message+'. Please, try again.');
                progress.finish();
                _app.handleError(err);
            }
        }
    },
    exportCsv(cols, rows, separator=';') {
        if(cols.length==0) {
            _app.$helper.notifyInfo('Columns definition not available');
            return false;
        }

        if(rows.length==0) {
            _app.$helper.notifyInfo('No data available to export');
            return false;
        }
        
        let col = [],
            csv = [];

        for (let i=0; i < cols.length; i++) {
            let val = cols[i].title.replace(/(\r\n|\n|\r)/gm, '').replace(/(\s\s)/gm, ' ')
            val = val.replace(/"/g, '""');
            col.push('"' + val + '"');
        }
        csv.push(col.join(separator));

        for (let i=0; i < rows.length; i++) {
            let row = [];
            for (const [key, value] of Object.entries(rows[i])) {
                let val = value.toString().replace(/(\r\n|\n|\r)/gm, '').replace(/(\s\s)/gm, ' ')
                val = val.replace(/"/g, '""');
                row.push('"' + val + '"');
            }
            csv.push(row.join(separator));
        }

        let csv_string = csv.join('\n'),
            filename = 'chat_export_' + new Date().toLocaleDateString() + '.csv',
            link = document.createElement('a');
        
        link.style.display = 'none';
        link.setAttribute('target', '_blank');
        link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv_string));
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    },
    backPage() {
        this.currentPage--;
    },
    nextPage() {
        this.currentPage++;
    },
    isOneBeforeLastItem(idx) {
      return idx === this.deviceChat.length - 2;
    },
    redoChat(idx) {
      this.promptFldValue=this.deviceChat[idx].str;
      this.removeChatItem(idx);
      this.callChat();
    },
    removeChatItem(idx) {
      if(idx==0)
        this.deviceChat = [];
      else {
        for(let i=(this.deviceChat.length-1); i>0; i--) {
          if(i>=idx) {
            this.deviceChat.splice(i, 1);
          }
        }
      }
    }
  }
}
</script>

<style scoped>
.chat-full-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: v-bind(maxLengthChatName);
}
.ul-chat {
  list-style: none !important;
}
.btn-outline-transparent {
  border: 1px solid rgba(255,255,255,0.3);
  border-radius: 5px;
  margin-bottom: 10px;
  color: rgba(255,255,255,0.6);
  width: 100%;
}
.btn-outline-transparent-active {
  border: 1px solid rgba(255,206,25,0.5);
  border-radius: 5px;
  margin-bottom: 10px;
  color: rgba(255,255,255,0.6);
  width: 100%;
}
.btn-outline-transparent:hover {
  color: rgba(255,255,255,0.6);
}
.btn-outline-transparent-hover:hover {
  color: rgba(255,255,255,0.8);
}

.bzChatHistoryField {
  color:rgba(255,255,255,0.8);
  width:100%;
  border:none;
}
.bzChatHistoryField:focus {
  outline: none;
  border-color:#262626;
}

div.pages-info {
    display: inline-block !important;
    margin-left: 20px;
}

/*.chat-name-heading {
  padding-left:0px;
  color:#FFCE19;
  margin-bottom:10px;
}*/

.btn-check:checked+label.btn-outline-primary, label.btn-outline-primary:hover {
  color: #262626 !important;
}

.floating-div {
  position: absolute;
  top: -10px;
  left: -5px;
  max-width: 40px;
  height: auto;
  padding: 10px;
  z-index: 999;
  /*opacity: 0.8;*/
}
</style>