<template>
  <FormFieldGroup :fieldName="name" :label="label" :pbClass="this.pbClass">
    <div class="d-flex align-items-center col-sm-10 col-md-8 col-lg-6" :class="this.class" >
      <input 
        v-model.number="state" 
        type="range" 
        :min="min" 
        :max="max" 
        :step="step" 
        class="form-range" 
        @change="onChange" 
        style="height:40px;"
      >
      <BaseField 
        v-model.number="state" 
        :name="name"
        :label="label" 
        style="width:70px" 
        :placeholder="placeholder" 
        :min="min" 
        :max="max" 
        :step="step" 
        as="input"
        type="number" 
        class="form-control mx-4"
        @change="onChange" 
      />
    </div>
  </FormFieldGroup>
</template>

<script>

  
  import FormFieldGroup from '@/App/components/Form/FormFieldGroup';
  import BaseField from '@/App/components/Form/BaseField';

  export default {
    name: 'FormSliderField',
    inheritAttrs: false,
    emits:['change'],
    props: {
      name: String, 
      label: String, 
      class: String,
      pbClass: String,
      value: Number,
      placeholder: {
        type: String,
        default: '%'
      },
      min: { 
        type: Number, 
        default: 0 
      },
      max: { 
        type: Number, 
        default: 100 
      },
      step: { 
        type: Number, 
        default: 1 
      }
    },
    components: {
      BaseField,
      FormFieldGroup
    },
    data() {
      return {
        state: this.value
      }
    },
		watch:{
			value() {
				this.state = this.value
			}
		},
    methods: {
      onChange(e) {
        this.$emit('change', this.state, e);
        //this.$emit('change', parseInt(e.target.value), e);
      }
    },
  }

</script>